const REFRESH = "ページを更新して再度お試しください。";
const CONTACT_SUPPORT = "引き続き問題が発生する場合は、サポートチャットやご意見・ご要望からお問い合わせください。";

const ERRORS = {
  SUBSCRIPTION: {
    UPDATE: `エラーが発生しました。お手数ですが、${REFRESH}`
  },
  GEOLOCATION: {
    DENIED: "位置情報の取得ができませんでした。ご利用を続ける場合は、位置情報の利用許可が必要となります。",
    INTERNAL_ERROR: `現在地の取得中に問題が発生しました。 ${REFRESH}`,
    TIMEOUT: `位置情報の取得に時間がかかり過ぎました。 ${REFRESH}`,
    UNSUPPORTED: "アプリが位置情報のサービスに対応しておりません。お手数ですが、お使いのブラウザでopluswork.comにアクセスいただき再度ご利用をお試しください。",
  },
  TIMECARD: {
    CREATE: `出勤時間が必須です`,
    ACTION: `タイムカードの打刻ができませんでした。${REFRESH}`,
    DELETE: `タイムカードの削除ができませんでした。${REFRESH}`,
    IMPORT: `タイムカードのCSVインポートができませんでした。${CONTACT_SUPPORT}`,
  },
  TEMPLATE: {
    DELETE: `テンプレの削除ができませんでした。${REFRESH}`,
  },
  TOUR: {
    CREATE: `デモツアーの予約ができませんでした。${CONTACT_SUPPORT}`,
  },
  SLOT: {
    CREATE: `シフトの作成ができませんでした。${REFRESH}`,
    UPDATE: `シフトの更新ができませんでした。${REFRESH}`,
    SELECTED_ROLE_FULL: `必要人数の上限に達しているため、シフトの変更はできません。`,
    DELETE: `シフトの削除ができませんでした。${REFRESH}`,
    IMPORT: `シフトのインポートができませんでした。${CONTACT_SUPPORT}`,
    CREATE_FROM_TEMPLATE: `テンプレからシフトを作成することができませんでした。${REFRESH}`,
  },
  COMMENT: {
    CREATE: `コメントすることができませんでした。${REFRESH}`,
  },
  SECTION: {
    NOT_FOUND: `セクションを見つかりませんでした。 ${REFRESH}`,
    DELETE: `セクションを削除することができませんでした。${REFRESH}`,
  },
  USER: {
    DELETE: `ユーザーの削除ができませんでした。${REFRESH}`,
  },
  PATTERN: {
    CREATE: `パターンの作成ができませんでした。${REFRESH}`,
    UPDATE: `パターンの更新ができませんでした。${REFRESH}`,
    DELETE: `パターンの削除ができませんでした。${REFRESH}`,
  },
  PLAN: {
    ACTION: `計画管理を行うことができませんでした。 ${REFRESH}`,
  },
  ROLE: {
    CREATE: `業務の作成ができませんでした。${REFRESH}`,
    UPDATE: `業務の更新ができませんでした。${REFRESH}`,
    DELETE: `業務の削除ができませんでした。${REFRESH}`,
    IMPORT: `業務のCSVインポートができませんでした。${CONTACT_SUPPORT}`,
  },
  STAFF: {
    CREATE: `スタッフの登録ができませんでした。${REFRESH}`,
    UPDATE: `スタッフの更新ができませんでした。${REFRESH}`,
    DELETE: `スタッフの削除ができませんでした。${REFRESH}`,
    IMPORT: `スタッフのCSVインポートができませんでした。${CONTACT_SUPPORT}`,
    JOIN: "登録することができませんでした。引き続き問題が発生する場合は、お手数ですが管理者様にお尋ねいただくか再度URLをお確かめください。",
    IMPORT_SMART_HR: `SmartHRスタッフのインポートができませんでした。${CONTACT_SUPPORT}`,
    IMPORT_PATHOSLOGOS: `PathosLogosスタッフのインポートができませんでした。${CONTACT_SUPPORT}`,
  },
  INVITE: {
    CREATE: `スタッフ紹介することができませんでした。${REFRESH}`,
    DELETE: `スタッフ紹介の削除ができませんでした。${REFRESH}`,
  },
  PTOS: {
    USERSETTING: `設定変更により、有休日数が変更されますが、よろしいですか？`,
    NOPTOSAVAIL: '取得日数以上の有休を使用しています。',
    NOSETTING: '有休日数が設定されていません。設定についてご不明な点がございましたら、お気軽にお問い合わせください。',
    LISTOVERFILLING: '日数超過：',
    REQUEST_FAIL: '有休を申請できませんでした。'
  },
  LABOR: {
    UPDATE: "更新ができませんでした",
  },
  IAM: {
    ADD: "管理者を追加できませんでした。",
    ACTION: `拠点管理を行うことができませんでした。 ${REFRESH}`,
    IMPORT_SMART_HR: `SmartHR拠点のインポートができませんでした。${CONTACT_SUPPORT}`,
    IMPORT_PATHOSLOGOS:`PathosLogos拠点のインポートができませんでした。${CONTACT_SUPPORT}`,
  },
  PASSWORD: {
    INVALID_INPUT: "無効な入力です、必要な条件をご確認ください。",
    INCORRECT: "パスワードが間違っています。",
    NOT_MATCH: "パスワードが一致しません。",
  },
  FORBIDDEN: {
    // This message should remain sufficiently vague.
    SUBSCRIPTION_REQUIRED: "本登録が完了されているかご確認いただき再度お試しだくさい。",
    INCOMPLETE_REGISTRATION: "opluswork.com で登録手続きを完了して再度お試しください。"
  },
  LOGIN: {
    GENERAL: "ログインに失敗しました。"
  },
  SKILL: {
    CREATE: `スキルの作成ができませんでした。${REFRESH}`,
    UPDATE: `スキルの更新ができませんでした。${REFRESH}`,
    DELETE: `スキルの削除ができませんでした。${REFRESH}`,
    EMPTY: `スキルを入力してください。`,
  },
  SMARTHR: {
    INVALID_TOKEN: `アクセストークンが無効または期限切れです。再度SmartHRと連携してください。`,
  },
}

export default ERRORS;
