import { createApp } from 'vue'
import { MODAL_ID } from './enums/modal_types.js'
import { store as roleStore } from '../js/stores/role_store'
import { store as tabooPatternStore } from '../js/stores/taboo_pattern_store'
import { store as tabooPatternModalStore } from '../js/stores/taboo_pattern_modal_store';
import TabooPatternsApp from '../vue/TabooPatternsApp.vue'
import { mountRolesListModal, mountPatternModal } from './modal'
import { getTabooPatternsRequest } from '../js/pattern_requests.js'

export async function initPage(props) {
  toggleTabooCreateBtn(props.rolesData);
  roleStore.init(props.rolesData);
  try {
    const response = await getTabooPatternsRequest();
    tabooPatternStore.init(response.tabooPatterns);
    mountTabooPatternContent(props)
    mountRolesListModal();
    mountPatternModal();
    hideDeleteOnClick();
    initCreateButtonEvent();
  } catch (err) {
    alertModal.alert(err);
    return;
  }
}

function mountComponent(component, mountPoint, props) {
  const app = createApp(component, props);
  app.mount(mountPoint);
}

function mountTabooPatternContent(props) {
  mountComponent(TabooPatternsApp, "#taboo-patterns-app", {
    userId: props.userId,
    branchId: props.branchId,
  });
}


function initCreateButtonEvent() {
  $(MODAL_ID.ROLES_LIST_MODAL + "-btn .oplus-btn").on("click", () => {
    tabooPatternModalStore.resetState();
    window[MODAL_ID.TABOO_PATTERN_MODAL].openModal();
  })
}

function hideDeleteOnClick() {
  $(document).on("click", (e) => {
    $(".pattern-delete-btn").removeClass("is-active")
  })
}

export function toggleTabooCreateBtn(rolesArray) {
  const hasRoles = rolesArray.length > 0;

  if (hasRoles) {
    $("#roles-list-modal-btn .oplus-btn").removeClass("disabled-btn");
  } else {
    $("#roles-list-modal-btn .oplus-btn").addClass("disabled-btn");
  }
}

export function togglePatternAccordion(tabooPatternId) {
  $("#taboo-pattern-" + tabooPatternId).attr("checked", true)
}
