import { reactive } from "vue";

export const store = {
  setIsRoleEditModalState: function(state) {
    this.data.isEditRolePattern = state
  },
  setRoleId: function(roleId) {
    this.data.selectedRoleId = roleId;
  },
  setTabooPatternId: function (tabooPatternId) {
    this.data.selectedTabooId = tabooPatternId;
  },
  setMainRoleId(mainRoleID = 0) {
    this.data.selectedRoleId = mainRoleID;
  },
  resetState: function() {
    this.data.isEditRolePattern = false;
    this.data.selectedRoleId = 0;
    this.data.selectedTabooId = 0;
  },
  data: reactive({
    isEditRolePattern: false,
    selectedRoleId: 0,
    selectedTabooId: 0,
  })
}
