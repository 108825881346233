export const DELETE_REPEAT_INPUT_VALUE = Object.freeze({
  1: "include",
  2: "exclude"
})

export const DELETE_SLOT_PARAM_STR = Object.freeze({
  DELETE_REPEAT: "delete-repeat",
})
export const SLOT_SUBMIT_BUTTON_ID = Object.freeze({
  SUBMIT: "#slot-submit",
  EXTRACT: "#slot-extract",
  DELETE: "#slot-modal-delete",
  DELETE_REPEAT: "#delete-repeat-btn",
  SUBMIT_COMMENT_EXTRACT: "#slot-modal-extract-comment-submit",
})

// As of June 2024, used as override for dash base modal functions on summary page
export function handleCreateSuccess(response) {
  if (!response.success) {
    alertModal.alert(errors.SLOT.CREATE);
    return;
  }

  if (response.refresh) {
    Refresh();
    return;
  }


  if (response.sections && response.sections.length > 0) {
    response.sections.forEach(section => {
      SlotMap[section.ID] = section;
    })
  }

  if (response?.extractedSlot?.ID) {
    SlotModal.onDelete(response.extractedSlot.ID, null, response.extractedSlot.date);
  }

  if (response?.addList && response.addList.length > 0) {
    for (let i = 0; i < response.addList.length; i++) {
      const slot = response.addList[i];
      //Create a clone of slot before the edit for dws stats
      const slotBeforeEdit = structuredClone(SlotMap[slot.ID]);
      SlotMap[slot.ID] = slot;
      SlotModal.onSubmit(slot, true, slotBeforeEdit);
      dashboard.reloadOnCondition(slot);
    }
    // Stats requires special handling for linked slots.
    if (response.addList[0]?.LinkNext || response.addList[0]?.LinkPrev) {
      SlotModal.handleStatsLinked(response.addList);
    } else {
      response.addList.forEach(s => {
        SlotModal.handleStats(s);
      })
    }

    if (typeof resizeFixedHeader === "function") {
      resizeFixedHeader();
    }
    return;
  } else if (response?.slot) {
    const slot = response.slot;
    SlotMap[slot.ID] = slot;
    SlotModal.onSubmit(slot, true);
    SlotModal.handleStats(slot);
    dashboard.reloadOnCondition(slot);
    if (typeof resizeFixedHeader === "function") {
      resizeFixedHeader();
    }
  }
}

export function handleTemplateCreateSuccess(response) {
  if (response.sections.length > 0) {
    response.sections.forEach(section => {
      SlotMap[section.ID] = section
    })
  }
  SlotModal.data["isTemplate"] = false;
  if (response.addList && response.addList.length > 0) {
    for (let i=0; i<response.addList.length; i++) {
      const slot = response.addList[i];
      SlotMap[slot.ID] = slot;
      SlotModal.onSubmit(slot, true);
    }
    // Stats requires special handling for linked slots.
    if (response.addList[0]?.LinkNext || response.addList[0]?.LinkPrev) {
      SlotModal.handleStatsLinked(response.addList);
    } else {
      response.addList.forEach(s => {
        SlotModal.handleStats(s);
      })
    }
    return
  } else if (response.slot) {
    const slot = response.slot;
    SlotMap[slot.ID] = slot;
    SlotModal.handleStats(slot);
    SlotModal.onSubmit(slot, true);
  }
}

export function handleUpdateSuccess(response, isRenderedByVue) {
  if (!response.success || (!response?.slot && !response.addList)) {
    alertModal.alert(errors.SLOT.UPDATE);
    return;
  }
  // editing normal slots with requested settings on, existing slot will become the child and replace by new slot;
  let isSlotConvertedToRequest = false;
  // currently used for adding overnight slots
  let isNew = false;
  // in order to re-render the slot that became a requested slot
  // "day" list are forced to hard refresh, so no need to render it there
  if (response.slot?.RequestedSlot && !isRenderedByVue) {
    isSlotConvertedToRequest = true;
  }

  let slots = response?.slot ? [response.slot] : [];
  if (response.deleteList) {
    for (let i = 0; i < response.deleteList.length; i++) {
      SlotModal.onDelete(response.deleteList[i]);
    }
  }

  if (response.addList && response.addList.length > 0) {
    isNew = true;
    slots = response.addList;
    for (let i = 0; i < slots.length; ++i) {
      const slot = slots[i];
      if (!isRenderedByVue) {
        $(`[data-alert-slotid="${slot.ID}"]`).remove();
        $(`#slot-${slot.ID}`).parent(".slots-cont").remove();
      }
    }
  }
  for (let i = 0; i < slots.length; ++i) {
    const slot = slots[i];
    if (SlotMap[slot.ID] != null) {
      slot.Alert = SlotMap[slot.ID].Alert;
    }
    //Create a clone of slot before the edit for dws stats
    const slotBeforeEdit = structuredClone(SlotMap[slot.ID]);
    SlotMap[slot.ID] = slot;

    if (slot.RequestedSlotID && isRenderedByVue) {
      isSlotConvertedToRequest = true;
      delete SlotMap[slot.RequestedSlotID]
      // add requested slot to linked slot manually, can be removed if BE provides it
      if (!slot.RequestedSlot) {
        const linkSlotID = slot.LinkNext || slot.LinkPrev;
        const reqLinkSlotID = SlotMap[linkSlotID].RequestedSlot.LinkNext || SlotMap[linkSlotID].RequestedSlot.LinkPrev;
        slot.RequestedSlot = SlotMap[reqLinkSlotID];
        slot.RequestedSlot.ManagedSlotID = slot.RequestedSlotID;
      }
      SlotModal.onRequestedSlotToManagedPair(slot);
    }
    const slotIsAddedToSlotMapOrCellsMap = isSlotConvertedToRequest || isNew;
    SlotModal.onSubmit(slot, slotIsAddedToSlotMapOrCellsMap, slotBeforeEdit);
    if (slotBeforeEdit) {
      SlotModal.onSlotOvernightChange(slot, slotBeforeEdit)
    }
    SlotModal.handleStats(slot);
    dashboard.reloadOnCondition(slot);
    if (typeof resizeFixedHeader === "function") {
      resizeFixedHeader();
    }
  }

  if (response.sections && response.sections.length > 0) {
    response.sections.forEach(section => {
      SlotMap[section.ID] = section;
    })
  }

  // Stats requires special handling for linked slots.
  if (response.addList[0]?.LinkNext || response.addList[0]?.LinkPrev) {
    SlotModal.handleStatsLinked(response.addList);
  } else {
    response.addList.forEach(s => {
      SlotModal.handleStats(s); //Maybe redundant
    })
  }

}

export function handleDeleteSuccess(response) {
  $(SLOT_SUBMIT_BUTTON_ID.DELETE).removeClass("loading");
  $(SLOT_SUBMIT_BUTTON_ID.DELETE_REPEAT).removeClass("loading");
  if (!response.success) {
    alertModal.alert(errors.SLOT.DELETE);
  }

  if (response.deleteList && response.deleteList.length > 0) {
    for (let i = 0; i < response.deleteList.length; i++) {
      let slotID = response.deleteList[i];
      SlotModal.onDelete(slotID);
    }
  } else if (response.addList || response.slot) {
    if (response.addList) {
      response.addList.forEach(slot => {
        addSlotToMapAndDomController(slot, false);
      });
    } else if (response.slot) {
      addSlotToMapAndDomController(response.slot, false);
    }
    alertModal.alert('こちらのシフト情報は最新のものではありません。最新の情報に更新します。');
  }

  SlotModal.close();
  showDashModal(false, DELETE_SLOT_PARAM_STR.DELETE_REPEAT);

  if (SlotModal?.slot?.Repeat || SlotModal?.slot?.SectionParent) {
    Refresh();
  }
}
