import { SLOT_TYPES } from "./models";
import { isNonWorkingSlotType } from "./slot_helpers";

// Helper function to compare current and previous slots if their Types of non-working and has changed or not, returns an integer;
export function calcDiffCurrentAndPrevSlots(currentSlot, previousSlot) {
  const isCurrentNonWorkingType = isNonWorkingSlotType(currentSlot);
  const isPrevNonWorkingType = isNonWorkingSlotType(previousSlot);

  if ((isCurrentNonWorkingType || isPrevNonWorkingType) && isCurrentNonWorkingType !== isPrevNonWorkingType) {
    if (isCurrentNonWorkingType) {
      return 1;
    }
    if (isPrevNonWorkingType) {
      return -1;
    }
  }
  return 0;
}

export function isHalfPTO(type) {
  if (type == SLOT_TYPES.HALF_PTO || type == SLOT_TYPES.KOT_HALF_PTO_AM || type == SLOT_TYPES.KOT_HALF_PTO_PM) {
    return true;
  }
  return false;
}
