<script>
import { roleBackgroundColor } from '../js/role_helpers'
import DeleteIcon from './icons/DeleteIcon.vue'
import EditIcon from './icons/EditIcon.vue'
import SortIcon from './icons/SortIcon.vue'
import KebabMenuIcon from './icons/KebabMenuIcon.vue'
import PatternsArrowIcon from './icons/PatternsArrowIcon.vue';
import PatternsTwinArrowIcon from './icons/PatternsTwinArrowIcon.vue';
import { store as roleStore } from '../js/stores/role_store';
import { store as tabooPatternStore } from '../js/stores/taboo_pattern_store';
import { store as tabooPatternModalStore } from '../js/stores/taboo_pattern_modal_store';
import { store as appStore } from '../js/stores/app_store';
import { deleteRolePatternRequest } from '../js/pattern_requests';
import { MODAL_ID } from '../js/modal';
import { LOADING_TYPES } from '../js/enums/loading_types';

  export default {
    props: {
      tabooPatternId: Number,
      addBorderTop: Boolean,
    },
    emits: ['pattern-delete'],
    data() {
      return {
        isDeleteIconVisible: false,
        isAccordionOpen: true,
        isPopupOpen: false,
      }
    },
    methods: {
      onEditPattern() {
        tabooPatternModalStore.setTabooPatternId(this.tabooPatternId);
        // We only show the main role of the pattern
        tabooPatternModalStore.setMainRoleId(this.mainRoleId);
        tabooPatternModalStore.setIsRoleEditModalState(true);
        window[MODAL_ID.TABOO_PATTERN_MODAL].openModal();
        this.isPopupOpen = false;
      },
      onAddRoleToPattern() {
        tabooPatternModalStore.setTabooPatternId(this.tabooPatternId);
        tabooPatternModalStore.setMainRoleId();
        tabooPatternModalStore.setIsRoleEditModalState(false);
        window[MODAL_ID.ROLES_LIST_MODAL].openModal();
      },
      onDeleteRoleFromPattern(deleteRoleId, deleteRoleIndex = -1) {
        if (this.isLoading) {
          return;
        }
        alertModal.confirm("本当に消去しますか？", () => {
          return this.deleteRoleFromPattern(deleteRoleId, deleteRoleIndex)
        });
      },
      async deleteRoleFromPattern(deletedRoleId = 0, deleteRoleIndex) {
        appStore.setLoading(LOADING_TYPES.PATTERN_ROLE_TEMPLATE, true)
        try {
          const response = await deleteRolePatternRequest(this.tabooPatternId, deletedRoleId, deleteRoleIndex);
          tabooPatternStore.deleteRoleFromPattern(response.tabooPatternId, deletedRoleId, deleteRoleIndex);
        } catch (err) {
          alertModal.alert("Unable to Delete Role");
        }
        appStore.setLoading(LOADING_TYPES.PATTERN_ROLE_TEMPLATE, false)
      },
      onPatternDelete() {
        this.isPopupOpen = false;
        alertModal.confirm("本当に消去しますか？", () => {
          return this.$emit("pattern-delete", this.tabooPatternId);
        });
      },
      roleBgColor(roleID) {
        const role = this.rolesMap?.[roleID];
        return roleBackgroundColor(role);
      },
      togglePopup() {
        this.isPopupOpen = !this.isPopupOpen;
      },
    },
    computed: {
      tabooPattern() {
        return tabooPatternStore.data.tabooPatternsMap[this.tabooPatternId];
      },
      mainRoleId() {
        return this.tabooPattern.patternData.MainRoleID;
      },
      rolesMap() {
        return roleStore.data.rolesMap;
      },
      patternRoleIds() {
        // Remove 1st element since its the Main Role
        return this.tabooPattern.patternData.RoleIDs.slice(1);
      },
      isFullPattern() {
        return this.tabooPattern.patternData.IsFullPattern;
      },
      showPatterns() {
        return this.patternRoleIds.length && this.isAccordionOpen;
      },
      borderTopClass() {
        return this.addBorderTop ? "border-top" : "";
      },
      isLoading() {
        return appStore.data.loading[LOADING_TYPES.PATTERN_ROLE_TEMPLATE];
      },
      loadingClass() {
        return this.isLoading ? "loading" : "";
      },
    },
    components: {
      DeleteIcon,
      EditIcon,
      KebabMenuIcon,
      SortIcon,
      PatternsArrowIcon,
      PatternsTwinArrowIcon,
    },
  }
</script>
<template>
  <tr>
    <td class="sort-cell" :class="borderTopClass">
      <SortIcon class="handle c-hand"/>
    </td>
    <td class="relative accordion taboo-pattern-header" :class="borderTopClass" style="max-width: 100%;">
      <input type="checkbox" :id="'taboo-pattern-' + tabooPattern.patternData.ID" name="taboo-patterns-checkbox" v-model="isAccordionOpen" hidden>
      <label class="accordion-header" :for="'taboo-pattern-' + tabooPattern.patternData.ID">
        <i class="icon icon-arrow-right mr-1"></i>
        <h5 class="pattern-name">{{ tabooPattern.patternData.Name }}&nbsp;</h5>
        <span>({{ isFullPattern ? '連日' : 'ORまとめ' }})</span>
      </label>

      <div ref="patternPopupContainer" class="d-flex">
        <div class="relative svg-container oplus-icon" @click.stop="togglePopup">
          <KebabMenuIcon/>
        </div>

        <!-- Popup Menu -->
        <div v-if="isPopupOpen" class="pattern-popup" @click.stop>
          <div class="oplus-icon popup-icon-container" @click="onEditPattern">
            <EditIcon/>
          </div>

          <div class="oplus-icon popup-icon-container" @click="onPatternDelete">
            <DeleteIcon/>
          </div>
        </div>
      </div>
    </td>
  </tr>

  <tr v-if="isAccordionOpen">
    <td class="sort-cell"></td>
    <td class="pattern-role-row">
      <div v-if="rolesMap?.[mainRoleId]" class="timeline-content role-pattern-container">
        <span class="chip role-pattern-chip" :style="roleBgColor(mainRoleId)">
          <span class="text-ellipsis">{{ rolesMap[mainRoleId].Name }}</span>
        </span>
        <span> が設置された時</span>
      </div>
    </td>
  </tr>

  <tr v-if="showPatterns && !isFullPattern" v-for="roleID, i in patternRoleIds" :key="roleID">
    <td class="sort-cell"></td>
    <td class="pattern-role-row">
      <div class="role-pattern-container">
        <span v-if="i === 0" class="flex-gap align-center">
          <PatternsArrowIcon/>
          次の日に 
        </span>
        <span v-else class="flex-gap align-center">
          <PatternsTwinArrowIcon class="mr-16"/>
          または 
        </span>
        <span class="chip role-pattern-chip" :style="roleBgColor(roleID)">
          <span class="text-ellipsis">{{ rolesMap[roleID].Name }}</span>
        </span>
        <span>を割り振らない</span>
        <i class="zmdi zmdi-close c-hand pattern-role-delete-icon" @click="onDeleteRoleFromPattern(roleID)"></i>
      </div>
    </td>
  </tr>

  <!-- New NG Pattern setup -->
  <tr v-else-if="showPatterns" v-for="roleID, i in patternRoleIds"  :key="`${roleID}-${i}`">
    <td class="sort-cell"></td>
    <td class="pattern-role-row">
      <div class="role-pattern-container">
        <span class="flex-gap align-center">
          <PatternsArrowIcon/>
          次の日に
        </span>
        <span class="chip role-pattern-chip" :style="roleBgColor(roleID)">
          <span class="text-ellipsis">{{ rolesMap[roleID].Name }}</span>
        </span>
        <span>を割り振らない</span>
        <i class="zmdi zmdi-close c-hand pattern-role-delete-icon" @click="onDeleteRoleFromPattern(roleID, i + 1)"></i><!-- we add 1 here because 0 index is reserved index for main-role  -->
      </div>
    </td>
  </tr>

  <tr v-if="isAccordionOpen">
    <td class="sort-cell"></td>
    <td class="text-left">
      <div class="c-hand oplus-add-icon" @click="onAddRoleToPattern">
        <i class="icon icon-plus"></i>
      </div>
    </td>
  </tr>
</template>

<style scoped>
  .role-pattern-chip {
    width: 150px;
    min-width: 100px;
    justify-content: center;
  }

  .role-pattern-container {
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .pattern-name {
    display: inline-block;
    margin-left: 4px;
    line-height: inherit;
  }
  .taboo-pattern-header {
    display:  flex;
    justify-content: space-between;
    max-width: 100%;
  }
  .pattern-role-delete-icon {
    padding-left: 8px;
  }
  .taboo-pattern-btn-wrapper{
    align-items: start;
  }

  .relative {
    position: relative;
  }

  .pattern-popup {
    display: flex;
    align-items: center;
    top: 0;
    right: 35px;
    position: absolute;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 0px #747474;
    height: 35px;
    width: 68px;
    z-index: 1;
  }

  .popup-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .sort-cell {
    width: 54px;
    vertical-align: bottom;
  }

  .oplus-add-icon {
    width: 22px;
    height: 22px;
    border-color: #52a3d5;
    color: #52a3d5;
    margin: 0;
  }

  .oplus-add-icon:hover {
    color: #fff;
    background-color: #52a3d5;
  }

  .icon-plus {
    height: 15px;
    width: 15px;
  }

  :deep(.delete-svg) {
    height: 25px;
  }

  :deep(.edit-svg) {
    height: 23px;
  }

  .mr-16 {
    margin-right: 0.8rem;
  }

  .pattern-role-row {
    max-width: none;
  }

  .border-top {
    border-top: 0.1rem solid #B1BABE;
  }
</style>
