"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenStream = void 0;
const token_1 = require("./token");
const tokenTypes_1 = require("./tokenTypes");
class TokenStream {
    current = new token_1.Token(tokenTypes_1.tokenTypes.END, tokenTypes_1.tokenTypes.END);
    stack = [];
    constructor(items) {
        for (let i = items.length - 1; i >= 0; i--) {
            const c = items[i];
            this.stack.push(c);
        }
    }
    get() {
        let c = this.stack.pop();
        if (!c) {
            c = new token_1.Token(tokenTypes_1.tokenTypes.END, tokenTypes_1.tokenTypes.END);
        }
        this.current = c;
        return this.current;
    }
    getCurrent() {
        return this.current;
    }
}
exports.TokenStream = TokenStream;
