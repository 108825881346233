<script>
import { OPLUS_COLOR_PALETTE } from '../js/models.js'

 export default {
  props: {
    isVisible: Boolean,
    selectedColor: String,
  },
  methods: {
    handleClickedColor(colorStr) {
      return this.$emit('color-select', colorStr);
    },
  },
  computed: {
    colorPalette() {
      return OPLUS_COLOR_PALETTE;
    },
    isDefaultColor() {
      return this.selectedColor === OPLUS_COLOR_PALETTE.DEFAULT;
    },
    defaultCheckColor() {
      return !this.isDefaultColor ? "white-color-font" : "";
    }
  },
  emits: [
    "color-select"
  ],
 }
</script>

<template>
  <div id="color-box-container" v-show="isVisible">
    <div class="color-palette-box-container">
      <div v-for="colorHexString, _ in colorPalette">
        <div @click="handleClickedColor(colorHexString)" class="color-palette-box" :style="{ backgroundColor: `#${colorHexString}` }">
          <i v-show="this.selectedColor === colorHexString" :class="defaultCheckColor" class="zmdi zmdi-check zmdi-hc-2x"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.color-palette-box-container {
  width: fit-content;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  background-color: #fff;
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 2px #bababa
}

.color-palette-box {
  width: 45px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-palette-box:hover,
.color-palette-box:focus {
  cursor: pointer;
  scale: 1.1;
  transition: .2s;
}
.white-color-font {
  color: #fff;
}
</style>
