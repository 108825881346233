<template>
    <div>
        <div class="svg-container emotion-rating">
            <svg version="1.1" class="emotion-rating-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 32 32" width="60px" height="60px" xml:space="preserve">
                <path class=""    d="M15.72,27.7c-6.43,0-11.67-5.23-11.67-11.67c0-6.43,5.23-11.67,11.67-11.67c6.43,0,11.67,5.23,11.67,11.67
                C27.39,22.46,22.15,27.7,15.72,27.7z M15.72,5.43c-5.85,0-10.6,4.76-10.6,10.6s4.76,10.6,10.6,10.6c5.85,0,10.6-4.76,10.6-10.6
                S21.57,5.43,15.72,5.43z M20.44,20.75c0-0.29-0.24-0.53-0.53-0.53h-8.37c-0.29,0-0.53,0.24-0.53,0.53s0.24,0.53,0.53,0.53h8.37
                C20.2,21.28,20.44,21.05,20.44,20.75z M12.17,12.4c-0.71,0-1.28,0.57-1.28,1.28s0.57,1.28,1.28,1.28s1.28-0.57,1.28-1.28
                S12.88,12.4,12.17,12.4z M19.28,12.4c-0.71,0-1.28,0.57-1.28,1.28s0.57,1.28,1.28,1.28c0.71,0,1.28-0.57,1.28-1.28
                S19.99,12.4,19.28,12.4z"/>
            </svg>
        </div>
        <span class="satisfaction-text">普通</span>
    </div>
</template>
