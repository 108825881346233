"use strict";
// As of api v2
Object.defineProperty(exports, "__esModule", { value: true });
const errorCodes = Object.freeze({
    SESSION_EXPIRED: "Session expired", // 401
    FORBIDDEN: "Forbidden", // 403
    NOT_FOUND: "Not found", // 404
    BAD_REQUEST: "Bad request", // 400
    SERVER_ERROR: "Server error", // 500
    GENERAL: "Unable to perform request", // Other request error
});
exports.default = errorCodes;
