// Modal types
export const MODAL_TYPES = Object.freeze({
  ROLES_LIST_MODAL: "roles-list-modal",
  PATTERN_MODAL: "pattern-modal",
  TABOO_PATTERN_MODAL: "taboo-pattern-modal",
  PASSWORD_MODAL: "password-modal",
  ROLE_RELATION_MODAL: "#role-relation-modal",
  TIMECARD_MODAL: "timecard-vue-modal",
  TIMEPICKER_MODAL: "timepicker-vue-modal",
  BRANCH_FILTER_MODAL: "branch-filter-modal",
  AUTO_MODAL: "auto-modal",
  TAG_ROLE_FILTER: "tag-role-filter-modal",
})

// Mount IDs
export const MODAL_ID = Object.freeze({
  ROLES_LIST_MODAL: "#" + MODAL_TYPES.ROLES_LIST_MODAL,
  PATTERN_MODAL: "#" + MODAL_TYPES.PATTERN_MODAL,
  TABOO_PATTERN_MODAL: "#" + MODAL_TYPES.TABOO_PATTERN_MODAL,
  PASSWORD_MODAL: "#" + MODAL_TYPES.PASSWORD_MODAL,
  TIMECARD_MODAL: "#" + MODAL_TYPES.TIMECARD_MODAL,
  TIMEPICKER_MODAL: "#" + MODAL_TYPES.TIMEPICKER_MODAL,
  BRANCH_FILTER_MODAL: "#" + MODAL_TYPES.BRANCH_FILTER_MODAL,
  AUTO_MODAL: "#" + MODAL_TYPES.AUTO_MODAL,
  TAG_ROLE_FILTER: "#" + MODAL_TYPES.TAG_ROLE_FILTER,
})

// Modal Names for Vue component, Used for assigning name for each modal vue component
export const MODAL_NAMES = Object.freeze({
  ROLES_LIST_MODAL: "RolesListModal",
  PATTERN_MODAL: "PatternModal",
  TABOO_PATTERN_MODAL: "TabooPatternModal",
})
