"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseFromRaw = void 0;
const employment_1 = require("../../model/v1/employment");
function parseFromRaw(r, activeBranchId) {
    // In the case of a help, there will be no company user id.
    // In this case, make a temporary FE only id for the employment placeholder.
    let feId = `FE-${r.ID.toString()}`;
    if (r.BranchUser?.ID) {
        feId = "";
    }
    // default value, we don't use it atm
    const required = {
        userId: r.ID,
        branchId: r.BranchUser?.BranchID || activeBranchId,
        id: r.BranchUser?.ID || 0
    };
    const options = {
        wage: r.BranchUser?.Wage || 0,
        transportCost: r.BranchUser?.Transport || 0,
        tags: r.BranchUser?.Tags,
        employeeId: r.BranchUser?.EmployeeID,
        memo: r.BranchUser?.Memo,
        permission: r.BranchUser?.Permission,
        monthlyLimit: r.BranchUser?.MonthlyLimit,
        feId: feId,
        branchName: r.BranchUser?.BranchName,
    };
    const employment = new employment_1.Employment(required, options);
    return employment;
}
exports.parseFromRaw = parseFromRaw;
