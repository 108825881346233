<script>
import { MODAL_NAMES } from '../js/modal.js';
import Modal from './Modal.vue';
import { validateEmail } from '../js/common.js'

export default {
  data() {
    return {
      isVisible: false,
      isLoading: false,
      email: "",
      companyID: 0,
    }
  },
  components: {
    Modal,
  },
  methods: {
    closeModal() {
      this.isVisible = false;
    },
    openModal(companyID = 0) {
      this.companyID = companyID;
      this.email = "";
      this.isVisible = true;
    },
    onConfirm() {
      this.submitAddGroupUser();
    },
    onCancel() {
      this.isVisible = false;
    },
    submitAddGroupUser() {
      if (this.isLoading || !validateEmail(this.email)) {
        return;
      }
      this.isLoading = true;
      if (this.companyID) {
        this.addBranchUser();
      } else {
        this.addGroupOwner();
      }
    },
    addGroupOwner() {
      $.post(DashURL("group/user"), { "email": this.email })
        .done((response) => {
          RefreshOnSuccess(response);
        })
        .fail((response) => {
          alertModal.alert(errors.IAM.ADD);
          this.isLoading = false;
          return;
        })
    },
    addBranchUser() {
      $.post(DashURL("multi/user"),
        {
          "comID": this.companyID,
          "email": this.email
        },
        function (response){
          RefreshOnSuccess(response);
        });
    }
  },
  computed: {
    loadingClass() {
      return this.isLoading ? "loading" : "";
    },
    modalName() {
      return MODAL_NAMES.ADD_GROUP_OWNER
    },
    computedModalTitle() {
      return this.companyID ? "スタッフを足す" : "オーナーを足す";
    }
  }
}
</script>

<template>
  <Modal :modal-name="modalName" v-if="isVisible" @closeModal="closeModal" @confirm="onConfirm" @cancel="onCancel" :has-title-divider="true" :has-footer-divider="true">
    <template v-slot:header-title>
      {{ computedModalTitle }}
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <div class="form-wrapper">
          <div class="svg-wrapper">
            <svg class="d-flex" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px"
              height="40px" viewBox="0 0 30.3 30.3" style="enable-background:new 0 0 30.3 30.3;" xml:space="preserve" fill="currentColor">
              <path fill="currentColor" d="M28.4,7.2c-0.2-0.4-0.5-0.7-0.9-0.9C27.2,6.1,26.8,6,26.4,6H4.3C3.9,6,3.5,6.1,3.2,6.3
                C2.8,6.5,2.5,6.9,2.3,7.2C2.1,7.5,2,7.9,2,8.3v14c0,0.3,0.1,0.6,0.2,0.9c0.2,0.4,0.4,0.7,0.8,1c0.4,0.3,0.8,0.4,1.3,0.4h22.1
                c0.5,0,1-0.1,1.3-0.4c0.4-0.2,0.6-0.6,0.8-1c0.1-0.3,0.2-0.6,0.2-0.9v-14C28.7,7.9,28.6,7.5,28.4,7.2z M4.3,7.2h22.1
                c0.1,0,0.1,0,0.2,0l-7.4,7l-0.9,0.8L17.4,16c-1.1,1-3,1-4,0l-0.9-0.8l-0.9-0.9l-7.4-7C4.2,7.2,4.3,7.2,4.3,7.2z M3.3,8.3
                c0,0,0-0.1,0-0.1l7.4,7l-7.4,7V8.3z M26.4,23.3H4.3c-0.1,0-0.3,0-0.4-0.1l7.6-7.2l0.9,0.9c0.8,0.7,1.8,1.1,2.9,1.1
                c1.1,0,2.1-0.4,2.9-1.1l0.9-0.9l7.6,7.2C26.6,23.3,26.5,23.3,26.4,23.3z M27.5,22.2l-7.4-7l7.4-7c0,0.1,0,0.1,0,0.2V22.2z"/>
            </svg>
          </div>
          <input v-model="email" name="email" type="email" class="email-form-input" placeholder="メールアドレス" pattern="[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$">
        </div>
      </div>
    </template>
    <template v-slot:footer-btn>
      <button class="oplus-modal-btn oplus-alt-confirm-btn" :class="loadingClass" @click="onConfirm">足す</button>
    </template>
  </Modal>
</template>

<style scoped>
  .header-wrapper {
    text-align: center;
    padding-bottom: 0;
  }
  .content-wrapper {
    display: flex;
    width: fit-content;
    text-align: center;
    gap: 10px;
    align-items: center;
    height: 100px;
    margin: auto;
  }

  .form-wrapper {
    display: flex;
    gap: 2px;
    border: .12rem solid #bbbdbf;
    border-radius: 5px;
    width: 320px;
  }

  .svg-wrapper {
    background-color: #bbbdbf;
    color: #fff;
    width: 50px;
  }
  .email-form-input {
    border: transparent;
    width: 100%;
    font-size: .8rem;
    padding-left: 5px;
  }

  input.email-form-input:invalid {
    outline: .1rem solid #ff6003;
    -moz-outline-radius: 5px;
  }
  input.email-form-input:focus,
  input.email-form-input:focus-visible {
    outline: #bbbdbf
  }
  input.email-form-input::placeholder {
    color: #a5a5a5;
    padding-left: 2px;
  }
</style>
