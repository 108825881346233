<template>
    <svg version="1.1" class="add-staff-btn-sub add-staff-icon-panel ml-0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 35 35" style="enable-background:new 0 0 35 35;" xml:space="preserve">
        <path fill="currentColor" d="M26.08,0.89H12.61C10.9,0.89,9.5,2.28,9.5,4v11.57h0.89V4c0-1.23,1-2.22,2.22-2.22H24.8v1.94
		c0,1.71,1.4,3.11,3.11,3.11h2.35v20.02c0,1.23-1,2.23-2.23,2.23h-5.36v0.89h5.36c1.71,0,3.11-1.4,3.12-3.12V5.54L26.08,0.89z
		M27.91,5.94c-1.22,0-2.22-1-2.22-2.22V1.78l4.57,4.16H27.91z M8.48,34.42H5.54c-1.38,0-2.5-1.12-2.5-2.5v-2.93
		c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v2.93c0,0.83,0.67,1.5,1.5,1.5h2.94c0.28,0,0.5,0.22,0.5,0.5
		C8.98,34.2,8.76,34.42,8.48,34.42z M17.54,34.42H14.6c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h2.94
		c0.83,0,1.5-0.67,1.5-1.5v-2.93c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5v2.93C20.04,33.3,18.92,34.42,17.54,34.42z M3.54,23.36
		c-0.28,0-0.5-0.22-0.5-0.5v-2.94c0-1.38,1.12-2.5,2.5-2.5h2.94c0.28,0,0.5,0.22,0.5,0.5c0,0.28-0.22,0.5-0.5,0.5H5.54
		c-0.83,0-1.5,0.67-1.5,1.5v2.94C4.04,23.14,3.82,23.36,3.54,23.36z M19.54,23.36c-0.28,0-0.5-0.22-0.5-0.5v-2.94
		c0-0.83-0.67-1.5-1.5-1.5H14.6c-0.28,0-0.5-0.22-0.5-0.5c0-0.28,0.22-0.5,0.5-0.5h2.94c1.38,0,2.5,1.12,2.5,2.5v2.94
		C20.04,23.14,19.82,23.36,19.54,23.36z M9.56,26.28c0,0.27-0.03,0.53-0.1,0.78c-0.07,0.25-0.17,0.47-0.31,0.66
		c-0.14,0.19-0.31,0.34-0.51,0.45c-0.21,0.11-0.45,0.17-0.72,0.17c-0.27,0-0.51-0.06-0.72-0.17c-0.2-0.11-0.37-0.27-0.51-0.45
		c-0.14-0.18-0.23-0.41-0.31-0.65c-0.07-0.24-0.1-0.51-0.1-0.77v-0.6c0-0.18,0.02-0.35,0.05-0.53c0.03-0.18,0.07-0.33,0.13-0.48
		c0.06-0.15,0.14-0.29,0.23-0.42c0.09-0.13,0.19-0.24,0.31-0.33c0.12-0.09,0.26-0.16,0.41-0.22s0.32-0.08,0.5-0.08
		c0.27,0,0.51,0.06,0.72,0.17c0.21,0.11,0.38,0.27,0.51,0.45c0.14,0.18,0.24,0.41,0.31,0.66c0.07,0.25,0.1,0.51,0.1,0.78V26.28z
		 M8.8,25.68c0-0.17-0.02-0.35-0.05-0.51s-0.08-0.32-0.14-0.45c-0.06-0.13-0.16-0.24-0.27-0.32c-0.11-0.08-0.26-0.12-0.43-0.12
		S7.6,24.32,7.48,24.4c-0.11,0.08-0.2,0.19-0.27,0.32S7.11,25,7.08,25.17s-0.04,0.34-0.04,0.51v0.6c0,0.17,0.02,0.35,0.04,0.51
		s0.07,0.32,0.14,0.46s0.15,0.24,0.27,0.33c0.11,0.09,0.25,0.13,0.43,0.13c0.17,0,0.31-0.04,0.43-0.13
		c0.11-0.09,0.21-0.19,0.27-0.33c0.06-0.14,0.12-0.29,0.14-0.46s0.04-0.34,0.05-0.51C8.8,26.27,8.8,25.68,8.8,25.68z M13.41,26.86
		c-0.02,0.22-0.07,0.42-0.16,0.6c-0.08,0.18-0.19,0.34-0.33,0.47c-0.14,0.13-0.3,0.23-0.49,0.3c-0.18,0.07-0.39,0.1-0.63,0.1
		c-0.28,0-0.52-0.06-0.73-0.16s-0.38-0.26-0.51-0.44c-0.14-0.18-0.24-0.4-0.31-0.64c-0.07-0.24-0.1-0.51-0.1-0.79v-0.63
		c0-0.28,0.04-0.54,0.11-0.79s0.18-0.47,0.31-0.65c0.14-0.18,0.31-0.33,0.52-0.44s0.45-0.16,0.72-0.16c0.24,0,0.46,0.03,0.64,0.1
		c0.18,0.07,0.35,0.17,0.48,0.31c0.14,0.13,0.24,0.29,0.32,0.47c0.08,0.18,0.13,0.39,0.15,0.62h-0.76
		c-0.02-0.14-0.04-0.26-0.08-0.37c-0.04-0.11-0.09-0.2-0.16-0.28c-0.07-0.08-0.14-0.14-0.25-0.18c-0.1-0.04-0.23-0.06-0.36-0.06
		c-0.17,0-0.31,0.04-0.43,0.11s-0.21,0.18-0.28,0.31c-0.07,0.13-0.12,0.28-0.15,0.45s-0.05,0.35-0.05,0.54v0.63
		c0,0.19,0.02,0.38,0.05,0.55c0.03,0.18,0.08,0.32,0.15,0.45c0.07,0.13,0.16,0.23,0.27,0.31c0.11,0.07,0.26,0.11,0.43,0.11
		c0.27,0,0.47-0.07,0.6-0.23c0.14-0.15,0.22-0.36,0.24-0.63L13.41,26.86L13.41,26.86z M15.7,26.49h-0.76v1.78h-0.76V23.7h1.45
		c0.23,0,0.45,0.03,0.65,0.09c0.2,0.06,0.37,0.14,0.51,0.26c0.14,0.11,0.26,0.26,0.34,0.43c0.08,0.17,0.12,0.37,0.12,0.6
		c0,0.15-0.02,0.3-0.06,0.43s-0.1,0.24-0.17,0.35c-0.07,0.1-0.16,0.19-0.27,0.27c-0.1,0.08-0.22,0.14-0.34,0.2l0.96,1.92v0.04h-0.8
		L15.7,26.49z M14.94,25.88h0.69c0.13,0,0.24-0.02,0.35-0.06c0.1-0.03,0.19-0.09,0.27-0.15c0.08-0.06,0.14-0.14,0.18-0.24
		s0.06-0.2,0.06-0.33s-0.02-0.24-0.06-0.34s-0.1-0.18-0.18-0.25c-0.07-0.06-0.17-0.12-0.27-0.15c-0.1-0.03-0.23-0.06-0.36-0.06
		h-0.69L14.94,25.88L14.94,25.88z M27.64,10.28H12.95c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h14.68c0.28,0,0.5,0.22,0.5,0.5
		S27.91,10.28,27.64,10.28z M27.64,12.9H12.95c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h14.68c0.28,0,0.5,0.22,0.5,0.5
		S27.91,12.9,27.64,12.9z M27.64,15.53H12.95c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h14.68c0.28,0,0.5,0.22,0.5,0.5
		S27.91,15.53,27.64,15.53z M27.64,18.16h-4.59c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h4.59c0.28,0,0.5,0.22,0.5,0.5
		S27.91,18.16,27.64,18.16z M27.64,20.78h-4.59c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h4.59c0.28,0,0.5,0.22,0.5,0.5
		S27.91,20.78,27.64,20.78z M27.64,23.41h-4.59c-0.28,0-0.5-0.22-0.5-0.5s0.22-0.5,0.5-0.5h4.59c0.28,0,0.5,0.22,0.5,0.5
		S27.91,23.41,27.64,23.41z"/>
    </svg>
</template>
