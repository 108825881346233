"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const japanesePublicHolidays = Object.freeze({
    "2023-01-01": "元日",
    "2023-01-02": "休日 元日",
    "2023-01-09": "成人の日",
    "2023-02-11": "建国記念の日",
    "2023-02-23": "天皇誕生日",
    "2023-03-21": "春分の日",
    "2023-04-29": "昭和の日",
    "2023-05-03": "憲法記念日",
    "2023-05-04": "みどりの日",
    "2023-05-05": "こどもの日",
    "2023-07-17": "海の日",
    "2023-08-11": "山の日",
    "2023-09-18": "敬老の日",
    "2023-09-23": "秋分の日",
    "2023-10-09": "スポーツの日",
    "2023-11-03": "文化の日",
    "2023-11-23": "勤労感謝の日",
    "2024-01-01": "元日",
    "2024-01-08": "成人の日",
    "2024-02-11": "建国記念の日",
    "2024-02-12": "建国記念の日 振替休日",
    "2024-02-23": "天皇誕生日",
    "2024-03-20": "春分の日",
    "2024-04-29": "昭和の日",
    "2024-05-03": "憲法記念日",
    "2024-05-04": "みどりの日",
    "2024-05-05": "こどもの日",
    "2024-05-06": "こどもの日 振替休日",
    "2024-07-15": "海の日",
    "2024-08-11": "山の日",
    "2024-08-12": "休日 山の日",
    "2024-09-16": "敬老の日",
    "2024-09-22": "秋分の日",
    "2024-09-23": "秋分の日 振替休日",
    "2024-10-14": "スポーツの日",
    "2024-11-03": "文化の日",
    "2024-11-04": "文化の日 振替休日",
    "2024-11-23": "勤労感謝の日",
    "2025-01-01": "元日",
    "2025-01-13": "成人の日",
    "2025-02-11": "建国記念の日",
    "2025-02-23": "天皇誕生日",
    "2025-02-24": "天皇誕生日 振替休日",
    "2025-03-20": "春分の日",
    "2025-04-29": "昭和の日",
    "2025-05-03": "憲法記念日",
    "2025-05-04": "みどりの日",
    "2025-05-05": "こどもの日",
    "2025-05-06": "みどりの日 振替休日",
    "2025-07-21": "海の日",
    "2025-08-11": "山の日",
    "2025-09-15": "敬老の日",
    "2025-09-23": "秋分の日",
    "2025-10-13": "スポーツの日",
    "2025-11-03": "文化の日",
    "2025-11-23": "勤労感謝の日",
    "2025-11-24": "勤労感謝の日 振替休日"
});
exports.default = japanesePublicHolidays;
