<script>
import Modal from './Modal.vue';

export default {
  props: {
    groupData: Object,
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      localGroupData: { ...this.groupData },
    }
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    async onSubmit() {
      if (this.isLoading) {
        return;
      }
      // TODO: Refactor, use updateGroup request function from request/group.ts file
      this.isLoading = true;
      const data = new URLSearchParams();
      data.append("name", this.localGroupData.name.trim());
      data.append("address", this.localGroupData.address.trim());

      if (this.localGroupData.hasKotPermission) {
        data.append("kot-access-token", this.localGroupData.kotAccessToken);
      }
      if (this.localGroupData.hasCcBizPreviewPermission) {
        data.append("ccbizmate-id", this.localGroupData.ccBizCompanyId);
        data.append("ccbizmate-api-password", this.localGroupData.ccBizAPIPassword);
      }
      if (this.localGroupData.hasSmartHRPermission) {
        data.append("smarthr-subdomain", this.localGroupData.smartHRSubdomain);
      }
      if (this.localGroupData.hasMinajinPermission) {
        data.append("minajin-company-code", this.localGroupData.minajinCompanyCode);
      }

      try {
        const response = await fetch(DashURL(`group/${this.localGroupData.id}`), {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: data.toString(),
        });

        const json = await response.json();
        if (!response.ok || !json.success) {
          alertModal.alert("送信に失敗しました、再度お試しください。");
        } else {
          window.location.reload();
        }

      } catch (error) {
        alertModal.alert("送信に失敗しました、再度お試しください。");
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    loadingClass() {
      return this.isLoading ? "loading" : "";
    }
  },
  components: {
    Modal,
  },
}
</script>

<template>
  <Modal :modal-name="modalName" v-if="isVisible" :has-title-divider="true" :has-footer-divider="true" @closeModal="closeModal" @confirm="onConfirm">
    <template v-slot:header-title>
      <span class="group-edit-header">会社編集</span>
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <form>
          <input class="form-input br-5 form-group" id="group-modal-name" type="text" placeholder="会社名" v-model="localGroupData.name">
          <input class="form-input br-5 form-group" id="group-modal-address" type="text" placeholder="住所" v-model="localGroupData.address">

          <div v-if="localGroupData.hasKotPermission" class="form-group">
            <input class="form-input br-5" id="group-modal-kot-access-token" type="text" placeholder="KOTアクセストークン" v-model="localGroupData.kotAccessToken">
          </div>

          <div v-if="localGroupData.hasCcBizPreviewPermission" class="form-group">
            <input class="form-input br-5" id="group-modal-ccbizmate-id" type="text" placeholder="CC·BizMate会社ID" v-model="localGroupData.ccBizCompanyId">
          </div>

          <div v-if="localGroupData.hasCcBizPreviewPermission" class="form-group">
            <input class="form-input br-5" id="group-modal-ccbizmate-password" type="text" placeholder="CC·BizMate API パスワード" v-model="localGroupData.ccBizAPIPassword">
          </div>

          <div v-if="localGroupData.hasSmartHRPermission" class="form-group">
            <input class="form-input br-5" id="group-modal-smarthr-subdomain" type="text" placeholder="SmartHRサブドメイン" v-model="localGroupData.smartHRSubdomain">
          </div>

          <div v-if="localGroupData.hasMinajinPermission" class="form-group">
            <input class="form-input br-5" id="group-modal-minajin-code" type="text" placeholder="ミナジン会社コード" v-model="localGroupData.minajinCompanyCode">
          </div>
        </form>
      </div>
    </template>
    <template v-slot:footer-btn>
      <div class="footer-btn-container">
        <button id="staff-import-btn" class="oplus-btn oplus-confirm-btn" :class="loadingClass" @click="onSubmit">編集</button>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
  .content-wrapper {
    width: 280px;
    padding: 1rem 0;
  }

  .oplus-btn {
    width: 100px;
    border-radius: 7px;
    background: #fff;
    height: 35px;
    cursor: pointer;
    border-width: 1px;
    text-decoration: none;
  }

  .oplus-btn:hover {
    color: #fff;
    border-width: 0.1px;
  }

  .oplus-confirm-btn {
    color: #52a3d5;
    border-color: #52a3d5;
  }

  .oplus-confirm-btn:hover {
    background: #52a3d5;
    border-color: #52a3d5;
  }

  .footer-btn-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .br-5 {
    border-radius: 5px;
  }

  .group-edit-header {
    color: #8D99A0;
  }
</style>
