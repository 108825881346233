"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanFunctionStore = void 0;
class PlanFunctionStore {
    data = {};
    constructor() {
    }
    add(item) {
        this.data[item.getId()] = item;
    }
    byId(id) {
        return this.data[id];
    }
    query(options) {
        let out = Object.values(this.data);
        if (options?.branchId) {
            out = out.filter(pf => pf.getBranchId() === options.branchId);
        }
        if (options?.variableName) {
            out = out.filter(pf => pf.getVariableName() === options.variableName);
        }
        return out;
    }
    delete(id) {
        delete this.data[id];
    }
}
exports.PlanFunctionStore = PlanFunctionStore;
