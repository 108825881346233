"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatsDiff = void 0;
class StatsDiff {
    numOfWorkers = 0;
    laborHours = 0;
    laborCost = 0;
    transportCost = 0;
    roleCounts = {};
    roleSectionCounts = {};
    orRoleCounts = {};
    offCounts = {};
    constructor() { }
}
exports.StatsDiff = StatsDiff;
