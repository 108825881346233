"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseFromRaw = void 0;
const roleSectionTemplate_1 = require("../../roleSectionTemplate/model/roleSectionTemplate");
const roleSectionTimeSlot_1 = require("../../roleSectionTemplate/model/roleSectionTimeSlot");
function parseFromRaw(raw) {
    let timeFrames = [];
    if (raw.Timeframes) {
        timeFrames = raw.Timeframes.map(t => {
            const start = new Date(t.StartTime);
            const end = new Date(t.EndTime);
            const reqParam = {
                roleSectionId: raw.SectionID,
                startTime: start,
                endTime: end,
                requiredCount: t.Headcount,
            };
            return new roleSectionTimeSlot_1.RoleSectionTimeSlot(reqParam);
        });
    }
    const roleSectTempReq = {
        id: raw.ID,
        roleSectionId: raw.SectionID,
    };
    let assignedDates = [];
    if (raw.Days) {
        assignedDates = raw.Days.map(date => {
            const d = new Date(date);
            return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);
        });
    }
    let skipDays = [];
    if (raw.Repeat.SkipDays) {
        raw.Repeat.SkipDays.forEach(date => {
            skipDays.push(new Date(date));
        });
    }
    const roleSectTempOpt = {
        name: raw.Name,
        assignedDates: assignedDates,
        requiredTimeSlots: timeFrames,
        skipDays: skipDays,
        repeatedWeekDays: raw.Repeat.RepeatWeekdays,
    };
    return new roleSectionTemplate_1.RoleSectionTemplate(roleSectTempReq, roleSectTempOpt);
}
exports.parseFromRaw = parseFromRaw;
