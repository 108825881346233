<script>
import Modal from './Modal.vue';
import { createDataForTypePTO } from '../js/slot_helpers.js'
import { SLOT_TYPES } from '../js/models.js'
import { MODAL_NAMES } from '../js/modal.js'

export default {
  props: {
    companyID: Number,
    userID: Number,
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      selectedDate: "",
      selectedPTO: SLOT_TYPES.PTO,
    }
  },
  components: {
    Modal,
  },
  methods: {
    closeModal() {
      this.isVisible = false;
      this.selectedPTO = SLOT_TYPES.PTO;
      this.selectedDate = "";
    },
    openModal() {
      this.isVisible = true;
    },
    onConfirm() {
      this.submitRequestedPTO();
    },
    onCancel() {
      this.isVisible = false;
    },
    submitRequestedPTO() {
      if (this.isLoading || !this.selectedDate) {
        return;
      }
      this.isLoading = true;
      const data = createDataForTypePTO(this.companyID, this.selectedDate, parseInt(this.selectedPTO), this.userID);
      $.post(DashURL("slot"), data)
        .done(function(response) {
          RefreshOnSuccess(response);
        })
        .fail(function(response) {
          alertModal.alert(errors.PTOS.REQUEST_PTO);
          this.isLoading = false;
          return;
        })
    }
  },
  computed: {
    loadingClass() {
      return this.isLoading ? "loading" : "";
    },
    modalName() {
      return MODAL_NAMES.PTO_REQUEST
    }
  },
}
</script>

<template>
  <Modal :modal-name="modalName" v-if="isVisible" @closeModal="closeModal" @confirm="onConfirm" @cancel="onCancel" :has-title-divider="true" :has-footer-divider="true">
    <template v-slot:header-title>
      有休を申請
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <div class="datepicker-container">
          <label class="oplus-form-date" for="datepicker">希望日
            <input v-model="selectedDate" type="date" id="datepicker" class="datepicker-input">
          </label>
        </div>
        <div class="form-group d-flex">
          <label class="form-radio">
            <input value="3" type="radio" v-model="selectedPTO">
            <i class="form-icon"></i> 有休
          </label>
          <label class="form-radio">
            <input value="5" type="radio" v-model="selectedPTO">
            <i class="form-icon"></i> 半有休
          </label>
        </div>
      </div>
    </template>
    <template v-slot:footer-btn>
      <button class="oplus-modal-btn oplus-alt-confirm-btn" :class="loadingClass" @click="onConfirm">確定</button>
    </template>
  </Modal>
</template>

<style scoped>
  .header-wrapper {
    text-align: center;
    padding-bottom: 0;
  }

  .pto-date-input {
    width: 100%;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    height: 140px;
    width: 250px;
    margin: auto;
    padding: 20px 0;
    gap: 10px;
  }

  .datepicker-container {
    width: 100%;
  }
</style>