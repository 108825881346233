<script>
import Modal from './Modal.vue';
import TimepickerItem from './TimepickerItem.vue';
import { shortOplusTimeDateLabel, dateFromSecondsTimestamp } from '../js/common';

export default {
  data() {
    return {
      isVisible: false,
      userId: 0,
      selectedDate: "",
    }
  },
  components: {
    Modal,
    TimepickerItem,
  },
  methods: {
    openModal(userId, date) {
      this.isVisible = true;
      this.userId = parseInt(userId);
      this.selectedDate = date;
    },
    closeModal() {
      this.isVisible = false;
      this.resetData();
    },
    openCreateTimecard() {
      return timecardVueModal.openCreate(this.userId, this.selectedDate);
    },
    handleTimecardData(timecardId) {
      return timecardVueModal.openEdit(timecardId);
    },
    resetData() {
      this.userId = 0
      this.selectedDate = ""
    },
  },
  computed: {
    shortJPDate() {
      return shortOplusTimeDateLabel(new Date(this.selectedDate), true)
    },
    timecardIdsArray() {
      const tcIds = Object.keys(TimeCardMap);
      const filteredTcIds = tcIds.filter(tcId => {
        return TimeCardMap[tcId].UserID === this.userId && TimeCardMap[tcId].Date === this.selectedDate
      })

      const date = (id) => dateFromSecondsTimestamp(TimeCardMap[id].Timestamp);
      return filteredTcIds.sort((a, b) => date(a) - date(b));
    },
  },
}
</script>

<template>
  <Modal v-if="isVisible" @closeModal="closeModal" :has-title-divider="true" :has-bottom-divider="false">
    <template v-slot:header-title>
        タイムカード： <span class="timecard-date">{{ shortJPDate }}</span>
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <div class="timecard-list">
          <div v-for="tcId in timecardIdsArray" class="timecard-list-wrapper">
            <TimepickerItem :timecard-id="parseInt(tcId)" @emit-timecard-data="handleTimecardData"/>
          </div>
          <div class="timecard-list-wrapper">
            <span>タイムカード作成</span>
            <div class="timecard-add-btn">
              <button class="btn btn-slot-add-secondary" @click="openCreateTimecard()">
                <i class="icon icon-plus"></i>
              </button>

            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
  .content-wrapper {
    display: flex;
    text-align: center;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    max-height: 350px;
    min-width: 280px;
  }

  .timecard-list {
    width: 100%;
  }

  .timecard-list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    border-bottom: .05rem solid #cfcfcf;
    font-size: .9rem;
    padding: 8px 0;
  }

  .timecard-list-wrapper + .timecard-list-wrapper {
    margin-top: 20px;
  }

  .timecard-add-btn {
    padding: 0 5px 0 5px;
  }
</style>
