import { dateFromSecondsTimestamp } from './common';
import { SLOT_TYPES } from './models';
import { isGroupRole } from './role_helpers';

//getSlotToDate returns a slot's To date object. It will account for HideTo.
export function getSlotToDate(slot) {
  const to = dateFromSecondsTimestamp(slot.ToUnix);

  //FE needs to set the time to the following day's midnight.
  if (slot.HideTo) {
    to.setUTCDate(to.getUTCDate() + 1);
  }
  return to;
}

// Helper function to check slot off text, text used here is in Japanese character of 'x'.
export function isSlotTypeXOffDayString(string) {
  return string === '×';
}

export function isClone(slot, activeComId) {
  return slot.CompanyID != activeComId;
}

export function isLocked(slot) {
  return slot.Locked;
}

export function isCreatedByActiveUser(slot, activeUserID) {
  return slot?.CreatedBy === parseInt(activeUserID);
}

export function isNonWorkingSlotType(slot) {
  if (slot) {
    return (slot.Type === SLOT_TYPES.OFF_DAY || slot.Type === SLOT_TYPES.PUBLIC_HOLIDAY || slot.Type === SLOT_TYPES.OTHER_HOLIDAY)
  } else {
    return null;
  }
}

// Checks if slot has link slot, returns a boolean
export function hasLinkSlot(slot) {
  return (slot?.LinkNext || slot?.LinkPrev) ? true : false;
}

// checks if slot is less than 30mins
// Currently used for displaying vertical-text in dws page
export function isSlotLessThanThirtyMins(slot) {
  const milliseconds = getSlotToDate(slot).valueOf() - dateFromSecondsTimestamp(slot.FromUnix).valueOf();

  // 1740000ms = 29mins
  return milliseconds < 1740000;
}

// constructed Data for requesting Type 3 or 5 PTO slots
export function createDataForTypePTO(comID, dateStr, typePTO = SLOT_TYPES.PTO, userID) {
  return {
    "from": "",
    "to": "",
    "comID": comID,
    "date": dateStr,
    "type": typePTO,
    "purpose": "",
    "locked": "",
    "rest": 0,
    "sendEmail": false,
    "help": "",
    "repeat": false,
    "edited": false,
    "userID": userID,
    "color": "",
  };
}

// Note: We use App.roles as the Roles map, should change this into something like the SlotMap.
export function getRoleFromSlot(slot) {
  const role = App.roles.find(r => r.ID === slot.RoleID);
  if (!role) {
    return null;
  }
  return role;
}
export function isSlotTypeOne(slot) {
  return slot.Type === SLOT_TYPES.WORKDAY;
}

export function isSlotPTOType(slot) {
  return slot.Type === SLOT_TYPES.PTO || SLOT_TYPES.HALF_PTO;
}

export function isSlotHourlyType(slot) {
  return slot.Type === SLOT_TYPES.HOURLY;
}

export function isSlotGroupRole(slot) {
  const role = getRoleFromSlot(slot);
  return isGroupRole(role);
}

export function isSlotRoleSection(slot) {
  const roleId = slot.RoleID;
  if (!roleId) {
    return false;
  }

  const role = App.roles.find(r => { return r.ID == roleId });

  return Boolean(role?.IsRoleSection);
}

export function getRoleSection(slot) {
  const sections = slot.Sections;
  const roleSections = [];
  if (!sections) {
    return roleSections
  } else {
    sections.forEach(sectionId => {
      const section = SlotMap?.[sectionId];
      const roleId = section?.RoleID
      if (!section || !roleId) {
        return roleSections
      }

      const role = getRoleFromSlot(section);
      if (role?.IsRoleSection) {
        roleSections.push(role.ID);
      }
    })
    return roleSections;
  }
}
