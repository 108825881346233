"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Branch = void 0;
class Branch {
    id;
    name;
    settings;
    canEditSettings = true;
    constructor(req, opt) {
        this.id = req.id;
        this.name = req.name;
        this.settings = req.settings;
        if (opt?.canEditSettings !== undefined) {
            this.canEditSettings = opt.canEditSettings;
        }
    }
    getOriginalId() {
        return this.id;
    }
    getBranchName() {
        return this.name;
    }
    getId() {
        return this.id.toString();
    }
    getSettings() {
        return this.settings;
    }
    getMonthStart() {
        return this.settings.getMonthStart();
    }
    getWeekStart() {
        return this.settings.getWeekStart();
    }
    getDayStart() {
        return this.settings.getSummaryDayStart();
    }
    getDayEnd() {
        return this.settings.getSummaryDayEnd();
    }
    getTimeZone() {
        return this.settings.getTimeZone();
    }
    setSettings(settings) {
        this.settings = settings;
    }
}
exports.Branch = Branch;
