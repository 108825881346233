import { summaryUndoDwsAuto } from "../requests/summaryDwsAuto";

export function handleAutoDwsSuccess(response) {
  if (response.slotIds.length) {
    window.sessionStorage.setItem("autoSlotIds", response.slotIds);
  }
  if (response.sections && response.sections.length > 0) {
    response.sections.forEach(section => {
      if (SlotMap?.[section.ID] && SlotMap[section.ID].isAuto) {
        onSectionSubmit(section, true);
        return;
      }
      onSectionSubmit(section, false);
    })
    alertModal.alertSuccess("業務セクションを割り当てました。")
  }
  enableAutoUndoButton();
}

async function handleUndoDwsAuto(e, meStore) {
  try {
    $(e).addClass("loading");
    let slotIds = window.sessionStorage.getItem("autoSlotIds");
    if (!slotIds) {
      throw new Error("No SlotIDs to Undo")
    }
    slotIds = slotIds.split(",").map(Number);
    const payload = {
      slotIds,
    }
    const response = await summaryUndoDwsAuto(meStore.getActiveUserId(), meStore.getActiveBranchId(), payload);
    if (response.success) {
      Refresh();
      // TODO: remove sections without refreshing
    } else {
      throw new Error("Failed to Undo auto")
    }
  } catch (err) {
    console.error(err);
    alertModal.alert(err);
  } finally {
    $(e).removeClass("loading");
    window.sessionStorage.removeItem("autoSlotIds");
    disableAutoUndoButton();
  }
}

export function initAutoUndoButton(meStore) {
  enableAutoUndoButton();
  const autoUndoBtn = $("#undo-auto-modal-btn");
  autoUndoBtn.on("click", (e) => {
    alertModal.confirm("業務セクションを戻します、よろしいですか？", () => {
      handleUndoDwsAuto(e.target, meStore);
    })
  })
}

function enableAutoUndoButton() {
  const autoUndoBtn = $("#undo-auto-modal-btn");
  if (window.sessionStorage.getItem("autoSlotIds")) {
    autoUndoBtn.removeClass("disabled-btn");
  }
}

export function disableAutoUndoButton() {
  const autoUndoBtn = $("#undo-auto-modal-btn");
  autoUndoBtn.addClass("disabled-btn");
}
