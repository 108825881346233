"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShiftStats = void 0;
class ShiftStats {
    isWorkerNumberCounted = false;
    isCountedInTransportCost = false;
    laborHours = 0;
    laborCost = 0;
    roleId = "";
    roleSectionIds = [];
    type = -1;
    constructor() {
    }
    setIsWorkerNumberCounted(v) {
        this.isWorkerNumberCounted = v;
    }
    setLaborHours(v) {
        this.laborHours = v;
    }
    setType(t) {
        this.type = t;
    }
    setLaborCost(v) {
        this.laborCost = v;
    }
    setTransportCost(v) {
        this.isCountedInTransportCost = v;
    }
    setRoleId(roleId) {
        this.roleId = roleId;
    }
    getRoleId() {
        return this.roleId;
    }
    setRoleSectionId(roleSectionId) {
        this.roleSectionIds.push(roleSectionId);
    }
    getRolesectionIds() {
        return this.roleSectionIds;
    }
}
exports.ShiftStats = ShiftStats;
