"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableColumn = void 0;
class TableColumn {
    y;
    constructor(y) {
        this.y = y;
    }
}
exports.TableColumn = TableColumn;
