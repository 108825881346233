"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = (n) => ((0, vue_2.pushScopeId)("data-v-9bea3e74"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = ["value"];
const vue_3 = require("vue");
const common_1 = require("../common/common");
exports.default = (0, vue_1.defineComponent)({
    __name: 'RoleRequiredCalendarCell',
    props: {
        dateData: {
            type: Object,
            required: true,
        },
        requiredData: {
            type: Object,
            required: true,
        },
        weekdayRequired: {
            type: Object,
            required: true,
        },
    },
    emits: ["emit-cell"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const requiredVal = (0, vue_3.ref)("0");
        const isEditing = (0, vue_3.ref)(false);
        const roleRequiredInput = (0, vue_3.ref)(null);
        const weekDayIndex = props.dateData.weekDayIndex;
        const computedReqValue = (0, vue_3.computed)(() => {
            if (!props.dateData) {
                return;
            }
            if (weekDayIndex !== undefined) {
                requiredVal.value = props.weekdayRequired[weekDayIndex];
            }
            if (props.dateData?.isHoliday && parseInt(props.weekdayRequired[7])) {
                requiredVal.value = props.weekdayRequired[7];
            }
            if (props.requiredData[props.dateData?.dateStr]?.required?.toString()) {
                requiredVal.value = props.requiredData[props.dateData?.dateStr]?.required?.toString() || "";
            }
            return requiredVal;
        });
        const styleClass = (0, vue_3.computed)(() => {
            if (!props.dateData) {
                return;
            }
            const hasRequired = props.requiredData[props.dateData?.dateStr]?.required !== undefined;
            return {
                'oplus-gray-text': !hasRequired,
                'required-content-color': hasRequired,
            };
        });
        const emits = __emit;
        function handleCellClick() {
            if (!props.dateData) {
                return;
            }
            isEditing.value = true;
            (0, vue_3.nextTick)(() => {
                roleRequiredInput.value?.focus();
                roleRequiredInput.value?.select();
            });
        }
        function handleCellBlur() {
            isEditing.value = false;
            if (!props.dateData?.realDate) {
                return;
            }
            const newVal = parseInt((0, common_1.fullWidthToAscii)(requiredVal.value));
            const textStr = isNaN(newVal) ? "0" : newVal.toString();
            if (requiredVal.value === "") {
                requiredVal.value = "";
            }
            else {
                requiredVal.value = textStr;
            }
            const emitData = {
                realDate: props.dateData.realDate
            };
            if (!isNaN(newVal)) {
                emitData.required = newVal;
            }
            else if (props.requiredData[props.dateData?.dateStr]?.required === undefined) {
                requiredVal.value = props.weekdayRequired[weekDayIndex].toString();
                return;
            }
            emits("emit-cell", emitData);
        }
        function handleKeyPress(e) {
            if (e.key === "Enter") {
                isEditing.value = false;
            }
        }
        function handleInput(e) {
            const target = e.target;
            requiredVal.value = target.value;
        }
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                class: "required-content",
                onClick: handleCellClick
            }, [
                (!isEditing.value)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", {
                        key: 0,
                        class: (0, vue_2.normalizeClass)(styleClass.value)
                    }, (0, vue_2.toDisplayString)(computedReqValue.value), 3 /* TEXT, CLASS */))
                    : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("input", {
                        key: 1,
                        ref_key: "roleRequiredInput",
                        ref: roleRequiredInput,
                        type: "text",
                        class: "role-required-input",
                        tabindex: "0",
                        value: requiredVal.value,
                        onBlur: handleCellBlur,
                        onKeypress: handleKeyPress,
                        onInput: handleInput
                    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1))
            ]));
        };
    }
});
