"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiClientImpl = void 0;
const planFuncParser = __importStar(require("./parser/planFunction"));
const planFuncBodyParser = __importStar(require("./parser/planFunctionBody"));
const planFunctionBodies_1 = require("./planFunctionBodies");
const planFunctions_1 = require("./planFunctions");
class ApiClientImpl {
    constructor() { }
    async fetchPlanFunctions(params) {
        const j = await (0, planFunctions_1.fetchPlanFunctions)(params);
        const out = planFuncParser.parse(j.data);
        return { data: out, pagination: j.pagination };
    }
    async createPlanFunction(data) {
        const j = await (0, planFunctions_1.createPlanFunction)(data);
        return planFuncParser.parse([j])[0];
    }
    async updatePlanFunction(data, id) {
        const j = await (0, planFunctions_1.updatePlanFunction)(data, id);
        return planFuncParser.parse([j])[0];
    }
    async deletePlanFunction(id) {
        await (0, planFunctions_1.deletePlanFunction)(id);
    }
    async fetchPlanValues(params) {
        const j = await (0, planFunctionBodies_1.fetchPlanFunctionBodies)(params);
        const out = planFuncBodyParser.parse(j.data);
        return { data: out, pagination: j.pagination };
    }
    async createPlanValue(params) {
        const j = await (0, planFunctionBodies_1.createPlanFunctionBody)(params);
        return planFuncBodyParser.parse([j])[0];
    }
    async updatePlanValue(functionBody, id) {
        const j = await (0, planFunctionBodies_1.updatePlanFunctionBody)(functionBody, id);
        return planFuncBodyParser.parse([j])[0];
    }
    async deletePlanValue(id) {
        await (0, planFunctionBodies_1.deletePlanFunctionBody)(id);
    }
}
exports.ApiClientImpl = ApiClientImpl;
