<script>
import { MODAL_NAMES } from '../js/modal';
import Modal from './Modal.vue';

// This modal is to show the Plan-equation form, in which adds an equation or calculation to the desired number of staff in a day/month/week
export default {
  props: {
    planEquationValue: String,
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      inputValue: "",
      currentValue: "",
    }
  },
  components: {
    Modal,
  },
  emits: ['success'],
  methods: {
    closeModal() {
      this.isVisible = false;
    },
    openModal() {
      this.isVisible = true;
    },
    setValue(v) {
      this.inputValue = v;
      this.currentValue = v;
    },
    onConfirm() {
      if (!this.isInputValid) {
        alertModal.alert("記号「+ - * /」の後に数字を入力して下さい。（例：+5）")
        return;
      }
      this.submitPlanEquation();
    },
    onCancel() {
      this.isVisible = false;
    },
    submitPlanEquation() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // setTimeout to delay and see loader
      setTimeout(() => {
        const data = { "plan-num-eq": this.inputValue ? this.inputValue : "none"};
        $.post(DashURL("plan-num-eq"), data, (res) => {
          if (!res.success) {
            alertModal.alert(errors.PLAN.UPDATE);
            this.isLoading = false;
            return;
          }
          this.$emit('success', data['plan-num-eq'])
          this.isLoading = false;
          this.currentValue = this.inputValue;
        });
      }, 1000)
    },
  },
  computed: {
    // checks if the input form has '+', '-', '*' or '/' as first character and followed by any number. Ex: '+10'
    isInputValid() {
      const pattern =  /^[+\-*\/]\d+$/;
      return !this.inputValue || this.inputValue === "0" || pattern.test(this.inputValue);
    },
    computedValue() {
      if (!this.currentValue) {
        this.currentValue = "無"
      }
      return this.currentValue;
    },
    loadingClass() {
      return this.isLoading ? "loading" : "";
    },
    modalName() {
      return MODAL_NAMES.PLAN_EQUATION
    }
  },
  created() {
    this.inputValue = this.planEquationValue;
    this.currentValue = this.planEquationValue;
  }
}
</script>

<template>
  <Modal :modal-name="modalName" v-if="isVisible" @closeModal="closeModal" @confirm="onConfirm" @cancel="onCancel" :has-title-divider="false" :has-footer-divider="false">
    <template v-slot:header-title>
      <div class="modal-plan-header">
        <div class="svg-container">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve" width="30px" height="30px" viewBox="0 0 85 85" id="calculator-icon">
            <path fill="currentColor" d="M64.643,81.367H20.621c-3.907,0-7.086-3.179-7.086-7.086V10.295c0-3.908,3.179-7.087,7.086-7.087h44.021
            c3.907,0,7.086,3.179,7.086,7.087v63.986C71.729,78.188,68.55,81.367,64.643,81.367z M20.621,6.043
            c-2.344,0-4.251,1.907-4.251,4.252v63.986c0,2.345,1.907,4.252,4.251,4.252h44.021c2.345,0,4.252-1.907,4.252-4.252V10.295
            c0-2.345-1.907-4.252-4.252-4.252H20.621z M61.633,30.433H23.632c-1.562,0-2.834-1.272-2.834-2.835V12.51
            c0-1.563,1.271-2.835,2.834-2.835h38.001c1.562,0,2.834,1.272,2.834,2.835v15.088C64.467,29.161,63.195,30.433,61.633,30.433z
              M61.633,27.598v1.417V27.598L61.633,27.598L61.633,27.598z M23.632,12.51v15.088h37.996l0.005-15.088H23.632z M63.218,44.444
            c0-0.783-0.634-1.417-1.417-1.417H46.145c-0.783,0-1.417,0.635-1.417,1.417c0,0.783,0.634,1.417,1.417,1.417h15.656
            C62.584,45.861,63.218,45.228,63.218,44.444z M40.305,66.449c0-0.783-0.634-1.417-1.417-1.417H23.232
            c-0.783,0-1.417,0.634-1.417,1.417s0.634,1.417,1.417,1.417h15.655C39.671,67.866,40.305,67.232,40.305,66.449z M55.979,66.45
            l4.53-4.53c0.55-0.55,0.55-1.45,0-2c-0.55-0.56-1.45-0.56-2.01,0l-4.53,4.53l-4.53-4.54c-0.55-0.55-1.45-0.55-2,0
            c-0.56,0.56-0.56,1.45,0,2.01l4.53,4.53l-4.54,4.54c-0.55,0.55-0.55,1.45,0,2c0.28,0.279,0.64,0.41,1,0.41
            c0.37,0,0.729-0.131,1.01-0.41l4.53-4.53l4.53,4.53c0.279,0.279,0.64,0.41,1.01,0.41c0.359,0,0.72-0.131,1-0.41
            c0.55-0.55,0.55-1.45,0-2L55.979,66.45z M38.89,43.03h-6.41v-6.42c0-0.78-0.64-1.41-1.42-1.41s-1.42,0.63-1.42,1.41v6.42h-6.41
            c-0.78,0-1.41,0.63-1.41,1.411c0,0.79,0.63,1.42,1.41,1.42h6.41v6.409c0,0.78,0.64,1.41,1.42,1.41s1.42-0.63,1.42-1.41V45.86h6.41
            c0.78,0,1.42-0.63,1.42-1.42C40.31,43.66,39.67,43.03,38.89,43.03z M31.039,59.025c-0.868,0-1.571,0.703-1.571,1.572
            s0.703,1.572,1.571,1.572c0.869,0,1.572-0.703,1.572-1.572S31.908,59.025,31.039,59.025z M31.039,70.589
            c-0.868,0-1.571,0.704-1.571,1.572s0.703,1.573,1.571,1.573c0.869,0,1.572-0.705,1.572-1.573S31.908,70.589,31.039,70.589z"/>
          </svg>
        </div>
        <span class="modal-plan-title-text">必要人数方程式</span>
      </div>
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <form class="d-flex" @submit.prevent="onConfirm()">
          <input v-model="inputValue" type="text" id="plan-equation-input" class="plan-equation-input" placeholder="+10">
          <button class="oplus-modal-btn oplus-alt-confirm-btn plan-equation-confirm-btn" :class="loadingClass" @click="onConfirm">確定</button>
        </form>
      </div>
      <div class="plan-eq-text">
        <span>記号を使い計算を行って下さい。足し算(+)、引き算(-)、割り算(/) 、掛け算(*)</span>
        <span>現在の方程式: <strong>{{ this.computedValue }}</strong></span>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
  .modal-plan-header {
    margin-left: -0.8rem;
    display: flex
  }
  .modal-plan-title-text {
    margin-left: 10px;
  }
  .content-wrapper {
    display: flex;
    width: fit-content;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    margin: auto;
  }

  .plan-eq-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .plan-equation-input {
    height: 35px;
    text-align: center;
    width: 70px;
    border-width: .1px;
    font-size: 1.1rem;
    font-weight: 300;
    color: #303742;
  }

  .plan-equation-input::placeholder {
    color: #a5a5a577;
  }

  .plan-equation-confirm-btn {
    width: 70px;
    border-radius: 0;
  }
</style>
