<template>
    <div>
        <div class="svg-container emotion-rating">
            <svg version="1.1" class="emotion-rating-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 32 32" width="60px" height="60px" xml:space="preserve">
                <path class=""   d="M15.8,27.7c-6.43,0-11.67-5.23-11.67-11.67C4.13,9.6,9.37,4.36,15.8,4.36S27.47,9.6,27.47,16.03
                C27.47,22.46,22.23,27.7,15.8,27.7z M15.8,5.43c-5.85,0-10.6,4.76-10.6,10.6s4.76,10.6,10.6,10.6s10.6-4.76,10.6-10.6
                S21.65,5.43,15.8,5.43z M20.21,21.67c0.27-0.12,0.39-0.44,0.27-0.7c-0.92-2.03-2.71-3.29-4.67-3.29s-3.76,1.26-4.67,3.29
                c-0.12,0.27,0,0.58,0.27,0.7c0.27,0.12,0.58,0,0.7-0.27c0.74-1.64,2.16-2.66,3.7-2.66s2.96,1.02,3.7,2.66
                c0.09,0.2,0.28,0.31,0.48,0.31C20.06,21.72,20.14,21.71,20.21,21.67z M13.72,12.76l-3.07-0.54c-0.29-0.05-0.56,0.14-0.61,0.43
                c-0.05,0.29,0.14,0.56,0.43,0.62l0.86,0.15c-0.17,0.21-0.26,0.48-0.26,0.77c0,0.71,0.57,1.28,1.28,1.28s1.28-0.57,1.28-1.28
                c0-0.13-0.02-0.26-0.06-0.38c0.02,0,0.04,0,0.06,0c0.25,0,0.48-0.18,0.52-0.44C14.2,13.09,14.01,12.81,13.72,12.76z M17.46,13.38
                c0.05,0.26,0.27,0.44,0.52,0.44c0.02,0,0.04,0,0.06,0c-0.04,0.12-0.06,0.25-0.06,0.38c0,0.71,0.57,1.28,1.28,1.28
                s1.28-0.57,1.28-1.28c0-0.29-0.1-0.56-0.26-0.77l0.86-0.15c0.29-0.05,0.48-0.33,0.43-0.62c-0.05-0.29-0.33-0.48-0.61-0.43
                l-3.07,0.54C17.6,12.81,17.41,13.09,17.46,13.38z"/>
            </svg>
        </div>
        <span class="satisfaction-text">不満</span>
    </div>
</template>
