"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrRole = void 0;
const subRole_1 = require("./subRole");
class OrRole extends subRole_1.SubRole {
    constructor(mainRoleId, data, optional) {
        super(mainRoleId, data, optional);
    }
    isOrRole() {
        return true;
    }
}
exports.OrRole = OrRole;
