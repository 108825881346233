<script>
import ColorBox from './ColorBox.vue';
import { OPLUS_COLOR_PALETTE, ROLE_VIEW_TYPE, SLOT_TYPES } from '../js/models.js'
import { changeRoleCreateBtn, updateRoleFromRolesApp, getRoleFromRolesApp } from '../js/roles.js'
import { putRoleRequest } from '../js/role_requests';

export default {
  props: {
    havePermission: Object,
    isGroupRoleForm: Boolean,
    isGroupOwner: Boolean,
    selectedOem: Number,
  },
  data() {
    return {
      isColorBoxVisible: false,
      isVisible: false,
      isLoading: false,
      formData: {
        name: "",
        purpose: "",
        from: "",
        to: "",
        rest: "",
        wage: "",
        isNightShift: false,
        nextDayOff: false,
        showCount: true,
        showStaffCount: true,
        color: OPLUS_COLOR_PALETTE.DEFAULT,
        type: ROLE_VIEW_TYPE.DAY_VIEW,
        slotType: SLOT_TYPES.HOURLY,
        kotHolidayType: "",
        externalID: "",
        isHiddenInShiftPage: false,
        isDisabledWhenFull: false,
        groupWide: this.isGroupRoleForm, // always set to true if we are on group-role page
        editSharedSettings: (this.isGroupRoleForm && this.isGroupOwner) || false,
      },
      selectedRoleID: 0,
    }
  },
  methods: {
    initFormData() {
      this.formData.name = "";
      this.formData.purpose = "";
      this.formData.from = "";
      this.formData.to = "";
      this.formData.rest = "";
      this.formData.wage = "";
      this.formData.isNightShift = false;
      this.formData.nextDayOff = false;
      this.formData.showCount = true;
      this.formData.showStaffCount = true;
      this.formData.color = OPLUS_COLOR_PALETTE.DEFAULT;
      this.formData.type = ROLE_VIEW_TYPE.DAY_VIEW;
      this.formData.slotType = SLOT_TYPES.HOURLY;
      this.formData.kotHolidayType = "";
      this.formData.externalID = "";
      this.formData.isHiddenInShiftPage = false;
      this.formData.isDisabledWhenFull = false;
      this.formData.groupWide = this.isGroupRoleForm;
      this.selectedRoleID = 0;
    },
    setFormData(role) {
      this.formData.name = role.Name;
      this.formData.purpose = role.Purpose;
      this.formData.from = role.From;
      this.formData.to = role.To;
      this.formData.rest = role.Rest;
      this.formData.wage = role.Wage;
      this.formData.isNightShift = role.IsNightShift;
      this.formData.nextDayOff = role.NextDayOff;
      this.formData.showCount = role.ShowCount;
      this.formData.showStaffCount = role.ShowStaffCount;
      this.formData.color = role.Color ? role.Color : OPLUS_COLOR_PALETTE.DEFAULT;
      this.formData.type = role.Type;
      this.formData.slotType = role.SlotType;
      this.formData.kotHolidayType = role.KOTHolidayType;
      this.formData.externalID = role.ExternalID
      this.formData.isHiddenInShiftPage = role.IsHiddenInShiftPage;
      this.formData.isDisabledWhenFull = role.IsDisabledWhenFull;
      this.formData.groupWide = role.GroupWide;
      this.selectedRoleID = role.ID
    },
    openRoleForm() {
      this.isVisible = true
    },
    closeRoleForm() {
      this.isVisible = false
    },
    toggleRoleForm(roleID) {
      const isNewRole = !roleID;

      if (isNewRole) {
        this.initFormData();
      } else {
        this.setFormData(getRoleFromRolesApp(roleID));
      }

      if (!this.isVisible) {
        this.isColorBoxVisible = false;
        this.openRoleForm();
      } else if (isNewRole) {
        this.closeRoleForm();
      }
    },
    openColorBox() {
      this.isColorBoxVisible = true;
    },
    // Close color box outside click
    handleRoleContainerClick(event) {
      if (!this.$refs["color-box"].contains(event.target)) {
        this.isColorBoxVisible = false;
      }
      return;
    },
    handleBeforeEnterAnimation() {
      if (!this.isEdit) {
        return this.initFormData();
      }
    },
    // emit handler from Colorbox component
    setSelectedColor(colorStr) {
      return this.formData.color = colorStr;
    },
    // We pass empty string to the request if its a default color
    setColorDataForSubmit() {
      if (this.formData.color === OPLUS_COLOR_PALETTE.DEFAULT) {
        return "none"
      } else {
        return this.formData.color;
      }
    },
    async handleSubmit() {
      if (this.isLoading) {
        return;
      }

      if (!this.formData.name) {
        alertModal.alert("業務名を入力して下さい。");
        return;
      }
      this.isLoading = true;
      const roleDataClone = {...this.formData}
      if (roleDataClone.type === "1") {
        roleDataClone.isDisabledWhenFull = false;
      }
      roleDataClone.from = roleDataClone.from.trim();
      roleDataClone.to = roleDataClone.to.trim();
      roleDataClone.from = roleDataClone.from.length === 3 ? `0${roleDataClone.from}` : roleDataClone.from;
      roleDataClone.to = roleDataClone.to.length === 3 ? `0${roleDataClone.to}` : roleDataClone.to;

      roleDataClone.color = this.setColorDataForSubmit();
      if (this.isEdit) {
        try {
          const response = await putRoleRequest(this.selectedRoleID, roleDataClone);
          updateRoleFromRolesApp(response.role);
        } catch (err) {
          alertModal.alert("Fail to update Role");
          return;
        }
      } else {
        $.post(DashURL("roles"), roleDataClone, (response) => {
          if (response.success) {
            response.role["Alert"] = "空の業務です";
            RolesApp.roles.push(response.role);
            RolesApp.canSubmit = true;
          }
        });
      }
      this.isVisible = false;
      this.isLoading = false;
      changeRoleCreateBtn(this.isVisible);
      this.initFormData();
    },
    disabledClass(hasPermission) {
      return hasPermission ? "" : "disabled";
    },
    handleDisableDisableWhenFullOption() {
      this.formData.isDisabledWhenFull = false;
    }
  },
  computed: {
    isEdit() {
      return this.selectedRoleID ? true : false;
    },
    submitButtonText() {
      return this.isEdit ? "編集" : "作成";
    },
    loadingClass() {
      return this.isLoading ? "loading" : "";
    },
    selectedColorBox() {
      return this.formData.color;
    },
    colorBoxStyle() {
      return {
        "backgroundColor": "#" + this.selectedColorBox,
        "borderColor": "#" + this.selectedColorBox
      }
    },
    disableRoleForm() {
      // Disable form from editing if:
      // its an edit
      // is not a group owner
      // role is a group role
      return this.isEdit && !this.isGroupOwner && this.formData.groupWide
    },
    selectedOemText() {
      let text = "";
      switch (this.selectedOem) {
        case 0:
          text = "KOT"
          break;
        case 1:
          text = "勤革時"
          break;
        case 2:
          text = "ToT"
          break;
        case 3:
          text = "セコム"
          break;
        case 4:
          text = "おまかせ"
          break;
        case 5:
          text = "Freee"
          break;
        case 6:
          text = "オフィス"
          break;
        default:
          text = "KOT"
          break;
      }

      return text;
    },
    disableTheDisabledWhenFullOption() {
      return this.formData.type == "1"; //=== doesn't work
    },
    optionDisabledClass() {
      return {
        "disabled": this.formData.type == "1", // dashboard_base.html has this css
      }
    },
  },
  components: {
    ColorBox
  },
}
</script>

<template>
  <transition name="slide-fade" @before-enter="handleBeforeEnterAnimation">
    <form v-show="isVisible" @submit.prevent="handleSubmit" @click="(event) => handleRoleContainerClick(event)" :inert="disableRoleForm">
      <div class="role-form-container">
        <!-- left side -->
        <div class="role-details">
          <div class="form-padding">
            <span class="role-form-title">
              <span>明細</span>
              <div ref="color-box" class="role-form-color-wrapper" @click="openColorBox">
                <div class="role-form-color-box" :style="colorBoxStyle"></div>
                <span class="role-form-color-text">色塗り
                  <transition name="slide-up">
                    <ColorBox class="oplus-color-box" :selected-color="selectedColorBox" :is-visible="isColorBoxVisible" @color-select="setSelectedColor"/>
                  </transition>
                </span>
              </div>
            </span>
          </div>

          <div class="role-form-title-divider"></div>

          <div class="form-padding">
            <label class="oplus-form-checkbox">
              <input v-model="formData.groupWide" type="checkbox" id="role-modal-groupwide" :disabled="isGroupRoleForm">
              他拠点へ同期
            </label>
          </div>

          <div class="form-padding">
            <label class="oplus-form-text" for="role-name">ラベル (必須)
              <input v-model="formData.name" type="text" name="role-name" id="" placeholder="業務名">
            </label>
          </div>
          <div class="form-padding">
            <label class="oplus-form-text" for="role-purpose">
              説明
              <input v-model="formData.purpose" type="text" name="role-purpose" id="" placeholder="朝番, レジ">
            </label>
          </div>
          <div class="form-padding">
            <div class="role-grid-wrapper" data-columns="2">
              <div>
                <label class="oplus-form-text" for="role-from">
                  から
                  <input v-model="formData.from" type="text" name="role-from" id="" placeholder="10, 10:00, 1000">
                </label>
              </div>

              <div>
                <label class="oplus-form-text" for="role-to">
                  まで
                  <input v-model="formData.to" type="text" name="role-to" id="" placeholder="18, 18:00, 1800">
                </label>
              </div>
            </div>
          </div>
          <div class="form-padding">
            <label class="oplus-form-text" for="role-rest">
              休憩時間
              <input v-model="formData.rest" type="text" name="role-rest" id="" placeholder="1, 1:30">
            </label>
          </div>
          <div class="form-padding">
            <label class="oplus-form-number" for="role-wage" :class="disabledClass(havePermission.roleWage)">
              時給
              <input v-model="formData.wage" type="number" name="role-wage" id="" placeholder="1000" :disabled="!havePermission.roleWage">
            </label>
          </div>

          <!-- Temporary disabled, will add on another task https://trello.com/c/JuP4W0U4 -->
          <!-- <div v-if="isEdit" class="form-padding">
            <div class="role-section-form-wrapper">
              <span class="oplus-form-text">セクション</span>
              <button class="btn btn-slot-add-secondary"><i class="icon icon-plus"></i></button>
            </div>
          </div> -->
        </div>

        <!-- right side -->
        <div class="role-details">
          <span class="form-padding role-form-title">設定</span>
          <div class="role-form-title-divider"></div>

          <div class="form-padding">
            <div class="role-grid-wrapper" data-columns="2">
              <label class="oplus-form-checkbox">
                <input v-model="formData.nextDayOff" type="checkbox" id="role-modal-nextdayoff">
                次の日休み
              </label>
              <label class="oplus-form-checkbox">
                <input v-model="formData.isNightShift" type="checkbox" id="role-modal-isnightshift">
                <div class="flex-start-align-center">
                  <span>夜勤</span>
                  <small>(次の日にまたぐ)</small>
                </div>
              </label>
            </div>
          </div>

          <div class="form-padding" :class="disabledClass(havePermission.auto)">
            <div class="role-setting-header">
              <span class="oplus-form-text ">割り当て方法</span>
              <span v-if="!havePermission.auto" class="pro-auto-lock tooltip" data-tooltip="この機能は、&#10;自動作成のアップグレード&#10;が必要となります。">
                <i class="zmdi zmdi-lock zmdi-hc-lg"></i>
              </span>
            </div>
            <div class="role-grid-wrapper" data-columns="2">
              <label class="form-radio">
                <input v-model="formData.type" type="radio" name="role-modal-type" value="0" checked="checked">
                <i class="form-icon"></i> 曜日別
              </label>
              <label class="form-radio">
                <input v-model="formData.type" type="radio" name="role-modal-type" value="1" :disabled="!havePermission.auto" @change="handleDisableDisableWhenFullOption">
                <i class="form-icon"></i> スタッフごと
              </label>
            </div>
          </div>

          <div class="form-padding">
            <div class="role-setting-header">
              <span class="oplus-form-text">シフトの種類</span>
            </div>
            <div class="role-grid-wrapper" data-columns="2">
              <label class="form-radio form-inline">
                <input  v-model="formData.slotType" type="radio" name="role-modal-slot-type" value="0" checked="checked">
                <i class="form-icon"></i> シフト
              </label>
              <label class="form-radio form-inline">
                <input  v-model="formData.slotType" type="radio" name="role-modal-slot-type" value="2">
                <i class="form-icon"></i> 公休
              </label>
              <label class="form-radio form-inline">
                <input  v-model="formData.slotType" type="radio" name="role-modal-slot-type" value="3">
                <i class="form-icon"></i> 有休
              </label>
              <label class="form-radio form-inline">
                <input  v-model="formData.slotType" type="radio" name="role-modal-slot-type" value="5">
                <i class="form-icon"></i> 半有休
              </label>
              <label class="form-radio form-inline">
                <input  v-model="formData.slotType" type="radio" name="role-modal-slot-type" value="6">
                <i class="form-icon"></i> 法定休日
              </label>
              <label class="form-radio form-inline">
                <input  v-model="formData.slotType" type="radio" name="role-modal-slot-type" value="7">
                <i class="form-icon"></i> 法定外休日
              </label>
            </div>
          </div>

          <div class="form-padding">
            <div class="role-setting-header">
              <span class="oplus-form-text ">シフト表に表示(業務)</span>
            </div>
            <div class="padding-top role-grid-wrapper" data-columns="2">
              <label class="oplus-form-checkbox">
                <input v-model="formData.showCount" type="checkbox" id="role-modal-show-count">
                カウント
              </label>
              <label class="oplus-form-checkbox">
                <input v-model="formData.showStaffCount" type="checkbox" id="role-modal-show-staff-count">
                 割当
              </label>
              <label class="oplus-form-checkbox">
                <input v-model="formData.isHiddenInShiftPage" type="checkbox" id="role-modal-hidden-in-shift-page">
                 シフト提出ページに非表示
              </label>
              <label class="oplus-form-checkbox" :class="optionDisabledClass">
                <input v-model="formData.isDisabledWhenFull" type="checkbox" id="role-modal-disabled-when-full" :disabled="disableTheDisabledWhenFullOption">
                  必要人数を満たしている場合無効
              </label>
            </div>
          </div>
          <div class="form-padding" :class="disabledClass(havePermission.KOT)">
            <div>
              <label class="oplus-form-number" for="role-modal-holiday-type">
                <div class="flex-start-align-center">
                  <span>{{ selectedOemText }}</span>
                  <span>連携</span>
                  <div v-if="!havePermission.KOT && havePermission.pro" class="circle-question tooltip" data-tooltip="この機能をご利用の場合は、&#10;設定画面にてKOTの連携設定を&#10;行ってください。"></div>
                  <div v-if="!havePermission.pro" class="role-kot-pro pro-label small"></div>
                </div>
                <input v-model="formData.kotHolidayType" id="role-modal-holiday-type" type="number" placeholder="休暇区分コード" :disabled="!havePermission.KOT">
              </label>
            </div>
          </div>
          <div class="form-padding">
            <label class="oplus-form-text" for="role-modal-external-id">
              <span>連携用ID</span>
              <input v-model="formData.externalID" type="text" id="role-modal-external-id" placeholder="101">
            </label>
          </div>
          <div class="form-padding role-form-footer">
            <button v-if="!disableRoleForm" :class="loadingClass" class="oplus-modal-btn oplus-confirm-btn">{{ submitButtonText }}</button>
            <small v-else class="text-error">オーナーのみ設定を編集できます</small>
          </div>
        </div>
      </div>
    </form>
  </transition>
</template>

<style scoped>
.role-form-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding-bottom: 20px;
  position: relative;
}

.role-details {
  width: 100%;
  padding: 10px 0;
  border: .05rem solid #dadee4;
  border-radius: 5px;
}

.role-form-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.1rem;
}

.role-form-title-divider {
  border-bottom: 1px solid #cfcfcf;
  padding: .2rem 0;
  margin-bottom: 1rem;
  width: 100%;
}

.role-grid-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
}

.role-section-form-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.form-padding {
  padding: 0.5rem 1rem;
}

.role-setting-header {
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: .5rem;
}

.form-padding + .form-padding {
  margin-top: 10px;
}

.role-form-color-wrapper {
  border: 0.05rem solid #cfcfcf;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 .3rem;
  gap: 10px;
  cursor: pointer;
}

.role-form-color-text {
  font-size: .8rem;
  color: #a5a5a5;
  position: relative;
}

.role-form-color-box {
  width: 15px;
  height: 15px;
  border: 1px solid;
  border-radius: 3px;
}

.role-form-footer {
  display: flex;
  justify-content: flex-end;
}

.oplus-color-box {
  z-index: 1;
  position: absolute;
  transform: translate(50%, 6px);
  right: 50%;
  top: 100%;
}

.oplus-form-checkbox + .oplus-form-checkbox {
  margin-top: 5px;
}
.padding-top {
  padding-top: .5rem
}

.flex-start-align-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 3px;
}

.pro-auto-lock i {
  color: #52a3d5;
  padding: 0 10px;
  cursor: pointer;
}

.pro-auto-lock.tooltip::after {
  text-align: center;
}

@media (max-width: 900px) {
  .oplus-color-box {
    transform: translate(17%);
  }
  .role-form-container {
    grid-template-columns: 1fr;
  }
}

/* Animations */
/* Form */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

/* Colorbox */
.slide-up-enter-active {
  transition: 0.1s ease-out;
}

.slide-up-leave-active {
  transition: 0.2s ease-in-out;
}

.slide-up-enter-from,
.slide-up-leave-to {
  opacity: 0;
}

/* Spectre style override TODO: move in dash if other page needs the redesign */
.form-radio input:checked+ .form-icon {
  background: #52a3d5;
  border-color: #52a3d5;
}
</style>
