"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeComputedValuesMap = exports.initPlanFunctions = exports.mergeLinkedShifts = exports.repeatShiftsFromTemplate = exports.makeDateToUserToShiftMap = void 0;
const luxon_1 = require("luxon");
const common_1 = require("../js/common");
const repeatShift_1 = require("../model/v1/repeatShift");
const section_1 = require("../model/v1/section");
const mergedShift_1 = require("../model/v1/mergedShift");
const common_2 = require("../common/common");
const apiClientImpl_1 = require("../webappApi/v2/apiClientImpl");
const plan_1 = require("../plan/v1/plan");
const webappApi = new apiClientImpl_1.ApiClientImpl();
function makeDateToUserToShiftMap(dates, shifts) {
    const dateToUserToSlots = {};
    dates.forEach(d => {
        dateToUserToSlots[d.index()] = {};
        const endDate = d.getEnd();
        shifts.forEach(s => {
            if (!(0, common_1.timespansOverlap)(s.getStartDate(), s.getEndDate(), d.getDate(), endDate)) {
                return;
            }
            if (!dateToUserToSlots[d.index()][s.getUserId()]) {
                dateToUserToSlots[d.index()][s.getUserId()] = {};
            }
            dateToUserToSlots[d.index()][s.getUserId()][s.index()] = s;
        });
    });
    return dateToUserToSlots;
}
exports.makeDateToUserToShiftMap = makeDateToUserToShiftMap;
function repeatShiftsFromTemplate(startDate, endDate, template, localTimeZone) {
    const out = [];
    const repeatStartInOriginalTz = luxon_1.DateTime.fromJSDate(template.getRepeatStart(), { zone: template.getTimeZone() }).set({ hour: template.getStartHour(), minute: template.getStartMinute() });
    const repeatStartInLocal = repeatStartInOriginalTz.setZone(localTimeZone);
    const repeatEndInOriginalTz = luxon_1.DateTime.fromJSDate(template.getRepeatEnd(), { zone: template.getTimeZone() }).set({ hour: template.getEndHour(), minute: template.getEndMinute() });
    const repeatEndInLocal = repeatEndInOriginalTz.setZone(localTimeZone);
    let start = repeatStartInLocal;
    if (start.valueOf() < startDate.valueOf()) {
        const s = luxon_1.DateTime.fromJSDate(startDate, { zone: localTimeZone });
        const weekdayDiff = ((start.weekday - s.weekday) + 7) % 7;
        start = s.plus({ day: weekdayDiff });
    }
    start = start.set({ hour: repeatStartInLocal.hour, minute: repeatStartInLocal.minute });
    let end = start.set({ hour: repeatEndInLocal.hour, minute: repeatEndInLocal.minute });
    if (end.valueOf() < start.valueOf()) {
        end = end.plus({ day: 1 });
    }
    let repeats = 1;
    // If template start and end overlap with dates
    while ((0, common_2.timespansOverlapIncl)(startDate, endDate, start.toJSDate(), end.toJSDate()) && (0, common_2.timespansOverlapIncl)(repeatStartInLocal.toJSDate(), repeatEndInLocal.toJSDate(), start.toJSDate(), end.toJSDate())) {
        let isIncluded = true;
        for (let i = 0; i < template.exclude.length; i++) {
            const d = template.exclude[i];
            const date = luxon_1.DateTime.fromJSDate(d, { zone: localTimeZone });
            const format = "yyyy MM dd";
            // If the same calendar date in local time, then excluded
            if (date.toFormat(format) == start.toFormat(format)) {
                isIncluded = false;
                break;
            }
        }
        if (isIncluded) {
            const feId = `${template.getOriginalId()}-${repeats}`;
            const options = {
                hideTo: template.isHideTo(),
                hideFrom: template.isHideFrom(),
                rest: template.getRest(),
                feId,
                roleId: template.getOriginalRoleId(),
                roleName: template.getRoleName(),
                purpose: template.getPurpose(),
                linkPrev: template.getLinkPrev(),
                linkNext: template.getLinkNext(),
                isEdited: template.getIsEdited(),
            };
            if (template.sections.length) {
                options.sections = [];
                for (const sTemplate of template.sections) {
                    const templateStart = luxon_1.DateTime.fromJSDate(sTemplate.getStartDate(), { zone: localTimeZone });
                    const templateEnd = luxon_1.DateTime.fromJSDate(sTemplate.getEndDate(), { zone: localTimeZone });
                    const sectionStart = start.set({ hour: templateStart.hour, minute: templateStart.minute });
                    const sectionEnd = start.set({ hour: templateEnd.hour, minute: templateEnd.minute });
                    if (sectionEnd.valueOf() < sectionStart.valueOf()) {
                        sectionEnd.plus({ day: 1 });
                    }
                    const required = {
                        id: sTemplate.getOriginalId(),
                        type: sTemplate.getType(),
                        branchId: template.branchId,
                        userId: template.userId,
                        startDate: sectionStart.toJSDate(),
                        endDate: sectionEnd.toJSDate(),
                    };
                    const shiftOptions = {
                        roleId: parseInt(sTemplate.getRoleId())
                    };
                    const section = new section_1.Section(repeats, required, shiftOptions);
                    options.sections.push(section);
                }
            }
            const required = {
                userId: template.userId,
                startDate: start.toJSDate(),
                endDate: end.toJSDate(),
                type: template.getType(),
                branchId: template.branchId,
                id: repeats,
            };
            const shift = new repeatShift_1.RepeatShift(template.getOriginalId(), required, options);
            out.push(shift);
            repeats++;
        }
        // Add 7 days to current date
        start = start.plus({ days: template.interval });
        end = end.plus({ days: template.interval });
    }
    return out;
}
exports.repeatShiftsFromTemplate = repeatShiftsFromTemplate;
function mergeLinkedShifts(morning, night) {
    const required = {
        id: night.getOriginalId(),
        branchId: night.getOriginalBranchId(),
        userId: night.getOriginalUserId(),
        type: night.getType(),
        startDate: night.getStartDate(),
        endDate: morning.getEndDate(),
    };
    const options = {
        rest: night.getRest() + morning.getRest(),
        hideTo: morning.isHideTo(),
        hideFrom: morning.isHideFrom(),
        sections: [],
        roleId: night.getOriginalRoleId() || undefined,
        feId: `${night.getId()}-${morning.getId()}`
    };
    options.sections?.push(...morning.getSections());
    options.sections?.push(...night.getSections());
    const out = new mergedShift_1.MergedShift(night.getOriginalId(), morning.getOriginalId(), required, options);
    return out;
}
exports.mergeLinkedShifts = mergeLinkedShifts;
async function initPlanFunctions(tableDates, branch, functionStore, valueStore) {
    const startInclusive = tableDates[0].getDate();
    const endInclusive = tableDates[tableDates.length - 1].getEnd();
    // query functions
    try {
        const { data, pagination } = await webappApi.fetchPlanFunctions({
            branchId: branch.getOriginalId(),
            isDeleted: false,
            showInSummary: true,
        });
        data.forEach(p => {
            functionStore.add(p);
        });
    }
    catch (e) {
        if (e instanceof Error) {
            alert(e.message);
        }
    }
    // For each function
    const planFunctions = functionStore.query({ branchId: branch.getId() });
    for await (const pf of planFunctions) {
        // Do not fetch plan values for legacy placeholders
        if (!pf.getOriginalId()) {
            continue;
        }
        const { data, pagination } = await webappApi.fetchPlanValues({
            functionId: pf.getOriginalId(),
            startDateGte: startInclusive,
            startDateLte: endInclusive,
        });
        data.forEach(v => {
            valueStore.add(v);
        });
    }
}
exports.initPlanFunctions = initPlanFunctions;
function makeComputedValuesMap(tableDates, toCompute, functionStore, valueStore) {
    const out = {};
    tableDates.forEach(td => {
        out[td.index()] = {};
        toCompute.forEach(pf => {
            const v = computeValue(pf, td, functionStore, valueStore);
            if (v === undefined) {
                return;
            }
            out[td.index()][pf.getId()] = v;
        });
    });
    return out;
}
exports.makeComputedValuesMap = makeComputedValuesMap;
function computeValue(pf, td, functionStore, valueStore) {
    if (pf.isAllowedDefaultFunction()) {
        const opts = {
            functionStore,
            valueStore,
            startDateGte: td.getDate(),
            endDateLte: td.getEnd(),
        };
        const { value, errors } = (0, plan_1.evaluatePlanFunction)(pf.getFunctionBody(), opts);
        if (errors.length) {
            return;
        }
        return value;
    }
    else {
        // Find plan value for given day
        const pvs = valueStore.query({
            variableId: pf.getId(),
            startDateEq: td.getDate(),
            endDateEq: td.getEnd(),
            periodLength: td.getEnd().valueOf() - td.getDate().valueOf(),
        });
        if (!pvs.length) {
            return;
        }
        return pvs[0].getNumValue();
    }
}
