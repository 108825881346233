<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve" viewBox="0 0 85 85" id="sort-icon" width="30px" height="30px">
        <path fill="currentColor" d="M44.6,69.6c-0.2,0.3-0.5,0.5-0.8,0.6c0.2-0.1,0.4-0.2,0.6-0.4L44.6,69.6z M76.1,31.5H44.9v-11l4.8,4.8
        c0.4,0.4,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5c0.7-0.7,0.7-1.9,0-2.6l-7.9-8c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-1,0.2-1.3,0.5l-8,8
        c-0.7,0.7-0.7,1.9,0,2.6c0.7,0.7,1.9,0.7,2.6,0l4.8-4.8v11H10.1c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h66.1c1,0,1.8-0.8,1.8-1.8
        S77.2,31.5,76.1,31.5z M76.1,49.5H10.1c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h31.2v10.9l-4.9-4.8c-0.7-0.7-1.9-0.7-2.6,0
        c-0.7,0.7-0.7,1.9,0,2.6l8,8c0.4,0.4,0.8,0.5,1.3,0.5c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3-0.1
        c0.2-0.1,0.4-0.2,0.6-0.4l0.2-0.2l7.7-7.7c0.7-0.7,0.7-1.9,0-2.6c-0.7-0.7-1.9-0.7-2.6,0L44.9,64V53.2h31.2c1,0,1.8-0.8,1.8-1.8
        C78,50.3,77.2,49.5,76.1,49.5z"/>
    </svg>
</template>
