<template>
<div class="svg-container">
  <svg class="role-group-star-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve" viewBox="10 10 65 65" width="15px" height="15px">
    <path fill="currentColor" d="M67.13,74.46H17.78c-4.04,0-7.33-3.29-7.33-7.33V17.78c0-4.04,3.29-7.33,7.33-7.33h49.35
    c4.04,0,7.33,3.29,7.33,7.33v49.35C74.46,71.17,71.17,74.46,67.13,74.46z M17.78,12.77c-2.76,0-5.01,2.25-5.01,5.01v49.35
    c0,2.77,2.25,5.01,5.01,5.01h49.35c2.77,0,5.01-2.25,5.01-5.01V17.78c0-2.76-2.25-5.01-5.01-5.01H17.78z M63.98,37.2
    c-0.11-0.35-0.42-0.6-0.78-0.67l-13.72-2.01L43.34,22.1c-0.32-0.67-1.45-0.67-1.8,0l-6.1,12.42l-13.72,2.01
    c-0.39,0.07-0.67,0.32-0.81,0.67c-0.11,0.39,0,0.78,0.28,1.02l9.91,9.67l-2.33,13.65c-0.07,0.39,0.07,0.78,0.39,0.99
    c0.18,0.14,0.39,0.21,0.56,0.21s0.32-0.07,0.49-0.14l12.24-6.42l12.28,6.42c0.32,0.18,0.74,0.18,1.02-0.07
    c0.32-0.21,0.49-0.6,0.42-0.99l-2.36-13.65l9.91-9.67C64.02,37.97,64.12,37.58,63.98,37.2z M63.21,36.53l-13.72-2.01L43.34,22.1
    c-0.32-0.67-1.45-0.67-1.8,0l-6.1,12.42l-13.72,2.01c-0.39,0.07-0.67,0.32-0.81,0.67c-0.11,0.39,0,0.78,0.28,1.02l9.91,9.67
    l-2.33,13.65c-0.07,0.39,0.07,0.78,0.39,0.99c0.18,0.14,0.39,0.21,0.56,0.21s0.32-0.07,0.49-0.14l12.24-6.42l12.28,6.42
    c0.32,0.18,0.74,0.18,1.02-0.07c0.32-0.21,0.49-0.6,0.42-0.99l-2.36-13.65l9.91-9.67c0.28-0.25,0.39-0.64,0.25-1.02
    C63.88,36.84,63.56,36.6,63.21,36.53z M63.98,37.2c-0.11-0.35-0.42-0.6-0.78-0.67l-13.72-2.01L43.34,22.1
    c-0.32-0.67-1.45-0.67-1.8,0l-6.1,12.42l-13.72,2.01c-0.39,0.07-0.67,0.32-0.81,0.67c-0.11,0.39,0,0.78,0.28,1.02l9.91,9.67
    l-2.33,13.65c-0.07,0.39,0.07,0.78,0.39,0.99c0.18,0.14,0.39,0.21,0.56,0.21s0.32-0.07,0.49-0.14l12.24-6.42l12.28,6.42
    c0.32,0.18,0.74,0.18,1.02-0.07c0.32-0.21,0.49-0.6,0.42-0.99l-2.36-13.65l9.91-9.67C64.02,37.97,64.12,37.58,63.98,37.2z"/>
  </svg>
</div>
</template>
