<script>
import Modal from './Modal.vue';
import { getUrlParam } from '../common/common';
import { summaryDwsAuto } from '../requests/summaryDwsAuto';
import { MeStore } from '../store/v1/meStore';
import { handleAutoDwsSuccess } from '../js/summary_dws_auto';

const ENDPOINTS = Object.freeze({
  AUTO: "auto",
  AUTO_FILL: "auto_fill",
  DWS_AUTO: "dws_auto"
})

export default {
  props: {
    roles: Array,
    timePeriodType: String,
    tags: Array,
    activeDate: String,
    isDailyAuto: {
      type: Boolean,
      default: false,
    },
    meStore: {
      type: MeStore,
      default: null,
    }
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      showPopup: null,
      checkedRoles: [],
      selectAll: false,
    }
  },
  components: {
    Modal,
  },
  methods: {
    closeModal() {
      this.isVisible = false;
    },
    openModal() {
      this.isVisible = true;
    },
    confirmAuto() {
      if (this.isDailyAuto) {
        this.submitDwsAuto();
        return;
      }
      return this.onConfirm(ENDPOINTS.AUTO);
    },
    confirmAutoFill() {
      return this.onConfirm(ENDPOINTS.AUTO_FILL);
    },
    onConfirm(endpoint) {
      if (!this.roles.length) {
        return this.closeModal();
      }
      if (!this.checkedRoles.length) {
        return alertModal.alert("業務が選択されておりません。");
      }
      this.submitAuto(endpoint);
    },
    onCancel() {
      this.isVisible = false;
    },
    toggleAllCheckboxes() {
      if (this.selectAll) {
        this.checkedRoles = this.roles.map((role) => role.ID);
      } else {
        this.checkedRoles = [];
      }
    },
    handleCheckboxChange() {
      this.selectAll = this.checkedRoles.length === this.roles.length;
    },
    async submitDwsAuto() {
      if (this.isLoading || !this.meStore) {
        return;
      }
      this.isLoading = true;
      try {
        let roleIds = [];
        let tags = [];
        const roleIdsParam = getUrlParam("roles");
        const tagsParam = getUrlParam("tags");

        if (roleIdsParam) {
          roleIds = roleIdsParam.split(",").map(r => parseInt(r));
        }
        if (tagsParam) {
          tags = tagsParam.split(",");
        }
        const payload = {
          date: this.activeDate,
          roleSectionIds: [...this.checkedRoles],
          tags: tags,
          roleIds: roleIds,
        }
        const response = await summaryDwsAuto(this.meStore.getActiveUserId(), this.meStore.getActiveBranchId(), payload);
        if (response.success) {
          handleAutoDwsSuccess(response)
        }
      }
      catch (err) {
        console.error(err);
        alertModal.alert(err);
      }
      finally {
        this.isLoading = false;
        this.isVisible = false;
      }
    },
    submitAuto(endpoint) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      const data = {
        list: this.timePeriodType,
        date: this.activeDate,
        tags: this.getSelectedTags,
        until: getUrlParam("until"),
        autoSel: this.checkedRoles.join(","),
      };
      const url = DashURL(endpoint);

      $.post(url, data, function (response){
        if (response.incomplete) {
          let i = 0;
          const message = ["以下の業務人数を満たすことができませんでした。", ""];
          for (; i < Math.min(7, response.incomplete.length); i++) {
            const j = response.incomplete[i];
            message.push(j.date + " - " + j.placed + "/" + j.required)
          }
          if (i < response.incomplete.length) {
            message.push('...');
          }
          alertModal.alert(message.join('\r\n'), true);
          return;
        }
        RefreshOnSuccess(response);
      })
      .fail(function() {
        alertModal.alert("fail to create auto slots", true);
      });
    },
  },
  computed: {
    loadingClass() {
      return this.isLoading ? "loading" : "";
    },
    modalName() {
      return "auto-modal";
    },
    getSelectedTags() {
      const tags = [];
      if (this.tags) {
        this.tags.forEach(tagObj => {
          if (tagObj.selected) {
            tags.push(tagObj.tag);
          }
        })
      }
      return tags.join(",")
    },
  },
}
</script>

<template>
  <Modal :modal-name="modalName" v-if="isVisible" @closeModal="closeModal" @confirm="onConfirm" @cancel="onCancel" :has-title-divider="true" :has-footer-divider="true">
    <template v-slot:header-title>
      業務を選択してください
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <!-- Roles -->
        <div v-if="roles.length" class="roles-auto-role">
          <div class="auto-role-wrapper">
            <label for="all-roles" class="oplus-form-checkbox">
              <input v-model="selectAll" type="checkbox" name="all" id="all-roles" @change="toggleAllCheckboxes">
              <strong>全部</strong>
            </label>
            <div v-for="(role, index) in roles" :key="role.ID">
              <label @click="showPopup = index" @mouseleave="showPopup = null" :style="{ color:'#' + role.Color }" class="oplus-form-checkbox" :data-role="role.ID">
                <input :value="role.ID" v-model="checkedRoles" @change="handleCheckboxChange" class="auto-role-checkbox text-popup" type="checkbox">
                <span class="auto-role-name">{{ role.Name }}</span>
                <transition name="fade">
                  <span v-show="showPopup === index" class="auto-role-text-popup">{{ role.Name }}</span>
                </transition>
              </label>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="empty centered" >
            <p class="empty-title h5">業務がありません</p>
            <p class="empty-subtitle">業務管理ページから業務を作成してください。</p>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer-btn>
      <div class="btn-wrapper">
        <button :class="loadingClass" class="oplus-modal-btn oplus-confirm-btn"  @click="confirmAutoFill()">穴埋め</button>
        <button :class="loadingClass" class="oplus-modal-btn oplus-alt-confirm-btn"  @click="confirmAuto()">確定</button>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
  .content-wrapper {
    display: flex;
    min-width: 320px;
    width: fit-content;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    height: fit-content;
    padding: 20px;
  }

  .auto-role-title {
    text-align: left;
    font-size: 1rem;
  }

  .auto-role-name {
    max-width: 140px;
    width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .auto-role-wrapper {
    display: grid;
    max-width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(100px,1fr));
    text-align: left;
    gap: 20px;
  }

  .auto-role-wrapper.etc-wrapper {
    padding-bottom: 0;
  }
  .btn-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .auto-role-text-popup {
    position: absolute;
    right: 0%;
    bottom: -80%;
    background: rgba(48,55,66,.95);
    border-radius: .1rem;
    color: #fff;
    font-size: .7rem;
    padding: .2rem .4rem;
    pointer-events: none;
    z-index: 300;
    max-width: 200px;
    height: fit-content;
    white-space: break-spaces;
    word-wrap: break-word;
  }

  .oplus-form-checkbox {
    position: relative;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  @media (max-width: 392px) {

    .auto-role-wrapper {
      max-width: 70%;
    }
    .content-wrapper {
      min-width: 280px;
    }
    .auto-role-name {
      max-width: 280px;
    }
  }

</style>
