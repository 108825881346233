"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OSType = void 0;
var OSType;
(function (OSType) {
    OSType["MAC"] = "mac";
    OSType["WINDOWS"] = "windows";
    OSType["IOS"] = "ios";
    OSType["ANDROID"] = "android";
    OSType["UNKNOWN"] = "unknown";
})(OSType || (exports.OSType = OSType = {}));
