<script>
import Modal from './Modal.vue';

export default {
  props: {
    modalName: String,
    companyID: Number,
    minVacationWeekly: Number,
    minVacationMonthly: Number,
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      weeklyValue: 0,
      monthlyValue: 0,
    }
  },
  components: {
    Modal,
  },
  methods: {
    closeModal() {
      this.isVisible = false;
    },
    openModal() {
      this.isVisible = true;
    },
    onConfirm() {
      this.submitLaborSetting();
    },
    onCancel() {
      this.isVisible = false;
    },
    handleWeeklySelect() {
      return this.weeklyValue
    },
    handleMonthlySelect() {
      return this.monthlyValue
    },
    submitLaborSetting() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      const data = {
        "min-vacation-weekly": this.weeklyValue,
        "min-vacation-monthly": this.monthlyValue,
      };

      $.put(DashURL("company/" + this.companyID), data, function (response){
        RefreshOnSuccess(response);
      })
      .fail(function(){
        alertModal.alert(errors.LABOR.UPDATE);
        this.isLoading = false;
      });
    }
  },
  computed: {
    loadingClass() {
      return this.isLoading ? "loading" : "";
    }
  },
  created() {
    this.weeklyValue = this.minVacationWeekly;
    this.monthlyValue = this.minVacationMonthly;
  }
}
</script>

<template>
  <Modal :modal-name="modalName" v-if="isVisible" @closeModal="closeModal" @confirm="onConfirm" @cancel="onCancel" :has-title-divider="true" :has-footer-divider="true">
    <template v-slot:header-title>
        労働設定
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <div class="form-group">
          <select v-model="weeklyValue" id="min-vacation-weekly" class="labor-select form-select">
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </select>
        </div>
        <span>日公休が週間で最低無ければアラート</span>
        <div class="form-group">
          <select v-model="monthlyValue" id="min-vacation-monthly" class="labor-select form-select">
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
            <option value="31">31</option>
          </select>
        </div>
        <span>日公休が月間で最低無ければアラート</span>
      </div>
    </template>
    <template v-slot:footer-btn>
      <button class="oplus-modal-btn oplus-alt-confirm-btn" :class="loadingClass" @click="onConfirm">確定</button>
    </template>
  </Modal>
</template>

<style scoped>
  .header-wrapper {
    text-align: center;
    padding-bottom: 0;
  }
  .content-wrapper {
    display: flex;
    width: fit-content;
    text-align: center;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    margin: auto;
  }

  .labor-select {
    width: 56px;
    border-radius: 5px;
    border-color: #929292;
    cursor: pointer;
    color: rgb(27, 27, 27);
    font-size: .9rem;
  }
</style>
