<script>
import Modal from '../Modal.vue';
import { setOthersPasswordRequest, setPasswordRequest } from '../../js/password_requests';
import { submitUserPass } from '../../js/login_password';
import { validateChangeOrAddPassword, isPasswordValid } from '../../js/validation/password.js';
import ERRORS from '../../js/errors'
import { PASSWORD_ERROR_TYPES } from '../../js/enums/password_error_types'

export default {
  props: {
    thisUser: Object,
    activeUserId: Number,
    changePasswordAndLogin: Boolean, // Note: this should have a separate Bool for checking whether the user "is logged in" or "has default password (AIM)"
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      passwordInput: "",
      repeatPassword: "",
    }
  },
  methods: {
    closeModal() {
      this.isVisible = false;
      this.resetData();
    },
    openModal() {
      this.isVisible = true;
    },
    resetData() {
      this.passwordInput = "";
      this.repeatPassword = "";
    },
    onConfirm() {
      // This Modal is used in to occasions:
      // 1. For Adding and Editing password
      // 2. For setting up password and logging in (AIM user exclusive)
      if (this.isLoading) {
        return;
      }

      const { message, isValid } = validateChangeOrAddPassword(this.passwordInput, this.repeatPassword);
      if (!isValid) {
        alertModal.alert(message);
        return;
      }
      this.isLoading = true;
      // Set password and login
      if (this.changePasswordAndLogin) {
        this.submitPassAndLogin();
        return;
      }

      this.submitPassword();
    },
    async submitPassword() {
      let response;
      try {
        // change other staff's password (only for branch/group owners)
        if (this.thisUser.id !== this.activeUserId) {
          response = await setOthersPasswordRequest(this.activeUserId, this.thisUser.id, {
            Pass: this.passwordInput
          })
        } else {
          response = await setPasswordRequest(this.activeUserId, {
            Pass: this.passwordInput,
          })
        }
        if (response.success) {
          alertModal.alertSuccess(this.successTextComputed, true);
          this.closeModal();
          return;
        }
        alertModal.alert(ERRORS.PASSWORD[data.detail], false);
      } catch (err) {
        alertModal.alert(err, true);
      }
      this.isLoading = false;
    },
    async submitPassAndLogin() {
      await submitUserPass(this.passwordInput);
    },
    passwordValidIcon(isInvalid) {
      if (!isInvalid) {
        return "zmdi-check text-success"
      } else {
        return "zmdi-close text-error"
      }
    },
  },
  computed: {
    isEditPassword() {
      return this.thisUser.hasPassword;
    },
    createOrEditText() {
      return this.isEditPassword ? "変更" : "作成";
    },
    loginText() {
      return this.changePasswordAndLogin ? "とログイン" : "";
    },
    successTextComputed() {
      let text = "パスワードが正常に追加されました。";
        if (this.isEditPassword) {
          text = "パスワードが正常に変更されました。";
        }
      return text;
    },
    passwordErrorType() {
      const { errorType } = isPasswordValid(this.passwordInput);
      return errorType
    },
    isPasswordTooLongOrShort() {
      const errorType = this.passwordErrorType;
      return this.passwordValidIcon(errorType[PASSWORD_ERROR_TYPES.CHARS_LENGTH]);
    },
    isPasswordHasInvalidChars() {
      const errorType = this.passwordErrorType;
      return this.passwordValidIcon(errorType[PASSWORD_ERROR_TYPES.INVALID_CHARS]);
    },
    loadingClass() {
      return this.isLoading ? "loading" : "";
    },
  },
  components: {
    Modal,
  },
}
</script>
<template>
  <Modal
    v-if="isVisible"
    @closeModal="closeModal"
    :has-title-divider="true"
    :has-footer-divider="false"
  >
    <template v-slot:header-title>
      <span>パスワードを{{ createOrEditText }}</span>
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <form @keyup.enter="onConfirm">
          <div class="form-group">
            <label class="form-label" for="new-password">新しいパスワード</label>
            <input v-model="passwordInput" class="form-input" type="password" id="new-password" placeholder="パスワードを入力" minlength="4" maxlength="72">
          </div>
          <div class="password-requirement-list">
            <div class="password-req-list-item">
              <i class="zmdi zmdi-hc-lg" :class="isPasswordHasInvalidChars"></i>
              <span>パスワードに無効な文字が含まれています。</span>
            </div>
            <div class="password-req-list-item">
              <i class="zmdi zmdi-hc-lg" :class="isPasswordTooLongOrShort"></i>
              <span>パスワードが<strong>4文字から〜72文字まで</strong>の長さが必要です。</span>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" for="confirm-password">新しいパスワードを再入力</label>
            <input v-model="repeatPassword" class="form-input" type="password" id="confirm-password" placeholder="パスワードを再入力" minlength="4" maxlength="72">
          </div>
        </form>
      </div>
    </template>
    <template v-slot:footer-btn>
      <button class="oplus-modal-btn oplus-alt-confirm-btn submit-password-btn" :class="loadingClass" @click="onConfirm">{{ createOrEditText }}{{ loginText }}</button>
    </template>
  </Modal>
</template>
<style scoped>
  .content-wrapper {
    min-width: 320px;
    padding: 25px 0;
  }

  .password-modal-body {
    padding-top: 0;
  }

  .submit-password-btn {
    width: 100%;
  }

  .password-requirement-list {
    display: flex;
    flex-direction: column;
    font-size: .65rem;
    padding-bottom: 10px;
  }

  .password-req-list-item {
    display: flex;
    gap: 3px;
    align-items: center;
  }
</style>
