// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.role-section-template-tab {
    display: flex;
    border-bottom: .05rem solid #dadee4;
    color: #bbbdbf;
    text-align: center;
}
.role-section-tab-item,
  .role-section-tab-item span {
    min-width: 105px;
    cursor: pointer;
}
.role-section-tab-item {
    padding-bottom: 8px;
    transition: .2s ease;
    position: relative;
}
.tab-underline  {
    border-bottom: .1rem solid #58585a;
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
}
.role-section-tab-item.active {
    color: #58585a;
}

`, "",{"version":3,"sources":["webpack://./src/roleSectionTemplate/components/RoleSectionTabs.vue"],"names":[],"mappings":";AAmCE;IACE,aAAa;IACb,mCAAmC;IACnC,cAAc;IACd,kBAAkB;AACpB;AACA;;IAEE,gBAAgB;IAChB,eAAe;AACjB;AAEA;IACE,mBAAmB;IACnB,oBAAoB;IACpB,kBAAkB;AACpB;AACA;IACE,kCAAkC;IAClC,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,SAAS;AACX;AACA;IACE,cAAc;AAChB","sourcesContent":["<script setup lang=\"ts\">\nimport { PropType } from 'vue';\n\nconst props = defineProps({\n  tabItemTexts: {\n    type: Array as PropType<string[]>,\n    required: true,\n  },\n  activeTabIndex: {\n    type: Number,\n    default: 0,\n    required: true,\n  }\n})\nconst emits = defineEmits<{\n  \"emit-tab-index\": [number];\n}>();\n\nconst activeClass = (index: number): void => {\n  index === props.activeTabIndex ? \"active\" : \"\";\n}\nfunction handleTabClick(index: number): void {\n  emits(\"emit-tab-index\", index);\n}\n</script>\n\n<template>\n  <div class=\"role-section-template-tab\">\n    <span v-for=\"text, index in tabItemTexts\" class=\"role-section-tab-item\" :class=\"activeClass(index)\" @click=\"handleTabClick(index)\">\n      <span class=\"role-section-tab-text\">{{ text }}</span>\n      <span class=\"tab-underline\" v-show=\"index === props.activeTabIndex\"></span>\n    </span>\n  </div>\n</template>\n<style>\n  .role-section-template-tab {\n    display: flex;\n    border-bottom: .05rem solid #dadee4;\n    color: #bbbdbf;\n    text-align: center;\n  }\n  .role-section-tab-item,\n  .role-section-tab-item span {\n    min-width: 105px;\n    cursor: pointer;\n  }\n\n  .role-section-tab-item {\n    padding-bottom: 8px;\n    transition: .2s ease;\n    position: relative;\n  }\n  .tab-underline  {\n    border-bottom: .1rem solid #58585a;\n    width: 100%;\n    display: block;\n    position: absolute;\n    bottom: 0;\n  }\n  .role-section-tab-item.active {\n    color: #58585a;\n  }\n\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
