"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parse = exports.getKeywords = exports.calculate = exports.evaluate = exports.whiteSpaceRegex = exports.keywordRegex = void 0;
const tokenTypes_1 = require("./model/tokenTypes");
const token_1 = require("./model/token");
const tokenStream_1 = require("./model/tokenStream");
const parseErrors_1 = require("./enum/parseErrors");
exports.keywordRegex = new RegExp(/[\w\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf]/);
exports.whiteSpaceRegex = new RegExp(/[\s\u3000]/);
function evaluate(functionBody, table) {
    let out = 0;
    const { tokens, errors } = parse(functionBody);
    if (!tokens.length || errors.length) {
        throw Error(`bad function: ${errors.join("\n")}`);
    }
    const toCalc = [];
    // Before calculating, replace keywords with their number values
    for (let i = 0; i < tokens.length; i++) {
        const c = tokens[i];
        if (c.isKeyword()) {
            const newToken = convertKeywordToNum(c, table);
            toCalc.push(newToken);
            continue;
        }
        toCalc.push(c);
    }
    try {
        out = calculate(toCalc);
    }
    catch (e) {
        throw new Error(`bad function: ${e}`);
    }
    return out;
}
exports.evaluate = evaluate;
function convertKeywordToNum(t, table) {
    const k = t.getSanitizedKeyword();
    let val = table?.[k];
    if (val === undefined) {
        throw new Error(`no variable given for keyword ${k}`);
    }
    if (t.isNegative()) {
        val = -val;
    }
    return new token_1.Token(tokenTypes_1.tokenTypes.NUM, val.toString(), val);
}
function calculate(list) {
    const s = new tokenStream_1.TokenStream(list);
    let out = 0;
    if (!list.length) {
        return out;
    }
    s.get();
    if (s.getCurrent().isEnd())
        return 0;
    return calculateAddSub(false, s);
}
exports.calculate = calculate;
function calculateAddSub(get, s) {
    let left = calculateMultDiv(get, s);
    while (true) {
        switch (s.getCurrent().type) {
            case tokenTypes_1.tokenTypes.ADD:
                left += calculateMultDiv(true, s);
                break;
            case tokenTypes_1.tokenTypes.SUB:
                left -= calculateMultDiv(true, s);
                break;
            default:
                return left;
        }
    }
}
function calculateMultDiv(get, s) {
    let left = calc(get, s);
    while (true) {
        switch (s.getCurrent().type) {
            case tokenTypes_1.tokenTypes.MULTI:
                left *= calc(true, s);
                break;
            case tokenTypes_1.tokenTypes.DIV:
                const r = calc(true, s);
                if (r === 0) {
                    throw new Error('dividing by zero!');
                }
                left /= r;
                break;
            default:
                return left;
        }
    }
}
function calc(get, s) {
    if (get) {
        s.get();
    }
    switch (s.getCurrent().type) {
        case tokenTypes_1.tokenTypes.NUM:
            const n = s.current.value;
            if (n === undefined) {
                throw new Error('invalid number!');
            }
            s.get();
            return n;
        case tokenTypes_1.tokenTypes.OPAREN:
            const e = calculateAddSub(true, s);
            if (s.getCurrent().type !== tokenTypes_1.tokenTypes.CPAREN) {
                throw new Error('expected closing parens');
            }
            s.get();
            return e;
        default:
            throw new Error('primary expected');
    }
}
function getKeywords(tokens) {
    const out = [];
    tokens.forEach(t => {
        if (t.isKeyword()) {
            out.push(t.getSanitizedKeyword());
        }
    });
    return out;
}
exports.getKeywords = getKeywords;
function parse(functionBody) {
    const tokens = [];
    const parensStack = [];
    const numStack = [];
    const fBody = functionBody + tokenTypes_1.tokenTypes.END;
    let isKeyword = false;
    let store = "";
    const errors = [];
    for (let i = 0; i < fBody.length; i++) {
        const curChar = fBody[i];
        if (!isKeyword && (isNaN(parseInt(curChar)) && curChar !== tokenTypes_1.tokenTypes.PERIOD) && !isNaN(parseFloat(store))) {
            // End of number
            const n = parseFloat(store);
            tokens.push(new token_1.Token(tokenTypes_1.tokenTypes.NUM, store, n));
            numStack.push(n);
            store = "";
        }
        if (isKeyword && !exports.keywordRegex.test(curChar)) {
            // End of keyword
            if (numStack.length) {
                errors.push(parseErrors_1.parseErrors.UNEXPECTED_KEYWORD);
            }
            tokens.push(new token_1.Token(tokenTypes_1.tokenTypes.KEY, store));
            numStack.push(0);
            store = "";
            isKeyword = false;
        }
        if (!isNaN(parseInt(curChar))) { // Number
            store += curChar;
        }
        else if (exports.keywordRegex.test(curChar)) { // Acceptable keyword character
            isKeyword = true;
            store += curChar;
        }
        else { // Other characters
            if (!isKeyword && exports.whiteSpaceRegex.test(curChar)) {
                // Allow spaces that are outside of keywords
                continue;
            }
            const lastToken = tokens[tokens.length - 1];
            switch (curChar) {
                case tokenTypes_1.tokenTypes.PERIOD:
                    store += curChar;
                    break;
                case tokenTypes_1.tokenTypes.END:
                    tokens.push(new token_1.Token(tokenTypes_1.tokenTypes.END, tokenTypes_1.tokenTypes.END));
                    break;
                case tokenTypes_1.tokenTypes.OPAREN:
                    // Throw error if not index 0 or if last token wasn't an operator.
                    if ((!lastToken && i !== 0) || (lastToken && !isOperator(tokens[tokens.length - 1].strValue))) {
                        errors.push(parseErrors_1.parseErrors.UNEXPECTED_PARENS);
                    }
                    tokens.push(new token_1.Token(tokenTypes_1.tokenTypes.OPAREN, curChar));
                    parensStack.push("(");
                    break;
                case tokenTypes_1.tokenTypes.CPAREN:
                    tokens.push(new token_1.Token(tokenTypes_1.tokenTypes.CPAREN, curChar));
                    if (parensStack.length) {
                        parensStack.pop();
                    }
                    else {
                        errors.push(parseErrors_1.parseErrors.CLOSED_PARENS);
                    }
                    break;
                case tokenTypes_1.tokenTypes.ADD:
                    tokens.push(new token_1.Token(tokenTypes_1.tokenTypes.ADD, tokenTypes_1.tokenTypes.ADD));
                    break;
                case tokenTypes_1.tokenTypes.SUB:
                    // If first token in stream or the previous token was not a number nor keyword, expect this to be a negative sign.
                    if (!lastToken || (exports.keywordRegex.test(fBody[i + 1]) && !(lastToken.isKeyword() || lastToken.isNumber()))) {
                        store += curChar;
                        continue;
                    }
                    else {
                        tokens.push(new token_1.Token(tokenTypes_1.tokenTypes.SUB, tokenTypes_1.tokenTypes.SUB));
                    }
                    break;
                case tokenTypes_1.tokenTypes.MULTI:
                    tokens.push(new token_1.Token(tokenTypes_1.tokenTypes.MULTI, tokenTypes_1.tokenTypes.MULTI));
                    break;
                case tokenTypes_1.tokenTypes.DIV:
                    tokens.push(new token_1.Token(tokenTypes_1.tokenTypes.DIV, tokenTypes_1.tokenTypes.DIV));
                    break;
                default:
                    errors.push(parseErrors_1.parseErrors.UNEXPECTED_CHAR);
            }
            if (isOperator(curChar)) {
                if (numStack.length) {
                    numStack.pop();
                }
                else {
                    errors.push(parseErrors_1.parseErrors.OPERATORS);
                }
            }
        }
    }
    if (parensStack.length) {
        errors.push(parseErrors_1.parseErrors.MISMATCH_PARENS);
    }
    if (numStack.length !== 1) {
        errors.push(parseErrors_1.parseErrors.MISMATCH_OP_AND_NUM);
    }
    return { tokens, errors };
}
exports.parse = parse;
function isOperator(s) {
    return s === tokenTypes_1.tokenTypes.ADD || s === tokenTypes_1.tokenTypes.DIV || s === tokenTypes_1.tokenTypes.SUB || s === tokenTypes_1.tokenTypes.MULTI;
}
