"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShiftStore = void 0;
class ShiftStore {
    data;
    constructor() {
        this.data = {};
    }
    add(slot) {
        this.data[slot.getId()] = slot;
    }
    byId(id) {
        return this.data[id];
    }
    query(options) {
        let out = Object.values(this.data);
        if (options.startDateMax) {
            const maxStart = options.startDateMax.valueOf();
            out = out.filter(s => {
                if (s.getStartDate().valueOf() < maxStart) {
                    return s;
                }
            });
        }
        if (options.startDateMin) {
            const minstart = options.startDateMin.valueOf();
            out = out.filter(s => {
                if (s.getStartDate().valueOf() >= minstart) {
                    return s;
                }
            });
        }
        if (options.repeatTemplateId) {
            out = out.filter(s => {
                if (s.getId().split("-")[0] == options.repeatTemplateId) {
                    return s;
                }
            });
        }
        return out;
    }
    delete(id) {
        if (!this.data[id]) {
            return;
        }
        delete this.data[id];
    }
}
exports.ShiftStore = ShiftStore;
