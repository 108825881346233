"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dateInt = Object.freeze({
    SUN: 0,
    MON: 1,
    TUE: 2,
    WED: 3,
    THUR: 4,
    FRI: 5,
    SAT: 6,
});
exports.default = dateInt;
