"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RepeatShiftTemplate = void 0;
const shiftTemplate_1 = require("./shiftTemplate");
class RepeatShiftTemplate extends shiftTemplate_1.ShiftTemplate {
    userId;
    branchId;
    sections;
    exclude;
    locked;
    interval = 7;
    repeatStart;
    repeatEnd;
    purpose;
    linkPrev;
    linkNext;
    isEdited;
    constructor(required, options) {
        super(required.templateParams, options?.shiftOptions);
        this.exclude = options?.exclude || [];
        this.locked = options?.locked || [];
        if (options?.interval) {
            this.interval = options.interval;
        }
        this.repeatStart = required.repeatStart;
        this.repeatEnd = required.repeatEnd;
        this.userId = required.userId;
        this.branchId = required.branchId;
        this.sections = options?.shiftOptions?.sections || [];
        this.purpose = options?.shiftOptions?.purpose || "";
        this.linkPrev = options?.shiftOptions?.linkPrev || null;
        this.linkNext = options?.shiftOptions?.linkNext || null;
        this.isEdited = options?.shiftOptions?.isEdited || false;
    }
    getRepeatStart() {
        return new Date(this.repeatStart);
    }
    getRepeatEnd() {
        return new Date(this.repeatEnd);
    }
    getPurpose() {
        return this.purpose;
    }
    getLinkPrev() {
        return this.linkPrev || 0;
    }
    getLinkNext() {
        return this.linkNext || 0;
    }
    getIsEdited() {
        return this.isEdited;
    }
    isRepeatShiftTemplate() {
        return true;
    }
    isShiftTemplate() {
        return false;
    }
}
exports.RepeatShiftTemplate = RepeatShiftTemplate;
