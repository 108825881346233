"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APlanType = void 0;
class APlanType {
    type;
    id;
    branchId;
    displayName = "";
    feId = "";
    constructor(required, options) {
        this.type = required.type;
        this.id = required.id;
        this.branchId = required.branchId;
        if (options?.displayName) {
            this.displayName = options.displayName;
        }
        if (options?.feId) {
            this.feId = options.feId;
        }
    }
    index() {
        throw new Error("Method not implemented.");
    }
    getDisplayName() {
        throw new Error("Method not implemented.");
    }
    getId() {
        throw new Error("Method not implemented.");
    }
    getOriginalId() {
        throw new Error("Method not implemented.");
    }
    getFunctionBody() {
        return "";
    }
}
exports.APlanType = APlanType;
