import { goDateString } from "./common";

export const BULK_SYNC_BUTTON = "kot-bulk-sync-button";
const KOT_MAIN_SELECTION_BUTTON = "kot-holiday-button"

export function bulkSync(startDate, endDate) {
  $(`#${KOT_MAIN_SELECTION_BUTTON}`).addClass('loading');
  const data = JSON.stringify({
    "startDate": goDateString(startDate),
    "endDate": goDateString(endDate),
  });

  fetch(DashURL("kot/bulk-sync"),{method: "POST", body: data}).then(response => {
    $(`#${KOT_MAIN_SELECTION_BUTTON}`).removeClass('loading');
    if (!response.ok) {
      response.text().then(errorMsg => {
        alertModal.alert(`同期に失敗しました: ${errorMsg}`, true)
      })
    } else {
      response.json().then(json => {
        let msg = "同期に成功しました。"
        if (json.errorMsg == null) {
          alertModal.alertSuccess(msg, true)
        } else {
          // Partial sync.
          if (json.numSynced > 0) {
            msg += json.errorMsg
          } else {
            msg = json.errorMsg
          }
          alertModal.alert(msg, true)
        }
      })
    }
  })
}