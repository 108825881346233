<script>
// Alert Modal, has option to refesh page when modal is closed;
// Usage: use the `confirm("text", callback)` and `alert("text", true/false)` methods replacing the browser's native counterpart
import Modal from './Modal.vue';
import { MODAL_NAMES } from '../js/modal.js'
import SuccessIcon from './icons/SuccessIcon.vue';

export default {
  data() {
    return {
      isVisible: false,
      isConfirmLoading: false,
      isConfirmModal: false,
      isAlertSuccess: false,
      isConfirmDelete: false,
      contentText: '',
      shouldRefreshPage: false,
      redirectURL: "",
      confirmCallback: async () => { return },
      skippedEmails: [],
      hasReadWarning: false,
    }
  },
  components: {
    Modal,
    SuccessIcon
},
  methods: {
    closeModal() {
      if (this.redirectURL) {
        window.location.href = this.redirectURL;
        return;
      };
      if (this.shouldRefreshPage) {
        Refresh();
        return;
      }
      this.onCancel();
    },
    openModal() {
      this.isVisible = true;
    },
    async onConfirm() {
      if (this.confirmCallback) {
        this.isConfirmLoading = true;
        await this.confirmCallback();
      };
      this.isConfirmLoading = false;
      if (this.isConfirmModal) {
        this.isVisible = false;
      }
      return;
    },
    onCancel() {
      this.isVisible = false;
    },
    handleConfirmBtn() {
      if (this.isConfirmModal) {
        this.onConfirm();
      } else {
        this.closeModal();
      }
    },
    init(text, isConfirmModal, shouldRefreshPage, callback, isAlertSuccess = false, redirectURL = "", isConfirmDelete = false) {
      this.shouldRefreshPage = shouldRefreshPage;
      this.isConfirmModal = isConfirmModal;
      this.isAlertSuccess = isAlertSuccess;
      this.isConfirmDelete = isConfirmDelete;
      this.contentText = text;
      this.redirectURL = redirectURL;
      this.confirmCallback = callback;
      this.openModal();
    },
    confirm(text, callback) {
      this.init(text, true, false, callback)
    },
    alert(text, shouldRefreshPage = false) {
      this.init(text, false, shouldRefreshPage)
    },
    alertSuccess(text, shouldRefreshPage = false) {
      this.init(text, false, shouldRefreshPage, null, true)
    },
    redirect(text, redirectURL = "") {
      this.init(text, false, false, null, false, redirectURL)
    },
    confirmDelete(text, callback) {
      this.hasReadWarning = false;
      this.init(text, true, false, callback, false, "", true);
    },
    csvImportAlert(skippedEmails) {
      const text = "以下のメールは無効です、メールアドレスを正しく入力してください。";
      this.skippedEmails = skippedEmails;
      this.init(text, false, true);
    },
  },
  computed: {
    modalName() {
      return MODAL_NAMES.ALERT_CONFIRM;
    },
    showEmailList(){
      return this.skippedEmails.length > 0;
    },
    confirmLoading() {
      return this.isConfirmLoading ? "loading" : "";
    },
    deleteBtnDisabled() {
      return !this.hasReadWarning;
    }
  }
}
</script>

<template>
  <Modal :modal-name="modalName" v-if="isVisible" @closeModal="closeModal" @confirm="onConfirm" @cancel="onCancel">
    <template v-slot:header-icon>
      <div class="header-wrapper">
        <svg v-if="!isAlertSuccess" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve" width="100px" viewBox="0 0 85 85" id="alert-icon">
          <path fill="#f71515" d="M45.8,60.8c0,1.2-0.4,2.1-1.1,2.9c-0.4,0.5-1.3,1.1-2.9,1.1c-1.1,0-2.1-0.4-2.9-1.1c-0.8-0.8-1.2-1.7-1.2-2.9
          c0-1.2,0.4-2.1,1.2-2.8c0.8-0.7,1.7-1,2.9-1c1.5,0,2.5,0.6,2.9,1.1C45.4,58.7,45.8,59.6,45.8,60.8z M44.6,30.3
          c-1.5-1.4-4.3-1.3-5.6,0c-0.7,0.8-1,1.8-0.9,2.9l1,20c0,1.1,0.4,1.7,0.8,2.1c0.5,0.5,1.1,0.7,1.8,0.7c0.7,0,1.3-0.2,1.9-0.8
          c0.4-0.5,0.7-1.1,0.8-2l1-20C45.5,32.1,45.3,31.2,44.6,30.3z M77,69.3c1.6-2.8,1.2-6.3-0.9-10L50.7,16.8C48.5,13,45.5,11,42.2,11
          c-3.3,0-6.3,2.1-8.5,5.8L8.4,59.4c-2.2,3.7-2.5,7.2-0.9,10c1.6,2.8,5,4.4,9.5,4.4h50.7C72,73.7,75.4,72.2,77,69.3z M48.7,18
          L74,60.6c1.7,2.9,2,5.6,0.9,7.6c-1.2,2.1-3.8,3.2-7.4,3.2H16.9c-3.6,0-6.2-1.1-7.4-3.2c-1.1-2-0.8-4.7,0.9-7.6L35.7,18
          c1.8-3,4.1-4.6,6.5-4.6C44.6,13.4,46.9,15,48.7,18z"/>
        </svg>
        <SuccessIcon v-if="isAlertSuccess"/>
      </div>
    </template>
    <template v-slot:content>
      <div class="content-wrapper flex-center-column">
        <p v-if="isConfirmDelete" class="oplus-delete-text">本当に削除しますか？</p>
        
        <p>{{ contentText }}</p>
        <ul v-if="showEmailList">
          <li v-for="email in skippedEmails">{{ email }}</li>
        </ul>

        <label v-if="isConfirmDelete" class="oplus-form-checkbox">
          <input v-model="hasReadWarning" type="checkbox"> 注意事項を確認いたしました。
        </label>
      </div>
    </template>
    <template v-slot:footer-btn>
      <div class="footer-btn-wrapper">
        <button v-if="isConfirmModal" class="oplus-modal-btn oplus-cancel-btn" @click="closeModal">キャンセル</button>
        <button v-if="isConfirmDelete" class="oplus-modal-btn oplus-confirm-btn oplus-delete-btn btn" :class="confirmLoading" :disabled="deleteBtnDisabled" @click="handleConfirmBtn">削除</button>
        <button v-else class="oplus-modal-btn oplus-confirm-btn" :class="confirmLoading" @click="handleConfirmBtn">確認</button>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
  .header-wrapper {
    text-align: center;
    padding-bottom: 0;
  }
  .content-wrapper {
    width: 320px;
    text-align: center;
    margin: auto;
  }

  .footer-btn-wrapper {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  @media (max-width: 400px) {
    .content-wrapper {
      width: inherit;
    }
  }

  @media (max-width: 600px) {
    .oplus-modal-btn {
      font-size: 14px;
    }
  }

</style>
