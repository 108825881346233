import { createApp } from "vue";
import BranchSelectorMenu from "../vue/components/BranchSelectorMenu.vue";

const branchMenu = Object.freeze({
  component: "#branch-selector-component",
  button: "#company-dropdown"
})

export function mountBranchSelectorComponent(props) {
  const app = createApp(BranchSelectorMenu, {
    activeBranchId: props.activeBranchId,
    preferredBranchId: props.preferredBranchId,
  })
  window[branchMenu.component] = app.mount(branchMenu.component);
  window[branchMenu.component].initData(props.employments);
  addClickEventOnBranchMenu();
  closeBranchListOnClick();
}

function addClickEventOnBranchMenu() {
  $(branchMenu.button).on("click", () => {
    window[branchMenu.component].showBranchList()
  })
}

function closeBranchListOnClick() {
  $("#app").on("click", () => {
    window[branchMenu.component].closeBranchList()
  })
}

export function updateBranchListComponent(newEmploymentOrder) {
  window[branchMenu.component].updateBranchPos(newEmploymentOrder)
}
