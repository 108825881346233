import { goDateString } from "./common";

export const CCBIZ_ELEMENT_IDS = Object.freeze({
  SYNC_BUTTON: "ccbiz-sync-button",
})

export function sync(startDate, endDate) {
  $(`#${CCBIZ_ELEMENT_IDS.SYNC_BUTTON}`).addClass('loading');
  const data = {
    "start": goDateString(startDate),
    "end": goDateString(endDate),
  };

  $.post(DashURL("ccbiz/sync-shifts"), data)
    .done(function(res){
      if (res.skipped?.length > 0) {
        let msg = "社員の既存のシフトのためインポートされない有休:\n";
        res.skipped.forEach(skipped => {
          msg += `${skipped.Employee} ${skipped.Date}\n`;
        })
        alertModal.alert(msg, true);
        return;
      }
      $(`#${CCBIZ_ELEMENT_IDS.SYNC_BUTTON}`).removeClass('loading');
      Refresh();
    })
    .fail(function(xhr) {
      alertModal.alert("CCBIZ連携エラー:" + xhr.responseText, false);
      $(`#${CCBIZ_ELEMENT_IDS.SYNC_BUTTON}`).removeClass('loading');
    });
}
