<script>
import ShiftCell from './ShiftCell.vue';
import ShiftWeeklyCell from './components/ShiftWeeklyCell.vue';
import { dateFromSecondsTimestamp, intToJapaneseWeekdayKanji, millisecondsFromSeconds } from '../js/common.js'
import { isMonthlyView } from '../js/shift';
import { fromOplusTime, yearMonthDateString } from '../common/common';
import { MeStore } from '../store/v1/meStore';

export default {
  props: {
    dates: Object, // All DayData object
    branchSettings: Object,
    meStore: MeStore,
  },
  data() {
    return {
      days: Object.values(intToJapaneseWeekdayKanji),
      selectedCellDate: 0,
      weeklyCellHeight: 0,
      selectedRoleID: "",
    }
  },
  methods: {
    selectedCellHandler(date) {
      return this.selectedCellDate = new Date(date).valueOf();
    },
    closePopup(event) {
      event.stopPropagation();
      return this.selectedCellDate = 0;
    },
    holidayClass(index) {
      return {
        "th-saturday": index === 6,
        "th-holiday": index === 0,
      }
    },
    handleWeeklyCellHeight(height) {
      return this.weeklyCellHeight = height;
    },
  },
  computed: {
    datesArray() {
      const isWeeklyView = this.dates.length === 7;
      const datesArray = [];
      const startDate = new Date(millisecondsFromSeconds(this.dates[0].TimeStamp));
      if (!isWeeklyView) {
        // Padding before the month starts
        for (let i = 0; i < startDate.getUTCDay(); i++) {
          datesArray.push("");
        }
      }

      // Add each day of the month to the array
      for (let i = 0; i < this.dates.length; i++) {
        const oplusDate = dateFromSecondsTimestamp(this.dates[i].TimeStamp);
        const realDate = fromOplusTime(oplusDate);
        datesArray.push({
          dateStr: yearMonthDateString(realDate),
          timeStamp: millisecondsFromSeconds(this.dates[i].TimeStamp),
          isHoliday: this.dates[i].IsHoliday
        });
      }

      // Padding after the month ends
      while (datesArray.length % 7 !== 0) {
        datesArray.push("");
      }
      return datesArray;
    },
    isCalendarMonthlyView() {
      return isMonthlyView();
    },
    timeViewArray() {
      const timeRangeArr = [];
      const start = this.branchSettings.summaryDayStart;
      // force the end time to not go over 24
      const end = this.branchSettings.summaryDayEnd > 24 ? 24 : this.branchSettings.summaryDayEnd;

      for (let i = start; i < end; i++) {
        timeRangeArr.push(i);
      }

      return timeRangeArr
    },
    computedGridStyle() {
      return {
        "gridTemplateRows": `${this.weeklyCellHeight + 30}px repeat(${this.timeViewArray.length}, ${this.weeklyCellHeight}px)`
      }
    }
  },
  components: {
    ShiftCell,
    ShiftWeeklyCell
  },
  inject: [
    'calendarReactiveObject'
  ],
}
</script>
<template>
  <!-- Monthly Calendar -->
  <div v-if="calendarReactiveObject.isCalendarView && isCalendarMonthlyView" id="shift-calendar" class="calendar-container">
    <!-- TODO: Separate the Monthly calendar into a component, both Monthly and Weekly components should be under one parent component -->
    <!-- Top most row, weekday name row -->
    <div class="calendar-rows weekday-name-row">
      <div v-for="day, index in days" class="day-name" :key="index">
        <span :class="holidayClass(index)">{{ day }}</span>
      </div>
    </div>
    <!-- Main Calendar Body -->
    <div class="calendar-body calendar-rows month">
      <div v-for="date, index in datesArray" :key="index">
        <div v-if="!date" class="day-cell-wrapper empty-cell"></div>
        <ShiftCell v-else
          :cell-props="calendarReactiveObject.cellsMap[date.dateStr]"
          :cell-index="index"
          :is-selected="selectedCellDate === date.timeStamp"
          :is-holiday="date.isHoliday"
          :branch-settings="branchSettings"
          :date="date.dateStr"
          :me-store="meStore"
          @emit-selected-cell="selectedCellHandler"
          @emit-close-popup="closePopup"
        />
      </div>
    </div>
  </div>

  <!-- Weekly Calendar -->
  <div v-if="calendarReactiveObject.isCalendarView && !isCalendarMonthlyView" id="shift-weekly-calendar" class="calendar-weekly-content">
    <div class="calendar-rows week">
      <!-- Time range column -->
      <div class="weekly-day-cell-wrapper time-cell-sticky">
        <div class="time-cell-wrapper" :style="computedGridStyle">
          <div class="weekly-calendar-corner-cell"></div>
          <div v-for="time in timeViewArray" class="time-cell time-text-wrapper">
            <span class="time-text">{{ time + ":00"  }}</span>
          </div>
        </div>
      </div>

      <div v-for="date, index in datesArray" :key="index" class="day-cell-wrapper weekly-day-cell-wrapper">
        <ShiftWeeklyCell
          :cell-props="calendarReactiveObject.cellsMap[date.dateStr]"
          :is-selected="selectedCellDate === date.timeStamp"
          :is-holiday="date.isHoliday"
          :time-view="timeViewArray"
          :branch-settings="branchSettings"
          :date="date.dateStr"
          :me-store="meStore"
          @emit-selected-cell="selectedCellHandler"
          @emit-close-popup="closePopup"
          @weekly-cell-height="handleWeeklyCellHeight"
        />
      </div>

    </div>
  </div>
</template>

<style>
  .calendar-container {
    height: 100%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
  }

  .calendar-weekly-content {
    height: 100%;
    border: .05rem solid #e0e0e0;
    border-radius: 5px;
    overflow: auto;
  }

  .calendar-body {
    border: .05rem solid #e0e0e0;
    border-radius: 5px;
    cursor: pointer;
  }

  .calendar-rows {
    display: grid;
    grid-template-columns: repeat(7, minmax(40px,1fr));
  }

  .month, .week {
    flex-grow: 1;
    height: 100%;
  }

  .calendar-rows.week {
    grid-template-columns: 67px repeat(7, minmax(49px,1fr));
  }

  .weekday-name-row {
    font-weight: bolder;
    text-align: center;
    height: 24px;
  }

  .empty-cell {
    background-color: #a8a8a810;
    height: 100%;
    width: 100%;
  }

  /* Shift cell (monthly and weekly) styles */

  .day-cell-wrapper {
    border: .05rem solid #e0e0e070;
    text-align: center;
    height: 100%;
    cursor: pointer;
  }

  .weekly-day-cell-wrapper {
    border-right: .05rem solid transparent;
  }

  .day-cell-wrapper:not(.empty-cell):hover {
    border: .05rem solid #52a3d58b;
    border-radius: 7px;
    background-color: #52a3d511;
    transition: .2s ease;
  }

  .day-cell-wrapper:hover .weekly-cell-header {
    background-color: #f3f9fc;
    transition: .2s ease;
  }

  .day-cell {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    gap: 5px;
    min-height: 41px;
    height: 99%;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 5px 10px;
  }

  .day-cell-content {
    position: absolute;
    width: 90%;
    top: 30%;
    left: 50%;
    gap: 10px;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: -webkit-fill-available;
    height: fill-available;
    height: -moz-available;
  }

  .today-cell {
    line-height: 0;
    background-color: #e85600;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cell-details-container {
    position: fixed;
    display: block;
    overflow: hidden;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100000;
  }

  .cell-details-overlay {
    background: rgba(247,248,249,.6);
    cursor: default;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .cell-details-card {
    text-align: center;
    height: 250px;
    width: 270px;
    background-color: #fff;
    z-index: 100001;
    box-shadow: 0px 1px 4px 1px #8e8e8e91;
    padding: 10px 10px;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .card-date-text {
    padding-bottom: 5px;
    border-bottom: .05rem solid #e0e0e0;
  }

  .add-slot-button-wrapper {
    padding-top: 10px;
    border-top: .05rem solid #e0e0e0;
  }

  .empty-slot-text {
    font-size: .6rem;
  }

  .cell-add-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
  }

  .calendar-slots-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 12px 10px 0 10px;
  }

  .calendar-slots-cell {
    pointer-events: none;
    width: 95%;
  }

  .time-range-container {
    margin: .05rem 0;
    z-index: 3;
    position: sticky;
    left: 0;
    background: #fff;
  }

  .time-cell-sticky {
    position: sticky;
    left: 0;
    z-index: 5;
    background-color: #fff;
  }

  .time-cell-wrapper {
    position: relative;
    display: grid;
    margin-top: 0.1rem;
  }

  .calendar-weekly-content .time-cell {
    border-top: .05rem solid #e0e0e0;
    width: 100%;
  }

  .time-range-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
  }

  .time-text-wrapper {
    position: relative;
  }

  .time-text{
    position: absolute;
    top: -25%;
    height: 50%;
    width: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background-color: #fff;
  }

  .weekly-calendar-corner-cell {
    position: sticky;
    left: 0;
    top: 0;
    background: linear-gradient(#fff 50%, transparent 50%);
    z-index: 4;
  }

  @media (max-width: 576px) {
    .cell-details-card {
      width: 160px;
    }
    .day-cell {
      display: block;
      font-size: .7rem;
    }
    .today-cell {
      height: 25px;
      width: 25px;
    }
    .cell-add-button {
      top: auto;
      bottom: 0;
      left: 0;
    }
  }

  @media (max-width: 430px) {
    .calendar-rows.week {
      width: fit-content;
    }
  }

  @media (max-width: 300px)  {
    .card-date-text {
      font-size: .6rem;
    }
    .cell-details-card {
      width: 130px;
    }
  }

  /* ANIMATION CLASSES */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .left-fade-enter-active,
  .right-fade-enter-active,
  .top-fade-enter-active {
    transition: all 0.2s ease-in-out;
  }
  .left-fade-leave-active,
  .right-fade-leave-active,
  .top-fade-leave-active {
    transition: all 0.2s linear;
  }
  .left-fade-enter-from,
  .left-fade-leave-to {
    transform: translateX(-20px);
    opacity: 0;
  }
  .right-fade-enter-from,
  .right-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }

  .top-fade-enter-from,
  .top-fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }

  /* Shift cell style END */

  @media (max-width: 769px) {
    .month {
      height: 70px;
    }
  }
  @media (max-width: 576px) {
    .month {
      height: 50px;
    }
  }
</style>
