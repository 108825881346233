import { reactive } from "vue"

// TODO: into RoleStore
export const store = {
  init: function(rolesData, relationMap = null) {
    rolesData.forEach(role => {
      const roleId = role.ID;
      this.data.rolesMap[roleId] = {
        ...role,
        subRoles: [],
        mainRole: null
      };

      // Add role relation inside each roles
      if (relationMap) {
        const subRoles = relationMap.mainRolesMap?.[roleId];
        if (subRoles) {
          this.data.rolesMap[roleId].subRoles = subRoles;
        }

        const mainRole = relationMap.subRolesMap?.[roleId];
        if (mainRole) {
          this.data.rolesMap[roleId].mainRole = mainRole;
        }
      }
    });
  },
  updateSubRoles: function(mainRoleId, subRoleIds = []) {
    this.data.rolesMap[mainRoleId].subRoles = subRoleIds;
    subRoleIds.forEach(sId => {
      this.data.rolesMap[sId].mainRole = mainRoleId;
    })
  },
  deleteMainRole: function(mainRoleId) {
    const subRoleIds = this.data.rolesMap?.[mainRoleId].subRoles;
    if (!subRoleIds) {
      return;
    }
    subRoleIds.forEach(sId => {
      if (!this.data.rolesMap[sId]) {
        return;
      }
      this.data.rolesMap[sId].mainRole = null;
    })
  },
  deleteSubRoles: function(mainRoleId) {
    this.data.rolesMap[mainRoleId].subRoles = [];
  },
  data: reactive({
    rolesMap: {},
  })
}
