import { createApp, computed, reactive } from "vue";
import ShiftCalendar from '../vue/ShiftCalendar.vue';
import ShiftViewToggleButton from '../vue/ShiftViewToggleButton.vue'
import { reactiveSlotLogMap } from "./slotLog";

export function mountShiftCalendarComponent(mountPoint, calendarReactiveObject, slotMap, props, roleDates) {
  const app = createApp(ShiftCalendar, props);
  const sectionMap = buildSectionMap(slotMap)
  app.config.unwrapInjectedRef = true;
  app.provide('calendarReactiveObject', computed(() => calendarReactiveObject));
  app.provide('roleDates', roleDates);
  app.provide('slotMap', slotMap);
  app.provide('sectionMap', sectionMap);
  app.provide('slotLogMap', computed(() => reactiveSlotLogMap));
  window[mountPoint] = app.mount(mountPoint);
}

export function mountShiftViewToggleButtonComponent(mountPoint, calendarReactiveObject) {
  const app = createApp(ShiftViewToggleButton, {});
  app.config.unwrapInjectedRef = true;
  app.provide('calendarReactiveObject', computed(() => calendarReactiveObject));
  window[mountPoint] = app.mount(mountPoint);
}

export function addRequestSlotsToSlotMap(slotMap = {}) {
  const slotIds = Object.keys(slotMap);
  try {
    slotIds.forEach(slotId => {
      const requestedSlot = slotMap[slotId]?.RequestedSlot;
      if (!requestedSlot) {
        return
      }
      slotMap[requestedSlot.ID] = requestedSlot
    })
  } catch (err) {
    throw err
  }
}

function buildSectionMap(slotMap) {
  const map = {};
  Object.values(slotMap).forEach(slot => {
    if (!slot?.SectionSlots) {
      return;
    }
    slot.SectionSlots.forEach(section => {
      map[section.ID] = section;
    })
  })

  return map;
}
