export const SLOT_TYPES = Object.freeze({
  HOURLY: 0, // 0:Time From ~ To
  WORKDAY: 1, // O
  OFF_DAY: 2, // X
  PTO: 3, // 有休
  SECTION_REST: 4,
  HALF_PTO: 5, // 半有休
  PUBLIC_HOLIDAY: 6, // 法定休日
  OTHER_HOLIDAY: 7, // 法定外休日
  KOT_PTO: 100, // KOT 有休
  KOT_HALF_PTO_AM: 101, // KOT AM休
  KOT_HALF_PTO_PM: 102, // KOT PM休
  KOT_HOURLY_PTO: 103, // KOT Hourly 有休
});

export const ROLE_VIEW_TYPE = Object.freeze({
  DAY_VIEW: 0,
  STAFF_VIEW: 1
})

export const OPLUS_COLOR_PALETTE = Object.freeze({
  DEFAULT: "eef0f3",
  BLUE_1: "7abefd",
  GREEN_1: "58ca1e",
  PINK_1: "fd7ae1",
  PURPLE_1: "ac7afd",
  GREEN_2: "45dc9d",
  BLUE_2: "0a6e94",
  YELLOW_1: "d0c113",
  GREY_1: "b1aeae",
  GREY_2: "636363",
  ORANGE_1: "ed3f1d",
  YELLOW_2: "fcac00",
  GREEN_3: "1b998b",
  PURPLE_2: "a106b2",
  BROWN_1: "9f7833"
});
