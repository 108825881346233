<!-- Base Modal Component -->
<script>
export default {
  props: {
    modalName: String,
    hasTitleDivider: Boolean,
    hasFooterDivider: Boolean,
    includeModalFooter: {
      type: Boolean,
      default: true,
    },
    includeModalHeader: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("cancel");
    }
  },
  computed: {
    alertPadding() {
      return !this.hasTitleDivider ? "alert-modal-padding" : "";
    },
  }
}
</script>
<template>
  <div :id="this.modalName" class="oplus-modal modal active">
    <div class="modal-overlay" aria-label="Close" @click="closeModal()"></div>
    <div class="modal-container" :class="alertPadding">
      <div v-if="includeModalHeader" class="modal-header">
        <div class="modal-header-wrapper">
          <span class="header-text">
            <slot name="header-title"></slot>
          </span>
          <span class="close-btn" aria-label="Close" @click="closeModal()">
            <i class="zmdi zmdi-close zmdi-hc-lg"></i>
          </span>
        </div>
        <slot name="header-icon"></slot>
      </div>
      <div v-if="hasTitleDivider" class="modal-divider"></div>
      <div class="modal-body">
        <slot name="content"></slot>
      </div>
      <div v-if="hasFooterDivider" class="modal-divider"></div>
      <div v-if="includeModalFooter" class="modal-footer">
        <slot name="footer-btn"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Custom Oplus modal css */
.modal {
  color: #929292
}

.oplus-modal {
  position: absolute;
  z-index: 10000000;
  display: flex;
}
.modal-header {
  padding: 0.6rem 0.8rem;
}

.modal-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-text {
  font-size: .9rem;
  display: flex;
}
.modal-container {
  padding: 0;
  border-radius: 16px;
  width: fit-content;
  height: fit-content;
  position: relative;
  color: #929292;
}

.modal-body {
  padding: 0 0.8rem;
  position: relative;
}

.modal-footer {
  padding: 0.8rem;
  position: relative;
}

.modal .close-btn {
  display: flex;
  justify-content: end;
  cursor: pointer;
  color: #c9c9c9;
  height: 100%;
}

.modal .close-btn:hover {
  color: #bbbdbf
}

.modal-divider {
  border: 0;
  width: 100%;
  border-top: 1px solid #cfcfcf;
}

.alert-modal-padding {
  padding: 0.2rem 0.8rem 0.8rem 0.8rem;
}

/* Make sure alert modal is always on top */
#alert-confirm-modal {
  z-index: 10000002;
}
/* Make sure timecard modal is on top of timepicker modal */
#timecard-vue-modal {
  z-index: 10000001;
}

</style>
