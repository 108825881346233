"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmploymentStore = void 0;
class EmploymentStore {
    data = {};
    constructor() {
    }
    add(e) {
        this.data[e.getId()] = e;
    }
    byId(id) {
        return this.data[id];
    }
    query(options) {
        let out = Object.values(this.data);
        if (options?.userId) {
            out = out.filter(e => {
                if (e.getUserId() == options.userId) {
                    return e;
                }
            });
        }
        if (options?.branchId) {
            out = out.filter(e => {
                if (e.getBranchId() == options.branchId) {
                    return e;
                }
            });
        }
        if (options?.employeeId) {
            out = out.filter(e => {
                if (e.employeeId == options.employeeId) {
                    return e;
                }
            });
        }
        return out;
    }
    delete(id) {
        if (!this.data[id]) {
            return;
        }
        delete this.data[id];
    }
}
exports.EmploymentStore = EmploymentStore;
