<template>
    <div>
        <div class="svg-container emotion-rating">
            <svg version="1.1" class="emotion-rating-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 32 32" width="60px" height="60px" xml:space="preserve">
                <path class=""    d="M16.06,27.7C9.63,27.7,4.4,22.46,4.4,16.03C4.4,9.6,9.63,4.36,16.06,4.36S27.73,9.6,27.73,16.03
                C27.73,22.46,22.5,27.7,16.06,27.7z M16.06,5.43c-5.85,0-10.6,4.76-10.6,10.6s4.76,10.6,10.6,10.6s10.6-4.76,10.6-10.6
                S21.91,5.43,16.06,5.43z M20.54,20.73c0.25-0.16,0.32-0.49,0.16-0.74c-0.93-1.42-2.7-2.3-4.63-2.3c-1.93,0-3.71,0.88-4.63,2.3
                c-0.16,0.25-0.09,0.58,0.16,0.74c0.25,0.16,0.58,0.09,0.74-0.16c0.73-1.12,2.17-1.82,3.74-1.82s3.01,0.7,3.74,1.82
                c0.1,0.16,0.27,0.24,0.45,0.24C20.35,20.81,20.45,20.78,20.54,20.73z M12.52,12.4c-0.71,0-1.28,0.57-1.28,1.28s0.57,1.28,1.28,1.28
                c0.71,0,1.28-0.57,1.28-1.28S13.23,12.4,12.52,12.4z M19.63,12.4c-0.71,0-1.28,0.57-1.28,1.28s0.57,1.28,1.28,1.28
                c0.71,0,1.28-0.57,1.28-1.28S20.33,12.4,19.63,12.4z"/>
            </svg>
        </div>
        <span class="satisfaction-text">やや不満</span>
    </div>
</template>
