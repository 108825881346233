<script>
import Modal from './Modal.vue';
import { MODAL_NAMES } from '../js/modal';

export default {
  data() {
    return {
      isVisible: false,
      isLoading: false,
      selectedValue: "0",
    }
  },
  components: {
    Modal,
  },
  methods: {
    closeModal() {
      this.isVisible = false;
    },
    openModal() {
      this.isVisible = true;
    },
    onConfirm() {
      this.syncKOTEmployees();
    },
    onCancel() {
      this.isVisible = false;
    },
    syncKOTEmployees() {
      if (!checkKOTHours() || this.isLoading) {
        return
      }
      this.isLoading = true;
      const url = DashURL(`kot\x2Fsync-employees${this.selectedValue === "1" ? '?importUsers=1' : ''}`);

      $.post(url, {})
        .done((response) => {
          RefreshOnSuccess(response);
        })
        .fail((res) => {
          alertModal.alert("同期エラー:", res.responseText);
          this.isLoading = false;
        });
    }
  },
  computed: {
    loadingClass() {
      return this.isLoading ? "loading" : "";
    },
    modalName() {
      return MODAL_NAMES.KOT_SYNC;
    }
  },
}
</script>

<template>
  <Modal :modal-name="modalName" v-if="isVisible" @closeModal="closeModal" @confirm="onConfirm" @cancel="onCancel" :has-title-divider="true" :has-footer-divider="true">
    <template v-slot:header-title>
      <img class="kot-modal-img-title" src="https://www.kingtime.jp/wp-content/uploads/2017/07/kot-logo-header.svg">
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <div class="form-group">
          <label class="form-label">同期とインポートを同時に行いますか？</label>
          <label class="form-radio">
            <input v-model="selectedValue" value="0" type="radio" checked>
            <i class="form-icon"></i> 同期のみ
          </label>
          <label class="form-radio">
            <input v-model="selectedValue" value="1" type="radio">
            <i class="form-icon"></i> 同期とインポート
          </label>
        </div>
      </div>
    </template>
    <template v-slot:footer-btn>
      <button class="oplus-modal-btn oplus-alt-confirm-btn" :class="loadingClass" @click="onConfirm">確定</button>
    </template>
  </Modal>
</template>

<style scoped>
  .kot-modal-img-title {
    width: 180px;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    text-align: left;
    gap: 5px;
    align-items: center;
    justify-content: center;
    height: fit-content;
    margin: auto;
  }

</style>