// Requests Functions for creating/editing/deleting slots, returns raw response from the backend,
// Sample data object to pass in these functions can be found at the very bottom of this file.

let slotURL = "";
export function getPageSlotURL(pipelineSlotURL) {
  slotURL = pipelineSlotURL;
}

function generateSlotURL(slotID) {
  // addURLSuffix is a global function from dashboard_base.html
  return addURLSuffix(slotURL, slotID);
}

export async function slotPostRequest(data) {
  if (!slotURL) {
    throw new Error("Invalid Slot URL");
  }

  return $.post(slotURL, data)
      .done((response) => {
        return { response, data };
      })
      .fail((error) => {
        throw error;
      });
}


export async function slotPutRequest(slotID, data) {
  const url = generateSlotURL(slotID);
  if (!url) {
    throw new Error("Invalid Slot URL");
  }

  return $.put(url, data)
      .done((response) => {
        return { response, data };
      })
      .fail((error) => {
        throw error;
      });
}

export async function slotDeleteRequest(slotID, params = '') {
  let url = generateSlotURL(slotID);
  if (params) {
    url = addURLSuffix(url, params);
  }

  if (!url) {
    throw new Error("Invalid Slot URL");
  }

  return $.delete(url)
      .done((response) => {
        return { response };
      })
      .fail((error) => {
        throw error;
      });
}
