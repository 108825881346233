"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainRole = void 0;
const roleWithRelation_1 = require("./roleWithRelation");
class MainRole extends roleWithRelation_1.RoleWithRelation {
    orRoles = [];
    constructor(orRoles, data, optional) {
        super(data, optional);
        this.orRoles = orRoles;
    }
    getOrRoles() {
        return this.orRoles.map(id => id.toString());
    }
    setOrRoles(ids) {
        this.orRoles = ids;
    }
    isMainRole() {
        return true;
    }
    isStandardRole() {
        return false;
    }
}
exports.MainRole = MainRole;
