export async function createRoleRequest(data) {
  const url = DashURL("roles");

  const response = await fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams(data).toString(),
  });
  return response.json();
}

export async function putRoleRequest(roleID, data) {
  const url = DashURL("role/" + roleID);

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams(data).toString(),
  });
  return response.json();
}

export async function deleteRoleRequest(roleID) {
  const url = DashURL("role/" + roleID);

  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  });
  return response.json();
}

export async function deleteGroupRoleRequest(roleID) {
  const url = DashURL(`role/groupwide/all/${roleID}`);

  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  });
  return response.json();
}
