"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleWithRelation = void 0;
const role_1 = require("./role");
class RoleWithRelation extends role_1.Role {
    getMainRoleId() {
        return this.getId();
    }
}
exports.RoleWithRelation = RoleWithRelation;
