<template>
    <div>
        <div class="svg-container emotion-rating">
            <svg version="1.1" class="emotion-rating-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 32 32" width="60px" height="60px" xml:space="preserve">
                <path class="" d="M20.72,18.05c-0.14-0.14-0.34-0.19-0.53-0.13c-0.01,0-1.37,0.39-4.31,0.39s-4.29-0.39-4.3-0.39
                    c-0.19-0.06-0.39-0.01-0.53,0.13c-0.14,0.14-0.19,0.35-0.13,0.54c0.02,0.05,0.39,1.19,1.22,1.89c0.73,0.62,1.97,1.35,3.74,1.35
                    c1.41,0,2.7-0.47,3.75-1.35c0.83-0.7,1.2-1.84,1.22-1.89C20.91,18.4,20.86,18.19,20.72,18.05z M18.94,19.67
                    c-0.86,0.73-1.89,1.09-3.06,1.09c-1.16,0-2.19-0.36-3.05-1.09c-0.18-0.15-0.33-0.33-0.45-0.52c0.75,0.12,1.9,0.23,3.5,0.23
                    c1.6,0,2.75-0.11,3.51-0.23C19.27,19.34,19.12,19.52,18.94,19.67z M15.89,4.36c-6.44,0-11.67,5.23-11.67,11.67
                    c0,6.43,5.23,11.66,11.67,11.66c6.43,0,11.66-5.23,11.66-11.66C27.55,9.59,22.32,4.36,15.89,4.36z M15.89,26.63
                    c-5.85,0-10.61-4.76-10.61-10.6c0-5.85,4.76-10.61,10.61-10.61c5.84,0,10.6,4.76,10.6,10.61C26.49,21.87,21.73,26.63,15.89,26.63z
                    M14.15,13.72c0,0.27-0.24,0.47-0.53,0.47c-0.28,0-0.42-0.21-0.53-0.43c-0.15-0.26-0.47-0.48-0.97-0.48
                    c-0.48,0-0.81,0.27-0.99,0.53c-0.13,0.19-0.26,0.38-0.52,0.38c-0.29,0-0.53-0.2-0.53-0.47c0-0.52,0.99-1.39,2.04-1.39
                    C13.19,12.32,14.15,13.1,14.15,13.72z M11.14,13.72C11.14,13.72,11.14,13.72,11.14,13.72C11.14,13.72,11.14,13.72,11.14,13.72z
                    M13.09,13.72C13.09,13.72,13.09,13.72,13.09,13.72C13.09,13.72,13.09,13.72,13.09,13.72z M21.72,13.72c0,0.27-0.24,0.47-0.53,0.47
                    c-0.28,0-0.42-0.21-0.53-0.43c-0.15-0.26-0.47-0.48-0.97-0.48c-0.48,0-0.81,0.27-0.99,0.53c-0.13,0.19-0.26,0.38-0.52,0.38
                    c-0.29,0-0.53-0.2-0.53-0.47c0-0.52,0.99-1.39,2.04-1.39C20.76,12.32,21.72,13.1,21.72,13.72z M18.71,13.72
                    C18.71,13.72,18.71,13.72,18.71,13.72C18.71,13.72,18.71,13.72,18.71,13.72z M20.66,13.72C20.66,13.72,20.66,13.72,20.66,13.72
                    C20.66,13.72,20.66,13.72,20.66,13.72z"/>
            </svg>
        </div>
        <span class="satisfaction-text">満足</span>
    </div>
</template>
