"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Token = void 0;
const tokenTypes_1 = require("./tokenTypes");
class Token {
    type;
    value;
    strValue;
    constructor(type, strValue, value) {
        this.type = type;
        this.value = value;
        this.strValue = strValue;
    }
    setValue(v) {
        this.value = v;
    }
    setStrValue(v) {
        this.strValue = v;
    }
    getSanitizedKeyword() {
        if (!this.isKeyword()) {
            return "";
        }
        let k = this.strValue;
        if (this.isNegative()) {
            // Remove "-" from sanitized keyword
            k = k.substring(1);
        }
        return k;
    }
    isNegative() {
        return this.strValue.startsWith(tokenTypes_1.tokenTypes.SUB) && (this.isKeyword() || this.isNumber());
    }
    isAddOp() {
        return this.type === tokenTypes_1.tokenTypes.ADD;
    }
    isSubOp() {
        return this.type === tokenTypes_1.tokenTypes.SUB;
    }
    isDivOp() {
        return this.type === tokenTypes_1.tokenTypes.DIV;
    }
    isMultOp() {
        return this.type === tokenTypes_1.tokenTypes.MULTI;
    }
    isNumber() {
        return this.type === tokenTypes_1.tokenTypes.NUM;
    }
    isKeyword() {
        return this.type === tokenTypes_1.tokenTypes.KEY;
    }
    isOpenParens() {
        return this.type === tokenTypes_1.tokenTypes.OPAREN;
    }
    isCloseParens() {
        return this.type === tokenTypes_1.tokenTypes.CPAREN;
    }
    isEnd() {
        return this.type === tokenTypes_1.tokenTypes.END;
    }
}
exports.Token = Token;
