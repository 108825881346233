<template>
    <svg version="1.1" class="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px"
        viewBox="0 0 35 35" style="enable-background:new 0 0 35 35;" xml:space="preserve">
        <path fill="currentColor" d="M32.22,12.34l-6.26,6.27c-0.17,0.17-0.4,0.25-0.62,0.25c-0.22,0-0.45-0.08-0.62-0.25
		c-0.34-0.34-0.34-0.9,0-1.24l4.77-4.77h-14.6c2.71,1.79,4.49,4.8,4.49,8.2v7.11l4.77-4.77c0.35-0.35,0.9-0.35,1.24,0
		c0.34,0.34,0.34,0.89,0,1.24l-6.26,6.26c-0.17,0.17-0.4,0.26-0.62,0.26c-0.23,0-0.45-0.09-0.62-0.26l-6.27-6.26
		c-0.34-0.35-0.34-0.9,0-1.24c0.35-0.34,0.9-0.35,1.24,0l4.77,4.76v-7.1c0-4.52-3.84-8.2-8.57-8.2H2.65
		c-0.48,0-0.87-0.39-0.87-0.88c0-0.48,0.39-0.87,0.87-0.87H29.5l-4.78-4.77c-0.34-0.35-0.34-0.9,0-1.24c0.34-0.34,0.9-0.34,1.24,0
		l6.26,6.26C32.56,11.45,32.56,12,32.22,12.34z"/>
    </svg>
</template>
