"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmploymentShiftTableStatsCell = void 0;
const tableCell_1 = require("../table/v1/tableCell");
const shiftCellStats_1 = require("../stats/shiftCellStats");
const shiftStats_1 = require("../stats/shiftStats");
class EmploymentShiftTableStatsCell extends tableCell_1.TableCell {
    shifts;
    data;
    employmentId;
    startDate;
    constructor(startDate, employment) {
        super(startDate, employment);
        this.employmentId = this.yAxis.getId();
        this.startDate = this.xAxis.getDate();
        this.shifts = {};
        this.data = new shiftCellStats_1.ShiftCellStats();
    }
    setSlots(slots) {
        slots.forEach(s => {
            this.shifts[s.getStatsId()] = new shiftStats_1.ShiftStats();
        });
    }
    getSlotStats(slotId) {
        return this.shifts[slotId];
    }
    setSlotStats(slotId, stats) {
        this.shifts[slotId] = stats;
    }
    getData() {
        return this.data;
    }
    setData(stats) {
        this.data = stats;
    }
}
exports.EmploymentShiftTableStatsCell = EmploymentShiftTableStatsCell;
