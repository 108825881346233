<script>
import Modal from './Modal.vue';

export default {
  props: {
    modalName: String,
    importURL: String,
    csvName: String,
    contentText: String,
    excelDownloadLink: String,
    successCallback: Function,
    errorMessage: String,
    importCSVKey: {
      type: String,
      default: 'file',
    }
  },
  data() {
    return {
      isVisible: false,
      isImporting: false,
      isDragging: false,
      selectedFile: null,
    }
  },
  methods: {
    init(csvName, contentText, excelDownloadLink) {
      this.csvName = csvName;
      this.contentText = contentText;
      this.excelDownloadLink = excelDownloadLink;
      this.openModal();
    },
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    onConfirm() {
      if (!this.isImporting) {
        this.importCsvFile();
      }
    },
    importCsvFile() {
      this.isImporting = true;

      if (!this.selectedFile || !this.isCSVFile(this.selectedFile)){
        alertModal.alert("CSVファイルではありません。サンプルをご確認ください。");
        this.isImporting = false;
        return;
      };

      let data = new FormData();
      data.append(this.importCSVKey, this.selectedFile);

      $.ajax({
        url:  this.importURL,
        type: 'post',
        contentType: false,
        processData: false,
        data: data,
        success: (response) => {
          this.successCallback(response);
          this.isImporting = false;
          this.isVisible = false;
          return;
        },
        error: (response) => {
          if (response.status == 404) {
            alertModal.alert("拠点名が見つかりません。サンプルをご確認ください。");
          } else {
            alertModal.alert("CSVインポートができませんでした。");
          }
          this.isImporting = false;
        }
      });
    },
    handleFileChange(event) {
      if (event.target.files.length > 0) {
        let file = event.target.files[0];
        if (!this.isCSVFile(file)){
          alertModal.alert("CSVファイルではありません。サンプルをご確認ください。");
          return;
        };
        this.selectedFile = file;
      }
    },
    handleFileDrop(event) {
      this.isDragging = false;
      if (event.dataTransfer.files.length > 0) {
        let file = event.dataTransfer.files[0];
        if (!this.isCSVFile(file)){
          alertModal.alert("CSVファイルではありません。サンプルをご確認ください。");
          return;
        };
        this.selectedFile = file;
      }
    },
    isCSVFile(file) {
      if (file) {
        let extension = file.name.split('.').pop().toLowerCase();
        return extension === "csv"
      }
      return false;
    },
  },
  computed: {
    currentFileName() {
      return this.selectedFile ? this.selectedFile.name : "選択されていません";
    },
    isLoading() {
      return this.isImporting ? "loading" : "";
    }
  },
  components: {
    Modal,
  },
}
</script>
<template>
  <Modal :modal-name="modalName" v-if="isVisible" :has-title-divider="true" :has-footer-divider="true" @closeModal="closeModal" @confirm="onConfirm">
    <template v-slot:header-title>
        CSV{{ csvName }}インポート
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <span class="import-text">
          {{ contentText }}
          <br>
          <a referrerpolicy="no-referrer" target="_blank" :href="excelDownloadLink">
            CSVファイルでのサンプル
          </a>
        </span>
        <div class="import-box"
            id="drop-area"
            :class="{ hover: isDragging }"
            @dragenter.prevent="isDragging = true"
            @dragover.prevent="isDragging = true"
            @dragleave.prevent="isDragging = false"
            @drop.prevent="handleFileDrop">
          <span class="import-box-text">ファイルをインポート</span>
          <div>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve" viewBox="0 0 85 85" id="upload-icon" width="100px">
              <path fill="#52a3d5" d="M71.1,29.6c0-0.3,0-0.6,0-0.9c0-7.5-6.1-13.5-13.5-13.5c-3,0-5.8,1-8.2,2.8C47,11.8,40.9,7.5,34.2,7.5
              c-9,0-16.2,7.3-16.2,16.3c0,1.8,0,4.2-0.1,5.4c-9,0.5-16.6,7.9-16.6,16.3c0,5.9,3.2,11.4,8.4,14.2c0.2,0.1,0.3,0.1,0.5,0.1h11
              c0,0.1,0,0.2,0,0.3c0,12.1,9.9,22,22,22c12.1,0,22-9.9,22-22c0-0.1,0-0.2,0-0.3h10.1c0.2,0,0.4,0,0.5-0.1c5.2-2.9,8.4-8.3,8.4-14.2
              C84.1,37.8,78.6,31.1,71.1,29.6z M43.1,80.1c-11,0-19.9-8.9-19.9-19.9c0-11,8.9-19.9,19.9-19.9c11,0,19.9,8.9,19.9,19.9
              C63,71.1,54.1,80.1,43.1,80.1z M74.9,57.8H65c-1.2-11-10.6-19.6-21.9-19.6c-11.3,0-20.7,8.6-21.9,19.6H10.4
              c-4.4-2.5-7.1-7.2-7.1-12.3c0-7.5,7.3-14.2,15.5-14.2c1.1,0,1.1-0.5,1.1-3.3c0-1.3,0-2.9,0-4.2c0-7.8,6.3-14.2,14.1-14.2
              c6.3,0,12,4.3,13.7,10.4c0.1,0.4,0.4,0.6,0.7,0.7c0.4,0.1,0.8,0,1-0.3c2.2-2.1,5-3.2,8-3.2c6.3,0,11.4,5.1,11.4,11.4
              c0,0.6,0,1.1-0.1,1.5c-0.1,0.3,0,0.6,0.2,0.8c0.2,0.2,0.4,0.4,0.7,0.4c7,0.9,12.2,6.9,12.2,14C82,50.5,79.3,55.2,74.9,57.8z
              M49.1,57.1c-0.4,0.4-1.1,0.5-1.5,0.1l-3.4-3v15.7c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1V54.2l-3.4,3
              c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.6-0.1-0.8-0.4c-0.4-0.4-0.3-1.1,0.1-1.5l5.2-4.6c0.4-0.3,1-0.3,1.4,0l5.2,4.6
              C49.4,56,49.5,56.7,49.1,57.1z"/>
          </svg>
          </div>
          <span class="current-file-name">{{ currentFileName }}</span>
          <span>ドラッグ＆ドロップまたは
          <label class="csv-file-label" for="staff-csv-file">
            <input type="file" name="file" id="staff-csv-file" @change="handleFileChange"/>
            参照
          </label>
          </span>
        </div>
      </div>
    </template>
    <template v-slot:footer-btn>
      <div class="footer-btn-container">
        <button id="staff-import-btn" class="oplus-btn oplus-confirm-btn" :class="isLoading" @click="onConfirm">インポート</button>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
  .content-wrapper {
    width: 280px;
    padding: 1rem 0;
  }

  .import-text {
    text-align: left;
  }

  .import-box-text {
    font-size: 1.1rem;
  }

  .csv-file-label {
    cursor: pointer;
    color: #52a3d5;
  }

  .csv-file-label:hover{
    color: #07aff7
  }

  .oplus-btn {
    width: fit-content;
    border-radius: 7px;
    background: #fff;
    height: 35px;
    cursor: pointer;
    border-width: 1px;
    text-decoration: none;
  }

  .oplus-btn:hover {
    color: #fff;
    border-width: 0.1px;
  }

  .oplus-confirm-btn {
    color: #52a3d5;
    border-color: #52a3d5;
  }

  .oplus-confirm-btn:hover {
    background: #52a3d5;
    border-color: #52a3d5;
  }

  .import-box {
    margin-top: 1rem;
    padding: .4rem;
    text-align: center;
    height: 200px;
    overflow: auto;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23333' stroke-width='3' stroke-dasharray='4%2c10' stroke-dashoffset='12' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: 0.2s ease-in;
  }

  .import-box.hover {
    background-color: #53a3d521;
  }

  .footer-btn-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  input[type="file"] {
      display: none;
  }

  .current-file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
