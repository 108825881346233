<script>
import SurveyAverage from "./icons/SurveyAverage.vue"
import SurveyGood from "./icons/SurveyGood.vue"
import SurveyBad from "./icons/SurveyBad.vue"
import SurveyVeryBad from "./icons/SurveyVeryBad.vue"
import SurveyVeryGood from "./icons/SurveyVeryGood.vue"

export default {
    props: {
    },
    data() {
        return {
            feedBackText: "",
            isActive: true,
            selectedLevel: 0,
            isLoading: false,
        }
    },
    computed: {
        computedClass() {
            return {
                "active": this.isActive,
            }
        },
        surveyButtonLoading() {
            return {
                "survey-btn-loading": this.isLoading,
            }
        }
    },
    methods: {
        handleTextChange(e) {
            this.feedBackText = e.target.value;
        },
        dismiss() {
            const url = DashURL("satisfaction_survey");
            const data = {
                dismiss: true,
            }
            fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams(data).toString(),
            });
            this.deactivate();
        },
        deactivate() {
            this.isActive = false;
        },
        activate() { // Not used currently
            this.isActive = true;
        },
        async postSurvey() {
            if (this.isLoading) {
                return;
            }
            if (!this.selectedLevel) {
                alertModal.alert("満足度をお聞かせください。");
                return;
            }
            this.isLoading = true;
            const url = DashURL("satisfaction_survey");
            const data = {
                textInput: this.feedBackText,
                satisfactionLevel: this.selectedLevel,
            }

            const res = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams(data).toString(),
            });
            const json = await res.json();
            if (!res.ok || !json.success) {
                alertModal.alert("送信に失敗しました、再度お試しください。");
                this.isLoading = false;
                return;
            }
            this.deactivate();
        },
        selectLevel(n) {
            this.selectedLevel = n;
        },
        isSelected(n) {
            return {
                "selected" : this.selectedLevel === n,
            }
        },
    },
    components: {
        SurveyAverage,
        SurveyVeryBad,
        SurveyGood,
        SurveyBad,
        SurveyVeryGood,
    },
}
</script>
<template>
    <div class="oplus-modal modal" :class="computedClass">
        <div class="modal-overlay" aria-label="Close" @click="dismiss"></div>
        <div id="survey-container" class="survey-container" :class="computedClass" @click="preventClose">
            <div class="survey-header">
                <b id="survey-title-text" class="survey-title-text">顧客満足度調査</b>
                <span class="close-btn" aria-label="Close" @click="dismiss">
                    <i class="zmdi zmdi-close zmdi-hc-2x"></i>
                </span>

            </div>
            <div class="survey-content-container">

                <div id="survey-level-container" class="survey-level-container">
                    <span class="survey-level-text">どのくらいサービスに満足していますか？</span>
                    <div class="survey-level-options">
                        <SurveyVeryBad class="survey-option" :class="isSelected(1)" @click="selectLevel(1)" />
                        <SurveyBad class="survey-option" :class="isSelected(2)" @click="selectLevel(2)"/>
                        <SurveyAverage class="survey-option" :class="isSelected(3)" @click="selectLevel(3)"/>
                        <SurveyGood class="survey-option" :class="isSelected(4)" @click="selectLevel(4)"/>
                        <SurveyVeryGood class="survey-option" :class="isSelected(5)" @click="selectLevel(5)"/>
                    </div>
                </div>

                <div id="survey-text-container" class="survey-text-container">
                    <div class="survey-input-title">ご意見をお聞かせください。</div>
                    <textarea class="survey-input-text" @change="handleTextChange"></textarea>
                </div>
            </div>
            <div class="survey-footer">
                <button class="survey-cancel-btn survey-btn oplus-modal-btn" @click="dismiss">キャンセル</button>
                <button class="survey-submit-btn survey-btn oplus-modal-btn oplus-confirm-btn" :class="surveyButtonLoading" @click="postSurvey">送信</button>
            </div>
        </div>
    </div>
</template>
<style>

.survey-container {
    font-family: Roboto;
    box-shadow: 0px 0px 21.9px 0px rgba(106, 106, 106, 0.39);
    color: #000000;
    padding: 1rem 1rem;
    background-color: #FFFFFF;
    width: 500px;
    height: fit-content;
    right: 20px;
	position: absolute;
	margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    transition:
        background-color 0.2s,
        fill 0.2s,
        opacity 0.5s;
    opacity: 0;
    text-align: left;
    border-radius: 10px;
    z-index: 1000000;
}

.survey-header .close-btn {
    cursor: pointer;
    margin-left: auto;
    margin-bottom: 5px;
    width: 15px;
    height: 15px;
    color: var(--Semantic-Background-Tertiary, rgba(177, 186, 190, 1));
}
.survey-level-text {
    margin-top: 30px;
}
.survey-option {
    display: flex;
    flex-direction: column;
    width: 60px;
    height: 80px;
    margin: auto 0;
}

.survey-option.selected .emotion-rating {
    fill: #FFFFFF;
    background-color: rgba(57, 174, 223, 1);
}

.survey-header {
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--Semantic-Extensions-Border-Table, rgba(208, 213, 215, 1));
}

.survey-container.active {
    visibility: visible;
    opacity: 1;
}

.selected .satisfaction-text {
    text-align: center;
    white-space: nowrap;
    font-size: 14px;
    color: rgba(57, 174, 223, 1);
    display: block;
}

.satisfaction-text {
    display: none;
}

.survey-level-options {
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 480px) {
    .survey-title-text.survey-title-text {
        font-size: 14px;
    }
    .survey-container {
        font-size: 12px;
        max-width: 90vw;
    }
    .selected .satisfaction-text {
        font-size: 12px;
    }
}

.survey-content-container {
    padding-right: 10px;
    max-height: 60vh;
    overflow-y: auto;
    color: rgba(95, 107, 113, 1);
}

.survey-level-container {
    display: flex;
    flex-direction: column;
    overflow-y: visible;
    margin-top: 5px;
    margin-bottom: 10px;
    padding-bottom: 5px;
}

.survey-input-title {
    margin: 10px 0px;
}

.survey-input-text {
    width: 100%;
    height: 150px;
    resize: none;
}

.survey-input-text:focus-visible {
    outline: rgba(57, 174, 223, 1) auto 1px;
}

.survey-title-text {
    color: rgba(95, 107, 113, 1);
    font-size: 18px;
    display: block;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: translateY(5px);
    padding: 16px, 0px, 10px, 0px;
}

.survey-footer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}

.survey-submit-btn {
    margin-left: auto;
}

.survey-btn {
    transition:
        background-color 0.2s,
        fill 0.2s,
        opacity 0.5s;
    cursor: pointer;
    width: 80px;
    height: 30px;
    color: rgba(141, 153, 160, 1);
    padding: 7px, 5px;
    font-size: 12px;
    border: 1px solid var(--Semantic-Border-Light, rgba(177, 186, 190, 1));
    border-radius: 5px;
    background-color: #FFFFFF;
}

.survey-btn-loading::after {
    animation: loading .5s infinite linear;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: loading;
    animation-timeline: auto;
    animation-range-start: normal;
    animation-range-end: normal;
    border: .1rem solid var(--Semantic-Border-Light, rgba(177, 186, 190, 1));
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: .8rem;
    margin-left: -.4rem;
    margin-top: -.4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    width: .8rem;
    z-index: 1;
}

.survey-submit-btn:hover {
    color: #FFFFFF;
    border-color: #FFFFFF;
    background-color: rgba(57, 174, 223, 1);
}

.survey-cancel-btn {
    width: 90px;
}
.survey-cancel-btn:hover {
    color: #FFFFFF;
    background: #bbbdbf;
    border-color: #bbbdbf;
}


.survey-btn-loading.survey-btn-loading {
    color: transparent;
    min-height: .8rem;
    pointer-events: none;
    position: relative;
}

.emotion-rating {
    transition:
        background-color 0.2s,
        fill 0.2s,
        opacity 0.5s;
    cursor: pointer;
    border-radius: 15px;
    background-color: rgba(233, 235, 236, 1);
    fill: rgba(18, 84, 113, 1);
    width: 60px;
    height: 60px;
    padding: 3px;
}
</style>
