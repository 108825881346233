"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanVariableStore = void 0;
class PlanVariableStore {
    data = {};
    add(item) {
        this.data[item.getId()] = item;
    }
    byId(id) {
        return this.data[id];
    }
    query(options) {
        let out = Object.values(this.data);
        if (options.branchId) {
            out = out.filter(p => p.getBranchId() == options.branchId);
        }
        return out;
    }
    delete(id) {
        throw new Error("Method not implemented.");
    }
}
exports.PlanVariableStore = PlanVariableStore;
