"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateRoleOverrideDates = exports.deleteRoleSectionFromRole = exports.addRoleSectionToRole = void 0;
async function addRoleSectionToRole(userId, activeBranchId, payload) {
    const requestURL = `/dashboard/${userId}/role_slot_section?c=${activeBranchId}`;
    const formData = new FormData();
    formData.set("roleID", payload.roleId.toString());
    formData.set("sectionRoleID", payload.sectionRoleId.toString());
    try {
        const response = await fetch(requestURL, {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (err) {
        throw err;
    }
}
exports.addRoleSectionToRole = addRoleSectionToRole;
async function deleteRoleSectionFromRole(userId, activeBranchId, payload) {
    const url = `/dashboard/${userId}/role_slot_section?c=${activeBranchId}`;
    const searchParams = new URLSearchParams({
        "roleID": payload.roleId.toString(),
        "sectionRoleID": payload.sectionRoleId.toString()
    });
    const requestURL = `${url}&${searchParams.toString()}`;
    try {
        const response = await fetch(requestURL, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.json();
    }
    catch (err) {
        throw err;
    }
}
exports.deleteRoleSectionFromRole = deleteRoleSectionFromRole;
async function updateRoleOverrideDates(userId, roleId, activeBranchId, payload) {
    const url = `/dashboard/${userId}/role/${roleId}?c=${activeBranchId}`;
    const formData = new URLSearchParams();
    if (payload.deleteDate) {
        formData.set("deleteDate", payload.deleteDate);
    }
    if (payload.addDate) {
        formData.set("addDate", payload.addDate);
        formData.set("num", payload.num?.toString() || "0");
    }
    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData,
        });
        return response.json();
    }
    catch (err) {
        throw err;
    }
}
exports.updateRoleOverrideDates = updateRoleOverrideDates;
