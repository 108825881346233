"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const isoDays = Object.freeze({
    MON: 1,
    TUE: 2,
    WED: 3,
    THUR: 4,
    FRI: 5,
    SAT: 6,
    SUN: 7,
});
exports.default = isoDays;
