"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deletePlanFunctionBody = exports.updatePlanFunctionBody = exports.createPlanFunctionBody = exports.fetchPlanFunctionBodies = void 0;
const webappApi_1 = require("./webappApi");
const route = "plan_function_bodies";
async function fetchPlanFunctionBodies(params) {
    const p = new URLSearchParams();
    p.append('functionID', params.functionId.toString());
    if (params.startDateGte) {
        p.append('startDateGte', params.startDateGte.toISOString());
    }
    if (params.startDateLte) {
        p.append('startDateLte', params.startDateLte.toISOString());
    }
    const res = await (0, webappApi_1.makeGetRequest)(`${route}?${p.toString()}`);
    const j = await res.json();
    return j;
}
exports.fetchPlanFunctionBodies = fetchPlanFunctionBodies;
async function createPlanFunctionBody(params) {
    const body = {
        functionBody: params.functionBody,
        functionID: params.functionId,
        startDate: params.startDate,
        endDate: params.endDate,
    };
    const res = await (0, webappApi_1.makePostRequest)(route, JSON.stringify(body));
    const j = await res.json();
    return j;
}
exports.createPlanFunctionBody = createPlanFunctionBody;
async function updatePlanFunctionBody(functionBody, id) {
    const body = {
        functionBody,
    };
    const res = await (0, webappApi_1.makePatchRequest)(`${route}/${id}`, JSON.stringify(body));
    const j = await res.json();
    return j;
}
exports.updatePlanFunctionBody = updatePlanFunctionBody;
async function deletePlanFunctionBody(id) {
    await (0, webappApi_1.makeDeleteRequest)(`${route}/${id}`);
}
exports.deletePlanFunctionBody = deletePlanFunctionBody;
