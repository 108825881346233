"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShiftColumnStats = void 0;
class ShiftColumnStats {
    totalCost = 0;
    laborHours = 0;
    transportCost = 0;
    laborCost = 0;
    numOfWorkers = 0;
    roleCounts = {};
    orRoleCounts = {};
    roleSectionCounts = {};
    constructor() { }
    setNumWorkers(v) {
        this.numOfWorkers = v;
    }
    setLaborHours(v) {
        this.laborHours = v;
    }
    setTransportCost(v) {
        this.transportCost = v;
    }
    setOrRoleCounts(v) {
        this.orRoleCounts = v;
    }
    setRoleCounts(v) {
        this.roleCounts = v;
    }
    setRoleSectionCounts(v) {
        this.roleSectionCounts = v;
    }
}
exports.ShiftColumnStats = ShiftColumnStats;
