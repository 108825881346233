<script>
  export default {
    props: {
      activeBranchId: Number,
      preferredBranchId: Number,
    },
    data() {
      return {
        showList: false,
        employmentMap: {},
        employmentList: [],
      }
    },
    methods: {
      initData(employments) {
        this.employmentList = employments;
        this.employmentList.forEach(branch => {
          this.employmentMap[branch.id] = branch;
        })
      },
      selectBranch(id) {
        // UserSelectCompany is a global function from dashboard_base.html
        return UserSelectCompany(id);
      },
      showBranchList() {
        this.showList = true;
      },
      closeBranchList() {
        this.showList = false;
      },
      updateBranchPos(newEmploymentOrder) {
        this.initData(newEmploymentOrder);
      },
      isActive(branch) {
        return branch.id == this.activeBranchId
      }
    },
    computed: {
      activeBranch() {
        return this.employmentMap?.[this.activeBranchId];
      },
      preferredBranch() {
        return this.employmentMap?.[this.preferredBranchId];
      },
      employmentsComputed() {
        return this.employmentList.filter(b => !b.isPreferred);
      }
    },
  }
</script>
<template>
  <ul v-if="showList" class="branch-list-menu">
    <div class="divider text-center" data-content="選択中の拠点"></div>
    <li v-if="activeBranch" class="branch-item selected">
      <a class="branch-link">{{ activeBranch.name }}</a>
    </li>
    <div class="divider"></div>
    <li v-if="preferredBranch" class="branch-item">
      <a class="branch-link" @click="selectBranch(preferredBranch.id);">{{ preferredBranch.name }}
        <i class="preferred-branch-icon zmdi zmdi-pin-assistant tooltip tooltip-left" data-tooltip="優先拠点"></i>
      </a>
    </li>
    <li v-for="branch in employmentsComputed" :key="branch.id" class="branch-item" :class="{'is-active': isActive(branch)}">
      <a class="branch-link" @click="selectBranch(branch.id);">{{ branch.name }}</a>
    </li>
  </ul>
</template>
<style scoped>
  .branch-list-menu {
    position: absolute;
    min-width: 120px;
    max-width: 300px;
    max-height: 50vh;
    width: fit-content;
    white-space: nowrap;
    overflow-y: auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0.05rem 0.2rem rgba(48,55,66,.3);
    list-style: none;
    margin: 0;
    padding: 0.4rem;
    transform: translateY(0.2rem);
    z-index: 300;
    animation: slide-down .15s ease 1;
  }

  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-1.6rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Override spectre's a tag design */
  a.branch-link {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: inherit;
    border-radius: 0.1rem;
    color: inherit;
    display: block;
    margin: 0 -0.4rem;
    padding: 0.2rem 0.8rem 0.2rem 0.4rem;
    text-decoration: none;
  }

  .branch-item {
    padding: 0 0.4rem;
    text-decoration: none;
    user-select: none;
    position: relative;
  }

  .branch-link:hover {
    color: #52a3d5;
    border-radius: 5px;
    background: #f1f1fc;
  }

  .branch-item.selected {
    background: #f1f1fc;
    color: #52a3d5;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .branch-item.is-active {
    color: #52a3d5;
  }

  .branch-item + .branch-item {
    margin-top: 0.2rem;
  }

  .branch-list-menu::-webkit-scrollbar {
    width: 10px;
  }

  .branch-list-menu::-webkit-scrollbar-track {
    border-radius: 0 5px 5px 0;
    border: 0.1px solid #e6e6e6;
  }

  .branch-list-menu::-webkit-scrollbar-thumb {
    background-color: #62799082;
    border: 3px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
    cursor: pointer;
  }

  .branch-list-menu .zmdi-lock {
    margin-left: 8px;
    color: #4897c9;
  }

  @media (max-width: 392px) {
    .branch-list-menu {
      max-width: 200px;
    }
  }
  @media (max-width: 280px) {
    .branch-list-menu {
      max-width: 120px;
    }
  }

  .preferred-branch-icon {
    position: absolute;
    right: 2%;
    bottom: 50%;
    transform: translateY(50%);
  }
</style>
