"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parse = void 0;
const planValue_1 = require("../../../model/v2/planValue");
function parse(input) {
    const out = [];
    input.forEach(p => {
        const req = {
            id: p.id,
            functionBody: p.functionBody,
            startDate: new Date(p.startDate),
            endDate: new Date(p.endDate),
            functionId: p.planFunctionId,
        };
        let value = undefined;
        const n = parseInt(p.functionBody);
        if (!isNaN(n)) {
            value = n;
        }
        const opt = {
            value,
        };
        const v = new planValue_1.PlanValue(req, opt);
        out.push(v);
    });
    return out;
}
exports.parse = parse;
