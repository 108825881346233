"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeStore = void 0;
const user_1 = require("../../model/v1/user");
class MeStore {
    me = new user_1.User({ id: 0 });
    activeBranchId = "";
    activeGroupId = "";
    timeZone = "";
    isLeader = false;
    constructor(req, opt) {
        this.me = req.user;
        if (opt?.activeBranchId) {
            this.activeBranchId = opt.activeBranchId;
        }
        if (opt?.activeGroupId) {
            this.activeGroupId = opt.activeGroupId;
        }
        if (opt?.isLeader) {
            this.isLeader = opt.isLeader;
        }
        if (opt?.timeZone) {
            this.timeZone = opt.timeZone;
        }
    }
    getActiveBranchId() {
        return this.activeBranchId;
    }
    setActiveBranchId(id) {
        this.activeBranchId = id;
    }
    getActiveGroupId() {
        return this.activeGroupId;
    }
    setActiveGroupId(id) {
        this.activeGroupId = id;
    }
    setMe(me) {
        this.me = me;
    }
    getTimeZone() {
        return this.timeZone;
    }
    setTimeZone(tz) {
        this.timeZone = tz;
    }
    getActiveUserId() {
        return this.me.getId();
    }
    getIsLeader() {
        return this.isLeader;
    }
}
exports.MeStore = MeStore;
