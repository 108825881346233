<script>
import Modal from './Modal.vue';
import { MODAL_NAMES } from '../js/modal'

export default {
  data() {
    return {
      isVisible: false,
      isLoading: false,
      formData: {
        timecardId: 0,
        restForm: [],
        userId: 0,
        date: "",
        punchIn: null,
        punchOut: null,
        memo: null,
      }
    }
  },
  components: {
    Modal,
  },
  methods: {
    initData(tcId, userID = 0, date = "") {
      this.formData.timecardId = tcId;
      const tcObject = TimeCardMap?.[tcId];
      // for create
      if (!tcId) {
        this.formData.userId = userID;
        this.formData.date = date;
        this.formData.memo = null;
        this.formData.punchIn = null;
        this.formData.punchOut = null;
      } else {
        // for Edit
        this.formData.userId = tcObject?.UserID;
        this.formData.date = tcObject?.Date;
        this.formData.punchIn = tcObject?.FromLabel;
        this.formData.punchOut = tcObject?.ToLabel;
        this.formData.memo = tcObject?.Memo;
      }
      this.getRestTime(tcObject);
    },
    closeModal() {
      this.initData();
      this.isVisible = false;
    },
    openCreate(userID, date) {
      this.initData(null, userID, date);
      this.isVisible = true;
    },
    openEdit(tcId) {
      this.initData(tcId);
      this.isVisible = true;
    },
    onConfirm() {
      this.submitTimecard();
    },
    onCancel() {
      this.isVisible = false;
    },
    onDelete() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      return deleteTimeCardModalAjax(this.formData.timecardId);
    },
    getRestTime(tcObject) {
      if (!tcObject) {
        this.formData.restForm = [];
        return
      }

      const restInArray = tcObject.RestIns.split(",");
      const restOutArray = tcObject.RestOuts.split(",");

      for (let i = 0; i < restInArray.length; i++) {
        this.formData.restForm[i] = {
          restIn: restInArray[i] || "",
          restOut: restOutArray[i] || "",
        }
      }
    },
    addRestForm() {
      this.formData.restForm.push({
        restIn: "",
        restOut: "",
      })
    },
    createDataForSubmit() {
      const restIns = [];
      const restOuts = [];

      this.formData.restForm.forEach(rest => {
        restIns.push(rest.restIn);
        restOuts.push(rest.restOut);
      });

      return {
        userID: this.formData.userId,
        date: this.formData.date,
        id: this.formData.timecardId,
        from: this.formData.punchIn,
        to: this.formData.punchOut,
        memo: this.formData.memo,
        restIns: restIns.join(","),
        restOuts: restOuts.join(","),
      };
    },
    async submitTimecard() {
      if (this.isLoading) {
        return;
      }
      const data = this.createDataForSubmit();
      this.isLoading = true;
      try {
        await submitTimeCardModalAjax(data);
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    }
  },
  computed: {
    loadingClass() {
      return this.isLoading ? "loading" : "";
    },
    modalName() {
      return MODAL_NAMES.TIMECARD_MODAL;
    },
    isEdit() {
      return this.formData.timecardId !== null;
    },
    renderCreateText() {
      return this.isEdit ? "編集" : "作成";
    }
  },
}
</script>

<template>
  <Modal :modal-name="modalName" v-if="isVisible" @closeModal="closeModal" @confirm="onConfirm" @cancel="onCancel" :has-title-divider="true" :has-footer-divider="true">
    <template v-slot:header-title>
        タイムカード{{ renderCreateText }}
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <label class="oplus-form-text" for="punch-in">出勤
          <input v-model="formData.punchIn" type="text" name="punch-in" id="" placeholder="10, 10:00, 1000">
        </label>
        <label class="oplus-form-text" for="punch-out">退勤
          <input v-model="formData.punchOut" type="text" name="punch-out" id="" placeholder="10, 10:00, 1000">
        </label>
        <label v-if="formData.restForm.length" class="oplus-form-text" for="">休憩
          <div v-for="_, index in formData.restForm" :key="index" class="rest-in-out-wrapper">
            <div class="d-flex">
              <span class="rest-in-out-label">から:</span>
              <input v-model="formData.restForm[index].restIn" type="text" name="rest-in" id="" placeholder="10, 10:00">
            </div>
            <div class="d-flex">
              <span class="rest-in-out-label">まで:</span>
              <input v-model="formData.restForm[index].restOut" type="text" name="rest-out" id="" placeholder="10, 10:00">
            </div>
          </div>
        </label>
        <div class="add-rest-wrapper">
          <span>Add 休憩</span>
          <button class="btn btn-slot-add-secondary" @click="addRestForm()"><i class="icon icon-plus"></i></button>
        </div>
        <label class="oplus-form-text" for="memo">メモ
          <input v-model="formData.memo" type="text" name="memo" id="" placeholder="（任意）">
        </label>
      </div>
    </template>
    <template v-slot:footer-btn>
      <div class="timecard-bottom-btn-wrapper">
        <div class="svg-container oplus-icon timecard-delete-btn" v-if="isEdit" :class="loadingClass" @click="onDelete">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve" viewBox="0 0 85 85" width="30px" id="delete-icon">
            <path fill="currentColor" d="M63.6,69.2c-0.1,1.6-1.4,2.8-3,2.8H26c-1.6,0-2.9-1.3-3-2.8l-1.7-37.8c-0.1-1.6,1.2-2.8,2.7-2.8h38.4
              c1.6,0,2.8,1.3,2.7,2.8L63.6,69.2z M70.5,20.1c0-2-1.6-3.6-3.6-3.6H54.5l-1.9-3.8H34l-2,3.8H19.5c-2,0-3.6,1.6-3.6,3.6
              c0,2,1.6,3.6,3.6,3.6h47.3C68.8,23.8,70.5,22.2,70.5,20.1z"/>
          </svg>
        </div>
        <button class="oplus-modal-btn oplus-alt-confirm-btn" :class="loadingClass" @click="onConfirm">{{ renderCreateText }}</button>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
  .oplus-form-text {
    width: 100%;
  }

  .header-wrapper {
    text-align: center;
    padding-bottom: 0;
  }

  .add-rest-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cfcfcf;
    padding: 10px 0;
  }

  .rest-in-out-wrapper {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    min-height: 230px;
    width: 280px;
    margin: auto;
    padding: 15px 0;
  }

  .rest-in-out-label {
    width: 80px;
    border-bottom: 1px solid #cfcfcf;
    padding-bottom: 0.18rem;
    margin: 0;
    height: fit-content;
    flex-grow: 1;
    flex-wrap: nowrap;
    text-wrap: nowrap;
  }

  .timecard-bottom-btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .timecard-delete-btn {
    margin-right: 160px;
  }
</style>
