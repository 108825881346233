import { PASSWORD_ERROR_TYPES } from "../enums/password_error_types";
import ERRORS from "../errors";

export function isPasswordValid(password) {
  // Regular expression to match ASCII printable characters
  const printableAsciiRegex = /^[ -~]+$/;
  const message = ERRORS.PASSWORD.INVALID_INPUT;

  let errorType = {
    [PASSWORD_ERROR_TYPES.INVALID_CHARS]: false,
    [PASSWORD_ERROR_TYPES.CHARS_LENGTH]: false,
  };
  let isValid = true;

  if (password === "") {
    errorType[PASSWORD_ERROR_TYPES.INVALID_CHARS] = true;
    errorType[PASSWORD_ERROR_TYPES.CHARS_LENGTH] = true;

    isValid = false;
  }

  if (!printableAsciiRegex.test(password)) {
    errorType[PASSWORD_ERROR_TYPES.INVALID_CHARS] = true;
    isValid = false;
  }

  if (password.length < 4 || password.length > 71) {
    errorType[PASSWORD_ERROR_TYPES.CHARS_LENGTH] = true;
    isValid = false;
  }

  // More conditions if necessary

  return { message, isValid, errorType };
}

export function validateChangeOrAddPassword(password, repeatPassword) {
  let { message, isValid } = isPasswordValid(password);

  if (!isValid) {
    return { message, isValid };
  }

  if (password !== repeatPassword) {
    message = ERRORS.PASSWORD.NOT_MATCH;
    isValid = false;
  }

  return { message, isValid };
}

