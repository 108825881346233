"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BranchStore = void 0;
class BranchStore {
    data;
    constructor() {
        this.data = {};
    }
    add(b) {
        this.data[b.getId()] = b;
    }
    byId(id) {
        return this.data[id];
    }
    query(options) {
        let out = Object.values(this.data);
        // For notification settings branch search
        if (options.name) {
            out = out.filter(b => {
                return b.getBranchName().includes(options.name ?? '');
            });
        }
        return out;
    }
    delete(id) {
        if (!this.data[id]) {
            return;
        }
        delete this.data[id];
    }
}
exports.BranchStore = BranchStore;
