"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BranchSettings = void 0;
class BranchSettings {
    timeZone = "Asia/Tokyo";
    // summary start date
    weekStart = 0;
    monthStart = 1;
    yearStart = 1;
    // daily summary start date
    summaryDayStart = 0;
    summaryDayEnd = 24;
    // shift text edit on modal
    staffCanViewSummary = false;
    splitNightShift = false;
    stackedRequest = false;
    showRoles = false;
    defaultView = 0;
    shiftInterval = 0;
    maxConsecutiveWorkdays = 0;
    closedWeekdays = new Array(7).fill(false);
    showRoleTimes = 0;
    shiftWorkText = "";
    shiftOffText = "";
    shiftPTOText = "";
    shiftHalfPTOText = "";
    shiftStaffOffText = "";
    // shift lock date
    shiftLockDate = undefined;
    shiftNotification = 0;
    // show summary row stats
    isWorkCountInSummaryPage = false;
    isVacationCountInSummaryPage = false;
    isLaborInSummaryPage = false;
    isPTOCountInSummaryPage = false;
    // show plan stats on summary page
    isPlanMemoInSummaryPage = false;
    isPlanNumInSummaryPage = false;
    isPlanNumEqInSummaryPage = false;
    isPlanRevenueInSummaryPage = false;
    // show extra row stats
    transportSummary = false;
    memoSummary = false;
    // Shift modal config
    isHidingPurposeInShiftModal = false;
    isHidingFromInShiftModal = false;
    isHidingToInShiftModal = false;
    isHidingRestInShiftModal = false;
    isHidingCommentInputInShiftModal = false;
    isHidingTypeOInShiftModal = false;
    isHidingTypeXInShiftModal = false;
    isHidingTypePTOInShiftModal = false;
    isHidingTypeHalfPTOInShiftModal = false;
    isHidingTypePublicHolidayInShiftModal = false;
    isHidingTypeOtherHolidayInShiftModal = false;
    isHidingRoleInStaffModal = false;
    staffLock = false;
    staffLockedSummary = false;
    // perms
    autoConsec = false;
    dwsSort = false;
    requestOff = false;
    roleRequestOff = false;
    hideTimeLess = false;
    hideLiveChat = false;
    enableKingOfTime = false;
    enableMoneyForward = false;
    enableSmartHR = false;
    enableMinajin = false;
    enablePathosLogos = false;
    staffNoBoard = false;
    // timecard settings
    timecardRoundFrom = 0;
    timecardRoundTo = 0;
    timecardRoundRestFrom = 0;
    timecardRoundRestTo = 0;
    timecardAutoRests = [];
    isHidingRestTimecard = false;
    freePunch = false;
    punchLocation = false;
    branchBasedPunch = false;
    enableHideTimecard = false;
    isHidingTimeCardEdit = false;
    enableOfflineKiosk = false;
    timecardAutoDelete = 3; // 3mins default
    timecardCeilingFrom = 0;
    timecardFloorTo = 0;
    overtimeWarning = 0;
    overtimeWarningMonthly = 0;
    // optional
    ccBizWorkplaceCode;
    constructor(req, opt) {
        this.timeZone = req.timeZone;
        if (opt?.weekStart) {
            this.weekStart = opt.weekStart;
        }
        if (opt?.monthStart) {
            this.monthStart = opt.monthStart;
        }
        if (opt?.yearStart) {
            this.yearStart = opt.yearStart;
        }
        if (opt?.summaryDayStart) {
            this.summaryDayStart = opt.summaryDayStart;
        }
        if (opt?.summaryDayEnd) {
            this.summaryDayEnd = opt.summaryDayEnd;
        }
        if (opt?.staffCanViewSummary) {
            this.staffCanViewSummary = opt.staffCanViewSummary;
        }
        if (opt?.splitNightShift) {
            this.splitNightShift = opt.splitNightShift;
        }
        if (opt?.stackedRequest) {
            this.stackedRequest = opt.stackedRequest;
        }
        if (opt?.showRoles) {
            this.showRoles = opt.showRoles;
        }
        if (opt?.defaultView) {
            this.defaultView = opt.defaultView;
        }
        if (opt?.shiftInterval) {
            this.shiftInterval = opt.shiftInterval;
        }
        if (opt?.maxConsecutiveWorkdays) {
            this.maxConsecutiveWorkdays = opt.maxConsecutiveWorkdays;
        }
        if (opt?.closedWeekdays) {
            this.closedWeekdays = opt.closedWeekdays || [false, false, false, false, false, false, false];
        }
        if (opt?.showRoleTimes) {
            this.showRoleTimes = opt.showRoleTimes;
        }
        if (opt?.shiftWorkText) {
            this.shiftWorkText = opt.shiftWorkText;
        }
        if (opt?.shiftOffText) {
            this.shiftOffText = opt.shiftOffText;
        }
        if (opt?.shiftPTOText) {
            this.shiftPTOText = opt.shiftPTOText;
        }
        if (opt?.shiftHalfPTOText) {
            this.shiftHalfPTOText = opt.shiftHalfPTOText;
        }
        if (opt?.shiftStaffOffText) {
            this.shiftStaffOffText = opt.shiftStaffOffText;
        }
        if (opt?.shiftLockDate) {
            this.shiftLockDate = opt.shiftLockDate;
        }
        if (opt?.shiftNotification) {
            this.shiftNotification = opt.shiftNotification;
        }
        if (opt?.isWorkCountInSummaryPage) {
            this.isWorkCountInSummaryPage = opt.isWorkCountInSummaryPage;
        }
        if (opt?.isVacationCountInSummaryPage) {
            this.isVacationCountInSummaryPage = opt.isVacationCountInSummaryPage;
        }
        if (opt?.isLaborInSummaryPage) {
            this.isLaborInSummaryPage = opt.isLaborInSummaryPage;
        }
        if (opt?.isPTOCountInSummaryPage) {
            this.isPTOCountInSummaryPage = opt.isPTOCountInSummaryPage;
        }
        if (opt?.isPlanMemoInSummaryPage) {
            this.isPlanMemoInSummaryPage = opt.isPlanMemoInSummaryPage;
        }
        if (opt?.isPlanNumInSummaryPage) {
            this.isPlanNumInSummaryPage = opt.isPlanNumInSummaryPage;
        }
        if (opt?.isPlanNumEqInSummaryPage) {
            this.isPlanNumEqInSummaryPage = opt.isPlanNumEqInSummaryPage;
        }
        if (opt?.isPlanRevenueInSummaryPage) {
            this.isPlanRevenueInSummaryPage = opt.isPlanRevenueInSummaryPage;
        }
        if (opt?.transportSummary) {
            this.transportSummary = opt.transportSummary;
        }
        if (opt?.memoSummary) {
            this.memoSummary = opt.memoSummary;
        }
        if (opt?.isHidingPurposeInShiftModal) {
            this.isHidingPurposeInShiftModal = opt.isHidingPurposeInShiftModal;
        }
        if (opt?.isHidingFromInShiftModal) {
            this.isHidingFromInShiftModal = opt.isHidingFromInShiftModal;
        }
        if (opt?.isHidingToInShiftModal) {
            this.isHidingToInShiftModal = opt.isHidingToInShiftModal;
        }
        if (opt?.isHidingRestInShiftModal) {
            this.isHidingRestInShiftModal = opt.isHidingRestInShiftModal;
        }
        if (opt?.isHidingCommentInputInShiftModal) {
            this.isHidingCommentInputInShiftModal = opt.isHidingCommentInputInShiftModal;
        }
        if (opt?.isHidingTypeOInShiftModal) {
            this.isHidingTypeOInShiftModal = opt.isHidingTypeOInShiftModal;
        }
        if (opt?.isHidingTypeXInShiftModal) {
            this.isHidingTypeXInShiftModal = opt.isHidingTypeXInShiftModal;
        }
        if (opt?.isHidingTypePTOInShiftModal) {
            this.isHidingTypePTOInShiftModal = opt.isHidingTypePTOInShiftModal;
        }
        if (opt?.isHidingTypeHalfPTOInShiftModal) {
            this.isHidingTypeHalfPTOInShiftModal = opt.isHidingTypeHalfPTOInShiftModal;
        }
        if (opt?.isHidingTypePublicHolidayInShiftModal) {
            this.isHidingTypePublicHolidayInShiftModal = opt.isHidingTypePublicHolidayInShiftModal;
        }
        if (opt?.isHidingTypeOtherHolidayInShiftModal) {
            this.isHidingTypeOtherHolidayInShiftModal = opt.isHidingTypeOtherHolidayInShiftModal;
        }
        if (opt?.isHidingRoleInStaffModal) {
            this.isHidingRoleInStaffModal = opt.isHidingRoleInStaffModal;
        }
        if (opt?.staffLock) {
            this.staffLock = opt.staffLock;
        }
        if (opt?.staffLockedSummary) {
            this.staffLockedSummary = opt.staffLockedSummary;
        }
        if (opt?.autoConsec) {
            this.autoConsec = opt.autoConsec;
        }
        if (opt?.dwsSort) {
            this.dwsSort = opt.dwsSort;
        }
        if (opt?.requestOff) {
            this.requestOff = opt.requestOff;
        }
        if (opt?.roleRequestOff) {
            this.roleRequestOff = opt.roleRequestOff;
        }
        if (opt?.hideTimeLess) {
            this.hideTimeLess = opt.hideTimeLess;
        }
        if (opt?.hideLiveChat) {
            this.hideLiveChat = opt.hideLiveChat;
        }
        if (opt?.enableKingOfTime) {
            this.enableKingOfTime = opt.enableKingOfTime;
        }
        if (opt?.enableMoneyForward) {
            this.enableMoneyForward = opt.enableMoneyForward;
        }
        if (opt?.enableSmartHR) {
            this.enableSmartHR = opt.enableSmartHR;
        }
        if (opt?.enableMinajin) {
            this.enableMinajin = opt.enableMinajin;
        }
        if (opt?.enablePathosLogos) {
            this.enablePathosLogos = opt.enablePathosLogos;
        }
        if (opt?.staffNoBoard) {
            this.staffNoBoard = opt.staffNoBoard;
        }
        if (opt?.timecardRoundFrom) {
            this.timecardRoundFrom = opt.timecardRoundFrom;
        }
        if (opt?.timecardRoundTo) {
            this.timecardRoundTo = opt.timecardRoundTo;
        }
        if (opt?.timecardRoundRestFrom) {
            this.timecardRoundRestFrom = opt.timecardRoundRestFrom;
        }
        if (opt?.timecardRoundRestTo) {
            this.timecardRoundRestTo = opt.timecardRoundRestTo;
        }
        if (opt?.timecardAutoRests) {
            this.timecardAutoRests = opt.timecardAutoRests;
        }
        if (opt?.isHidingRestTimecard) {
            this.isHidingRestTimecard = opt.isHidingRestTimecard;
        }
        if (opt?.freePunch) {
            this.freePunch = opt.freePunch;
        }
        if (opt?.punchLocation) {
            this.punchLocation = opt.punchLocation;
        }
        if (opt?.branchBasedPunch) {
            this.branchBasedPunch = opt.branchBasedPunch;
        }
        if (opt?.enableHideTimecard) {
            this.enableHideTimecard = opt.enableHideTimecard;
        }
        if (opt?.isHidingTimeCardEdit) {
            this.isHidingTimeCardEdit = opt.isHidingTimeCardEdit;
        }
        if (opt?.enableOfflineKiosk) {
            this.enableOfflineKiosk = opt.enableOfflineKiosk;
        }
        if (opt?.timecardAutoDelete) {
            this.timecardAutoDelete = opt.timecardAutoDelete;
        }
        if (opt?.timecardCeilingFrom) {
            this.timecardCeilingFrom = opt.timecardCeilingFrom;
        }
        if (opt?.timecardFloorTo) {
            this.timecardFloorTo = opt.timecardFloorTo;
        }
        if (opt?.overtimeWarning) {
            this.overtimeWarning = opt.overtimeWarning;
        }
        if (opt?.overtimeWarningMonthly) {
            this.overtimeWarningMonthly = opt.overtimeWarningMonthly;
        }
        if (opt?.ccBizWorkplaceCode) {
            this.ccBizWorkplaceCode = opt.ccBizWorkplaceCode;
        }
    }
    getSummaryDayStart() {
        return this.summaryDayStart;
    }
    getSummaryDayEnd() {
        return this.summaryDayEnd;
    }
    getMonthStart() {
        return this.monthStart;
    }
    getWeekStart() {
        return this.weekStart;
    }
    getTimeZone() {
        return this.timeZone;
    }
    getShiftSettings() {
        const { staffCanViewSummary, splitNightShift, stackedRequest, showRoles, defaultView, shiftInterval, maxConsecutiveWorkdays, weekStart, monthStart, yearStart, showRoleTimes, shiftWorkText, shiftOffText, shiftPTOText, shiftHalfPTOText, shiftStaffOffText, closedWeekdays, summaryDayStart, summaryDayEnd, shiftLockDate, shiftNotification, isWorkCountInSummaryPage, isVacationCountInSummaryPage, isLaborInSummaryPage, isPTOCountInSummaryPage, isPlanMemoInSummaryPage, isPlanNumInSummaryPage, isPlanNumEqInSummaryPage, isPlanRevenueInSummaryPage, isHidingPurposeInShiftModal, isHidingFromInShiftModal, isHidingToInShiftModal, isHidingRestInShiftModal, isHidingCommentInputInShiftModal, isHidingTypeOInShiftModal, isHidingTypeXInShiftModal, isHidingTypePTOInShiftModal, isHidingTypeHalfPTOInShiftModal, isHidingTypePublicHolidayInShiftModal, isHidingTypeOtherHolidayInShiftModal, isHidingRoleInStaffModal, autoConsec, staffLock, staffLockedSummary, transportSummary, memoSummary, dwsSort, requestOff, roleRequestOff, hideTimeLess, hideLiveChat, enableKingOfTime, enableMoneyForward, enableSmartHR, enableMinajin, enablePathosLogos, ccBizWorkplaceCode, staffNoBoard } = this;
        return {
            staffCanViewSummary,
            splitNightShift,
            stackedRequest,
            showRoles,
            defaultView,
            shiftInterval,
            maxConsecutiveWorkdays,
            weekStart,
            monthStart,
            yearStart,
            showRoleTimes,
            shiftWorkText,
            shiftOffText,
            shiftPTOText,
            shiftHalfPTOText,
            shiftStaffOffText,
            closedWeekdays,
            summaryDayStart,
            summaryDayEnd,
            shiftLockDate,
            shiftNotification,
            isWorkCountInSummaryPage,
            isVacationCountInSummaryPage,
            isLaborInSummaryPage,
            isPTOCountInSummaryPage,
            isPlanMemoInSummaryPage,
            isPlanNumInSummaryPage,
            isPlanNumEqInSummaryPage,
            isPlanRevenueInSummaryPage,
            isHidingPurposeInShiftModal,
            isHidingFromInShiftModal,
            isHidingToInShiftModal,
            isHidingRestInShiftModal,
            isHidingCommentInputInShiftModal,
            isHidingTypeOInShiftModal,
            isHidingTypeXInShiftModal,
            isHidingTypePTOInShiftModal,
            isHidingTypeHalfPTOInShiftModal,
            isHidingTypePublicHolidayInShiftModal,
            isHidingTypeOtherHolidayInShiftModal,
            isHidingRoleInStaffModal,
            autoConsec,
            staffLock,
            staffLockedSummary,
            transportSummary,
            memoSummary,
            dwsSort,
            requestOff,
            roleRequestOff,
            hideTimeLess,
            hideLiveChat,
            enableKingOfTime,
            enableMoneyForward,
            enableSmartHR,
            enableMinajin,
            enablePathosLogos,
            ccBizWorkplaceCode,
            staffNoBoard
        };
    }
    getTimecardSettings() {
        const { timecardRoundFrom, timecardRoundTo, timecardRoundRestFrom, timecardRoundRestTo, timecardAutoRests, isHidingRestTimecard, freePunch, punchLocation, branchBasedPunch, enableHideTimecard, isHidingTimeCardEdit, enableOfflineKiosk, timecardAutoDelete, timecardCeilingFrom, timecardFloorTo, overtimeWarning, overtimeWarningMonthly } = this;
        return {
            timecardRoundFrom,
            timecardRoundTo,
            timecardRoundRestFrom,
            timecardRoundRestTo,
            timecardAutoRests,
            isHidingRestTimecard,
            freePunch,
            punchLocation,
            branchBasedPunch,
            enableHideTimecard,
            isHidingTimeCardEdit,
            enableOfflineKiosk,
            timecardAutoDelete,
            timecardCeilingFrom,
            timecardFloorTo,
            overtimeWarning,
            overtimeWarningMonthly
        };
    }
}
exports.BranchSettings = BranchSettings;
