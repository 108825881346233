"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.restTimeToDuration = exports.parseRepeatShiftTemplateOplusTimeRaw = exports.parseNightShiftOplusTimeRaw = exports.parseMorningShiftOplusTimeRaw = exports.parseSectionOplusTimeRaw = exports.parseOplusTimeRaw = void 0;
const luxon_1 = require("luxon");
const common_1 = require("../../common/common");
const morningShift_1 = require("../../model/v1/morningShift");
const repeatShiftTemplate_1 = require("../../model/v1/repeatShiftTemplate");
const section_1 = require("../../model/v1/section");
const shift_1 = require("../../model/v1/shift");
const nightShift_1 = require("../../model/v1/nightShift");
function parseOplusTimeRaw(raw) {
    const rawFrom = new Date(raw.From);
    const startDate = (0, common_1.fromOplusTime)(rawFrom);
    const rawTo = new Date(raw.To);
    const endDate = (0, common_1.fromOplusTime)(rawTo);
    const sectionsSlots = [];
    if (raw.SectionSlots) {
        raw.SectionSlots.forEach((sR) => {
            const section = parseSectionOplusTimeRaw(sR);
            sectionsSlots.push(section);
        });
    }
    let rest = 0;
    if (raw.Rest && raw.Rest != "0") { // hh:mm or minutes
        rest = restTimeToDuration(raw.Rest);
    }
    const required = {
        id: raw.ID,
        userId: raw.UserID,
        startDate,
        endDate,
        type: raw.Type,
        branchId: raw.CompanyID,
    };
    const options = {
        roleId: raw.RoleID || undefined,
        sections: sectionsSlots,
        hideTo: raw.HideTo,
        hideFrom: raw.HideFrom,
        rest,
        roleName: raw.RoleName || "",
        purpose: raw.Purpose || "",
        isAuto: raw.IsAuto || undefined,
        isEdited: raw.IsEdited || undefined,
        isTemplate: raw.IsTemplate || undefined,
    };
    if (raw.CreatedTimeUnix) {
        const rawCreated = new Date(raw.CreatedTimeUnix * 1000);
        const created = (0, common_1.fromOplusTime)(rawCreated);
        options.created = created;
    }
    else if (raw.CreatedTime) {
        const rawCreated = new Date(raw.CreatedTime);
        const created = (0, common_1.fromOplusTime)(rawCreated);
        options.created = created;
    }
    if (raw.UpdatedTimeUnix) {
        const rawUpdated = new Date(raw.UpdatedTimeUnix * 1000);
        const updated = (0, common_1.fromOplusTime)(rawUpdated);
        options.updated = updated;
    }
    else if (raw.UpdatedTime) {
        const rawUpdated = new Date(raw.UpdatedTime);
        const updated = (0, common_1.fromOplusTime)(rawUpdated);
        options.updated = updated;
    }
    if (raw.LinkPrev) {
        options.linkPrev = raw.LinkPrev;
    }
    if (raw.LinkNext) {
        options.linkNext = raw.LinkNext;
    }
    return new shift_1.Shift(required, options);
}
exports.parseOplusTimeRaw = parseOplusTimeRaw;
function parseSectionOplusTimeRaw(raw) {
    const rawFrom = new Date(raw.From);
    const startDate = (0, common_1.fromOplusTime)(rawFrom);
    const rawTo = new Date(raw.To);
    const endDate = (0, common_1.fromOplusTime)(rawTo);
    let rest = 0;
    if (raw.Rest && raw.Rest != "0") { // hh:mm or minutes
        rest = restTimeToDuration(raw.Rest);
    }
    const required = {
        id: raw.ID,
        userId: raw.UserID,
        startDate,
        endDate,
        type: raw.Type,
        branchId: raw.CompanyID,
    };
    return new section_1.Section(raw.SectionParent || 0, required, { roleId: raw.RoleID || undefined, hideTo: raw.HideTo, hideFrom: raw.HideFrom, rest });
}
exports.parseSectionOplusTimeRaw = parseSectionOplusTimeRaw;
function parseMorningShiftOplusTimeRaw(raw) {
    const rawFrom = new Date(raw.From);
    const startDate = (0, common_1.fromOplusTime)(rawFrom);
    const rawTo = new Date(raw.To);
    const endDate = (0, common_1.fromOplusTime)(rawTo);
    const sectionsSlots = [];
    if (raw.SectionSlots) {
        raw.SectionSlots.forEach((sR) => {
            const section = parseSectionOplusTimeRaw(sR);
            sectionsSlots.push(section);
        });
    }
    let rest = 0;
    if (raw.Rest && raw.Rest != "0") { // hh:mm or minutes
        rest = restTimeToDuration(raw.Rest);
    }
    const required = {
        id: raw.ID,
        userId: raw.UserID,
        startDate,
        endDate,
        type: raw.Type,
        branchId: raw.CompanyID,
    };
    return new morningShift_1.MorningShift(raw.LinkPrev || 0, required, { roleId: raw.RoleID || undefined, sections: sectionsSlots, hideTo: raw.HideTo, hideFrom: raw.HideFrom, rest });
}
exports.parseMorningShiftOplusTimeRaw = parseMorningShiftOplusTimeRaw;
function parseNightShiftOplusTimeRaw(raw) {
    const rawFrom = new Date(raw.From);
    const startDate = (0, common_1.fromOplusTime)(rawFrom);
    const rawTo = new Date(raw.To);
    const endDate = (0, common_1.fromOplusTime)(rawTo);
    const sectionsSlots = [];
    if (raw.SectionSlots) {
        raw.SectionSlots.forEach((sR) => {
            const section = parseSectionOplusTimeRaw(sR);
            sectionsSlots.push(section);
        });
    }
    let rest = 0;
    if (raw.Rest && raw.Rest != "0") { // hh:mm or minutes
        rest = restTimeToDuration(raw.Rest);
    }
    const required = {
        id: raw.ID,
        userId: raw.UserID,
        startDate,
        endDate,
        type: raw.Type,
        branchId: raw.CompanyID,
    };
    return new nightShift_1.NightShift(raw.LinkNext || 0, required, { roleId: raw.RoleID || undefined, sections: sectionsSlots, hideTo: raw.HideTo, hideFrom: raw.HideFrom, rest });
}
exports.parseNightShiftOplusTimeRaw = parseNightShiftOplusTimeRaw;
function parseRepeatShiftTemplateOplusTimeRaw(raw, timeZone) {
    const rawFrom = new Date(raw.From);
    const startDate = (0, common_1.fromOplusTime)(rawFrom);
    const rawTo = new Date(raw.To);
    const endDate = (0, common_1.fromOplusTime)(rawTo);
    const rawRepeatUntil = new Date(raw.RepeatUntil);
    const repeatUntil = (0, common_1.fromOplusTime)(rawRepeatUntil);
    const sectionsSlots = [];
    if (raw.SectionSlots) {
        raw.SectionSlots.forEach((sR) => {
            const section = parseSectionOplusTimeRaw(sR);
            sectionsSlots.push(section);
        });
    }
    let rest = 0;
    if (raw.Rest && raw.Rest != "0") { // hh:mm or minutes
        rest = restTimeToDuration(raw.Rest);
    }
    const exclude = [];
    if (raw.RepeatExclude) {
        raw.RepeatExclude.forEach((dateString) => {
            const rawDate = new Date(dateString);
            const date = (0, common_1.fromOplusTime)(rawDate);
            exclude.push(date);
        });
    }
    // TODO
    const locked = [];
    // Be sure to use correct time zone before converting date to floating hour and minute numbers
    const startInTz = luxon_1.DateTime.fromJSDate(startDate, { zone: timeZone });
    const endInTz = luxon_1.DateTime.fromJSDate(endDate, { zone: timeZone });
    const required = {
        userId: raw.UserID,
        branchId: raw.CompanyID,
        repeatStart: startDate,
        repeatEnd: repeatUntil,
        templateParams: {
            id: raw.ID,
            startHour: startInTz.hour,
            startMinute: startInTz.minute,
            endHour: endInTz.hour,
            endMinute: endInTz.minute,
            timeZone: timeZone,
            type: raw.Type,
        }
    };
    const shiftOptions = {
        sections: sectionsSlots,
        roleId: raw.RoleID || 0,
        roleName: raw.RoleName || "",
        hideTo: raw.HideTo,
        hideFrom: raw.HideFrom,
        rest,
        purpose: raw.Purpose || "",
        isEdited: raw.IsEdited || false,
    };
    if (raw.LinkPrev) {
        shiftOptions.linkPrev = raw.LinkPrev;
    }
    if (raw.LinkNext) {
        shiftOptions.linkNext = raw.LinkNext;
    }
    return new repeatShiftTemplate_1.RepeatShiftTemplate(required, { exclude, locked, shiftOptions });
}
exports.parseRepeatShiftTemplateOplusTimeRaw = parseRepeatShiftTemplateOplusTimeRaw;
function restTimeToDuration(time) {
    const timeNum = Number(time);
    if (!isNaN(timeNum) && Number.isInteger(timeNum) && timeNum >= 0) {
        return timeNum / 60;
    }
    return (0, common_1.kitchenTimeToDuration)(time, "hours"); // TODO: ENUM!
}
exports.restTimeToDuration = restTimeToDuration;
