import { slotHistoryStore } from "./slot_history_store";
import { secondsTimestampToOplusTimeString } from "./common";
import { hasLinkSlot } from "./slot_helpers";
import { slotDeleteRequest, slotPostRequest, slotPutRequest } from "./slot_crud_requests";

const SLOT_BUTTON_ID = Object.freeze({
  UNDO: "#summary-slot-undo-btn",
})
export const ACTIONS_TYPE = Object.freeze({
  CREATE: "create",
  DELETE: "delete",
  UPDATE: "update",
})
const UNDO_ACTIONS_TYPE = Object.freeze({
  [ACTIONS_TYPE.CREATE]: ACTIONS_TYPE.DELETE,
  [ACTIONS_TYPE.DELETE]: ACTIONS_TYPE.CREATE,
  [ACTIONS_TYPE.UPDATE]: ACTIONS_TYPE.UPDATE,
});

export function makeUndoAction(actionType, slot, newSlot, isDrag = false) {
  // Created slots only needs to save the slotID for delete
  // Deleted slots need the slot's form data to be re-created again
  // Updated slots needs both old and new slot
  return {
    undoActionType: UNDO_ACTIONS_TYPE[actionType], // Action undo string, ex. if slot is created, we save "delete" here
    slot: slot,
    newSlot: newSlot,
    isDrag: isDrag,
  }
}

async function undoSlotDeleteRequest(slot) {
  try {
    const response = await slotDeleteRequest(slot.ID);
    return response;
  } catch (err) {
    throw err
  }
}

async function undoSlotCreateRequest(slot) {
  try {
    const overrideData = linkedSlotDataOverride(slot);
    const data = createUndoSlotData(slot, overrideData);
    const response = await slotPostRequest(data);
    return response;
  } catch (err) {
    throw err;
  }
}

async function undoSlotUpdateRequest(slot, newSlot) {
  try {
    const overrideData = linkedSlotDataOverride(slot, newSlot);
    const updateData = createUndoSlotData(slot, overrideData);
    const response = await slotPutRequest(slot.ID, updateData);

    return response;
  } catch (err) {
    throw err;
  }
}

export async function undoActionRequest(undoActionType, slot, newSlot) {
  let response;
  try {
    switch (undoActionType) {
      case ACTIONS_TYPE.DELETE:
        response = await undoSlotDeleteRequest(slot);
        break;

      case ACTIONS_TYPE.CREATE:
        response = await undoSlotCreateRequest(slot);
        break;

      case ACTIONS_TYPE.UPDATE:
        response = await undoSlotUpdateRequest(slot, newSlot);
        break;
    }
  } catch (err) {
    throw err;
  }

  return response;
}


export function createUndoSlotData(slot, overrideData = {}) {
  const data = {
    "from": secondsTimestampToOplusTimeString(slot.FromUnix),
    "to": secondsTimestampToOplusTimeString(slot.ToUnix),
    "comID": slot.CompanyID,
    "date": slot.Date,
    "type": slot.Type,
    "purpose": slot.Purpose,
    "locked": slot.Locked,
    "rest": slot.Rest,
    "help": "",
    "edited": true, // assume that undo feature is only available for manager and shift-leaders
    "userID": slot.UserID,
  };

  if (slot.RoleID) {
    data["roleID"] = slot.RoleID
  }

  if (slot.PatternID) {
    data["patternID"] = slot.PatternID
  }

  if (slot.AskStatus) {
    data["askStatus"] = slot.AskStatus
  }

  if (slot.Color) {
    data["color"] = slot.Color
  }

  if (overrideData.from) {
    data["from"] = overrideData.from;
  }

  if (overrideData.to) {
    data["to"] = overrideData.to;
  }

  if (overrideData.date) {
    data["date"] = overrideData.date;
  }

  return data;
}

function linkedSlotDataOverride(slot, newSlot) {
  const isPrevOrCurrentHasLink = hasLinkSlot(slot) && hasLinkSlot(newSlot);

  const linkedDataObj = {}
  // if slot changes from linked to non-linked (including linked slot that are deleted then will be created via Undo), we take the "From" data from the evening slot, and "To" from morning slot
  // Slot edited from split to non-split or vice versa
  if (!isPrevOrCurrentHasLink) {
    if (slot?.LinkNext && SlotMap?.[slot.LinkNext]) {
      linkedDataObj["to"] = secondsTimestampToOplusTimeString(SlotMap[slot.LinkNext].ToUnix);
    }
    // if the saved slot is the morning slot, we assign the date from LinkPrev
    if (slot?.LinkPrev && SlotMap?.[slot?.LinkPrev]) {
      linkedDataObj["from"] = secondsTimestampToOplusTimeString(SlotMap[slot.LinkPrev].FromUnix);
      linkedDataObj["date"] = SlotMap[slot.LinkPrev].Date
    }
  }

  return linkedDataObj;
}

export function toggleUndoButton() {
  if (slotHistoryStore.undoStack.length > 0) {
    $(SLOT_BUTTON_ID.UNDO).removeClass("disabled")
  } else {
    $(SLOT_BUTTON_ID.UNDO).addClass("btn disabled");
  }
}

export function toggleLoadingClassUndoBtn() {
  if (slotHistoryStore.isLoading) {
    $(SLOT_BUTTON_ID.UNDO).addClass("loading");
  } else {
    $(SLOT_BUTTON_ID.UNDO).removeClass("loading");
  }
}

function saveCommandHistory(action, slot, newSlot, isDrag) {
  // Only save if requestedSlot is off,
  // Only save one of the slot links if its a overnight slot (with split settings on)
  // Only save slot and not sections
  if (!slotHistoryStore.enableSave || slot?.SectionParent || slot?.IsTemplate) {
    return;
  }
  // for now, we only undo the last 5 actions
  if (slotHistoryStore.undoStack.length > slotHistoryStore.maxStack) {
    slotHistoryStore.undoStack.shift();
  }
  slotHistoryStore.undoStack.push(makeUndoAction(action, slot, newSlot, isDrag));

  toggleUndoButton();
}

function saveCreatedSlot(createdSlot) {
  saveCommandHistory(ACTIONS_TYPE.CREATE, createdSlot);
}

function saveDeletedSlot(deletedSlot) {
  saveCommandHistory(ACTIONS_TYPE.DELETE, deletedSlot);
}

function saveUpdatedSlot(slot, slotBeforeEdit, isDrag) {
  saveCommandHistory(ACTIONS_TYPE.UPDATE, slotBeforeEdit, slot, isDrag);
}

// Controller functions
export function addToUndoStack(slot, slotBeforeEdit, isDelete, isDrag) {
  if (!slotHistoryStore.enableSave || slot?.IsTemplate) {
    return;
  }

  if (slotBeforeEdit) {
    saveUpdatedSlot(slot, slotBeforeEdit, isDrag)
    return;
  }

  if (isDelete) {
    saveDeletedSlot(slot);
  } else {
    saveCreatedSlot(slot);
  }

  return;
}

export function removeDeletedSlotInStack(undoActionType, slotID) {
  if (undoActionType === ACTIONS_TYPE.CREATE) {
    const linkedSlot = SlotMap?.[slotID]?.LinkPrev || SlotMap?.[slotID]?.LinkNext;
    slotHistoryStore.undoStack = slotHistoryStore.undoStack.filter(obj => obj.slot.ID !== slotID);

    if (linkedSlot) {
      slotHistoryStore.undoStack = slotHistoryStore.undoStack.filter(obj => obj.slot.ID !== linkedSlot);
    }
  }
  toggleUndoButton();
  return;
}

export function getLastUndoStack() {
  if (slotHistoryStore.isLoading || !slotHistoryStore.undoStack.length) {
    return;
  } else {
    return slotHistoryStore.undoStack[slotHistoryStore.undoStack.length - 1];
  }
}

export function removeLastActionFromUndoStack(){
  slotHistoryStore.undoStack.pop();
  toggleUndoButton();
}

export function enableUndoLoading() {
  slotHistoryStore.isLoading = true;
  toggleLoadingClassUndoBtn();
}


export function disableUndoLoading() {
  slotHistoryStore.isLoading = false;
  toggleLoadingClassUndoBtn();
}

export function disableSaveUndo() {
  return slotHistoryStore.enableSave = false;
}