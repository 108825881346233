export function transformCompany(pipelineCom) {
    const com = {};
    com.isUsingRequestSlots = pipelineCom["StackRequestSlots"];
    com.ID = pipelineCom["ID"];
    com.hasCCBizMate = pipelineCom["hasCCBizMate"]
    return com;
}

export function transformUser(userObject) {
    const user = {};
    user.canEdit = userObject?.canEdit;
    return user;
}
