"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = void 0;
const IndexedDB_1 = require("./IndexedDB");
class User {
    id;
    name = "";
    isFake;
    furigana;
    constructor(required, optional) {
        this.id = required.id;
        this.name = optional?.name || "";
        this.furigana = optional?.furigana || "";
        this.isFake = Boolean(optional?.isFake);
    }
    // Only for use with communication with BE
    getOriginalId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    // For FE use
    getId() {
        return this.id.toString();
    }
    index() {
        return this.getId();
    }
    getStoreName() {
        return IndexedDB_1.StoreNames.User;
    }
    getKey() {
        return this.getId();
    }
    toJSON() {
        return {
            id: this.id,
            name: this.name,
            isFake: this.isFake,
            furigana: this.furigana
        };
    }
}
exports.User = User;
