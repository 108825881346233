"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseOrRoleFromRaw = exports.parseMainRoleFromRaw = exports.parseFromRaw = void 0;
const role_1 = require("../../model/v1/role");
const mainRole_1 = require("../../model/v1/mainRole");
const orRole_1 = require("../../model/v1/orRole");
const common_1 = require("../../common/common");
const common_2 = require("../../js/common");
const luxon_1 = require("luxon");
const models_1 = require("../../js/models");
function makeRoleRequiredParams(raw, timezone) {
    return {
        id: raw.ID,
        name: raw.Name,
        timezone: timezone,
    };
}
function makeRoleOptionalParams(raw, timezone = "Asia/Tokyo") {
    let startHour = 0;
    let startMinute = 0;
    let endHour = 24;
    let endMinute = 0;
    if (raw.From) {
        const startTime = (0, common_1.extractHourAndMinFromKitchenTime)(raw.From);
        // Temporary hack, Role's from and to field should return a date too
        const detailedDate = new Date(`1970-01-01T${(0, common_2.addZeroPlaceholders)(startTime.hour)}:${(0, common_2.addZeroPlaceholders)(startTime.minute)}Z`);
        // add aditional param to fromOplusTime to set timezone?
        const realDate = (0, common_1.fromOplusTime)(detailedDate);
        startHour = luxon_1.DateTime.fromJSDate(realDate, { zone: timezone }).hour;
        startMinute = luxon_1.DateTime.fromJSDate(realDate, { zone: timezone }).minute;
    }
    if (raw.To) {
        const endTime = (0, common_1.extractHourAndMinFromKitchenTime)(raw.To);
        // Temporary hack, Role's from and to field should return a date too
        const detailedDate = new Date(`1970-01-01T${(0, common_2.addZeroPlaceholders)(endTime.hour)}:${(0, common_2.addZeroPlaceholders)(endTime.minute)}Z`);
        // add aditional param to fromOplusTime to set timezone?
        const realDate = (0, common_1.fromOplusTime)(detailedDate);
        endHour = luxon_1.DateTime.fromJSDate(realDate, { zone: timezone }).hour;
        endMinute = luxon_1.DateTime.fromJSDate(realDate, { zone: timezone }).minute;
    }
    return {
        startHour: startHour,
        startMinute: startMinute,
        endHour: endHour,
        endMinute: endMinute,
        color: raw.Color,
        purpose: raw.Purpose,
        rest: parseInt(raw.Rest) || 0,
        roleSections: raw.RoleSections,
        wage: raw.Wage,
        isRoleSection: raw.IsRoleSection,
        showCount: raw.ShowCount,
        isRestSection: raw.SlotType === models_1.SLOT_TYPES.SECTION_REST,
    };
}
function parseFromRaw(raw, timezone = "Asia/Tokyo") {
    const required = makeRoleRequiredParams(raw, timezone);
    const options = makeRoleOptionalParams(raw);
    const role = new role_1.Role(required, options);
    return role;
}
exports.parseFromRaw = parseFromRaw;
function parseMainRoleFromRaw(raw, orRoles, timezone = "Asia/Tokyo") {
    const required = makeRoleRequiredParams(raw, timezone);
    const options = makeRoleOptionalParams(raw);
    const mainRole = new mainRole_1.MainRole(orRoles, required, options);
    return mainRole;
}
exports.parseMainRoleFromRaw = parseMainRoleFromRaw;
function parseOrRoleFromRaw(raw, mainRole, timezone = "Asia/Tokyo") {
    const required = makeRoleRequiredParams(raw, timezone);
    const options = makeRoleOptionalParams(raw);
    const orRole = new orRole_1.OrRole(mainRole, required, options);
    return orRole;
}
exports.parseOrRoleFromRaw = parseOrRoleFromRaw;
