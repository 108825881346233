"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanFunction = void 0;
const APlanType_1 = require("./APlanType");
class PlanFunction extends APlanType_1.APlanType {
    id;
    created;
    variableName;
    branchId;
    displayName = "";
    defaultFunctionBody = "";
    allowDefaultFunction = false;
    showInSummaryPage = true;
    isDeleted = false;
    constructor(req, opt) {
        const superReq = {
            id: req.id,
            branchId: req.branchId,
            type: req.type,
        };
        const superOpt = {
            displayName: opt?.displayName,
            feId: opt?.feId,
        };
        super(superReq, superOpt);
        this.id = req.id;
        this.variableName = req.variableName;
        this.branchId = req.branchId;
        this.created = req.created;
        if (opt?.displayName !== undefined) {
            this.displayName = opt.displayName;
        }
        if (opt?.functionBody !== undefined) {
            this.defaultFunctionBody = opt.functionBody;
        }
        if (opt?.allowDefaultFunction !== undefined) {
            this.allowDefaultFunction = opt.allowDefaultFunction;
        }
        if (opt?.isDeleted !== undefined) {
            this.isDeleted = opt.isDeleted;
        }
        if (opt?.showInSummaryPage !== undefined) {
            this.showInSummaryPage = opt.showInSummaryPage;
        }
    }
    index() {
        return this.getId();
    }
    getVariableName() {
        return this.variableName;
    }
    getDisplayName() {
        return this.displayName || this.variableName;
    }
    getId() {
        if (this.feId) {
            return this.feId;
        }
        return this.id.toString();
    }
    getOriginalId() {
        return this.id;
    }
    getBranchId() {
        return this.branchId.toString();
    }
    getFunctionBody() {
        return this.defaultFunctionBody;
    }
    setFunctionBody(s) {
        this.defaultFunctionBody = s;
    }
    isAllowedDefaultFunction() {
        return this.allowDefaultFunction;
    }
    isShownInSummaryPage() {
        return this.showInSummaryPage;
    }
    isSoftDeleted() {
        return this.isDeleted;
    }
}
exports.PlanFunction = PlanFunction;
