import ERRORS from './errors'
import { loginRequest } from './password_requests'
import { isPasswordValid } from './validation/password'
import { MODAL_ID, mountPasswordModal } from './modal'

let activeUserId = 0
let isLoading = false;
export function initPage(props) {
  activeUserId = props.userId
  mountPasswordModal({
    activeUserId: activeUserId,
    changePasswordAndLogin: true,
    thisUser: {
      hasPassword: false,
      id: activeUserId,
    },
  });
  $("#user-pass-form").on("submit", (e) => {
    e.preventDefault();
    submitUserPass();
  })
}

export async function submitUserPass(newPassword) {
  if (isLoading) {
    return;
  }

  $("#submit-password-btn").addClass("loading");
  isLoading = true;

  const pass = $("#password").val();
  const { message, isValid } = isPasswordValid(pass);

  if (!isValid) {
    alertModal.alert(message);
    $("#submit-password-btn").removeClass("loading");
    isLoading = false;
    return;
  }

  // For mobile apps, there will be a deeplink value. Include in payload for proper redirecting.
  const deeplink = $("#deeplink").val()

  const payload = {
    Pass: pass,
    UserID: activeUserId.toString(),
    Deeplink: deeplink,
  }

  if (newPassword) {
    payload["NewPassword"] = newPassword;
  }

  try {
    const response = await loginRequest(payload);
    // If user has default password (AIM User exclusive)
    if (response.changePassword) {
      window[MODAL_ID.PASSWORD_MODAL].openModal();
    }

    if (response.redirected) {
      window.location.href = response.url;
      return;
    }
  } catch (errorResponse) {
    const data = errorResponse.responseJSON;

    let errMessage = "";

    switch (data.status) {
      // Forbidden because of perms or other status
      case "403":
        errMessage = ERRORS.FORBIDDEN[data.detail];
        break;
      // Issue with password request
      case "400":
        errMessage = ERRORS.PASSWORD[data.detail];
        break;
      default:
        // Internal server error or other unexpected error
        errMessage = ERRORS.LOGIN.GENERAL;
        break;
    }

    alertModal.alert(errMessage);
  } finally {
    isLoading = false;
    $("#submit-password-btn").removeClass("loading");
  }
}
