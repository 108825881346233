// Input Types messages
const EMPTY_INPUT = {
  TEXT: "を入力してください",
  SELECT: "を選択してください",
}

export const ALERT_MESSAGE = Object.freeze({
  PATTERN: {
    NO_NAME: `パターン名前${EMPTY_INPUT.TEXT}`
  },
  ROLE: {
    NO_SELECT: `業務${EMPTY_INPUT.SELECT}`
  }
})
