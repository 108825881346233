"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deletePlanFunction = exports.updatePlanFunction = exports.createPlanFunction = exports.fetchPlanFunctions = void 0;
const webappApi_1 = require("./webappApi");
const route = "plan_functions";
async function fetchPlanFunctions(params) {
    const p = new URLSearchParams();
    if (params.branchId) {
        p.append('branchID', params.branchId.toString());
    }
    if (params.isDeleted !== undefined) {
        p.append('isDeleted', params.isDeleted.toString());
    }
    if (params.showInSummary !== undefined) {
        p.append('showInSummary', params.showInSummary.toString());
    }
    const res = await (0, webappApi_1.makeGetRequest)(`${route}?${p.toString()}`);
    const j = await res.json();
    return j;
}
exports.fetchPlanFunctions = fetchPlanFunctions;
async function createPlanFunction(data) {
    const body = {
        branchID: data.branchId,
        variableName: data.name,
        defaultFunctionBody: data.defaultFunctionBody,
        showInSummary: data.showInSummary,
        allowDefaultFunction: data.allowDefaultFunction,
    };
    const res = await (0, webappApi_1.makePostRequest)(route, JSON.stringify(body));
    const j = await res.json();
    return j;
}
exports.createPlanFunction = createPlanFunction;
async function updatePlanFunction(data, id) {
    const body = {};
    if (data.name !== undefined) {
        body.variableName = data.name;
    }
    if (data.defaultFunctionBody !== undefined) {
        body.defaultFunctionBody = data.defaultFunctionBody;
    }
    if (data.showInSummary !== undefined) {
        body.showInSummary = data.showInSummary;
    }
    if (data.allowDefaultFunction !== undefined) {
        body.allowDefaultFunction = data.allowDefaultFunction;
    }
    const res = await (0, webappApi_1.makePatchRequest)(`${route}/${id}`, JSON.stringify(body));
    const j = await res.json();
    return j;
}
exports.updatePlanFunction = updatePlanFunction;
async function deletePlanFunction(id) {
    await (0, webappApi_1.makeDeleteRequest)(`${route}/${id}`);
}
exports.deletePlanFunction = deletePlanFunction;
