<script setup>
const emptyText = defineProps({
  title: String,
  subTitle: String,
})
</script>
<template>
  <div class="empty centered">
    <p class="empty-title h5">{{ emptyText.title }}</p>
    <p class="empty-subtitle">{{ emptyText.subTitle }}</p>
  </div>
</template>
