"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Employment = void 0;
const IndexedDB_1 = require("./IndexedDB");
class Employment {
    id;
    userId;
    feId = "";
    branchId;
    wage = 0;
    transportCost = 0;
    tags = [];
    employeeId = "";
    memo = "";
    permission = "";
    monthlyLimit = 0;
    branchName = "";
    constructor(req, opt) {
        this.id = req.id;
        this.branchId = req.branchId;
        this.userId = req.userId;
        if (opt?.wage) {
            this.wage = opt.wage;
        }
        if (opt?.transportCost) {
            this.transportCost = opt.transportCost;
        }
        if (opt?.tags) {
            this.tags = opt.tags;
        }
        if (opt?.employeeId) {
            this.employeeId = opt.employeeId;
        }
        if (opt?.memo) {
            this.memo = opt.memo;
        }
        if (opt?.permission) {
            this.permission = opt.permission;
        }
        if (opt?.monthlyLimit) {
            this.monthlyLimit = opt.monthlyLimit;
        }
        if (opt?.feId) {
            this.feId = opt.feId;
        }
        if (opt?.branchName) {
            this.branchName = opt.branchName;
        }
    }
    // Only for use with communication with BE
    getOriginalId() {
        return this.id;
    }
    // For FE use
    getId() {
        if (this.feId) {
            return this.feId;
        }
        return this.id.toString();
    }
    getBranchId() {
        return this.branchId.toString();
    }
    getBranchName() {
        return this.branchName;
    }
    index() {
        return this.getId();
    }
    getUserId() {
        return this.userId.toString();
    }
    getWage() {
        return this.wage;
    }
    getTransportCost() {
        return this.transportCost;
    }
    getStoreName() {
        return IndexedDB_1.StoreNames.Employment;
    }
    getKey() {
        return this.getId();
    }
    toJSON() {
        return {
            id: this.id,
            userId: this.userId,
            feId: this.feId,
            branchId: this.branchId,
            wage: this.wage,
            transportCost: this.transportCost,
            tags: [...this.tags],
            employeeId: this.employeeId,
            memo: this.memo,
            permission: this.permission,
            monthlyLimit: this.monthlyLimit,
            branchName: this.branchName
        };
    }
}
exports.Employment = Employment;
