// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.comment-read-badge[data-v-6e02f7c9] {
  background-color: #B1BABE;
}
`, "",{"version":3,"sources":["webpack://./src/vue/components/SlotBadges.vue"],"names":[],"mappings":";AAkDA;EACE,yBAAyB;AAC3B","sourcesContent":["<script>\nimport PaperPlaneIcon from '../icons/PaperPlaneIcon.vue';\n\nexport default {\n  props: {\n    numReadComments: Number,\n    numUnreadComments: Number,\n    isClone: Boolean,\n    shouldShowAlert: Boolean,\n    alertDescription: String,\n    isAsked: Boolean,\n    tooltipBottom: Boolean,\n  },\n  computed: {\n    slotTopRightStyle() {\n        return {\n            'right': this.tooltipBottom ? '-2px' : '',\n        }\n    },\n    displayUnreadCommentsCount() {\n        return this.numUnreadComments > 9 ? '9+' : this.numUnreadComments;\n    },\n    displayReadCommentsCount() {\n        return this.numReadComments > 9 ? '9+' : this.numReadComments;\n    },\n    displayReadCommentBadge() {\n        return this.numReadComments && !this.isClone;\n    }\n  },\n  components: {\n    PaperPlaneIcon,\n  }\n};\n</script>\n\n<template>\n    <!-- Top Right Slot Badges -->\n    <div class=\"slot-top-right\" :style=\"slotTopRightStyle\">\n        <span v-if=\"numUnreadComments\" class=\"circle-container comment-number-badge\">{{ displayUnreadCommentsCount }}</span>\n        <span v-else-if=\"displayReadCommentBadge\" class=\"circle-container comment-number-badge comment-read-badge\">{{ displayReadCommentsCount }}</span>\n\n        <span v-if=\"isAsked\" class=\"tooltip tooltip-left text-warning circle-container asked-badge\" data-tooltip=\"要請\">\n            <PaperPlaneIcon class=\"text-light\"/>\n        </span> \n\n        <span v-if=\"shouldShowAlert\" class=\"tooltip tooltip-left circle-container alert-badge\" :data-tooltip=\"alertDescription\">!</span>         \n    </div>\n</template>\n\n<style scoped>\n.comment-read-badge {\n  background-color: #B1BABE;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
