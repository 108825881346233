import { reactive } from 'vue';
import { LOADING_TYPES } from '../enums/loading_types';

export const store = {
  setLoading: function(type, state) {
    this.data.loading[type] = state;
  },

  data: reactive({
    loading: {
      [LOADING_TYPES.TABOO_PATTERN_MODAL]: false,
      [LOADING_TYPES.PATTERN_ROLE_TEMPLATE]: false,
    },
  })
}
