<script>
import PaperPlaneIcon from '../icons/PaperPlaneIcon.vue';

export default {
  props: {
    numReadComments: Number,
    numUnreadComments: Number,
    isClone: Boolean,
    shouldShowAlert: Boolean,
    alertDescription: String,
    isAsked: Boolean,
    tooltipBottom: Boolean,
  },
  computed: {
    slotTopRightStyle() {
        return {
            'right': this.tooltipBottom ? '-2px' : '',
        }
    },
    displayUnreadCommentsCount() {
        return this.numUnreadComments > 9 ? '9+' : this.numUnreadComments;
    },
    displayReadCommentsCount() {
        return this.numReadComments > 9 ? '9+' : this.numReadComments;
    },
    displayReadCommentBadge() {
        return this.numReadComments && !this.isClone;
    }
  },
  components: {
    PaperPlaneIcon,
  }
};
</script>

<template>
    <!-- Top Right Slot Badges -->
    <div class="slot-top-right" :style="slotTopRightStyle">
        <span v-if="numUnreadComments" class="circle-container comment-number-badge">{{ displayUnreadCommentsCount }}</span>
        <span v-else-if="displayReadCommentBadge" class="circle-container comment-number-badge comment-read-badge">{{ displayReadCommentsCount }}</span>

        <span v-if="isAsked" class="tooltip tooltip-left text-warning circle-container asked-badge" data-tooltip="要請">
            <PaperPlaneIcon class="text-light"/>
        </span> 

        <span v-if="shouldShowAlert" class="tooltip tooltip-left circle-container alert-badge" :data-tooltip="alertDescription">!</span>         
    </div>
</template>

<style scoped>
.comment-read-badge {
  background-color: #B1BABE;
}
</style>
