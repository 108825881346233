import { OPLUS_COLOR_PALETTE } from "./models";
import { store as roleStore } from "../js/stores/role_store"

export function roleFontColorStyle(role) {
  if (!role || !role.Color || role.Color === OPLUS_COLOR_PALETTE.DEFAULT) {
    return;
  }
  return {
    color: "#" + role.Color,
  }
}

// For role's BG color
export function roleBackgroundColor(role) {
  if (!role || !role.Color || role.Color === OPLUS_COLOR_PALETTE.DEFAULT) {
    return;
  }
  return {
    backgroundColor: "#" + role.Color,
    color: "#fff",
  }
}

export function isGroupRole(role) {
  return role?.GroupWide;
}

// Renders role's shift time label (e.g. 12:00 ~ 20:30)
export function roleShiftTimeLabel(role) {
  if (role?.From || role?.To) {
    return `${role.From} ~ ${role.To}`
  }
  return "";
}

export function getOrRoleRelation(roleId) {
  const role = roleStore.data.rolesMap?.[roleId];
  if (!role) {
    return {
      mainRoleId: null,
      subRoleIds: [],
    };
  }
  // if subRoleIds exist, that means roleId is a Main-Role
  const subRoleIds =  role.subRoles;
  // if mainRoleId exist, that means roleId is a Sub-Role
  const mainRoleId = role.mainRole;
  if (subRoleIds.length === 0 && !mainRoleId) {
    return {
      mainRoleId: null,
      subRoleIds: [],
    };
  }

  if (subRoleIds.length > 0) {
    return {
      mainRoleId: roleId,
      subRoleIds: role.subRoles
    }
  }

  if (mainRoleId) {
    const mainRole = roleStore.data.rolesMap[mainRoleId].subRoles;
    const siblings = mainRole.filter(rId => rId !== parseInt(roleId));
    return {
      mainRoleId: mainRoleId,
      subRoleIds: siblings,
     }
  }
}
