import { reactive } from 'vue';

export const reactiveSlotLogMap = reactive({
  slotLogMap: {},
});

export function initSlotLogMap(newSlotLogMap) {
  Object.assign(reactiveSlotLogMap, newSlotLogMap);
}

export function clearSlotInSlotLogMap(slotId) {
  if (reactiveSlotLogMap && reactiveSlotLogMap[slotId]) {
    reactiveSlotLogMap[slotId] = [];
  }
}

export function updateSlotUnreadCommentBadge(slotId, numComments) {
 const slotUnreadCommentBadge = document.getElementById(`slot-${slotId}-comment-badge`);
  const slotLogCount = slotLogMap[slotId]?.length || 0;
    
  if (slotUnreadCommentBadge) {
    const displayCount = slotLogCount > 0 ? slotLogCount : numComments;
    slotUnreadCommentBadge.textContent = displayCount > 9 ? '9+' : displayCount;
    slotUnreadCommentBadge.style.backgroundColor = slotLogCount > 0 ? '#53D064' : '#B1BABE';
  }
}
