<script>
import Modal from './Modal.vue';

export default {
  data() {
    return {
      isVisible: false,
      isLoading: false,
    }
  },
  components: {
    Modal,
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    onConfirm() {
      this.isLoading = true;
      $.get('/logout')
        .done(function () {
          window.location.href = '/';
        })
        .fail(function () {
        	alertModal.alert("ログアウト出来ませんでした。");
          this.isLoading = false;
        })
    },
    onCancel() {
      this.isVisible = false;
    }
  },
  computed: {
    loadingClass() {
      return this.isLoading ? "loading" : "";
    },
  }
}
</script>

<template>
  <Modal v-if="isVisible" @closeModal="onCancel" @confirm="onConfirm" @cancel="onCancel">
    <template v-slot:header-icon>
      <div class="header-wrapper logout-title-text">
        ログアウト
      </div>
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <p>本当にログアウトしますか？</p>
      </div>
    </template>
    <template v-slot:footer-btn>
      <div class="footer-btn-wrapper">
        <button class="oplus-modal-btn oplus-cancel-btn p-0" @click="onCancel">キャンセル</button>
        <button class="oplus-modal-btn oplus-confirm-btn" :class="loadingClass" @click="onConfirm">確認</button>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
  .header-wrapper {
    text-align: center;
    padding-bottom: 0;
  }
  .content-wrapper {
    width: 320px;
    text-align: center;
    margin: auto;
  }

  .footer-btn-wrapper {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .logout-title-text {
    color: #5F6B71;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
  }

  @media (max-width: 400px) {
    .content-wrapper {
      width: inherit;
    }
  }
</style>