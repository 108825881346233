import { mountAlertModal, mountLogoutModal, mountCustomerSurveyModal } from "./modal";
import { isVacationLimitMaxedOut } from "./shift";
import { isNonWorkingSlotType } from "./slot_helpers";
import { MODAL_ID } from '../js/modal.js'
import { mountBranchSelectorComponent } from "./branch_menu";

import { createApp } from "vue";
import NotificationDropdown from "../view/components/notificationDropdown/NotificationDropdown.vue";
import { NotificationType } from "../view/components/notificationDropdown/type/notificationType";
import { clearSlotInSlotLogMap, updateSlotUnreadCommentBadge } from "./slotLog";

export function initPage(props) {
  mountAlertModal();
  mountLogoutModal();
  mountNotificationDropdown({
    branchId: props.activeBranchId,
    userId: props.activeUser ? props.activeUser.ID : null,
    hasBoardPermission: props.hasBoardPermission,
    hasSummaryPermission: 
      props.activeUser && 
      !(props.hasBranchPermission || props.isGroupEditor) && 
      props.activeBranchId && 
      !props.isBranchOrGroupOwner,
    goToSummary:
      props.hasBranchPermission || 
      props.isGroupEditor || 
      (props.activeBranchId && props.isBranchOrGroupOwner),
    viewedActivityStr: props.viewedActivityStr,
  });
  
  if (props.activeBranchId && props.employments) {
    mountBranchSelectorComponent({
      activeBranchId: props.activeBranchId,
      preferredBranchId: props.preferredBranchId,
      employments: props.employments,
    });
  }
  const { isBranchOrGroupOwner, activeUser, userNeedsSurvey } = props;
  if (!activeUser || !isBranchOrGroupOwner) {
    return;
  }

  if (userNeedsSurvey) {
    mountCustomerSurveyModal();
  }
}

export function openLogoutModal() {
  window[MODAL_ID.LOGOUT_CONFIRM].openModal();
}

export function reloadOnCondition(slot) {
  if (slot.Repeat || slot.IsHelp) {
    Refresh();
  }
}

export function alertStaffIfVacationMaxedOut(slot) {
  if (isNonWorkingSlotType(slot)) {
    return isVacationLimitMaxedOut();
  }
}

export function updateBadge(type) {
  let badge;

  if (type === NotificationType.BoardNotification) {
    badge = parseInt($("#nav-board").attr("data-badge"));
    badge--;
    if (badge > 0) {
      $("#nav-board").attr("data-badge",badge);
    } else {
      $("#nav-board").removeClass("badge");
    }
  } else {
    if ($("#nav-summary").length == 0) {
      badge = parseInt($("#nav-shift-span").attr("data-badge"));
    } else {
      badge = parseInt($("#nav-summary").attr("data-badge"));
    }
    badge--;
    if (badge > 0) {
      $("#nav-summary").attr("data-badge",badge);
      $("#nav-shift-span").attr("data-badge",badge);
    } else {
      $("#nav-summary").removeClass("badge");
      $("#nav-shift-span").removeClass("badge");
    }
  }
}

export function removeBadge() {
  $("#nav-summary").removeClass("badge");
  $("#nav-shift-span").removeClass("badge");
  $("#nav-board").removeClass("badge");
}

function mountNotificationDropdown(props) {
  const app = createApp(NotificationDropdown, props);
  window["#notification-dropdown"] = app.mount("#notification-dropdown");
}

export function handleClearSlotInSlotLogMap(slotId) {
  clearSlotInSlotLogMap(slotId);
}

export function handleUpdateSlotUnreadCommentBadge(slotId, numComments = 0) {
  updateSlotUnreadCommentBadge(slotId, numComments);
}
