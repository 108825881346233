import { reactive } from "vue";

export const store = {
  init: function(tabooPatternsData) {
    this.data.tabooPatternsOrder = tabooPatternsData.map(pattern => pattern.ID);
    tabooPatternsData.forEach(tabooPattern => {
      this.data.tabooPatternsMap[tabooPattern.ID] = {
        patternData: tabooPattern,
      };
    })
  },
  addTabooPattern: function(tabooPattern) {
    this.data.tabooPatternsMap[tabooPattern.ID] = {
      patternData: tabooPattern,
    };
    if (!this.data.tabooPatternsOrder.includes(tabooPattern.ID)) {
      this.data.tabooPatternsOrder.push(tabooPattern.ID);
    }
  },
  deleteTabooPattern: function(tabooPatternId) {
    delete this.data.tabooPatternsMap[tabooPatternId];
    const index = this.data.tabooPatternsOrder.indexOf(tabooPatternId);
    if (index > -1) {
      this.data.tabooPatternsOrder.splice(index, 1);
    }
  },
  updateTabooPattern: function(tabooPatternId, newName, newRoleIDs, isFullPattern) {
    this.data.tabooPatternsMap[tabooPatternId].patternData.Name = newName;
    this.data.tabooPatternsMap[tabooPatternId].patternData.RoleIDs = newRoleIDs;
    this.data.tabooPatternsMap[tabooPatternId].patternData.IsFullPattern = isFullPattern;
  },
  // For roles inside the taboo pattern
  addRoleToPattern: function(tabooPatternId, roleId, isFullPattern = false) {
    // Do not include duplicate roles (for old NG pattern setup)
    if (!isFullPattern && this.data.tabooPatternsMap[tabooPatternId].patternData.RoleIDs.slice(1).includes(roleId)) {
      throw new Error ("Cannot have duplicate role in ORまとめ");
    }
    this.data.tabooPatternsMap[tabooPatternId].patternData.RoleIDs.push(roleId);
  },
  deleteRoleFromPattern(tabooPatternId, deletedRoleId, deletedRoleIndex) {
    const roleIDs = this.data.tabooPatternsMap[tabooPatternId].patternData.RoleIDs;
    let deleteIdx = -1;
    if (deletedRoleIndex > 0) {
      deleteIdx = deletedRoleIndex;
    } else {
      deleteIdx = roleIDs.findIndex((roleId, i) => i > 0 && roleId === deletedRoleId);
    }

    if (deleteIdx > 0) {
      roleIDs.splice(deleteIdx, 1);
    }
  },
  updateRolePattern: function(tabooPatternId, newMainRoleId) {
    this.data.tabooPatternsMap[tabooPatternId].patternData.MainRoleID = newMainRoleId;
  },
  data: reactive({
    tabooPatternsMap: {},
    tabooPatternsOrder: [], // Array to track order for drag and drop sorting
  })
}
