"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseErrors = void 0;
exports.parseErrors = Object.freeze({
    UNEXPECTED_KEYWORD: new Error('unexpected keyword'),
    UNEXPECTED_PARENS: new Error('unexpected parentheses'),
    CLOSED_PARENS: new Error('too many closed parens'),
    UNEXPECTED_CHAR: new Error('unexpected character'),
    OPERATORS: new Error('too many operators'),
    MISMATCH_PARENS: new Error('mismatched parens'),
    MISMATCH_OP_AND_NUM: new Error('invalid number of keywords and numbers'),
});
