"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RepeatShift = void 0;
const shift_1 = require("./shift");
class RepeatShift extends shift_1.Shift {
    repeatTemplateId; // Refers to the repeat template in store
    constructor(repeatTemplateId, required, options) {
        super(required, options);
        this.repeatTemplateId = repeatTemplateId;
    }
    getRepeatTemplateId() {
        return this.repeatTemplateId.toString();
    }
    isRepeatShift() {
        return true;
    }
    isStandardShift() {
        return false;
    }
}
exports.RepeatShift = RepeatShift;
