"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleStore = void 0;
class RoleStore {
    data;
    constructor() {
        this.data = {};
    }
    query(options) {
        let out = Object.values(this.data);
        if (options?.filterMainRole) {
            out = out.filter(r => {
                if (r.isMainRole() == options.isMainRole) {
                    return r;
                }
            });
        }
        if (options?.filterRoleSection) {
            out = out.filter(r => {
                if (r.isRoleSection == options.isRoleSection) {
                    return r;
                }
            });
        }
        if (options?.joinedEmployee != undefined) {
            const joinedEmployee = options.joinedEmployee;
            out = out.filter(r => {
                if (r.getJoinedEmployees().includes(joinedEmployee)) {
                    return r;
                }
            });
        }
        return out;
    }
    add(r) {
        this.data[r.getId()] = r;
    }
    byId(id) {
        return this.data[id];
    }
    delete(id) {
        if (!this.data[id]) {
            return;
        }
        delete this.data[id];
    }
    resetData() {
        this.data = {};
    }
}
exports.RoleStore = RoleStore;
