"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanValue = void 0;
class PlanValue {
    id;
    variableId;
    feVariableId = "";
    feId = "";
    functionBody;
    numValue = undefined; // Computed
    strValue = ""; // Computed
    startDate;
    endDate;
    constructor(required, options) {
        this.id = required.id;
        this.variableId = required.functionId;
        this.startDate = required.startDate;
        this.endDate = required.endDate;
        this.functionBody = required.functionBody;
        if (options?.value !== undefined) {
            this.numValue = options.value;
        }
        if (options?.strValue) {
            this.strValue = options.strValue;
        }
        if (options?.feFunctionId) {
            this.feVariableId = options.feFunctionId;
        }
        if (options?.feId) {
            this.feId = options.feId;
        }
    }
    index() {
        return this.getId();
    }
    getId() {
        if (this.feId) {
            return this.feId;
        }
        if (this.id == 0) {
            return "";
        }
        return this.id.toString();
    }
    setId(v) {
        this.id = v;
    }
    getOriginalId() {
        return this.id;
    }
    getVariableId() {
        if (this.feVariableId) {
            return this.feVariableId;
        }
        if (this.variableId == 0) {
            return "";
        }
        return this.variableId.toString();
    }
    getOriginalFunctionId() {
        return this.variableId;
    }
    getNumValue() {
        return this.numValue;
    }
    setNumValue(v) {
        this.numValue = v;
    }
    getStrValue() {
        return this.strValue;
    }
    setStrValue(s) {
        this.strValue = s;
    }
    getStartDate() {
        return this.startDate;
    }
    getEndDate() {
        return this.endDate;
    }
    getFunctionBody() {
        return this.functionBody;
    }
    setFunctionBody(v) {
        this.functionBody = v;
    }
    getCopy() {
        const req = {
            id: this.id,
            functionId: this.variableId,
            startDate: new Date(this.startDate),
            endDate: new Date(this.endDate),
            functionBody: this.functionBody,
        };
        const options = {
            feId: this.feId,
            feFunctionId: this.feVariableId,
            value: this.numValue,
            strValue: this.strValue,
        };
        const copy = new PlanValue(req, options);
        return copy;
    }
}
exports.PlanValue = PlanValue;
