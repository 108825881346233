<script>
export default {
  props: {
    timecardId: Number,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  emits: [
    "emit-timecard-data",
  ],
  methods: {
    openEditTimecard() {
      return this.$emit("emit-timecard-data", this.timecardId)
    },
    deleteTimecard() {
      if (this.isLoading) {
        return;
      }
      alertModal.confirm("このタイムカードを削除しますが、よろしいですか？", () => {
        this.isLoading = true;
        return deleteTimeCardModalAjax(this.timecardId);
      })
    },
  },
  computed: {
    userId() {
      return TimeCardMap[this.timecardId].UserID;
    },
    date() {
      return TimeCardMap[this.timecardId].Date;
    },
    renderTimecardLabel() {
      const tcObj = TimeCardMap[this.timecardId];
      return `${tcObj.FromLabel} ~ ${tcObj.ToLabel}`
    },
    loadingClass() {
      return this.isLoading ? "loading" : "";
    },
  },
}
</script>
<template>
  <span>{{ renderTimecardLabel }}</span>
  <div class="timepicker-btn-wrapper">
    <button class="timepicker-btn" @click="openEditTimecard()">
      <div class="svg-container oplus-icon">
        <svg class="c-hand" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve" viewBox="0 0 85 85" id="edit-icon" width="25px">
          <path fill="currentColor" d="M26.3,55.6c-0.1,0.1-0.2,0.3-0.2,0.4l-0.8,11.3c0,0.2,0.1,0.5,0.3,0.6c0.2,0.1,0.5,0.1,0.7,0l10.2-4.7
          c0.1-0.1,0.3-0.1,0.3-0.3l17.8-25.3l-10.5-7.4L26.3,55.6z M59,22.1l-1.7-1.2c-2.4-1.7-6-0.9-7.9,1.8l-3.8,5.4l10.5,7.4l3.8-5.4
          C61.9,27.4,61.5,23.8,59,22.1z M60.2,80.1H25.6c-11,0-19.9-8.9-19.9-19.9V25.4c0-11,8.9-19.9,19.9-19.9h34.6
          c11,0,19.9,8.9,19.9,19.9v34.9C80.1,71.2,71.1,80.1,60.2,80.1z M25.6,9.1c-9,0-16.3,7.3-16.3,16.3v34.9c0,9,7.3,16.3,16.3,16.3
          h34.6c9,0,16.3-7.3,16.3-16.3V25.4c0-9-7.3-16.3-16.3-16.3H25.6z"/>
        </svg>
      </div>
    </button>
    <button class="timepicker-btn" @click="deleteTimecard()">
      <div class="svg-container oplus-icon" :class="loadingClass">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve" viewBox="0 0 85 85" width="25px" id="delete-icon">
          <path fill="currentColor" d="M63.6,69.2c-0.1,1.6-1.4,2.8-3,2.8H26c-1.6,0-2.9-1.3-3-2.8l-1.7-37.8c-0.1-1.6,1.2-2.8,2.7-2.8h38.4
            c1.6,0,2.8,1.3,2.7,2.8L63.6,69.2z M70.5,20.1c0-2-1.6-3.6-3.6-3.6H54.5l-1.9-3.8H34l-2,3.8H19.5c-2,0-3.6,1.6-3.6,3.6
            c0,2,1.6,3.6,3.6,3.6h47.3C68.8,23.8,70.5,22.2,70.5,20.1z"/>
        </svg>
      </div>
    </button>
  </div>
</template>

<style scoped>
  .timepicker-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px 0;
  }
  .timepicker-btn {
    border: none;
    background-color: #fff;
  }
</style>
