<script>
import Modal from './Modal.vue';
import { isQueryParamTrue } from '../js/common.js'

const DROPDOWN_KEYS = Object.freeze({
  ROLES: "roles",
  TAGS: "tags",
  ETC: "etc",
})

export default {
  props: {
    modalName: String,
    companyID: Number,
    tags: Array,
    roles: Object,
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      popUp: null,
      checkedRoles: [],
      checkedTags: [],
      workerOnly: isQueryParamTrue("workerOnly"),
      lockedOnly: isQueryParamTrue("lockedOnly"),
      dropDownState: {
        roles: true,
        tags: true,
        etc: true,
      }
    }
  },
  components: {
    Modal,
  },
  methods: {
    closeModal() {
      this.isVisible = false;
    },
    openModal() {
      this.isVisible = true;
    },
    onConfirm() {
      this.confirmFilter();
    },
    onCancel() {
      this.isVisible = false;
    },
    clearFilter() {
      this.checkedRoles = [];
      this.checkedTags = [];
      this.lockedOnly = false;
      this.workerOnly = false;
    },
    // Note: tags and roles props here have different data structure, resulting to different way of adding them in the vue's data prop
    // roles props are Key-value pair objects: { ID: role-object }
    // tags are an array of objects: [tag1: {selected: bool, tag: string, decodedTag: string}, tag2: {selected: bool, tag: string2, decodedTag: string}]
    getSelectedRoles() {
      const selecteRoleIDStr = GetURLParam("roles");
      if (selecteRoleIDStr) {
        return this.checkedRoles = selecteRoleIDStr.split(",");
      } else {
        return null;
      }
    },
    getSelectedTags() {
      if (this.tags) {
        this.tags.forEach(tagObj => {
          if (tagObj.selected) {
            this.checkedTags.push(tagObj.tag);
          }
        })
      }
    },
    initSelectedData() {
      this.getSelectedRoles();
      this.getSelectedTags();
    },
    confirmFilter() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      const param = {
        list: GetURLParam("list"),
        date: GetURLParam("date"),
        tags: this.checkedTags.join(","),
        roles: this.checkedRoles.join(","),
        workerOnly: this.workerOnly,
        lockedOnly: this.lockedOnly,
        c: this.companyID,
      };

      const until = GetURLParam("until");
      if (until.length > 0) {
        param.until = until;
      }

      const url = window.location.origin + window.location.pathname;
      window.location = url + "?" + $.param(param);
    },
    setRoleDropDownState() {
      window.sessionStorage.setItem("dropDownState", JSON.stringify(this.dropDownState))
    },
    getDropDownState() {
      const state = window.sessionStorage.getItem("dropDownState");
      if (!state) {
        return
      }
      this.dropDownState = JSON.parse(state);
    },
    handleDropDown(event, keyVal) {
      this.dropDownState[keyVal] = event.currentTarget.checked;
      this.setRoleDropDownState();
    },
    handleRolesDropDown(event) {
      this.handleDropDown(event, DROPDOWN_KEYS.ROLES)
    },
    handleTagsDropDown(event) {
      this.handleDropDown(event, DROPDOWN_KEYS.TAGS)
    },
    handleEtcDropDown(event) {
      this.handleDropDown(event, DROPDOWN_KEYS.ETC)
    },
    resetPopupState() {
      this.popUp = null;
    },
    setPopup(id) {
      this.popUp = id;
    },
    showPopup(id) {
      return this.popUp === id;
    },
    setRoleColor(role) {
      return {
        color:`#${role.Color}`
      }
    }
  },
  computed: {
    hasTags() {
      return this.tags ? true : false;
    },
    hasRoles() {
      return this.filterRoles.length > 0;
    },
    loadingClass() {
      return this.isLoading ? "loading" : "";
    },
    hasAnyActiveFilters() {
      return this.checkedRoles.length || this.checkedTags.length || this.workerOnly || this.lockedOnly;
    },
    filterRoles() {
      return this.roles.filter(r => !r.IsRoleSection);
    }
  },
  created() {
    this.initSelectedData();
  },
  mounted() {
    this.getDropDownState();
  }
}
</script>

<template>
  <Modal :modal-name="modalName" v-if="isVisible" @closeModal="closeModal" @confirm="onConfirm" @cancel="onCancel" :has-title-divider="true" :has-footer-divider="true">
    <template v-slot:header-title>
        フィルター
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <!-- Roles -->
        <div v-if="hasRoles" class="roles-filter">

          <div class="accordion">
            <input type="checkbox" id="role-filter-accordion" name="accordion-checkbox" hidden @change="handleRolesDropDown" :checked="dropDownState.roles">
            <label class="accordion-header text-left" for="role-filter-accordion">
              <i class="icon icon-arrow-right mr-1"></i>
              <span class="filter-title">業務</span>
            </label>
            <div class="accordion-body">
              <div class="filter-wrapper">
                <div v-for="role in filterRoles" :key="role.ID">
                  <label @click="setPopup(role.ID)" @mouseleave="resetPopupState()" :style="setRoleColor(role)" class="oplus-form-checkbox" :data-role="role.ID">
                    <input :value="role.ID" v-model="checkedRoles" class="filter-checkbox text-popup" type="checkbox">
                    <span class="filter-name">{{ role.Name }}</span>
                    <transition name="fade">
                      <span v-show="showPopup(role.ID)" class="filter-text-popup">{{ role.Name }}</span>
                    </transition>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-divider"></div>
        </div>

        <!-- Tags -->
        <div v-if="hasTags" class="tags-filter">
          <div class="accordion">
            <input type="checkbox" id="tags-filter-accordion" name="accordion-checkbox" hidden @change="handleTagsDropDown" :checked="dropDownState.tags">
            <label class="accordion-header text-left" for="tags-filter-accordion">
              <i class="icon icon-arrow-right mr-1"></i>
              <span class="filter-title">タグ</span>
            </label>
            <div class="accordion-body">
              <div class="filter-wrapper">
                <div v-for="tag in tags" :key="tag">
                  <label class="oplus-form-checkbox" :data-tag="tag.tag"  @click="setPopup(tag.tag)" @mouseleave="resetPopupState()">
                    <input :value="tag.tag" v-model="checkedTags" class="filter-checkbox" type="checkbox">
                    <span class="filter-name">{{ tag.decodedTag }}</span>
                    <transition name="fade">
                      <span v-show="showPopup(tag.tag)" class="filter-text-popup">{{ tag.decodedTag }}</span>
                    </transition>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-divider"></div>
        </div>

        <!-- Etc. (worker and locked only options) -->
        <div class="etc-filter">
          <div class="accordion">
            <input type="checkbox" id="etc-filter-accordion" name="accordion-checkbox" hidden @change="handleEtcDropDown" :checked="dropDownState.etc">
            <label class="accordion-header text-left" for="etc-filter-accordion">
              <i class="icon icon-arrow-right mr-1"></i>
              <span class="filter-title">その他</span>
            </label>
            <div class="accordion-body">
              <div class="filter-wrapper">
                <div>
                  <label class="oplus-form-checkbox">
                    <input class="filter-checkbox" type="checkbox" v-model="workerOnly">
                    出勤者のみ
                  </label>
                </div>
                <div>
                  <label class="oplus-form-checkbox">
                    <input class="filter-checkbox" type="checkbox" v-model="lockedOnly">
                    確定のみ
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer-btn>
      <div class="btn-wrapper">
        <button class="oplus-modal-btn oplus-cancel-btn"  @click="clearFilter">クリア</button>
        <button :class="loadingClass" class="oplus-modal-btn oplus-alt-confirm-btn"  @click="onConfirm">確定</button>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
  .header-wrapper {
    text-align: center;
    padding-bottom: 0;
  }
  .content-wrapper {
    display: flex;
    width: 576px;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    height: fit-content;
    padding: 20px 0;
  }

  .filter-title {
    text-align: left;
    font-size: 1rem;
  }

  .filter-name {
    max-width: 140px;
    width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .filter-wrapper {
    display: grid;
    max-width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(150px,1fr));
    text-align: left;
    padding: 20px 10px;
    gap: 20px;
  }

  .filter-wrapper.etc-wrapper {
    padding-bottom: 0;
  }

  .roles-filter, .tags-filter, .etc-filter {
    display: flex;
    flex-direction: column;
  }

  .modal-divider {
    border: 0;
    width: 100%;
    border-top: 1px solid #cfcfcf;
  }

  .btn-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .filter-text-popup {
    position: absolute;
    right: 0%;
    bottom: -80%;
    background: rgba(48,55,66,.95);
    border-radius: .1rem;
    color: #fff;
    font-size: .7rem;
    padding: .2rem .4rem;
    pointer-events: none;
    z-index: 300;
    max-width: 200px;
    height: fit-content;
    white-space: break-spaces;
    word-wrap: break-word;
  }

  .oplus-form-checkbox {
    position: relative;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  @media (max-width: 576px) {
    .content-wrapper {
      width: 90vw;
    }
  }

  @media (max-width: 392px) {
    .filter-name {
      max-width: 280px;
    }
  }

</style>
