"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShiftCellStats = void 0;
const models_1 = require("../js/models");
class ShiftCellStats {
    numOfWorkers = 0;
    laborHours = 0;
    laborCost = 0;
    transportCostEnabledSlots = 0;
    roleCounts = {};
    roleSectionCounts = {};
    orRoleCounts = {};
    offCounts = {
        [models_1.SLOT_TYPES.OFF_DAY]: 0,
        [models_1.SLOT_TYPES.PTO]: 0,
        [models_1.SLOT_TYPES.HALF_PTO]: 0,
        [models_1.SLOT_TYPES.PUBLIC_HOLIDAY]: 0,
        [models_1.SLOT_TYPES.OTHER_HOLIDAY]: 0,
        [models_1.SLOT_TYPES.KOT_PTO]: 0,
        [models_1.SLOT_TYPES.KOT_HALF_PTO_AM]: 0,
        [models_1.SLOT_TYPES.KOT_HALF_PTO_PM]: 0,
    };
    constructor() { }
    setNumOfWorkers(v) {
        this.numOfWorkers = v;
    }
    setNumOfTransports(v) {
        this.transportCostEnabledSlots = v;
    }
    setRoleCounts(v) {
        this.roleCounts = v;
    }
    setOrRoleCounts(v) {
        this.orRoleCounts = v;
    }
    setRoleSectionCounts(v) {
        this.roleSectionCounts = v;
    }
    setOffCounts(v) {
        this.offCounts = v;
    }
    setLaborHours(v) {
        this.laborHours = v;
    }
    setLaborCost(v) {
        this.laborCost = v;
    }
}
exports.ShiftCellStats = ShiftCellStats;
