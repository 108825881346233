"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmploymentShiftTableStatsRow = void 0;
const shiftRowStats_1 = require("../stats/shiftRowStats");
const tableRow_1 = require("../table/v1/tableRow");
class EmploymentShiftTableStatsRow extends tableRow_1.TableRow {
    data;
    constructor(x) {
        super(x);
        this.data = new shiftRowStats_1.ShiftRowStats();
    }
    getData() {
        return this.data;
    }
    setData(v) {
        this.data = v;
    }
}
exports.EmploymentShiftTableStatsRow = EmploymentShiftTableStatsRow;
