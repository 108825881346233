"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableMap = void 0;
class TableMap {
    xAxisData;
    yAxisData;
    rows = {};
    columns = {};
    data = {};
    constructor(xData, yData, t, r, c) {
        this.xAxisData = xData;
        this.yAxisData = yData;
        this.initData(t, r, c);
    }
    initData(t, r, c) {
        this.xAxisData.forEach(x => {
            this.columns[x.index()] = new c(x);
            this.data[x.index()] = {};
            this.yAxisData.forEach(y => {
                this.rows[y.index()] = new r(y);
                this.data[x.index()][y.index()] = new t(x, y);
            });
        });
    }
}
exports.TableMap = TableMap;
