"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseSlotNotification = exports.parseBoardNotification = void 0;
function parseBoardNotification(notification) {
    return {
        id: notification.PostID,
        userId: notification.UserID,
        message: notification.Message,
        userName: notification.UserName,
        seenBy: notification.SeenBy,
        date: new Date(notification.Date * 1000),
        boardData: {
            createdStr: notification.CreatedStr,
            parentID: notification.ParentID,
        },
    };
}
exports.parseBoardNotification = parseBoardNotification;
function parseSlotNotification(notification) {
    return {
        id: notification.SlotLogId,
        userId: notification.ReferenceId,
        message: notification.Msg,
        userName: notification.Author,
        seenBy: notification.SeenBy,
        date: new Date(notification.Date * 1000),
        slotData: {
            slotUserId: notification.SlotUserId,
            slotColor: notification.SlotColor,
            slotFrom: notification.SlotFrom,
            slotText: notification.SlotText,
        },
    };
}
exports.parseSlotNotification = parseSlotNotification;
