"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MorningShift = void 0;
const linkedShift_1 = require("./linkedShift");
class MorningShift extends linkedShift_1.LinkedShift {
    constructor(linkedId, required, options) {
        super(linkedId, required, options);
    }
    isMorningShift() {
        return true;
    }
}
exports.MorningShift = MorningShift;
