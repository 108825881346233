<script>
  import Modal from './Modal.vue';
  import { togglePatternAccordion } from '../js/taboo_patterns';
  import { roleBackgroundColor } from '../js/role_helpers';
  import { store as roleStore } from '../js/stores/role_store';
  import { store as tabooPatternStore } from '../js/stores/taboo_pattern_store';
  import { store as tabooPatternModalStore } from '../js/stores/taboo_pattern_modal_store';
  import { createTabooPatternRequest, editRolePatternRequest } from '../js/pattern_requests';
  import { MODAL_NAMES, MODAL_ID } from '../js/enums/modal_types';
  import { ALERT_MESSAGE } from '../js/enums/form_alerts';

  export default {
    name: MODAL_NAMES.TABOO_PATTERN_MODAL,
    data() {
      return {
        patternName: "",
        isFullPattern: true, // determine if the pattern is Old or New:
        // that Old patterns doesn't allow duplicates, New on the other hand allows it
        // Old Pattern role relations are like this: Role A -> Role B OR Role C
        // New Pattern role relations are like this: Role A -> Role B -> Role C
        isLoading: false,
      }
    },
    methods: {
      initData() {
        const tabooId = tabooPatternModalStore.data.selectedTabooId;
        const taboo = tabooPatternStore.data.tabooPatternsMap?.[tabooId];
        if (taboo) {
          this.patternName = taboo.patternData?.Name;
          this.isFullPattern = Boolean(taboo.patternData?.IsFullPattern);
        }
      },
      closeModal() {
        return this.$emit("emit-close-modal", false);
      },
      onSelectRoleClick() {
        window[MODAL_ID.ROLES_LIST_MODAL].openModal();
      },
      onRoleClick(roleID) {
        tabooPatternModalStore.setRoleId(roleID);
        window[MODAL_ID.ROLES_LIST_MODAL].openModal();
      },
      invalidInput() {
        let msg = "";
        let isInvalid = false;

        if (!this.patternName) {
          msg = ALERT_MESSAGE.PATTERN.NO_NAME;
          isInvalid = true;
        }

        if (!this.selectedRoleId) {
          msg = ALERT_MESSAGE.ROLE.NO_SELECT;
          isInvalid = true;
        }

        return { isInvalid, msg };
      },
      onConfirm() {
        const { isInvalid, msg } = this.invalidInput();

        if (isInvalid) {
          alertModal.alert(msg);
          return;
        }

        if (this.isLoading) {
          return;
        };

        this.isLoading = true;

        if (this.isTabooPatternEdit) {
          this.handleTabooPatternEdit();
        } else {
          this.createTabooPattern();
        }
      },
      handleTabooPatternEdit() {
        if (this.selectedIsFullPattern && !this.isFullPattern) {
          alertModal.confirm("Changing to ORまとめ will remove duplicate roles, continue?", () => {
            this.updateTabooPatternSettings();
          });
        } else {
          this.updateTabooPatternSettings();
        }
        this.isLoading = false;
      },
      async createTabooPattern() {
        try {
          const payload = {
            roleID: this.selectedRoleId,
            patternName: this.patternName,
            isFullPattern: this.isFullPattern,
          }
          const response = await createTabooPatternRequest(payload);
          tabooPatternStore.addTabooPattern(response.tabooPatterns);
          this.$nextTick(() => {
            togglePatternAccordion(response.tabooPatterns.ID);
          })
        } catch (err) {
          alertModal.alert(err);
        } finally {
          this.isLoading = false;
          this.closeModal();
        }
      },
      async updateTabooPatternSettings() {
        try {
          const response = await editRolePatternRequest(tabooPatternModalStore.data.selectedTabooId, this.patternName, this.isFullPattern);
          const { tabooPatternId, newName, isFullPattern, newRoleIDs } = response;
          tabooPatternStore.updateTabooPattern(tabooPatternId, newName, newRoleIDs, isFullPattern)
        } catch (err) {
          alertModal.alert(err);
        } finally {
          this.isLoading = false;
          this.closeModal();
        }
      },
    },
    computed: {
      rolesMap() {
        return roleStore.data.rolesMap;
      },
      selectedTabooId() {
        return tabooPatternModalStore.data.selectedTabooId;
      },
      selectedRoleId() {
        return tabooPatternModalStore.data.selectedRoleId;
      },
      isTabooPatternEdit() {
        return this.selectedTabooId ? true : false;
      },
      mainRoleId() {
        if (this.isTabooPatternEdit) {
          return tabooPatternStore.data.tabooPatternsMap[this.selectedTabooId].patternData.MainRoleID;
        }
        return null;
      },
      isNewPattern() {
        return tabooPatternStore.data.tabooPatternsMap[this.selectedTabooId].patternData.IsFullPattern;
      },
      selectedIsFullPattern() {
        if (this.isTabooPatternEdit) {
          return this.isNewPattern
        }
        return true;
      },
      mainRoleStyle() {
        return roleBackgroundColor(this.rolesMap[this.mainRoleId]);
      },
      selectedRoleStyle() {
        return roleBackgroundColor(this.rolesMap[this.selectedRoleId])
      },
      createOrEditText() {
        return this.isTabooPatternEdit ? "編集" : "作成"
      },
      loadingClass() {
        return this.isLoading ? "loading" : "";
      },
    },
    components: {
      Modal,
    },
    mounted() {
      this.initData();
    },
  }
</script>

<template>
  <Modal @closeModal="closeModal" :has-title-divider="true" :has-footer-divider="false">
    <template v-slot:header-title>
      <span>
        NGパターン{{ createOrEditText }}
      </span>
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <form @submit.prevent="onConfirm">
          <div class="form-group">
            <div class="d-flex">
              <label class="form-radio">
                <input v-model="isFullPattern" type="radio" name="full-pattern" :value="true">
                <i class="form-icon"></i> 連日
              </label>
              <label class="form-radio">
                <input v-model="isFullPattern" type="radio" name="full-pattern" :value="false">
                <i class="form-icon"></i> ORまとめ
              </label>
            </div>
            <input v-model="patternName" type="text" class="form-input" name="patternName" id="pattern-name" placeholder="NGパターン名前">
          </div>
          <div class="pattern-divider divider text-center" data-content="軸となる業務"></div>
        </form>
        <div class="main-role-wrapper">
          <!-- if Pattern edit, show first role of that Pattern -->
          <span v-if="isTabooPatternEdit" class="chip c-hand text-ellipsis main-pattern-role" :style="mainRoleStyle" @click="onRoleClick(mainRoleId)">
            {{ rolesMap[mainRoleId].Name }}
          </span>
          <!-- else if its a new Pattern then show the selected role from the RoleList Modal -->
          <span v-else-if="selectedRoleId" class="chip c-hand" :style="selectedRoleStyle" @click="onRoleClick(selectedRoleId)">
            {{ rolesMap[selectedRoleId].Name }}
          </span>
          <!-- else show "add" button -->
          <span v-else class="c-hand">
            <button class="role-add-pattern btn btn-slot-add-primary" @click="onSelectRoleClick">
              <i class="icon icon-plus"></i>
              業務を追加
            </button>
          </span>
        </div>
      </div>
    </template>
    <template v-slot:footer-btn>
      <button class="oplus-modal-btn oplus-alt-confirm-btn" :class="loadingClass" @click="onConfirm">{{ createOrEditText }}</button>
    </template>
  </Modal>
</template>
<style>
  .content-wrapper {
    width: 250px;
    padding: 20px 0;
  }
  .pattern-divider.divider {
    margin: 1rem 0;
  }
  .role-add-pattern {
    height: fit-content;
    width: fit-content;
    padding: 4px 8px;
  }

  .main-role-wrapper,
  .main-pattern-role {
    width: fit-content;
    max-width: 100%;
  }
</style>
