"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkedShift = void 0;
const shift_1 = require("./shift");
class LinkedShift extends shift_1.Shift {
    linkedId;
    constructor(linkedId, required, options) {
        super(required, options);
        this.linkedId = linkedId;
    }
    getLinkedId() {
        return this.linkedId.toString();
    }
    isLinkedShift() {
        return true;
    }
    isStandardShift() {
        return false;
    }
}
exports.LinkedShift = LinkedShift;
