import RoleForm from '../vue/RoleForm.vue'
import { mountRoleImportModal } from './modal.js';
import { computed, createApp, reactive } from 'vue';
import { deleteGroupRoleRequest, deleteRoleRequest } from './role_requests.js';
import { addRoleSectionToRole, deleteRoleSectionFromRole } from '../requests/roleRequest';
import ERRORS from './errors.js';
import RolesView from '../vue/view/RolesView.vue'
import { MeStore } from '../store/v1/meStore';
import { User } from '../model/v1/user';
import { parseFromRaw as roleParser } from '../legacy/parser/roleParser';
import RoleRequiredCalendar from '../roleRequiredCalendar/RoleRequiredCalendar.vue';
import { fromOplusTime, jpDateString } from '../common/common';

let meStore;
const ROLES_FORM = {
  CREATE: "#role-create-form",
}

let isGroupRoleList;
let isGroupOwner = false;

// will remove when other role component comes in
const roleRequiredMap = reactive({
  val: {}
});
const weekDayRequired = reactive({
  val: {}
});

export function initPage(props) {
  // Role specific page
  if (props?.renderRoleData) {
    meStore = new MeStore({user: new User({id: props.userId})}, {activeBranchId: props.branchId});
    RoleApp.$mount("#role-app");

    roleRequiredMap.val = makeRoleRequiredMap(RoleApp.role);
    weekDayRequired.val = (Array.from(RoleApp.role.DayNumbers));
    const roleId = RoleApp.role.ID;
    mountRoleRequiredCalendar({meStore, roleId});
  }
  // Roles table page
  if (props?.renderRolesTable) {
    isGroupRoleList = props.isGroupRoleForm;
    isGroupOwner = props.isGroupOwner;
    mountRoleImportModal(props.isGroupRoleForm);
    mountRoleForm(props);
  }
  // only uses in role-section page for now, in future all role related page will be render through here
  if (props?.renderRolesView) {
    meStore = new MeStore({user: new User({id: props.userId})}, {activeBranchId: props.branchId});
    mountRolesApp(props.rawRoles);
  }
}

export function updateRoleRequiredInRoleApp() {
  const required = [];
  Object.keys(roleRequiredMap.val).forEach(r => {
    const data = roleRequiredMap.val[r];
    if (data.required === undefined) {
      return;
    }
    required.push({
      date: jpDateString(data.realDate),
      dateStr: r,
      num: data.required.toString(),
    })
  })
  RoleApp.role.vDates = [...required];
}
export function updateRequiredCalendar(newWeekDayRequired, requiredData = null) {
  if (newWeekDayRequired) {
    weekDayRequired.val = [...newWeekDayRequired];
  }
  if (requiredData) {
    roleRequiredMap.val[requiredData.date] = {
      required: parseInt(requiredData.requiredNum) || 0,
      realDate: fromOplusTime(new Date(requiredData.date)),
    };
  }
}

function mountFormComponent(component, mountPoint, props) {
  const app = createApp(component, props);
  window[mountPoint] = app.mount(mountPoint);
}

function mountRoleForm(props) {
  mountFormComponent(RoleForm, ROLES_FORM.CREATE, {
    havePermission: props.havePermission,
    isGroupRoleForm: props.isGroupRoleForm,
    isGroupOwner: props.isGroupOwner,
    selectedOem: props.selectedOem
  });
  $(`${ROLES_FORM.CREATE}-btn .oplus-btn`).on("click", () => {
    roleFormBtnHandler()
  });
}

function mountRoleRequiredCalendar(props) {
  const app = createApp(RoleRequiredCalendar, props);
  app.provide('roleRequiredMap', computed(() => roleRequiredMap))
  app.provide('weekDayRequired', computed(() => weekDayRequired))
  app.mount("#role-required-calendar")
}

function makeRoleRequiredMap(role) {
  const requiredNum = role.vDates;
  const reqMap = {};

  if (requiredNum) {
    requiredNum.forEach(reqData => {
      reqMap[reqData.dateStr] = {
        required: parseInt(reqData.num),
        realDate: fromOplusTime(reqData.dateStr),
      }
    })
  }

  return reqMap
}

export function openRoleFormEdit(roleID) {
  roleFormBtnHandler(roleID);
}

function roleFormBtnHandler(roleID) {
  const roleForm = window[ROLES_FORM.CREATE];
  roleForm.toggleRoleForm(roleID);
  changeRoleCreateBtn(roleForm.isVisible);
}

export function changeRoleCreateBtn(isOpen) {
  if (isOpen) {
    $(`${ROLES_FORM.CREATE}-btn .oplus-btn`).addClass("active");
    $(`${ROLES_FORM.CREATE}-btn-text`).text("閉じる");
    $(`${ROLES_FORM.CREATE}-icon`).addClass("close");
  } else {
    $(`${ROLES_FORM.CREATE}-btn .oplus-btn`).removeClass("active");
    $(`${ROLES_FORM.CREATE}-btn-text`).text("作成");
    $(`${ROLES_FORM.CREATE}-icon`).removeClass("close");
  }
}

export function rolesImportSuccessCallback(response) {
  if (response.badRows) {
    // Invalid column input
    let errorMessage = "";

    for (const key in response.badRows) {
      errorMessage += `${response.badRows[key]},\n`;
    }
    alertModal.alert(`CSV業務インポートの関数エラー\n\n${errorMessage}`);
  }
  if (response.error) {
    alertModal.alert(errors.ROLE.IMPORT);
    return;
  }
  // Note: the following Fields in Role are the ones being updated on the import:
  // ** START **
  // ID
  // Name
  // Purpose
  // From
  // To
  // Rest
  // Wage
  // IsNightShift
  // NextDayOff
  // Type
  // GroupWide
  // SlotType
  // ShowCount
  // ShowStaffCount
  // IsHiddenInShiftPage
  // ** END **

  // And these are the ones not being updated:
  // ** START **
  // Color
  // DayNumbers
  // Description
  // EmployeePriority
  // Evenly
  // ExternalID
  // IsFromHidden
  // IsRoleSection
  // IsToHidden
  // KOTHolidayType
  // RoleSections
  // Sections
  // WorkEvenly
  // vColor
  // vDates
  // vEdit
  // vEmployees
  // vNumDaysSetting
  // vUnjoined
  // ** END **
  response.roles.forEach(role => {
    const existingRoleIndex = RolesApp.roles.findIndex(oldRole => oldRole.ID === role.ID);
    const existingRole = RolesApp.roles[existingRoleIndex];
    if (existingRoleIndex !== -1) {
      const importedRole = {...role, ...getMissingFieldsFromRoleImport(existingRole)};
      RolesApp.roles.splice(existingRoleIndex, 1); // remove the existing role to keep order
      RolesApp.roles.push(importedRole);
    } else {
      RolesApp.roles.push(role);
    }
  });
  RolesApp.initRoleAlert();
}

function getMissingFieldsFromRoleImport(role) {
  const {
    Color,
    DayNumbers,
    Description,
    EmployeePriority,
    Evenly,
    ExternalID,
    IsFromHidden,
    IsRoleSection,
    IsToHidden,
    RoleSections,
    Sections,
    WorkEvenly,
    vColor,
    vDates,
    vEdit,
    vEmployees,
    vNumDaysSetting,
    vUnjoined,
  } = role
  return {
      Color,
      DayNumbers,
      Description,
      EmployeePriority,
      Evenly,
      ExternalID,
      IsFromHidden,
      IsRoleSection,
      IsToHidden,
      RoleSections,
      Sections,
      WorkEvenly,
      vColor,
      vDates,
      vEdit,
      vEmployees,
      vNumDaysSetting,
      vUnjoined,
  }
}

export function updateRoleFromRolesApp(updatedRole) {
  const role = RolesApp.roles.find(r => r.ID == updatedRole.ID);
  if (!role) {
    return;
  }
  RolesApp.canSubmit = true;
  Object.assign(role, updatedRole);
}

export function getRoleFromRolesApp(roleID) {
  const role = RolesApp.roles.find(r => r.ID == roleID);
  if (!role) {
    return null
  }

  return role;
}

function deleteRoleFromRolesApp(roleId) {
  const i = roleAppToIndex(roleId);
  RolesApp.roles.splice(i, 1);
}

export function deleteRole(roleId) {
  if (isGroupRoleList) {
    if (!isGroupOwner) {
      alertModal.alert("オーナーのみマスター業務を削除できます。");
      return;
    }

    const message = "拠点内で使用している業務を削除した場合、業務カウントに影響を与える可能性があります。削除前に全拠点にて業務が使用されていない事をご確認ください。";
    alertModal.confirmDelete(message, () => {
      return deleteGroupRole(roleId);
    })
  } else {
    alertModal.confirm("本当に消去しますか？", () => {
      return deleteBranchRole(roleId);
    })
  }
}

export async function deleteBranchRole(roleId) {
  try {
    await deleteRoleRequest(roleId);
    deleteRoleFromRolesApp(roleId);
  } catch (err) {
    alertModal.alert(ERRORS.ROLE.DELETE)
  }
}

export async function deleteGroupRole(roleId) {
  try {
    await deleteGroupRoleRequest(roleId);
    deleteRoleFromRolesApp(roleId);
  } catch (err) {
    alertModal.alert(ERRORS.ROLE.DELETE)
  }
}

export async function submitAddRoleSection(parentRoleId, roleSectionId) {
  $("#role-section-submit").addClass("loading");
  try {
    const userId = meStore.getActiveUserId();
    const branchId = meStore.getActiveBranchId();
    if (!userId) {
      throw new Error("invalid User ID")
    }
    const payload = {
      roleId: parentRoleId,
      sectionRoleId: roleSectionId,
    }
    const response = await addRoleSectionToRole(userId, branchId, payload);
    if (response?.success) {
      RoleApp.addRoleSection(response.section);
    } else {
      throw new Error(response.detail);
    }
  } catch(err) {
    alertModal.alert(err);
  } finally {
    $("#role-section-submit").removeClass("loading");
    $("#role-section-modal").removeClass("active");
  }
}

export async function removeRoleSectionFromRole(parentRoleId, roleSectionId) {
  const roleDeleteIcon = $(`#role-section-delete-icon[data-role-sect-id='${roleSectionId}']`);
  roleDeleteIcon.addClass("loading");
  try {
    const userId = meStore.getActiveUserId();
    const activeBranchId = meStore.getActiveBranchId();
    const payload = {
      roleId: parentRoleId,
      sectionRoleId: roleSectionId,
    }
    const response = await deleteRoleSectionFromRole(userId, activeBranchId, payload);
    if (response.success) {
      RoleApp.removeRoleSection(roleSectionId);
    }
  } catch(err) {
    alertModal.alert(err)
  } finally {
    roleDeleteIcon.removeClass("loading");
  }
}

function mountRolesApp(rawRoles) {
  const parsedRoles = [];
  rawRoles.forEach(rawRole => {
    const r = roleParser(rawRole);
    parsedRoles.push(r);
  })
  const app = createApp(RolesView, { roles: parsedRoles, meStore: meStore });
  app.mount("#role-section-content-app");
}

export function openAlertModal(msg, refreshPage = false) {
  alertModal.alert(msg, refreshPage);
}

export function openConfirmModal(msg, callback) {
  alertModal.confirm(msg, callback);
}
