<template>
  <svg version="1.1" class="shift-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="20px" height="20px" viewBox="0 0 18.698 19.533" style="enable-background:new 0 0 18.698 19.533;" xml:space="preserve">
    <path fill="currentColor" d="M15.722,2.65h-1.6V0.5c0-0.28-0.23-0.5-0.5-0.5c-0.279,0-0.5,0.22-0.5,0.5v2.15H6.013V0.5
    c0-0.28-0.221-0.5-0.5-0.5c-0.28,0-0.5,0.22-0.5,0.5v2.15H3.492c-0.979,0-1.78,0.8-1.78,1.78v12.28c0,0.98,0.801,1.77,1.78,1.77
    h12.229c0.971,0,1.771-0.79,1.771-1.77V4.43C17.492,3.45,16.692,2.65,15.722,2.65z M16.673,16.71c0,0.53-0.431,0.96-0.951,0.96
    H3.492c-0.53,0-0.96-0.43-0.96-0.96V7.4h14.141V16.71z M16.673,6.476H2.532V4.43c0-0.53,0.43-0.96,0.96-0.96h12.229
    c0.521,0,0.951,0.43,0.951,0.96V6.476z"/>
    <path fill="currentColor" d="M8.343,15.283c-0.154,0-0.308-0.059-0.425-0.176l-2.25-2.25c-0.234-0.234-0.234-0.614,0-0.849
    s0.614-0.234,0.849,0l1.826,1.826l4.388-4.388c0.234-0.234,0.614-0.234,0.849,0s0.234,0.614,0,0.849l-4.812,4.812
    C8.649,15.225,8.495,15.283,8.343,15.283z"/>
  </svg>
</template>
