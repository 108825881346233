"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShiftTemplate = void 0;
const AShift_1 = require("./AShift");
class ShiftTemplate extends AShift_1.AShift {
    startHour;
    startMinute;
    endHour;
    endMinute;
    timeZone;
    constructor(required, options) {
        super(required.id, required.type, options);
        this.startHour = required.startHour;
        this.startMinute = required.startMinute;
        this.endHour = required.endHour;
        this.endMinute = required.endMinute;
        this.timeZone = required.timeZone;
    }
    getStartHour() {
        return this.startHour;
    }
    getStartMinute() {
        return this.startMinute;
    }
    getEndHour() {
        return this.endHour;
    }
    getEndMinute() {
        return this.endMinute;
    }
    isShiftTemplate() {
        return true;
    }
    isStandardShift() {
        return false;
    }
    getTimeZone() {
        return this.timeZone;
    }
}
exports.ShiftTemplate = ShiftTemplate;
