"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Shift = void 0;
const AShift_1 = require("./AShift");
class Shift extends AShift_1.AShift {
    userId;
    branchId;
    startDate;
    endDate;
    sections = [];
    created;
    updated;
    purpose;
    isAuto;
    isEdited; // slot was *created or edited* by someone that's not the slot's user
    isTemplate; // might be unnecessary, but was included to maintain existing logic in shiftName()
    linkPrev;
    linkNext;
    constructor(required, options) {
        super(required.id, required.type, options);
        this.userId = required.userId;
        this.branchId = required.branchId;
        this.startDate = required.startDate;
        this.endDate = required.endDate;
        this.sections = options?.sections || [];
        this.updated = options?.updated || null;
        this.created = options?.created || null;
        this.purpose = options?.purpose || "";
        this.isAuto = options?.isAuto || false;
        this.isEdited = options?.isEdited || false;
        this.isTemplate = options?.isTemplate || false;
        this.linkPrev = options?.linkPrev || null;
        this.linkNext = options?.linkNext || null;
    }
    getUserId() {
        if (this.userId == 0) {
            return "";
        }
        return this.userId.toString();
    }
    getStartDate() {
        return new Date(this.startDate);
    }
    getEndDate() {
        const d = new Date(this.endDate);
        if (this.isHideTo()) {
            d.setDate(d.getDate() + 1);
        }
        return d;
    }
    setEndDate(d) {
        this.endDate = d;
    }
    getSections() {
        return this.sections;
    }
    getPurpose() {
        return this.purpose;
    }
    getLinkPrev() {
        return this.linkPrev || 0;
    }
    getLinkNext() {
        return this.linkNext || 0;
    }
    isStandardShift() {
        return true;
    }
    // Deprecated, it relies on LinkPrev field
    isMorningShift() {
        return false;
    }
    // Deprecated, it relies on LinkNext field
    isNightShift() {
        return false;
    }
    isOvernightMorningShift(cellDate) {
        return this.startDate.getDate() < cellDate.getDate() && this.endDate.getDate() === cellDate.getDate();
    }
    getCreated() {
        return this.created || new Date(0);
    }
    getUpdated() {
        return this.updated || new Date(0);
    }
    getIsAuto() {
        return this.isAuto;
    }
    getIsEdited() {
        return this.isEdited;
    }
    // TODO is this the same as `AShift.isShiftTemplate`?
    getIsTemplate() {
        return this.isTemplate;
    }
    isSection() {
        return false;
    }
    isClone(companyId) {
        return this.getBranchId() != companyId;
    }
    getBranchId() {
        if (this.branchId == 0) {
            return "";
        }
        return this.branchId.toString();
    }
    getOriginalBranchId() {
        return this.branchId;
    }
    getOriginalUserId() {
        return this.userId;
    }
    setSectionSlots(sections) {
        this.sections = sections;
    }
    hasRoleSection() {
        return this.sections.some(s => {
            return s.getRoleId() !== "";
        });
    }
    index() {
        return this.getId();
    }
}
exports.Shift = Shift;
