"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Section = void 0;
const shift_1 = require("./shift");
class Section extends shift_1.Shift {
    parentId;
    constructor(parentId, required, options) {
        super(required, options);
        this.parentId = parentId;
    }
    getParentId() {
        return this.parentId.toString();
    }
    isSection() {
        return true;
    }
    isStandardShift() {
        return false;
    }
}
exports.Section = Section;
