<script>
  import { isSlotTypeOne, isNonWorkingSlotType, isSlotPTOType } from '../js/slot_helpers';

  export default {
    props: {
      slot: Object
    },
    computed: {
      haveShiftIconClass() {
        return {
          'working': isSlotTypeOne(this.slot),
          'non-working': isNonWorkingSlotType(this.slot) || isSlotPTOType(this.slot),
        }
      },
      computedStyle() {
        return { backgroundColor: '#' + this.slot.Color };
      }
    }
  }
</script>

<template>
    <div class="have-shift-icon show-sm" :class="haveShiftIconClass" :style="computedStyle"></div>
</template>

<style scoped>

  .have-shift-icon {
    position: relative;
    margin: 0 auto;
    width: 10px;
    height: 10px;
    background-color: #dbdbdb;
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 4px;
  }
  .have-shift-icon::before {
    content: "";
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    transform: translate(-50%, -50%);
  }

  .have-shift-icon.non-working::before {
    background: #e85600;
  }

  .have-shift-icon.working::before {
    background: #32b643;
  }
  </style>
