"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleSectionTemplateStore = void 0;
class RoleSectionTemplateStore {
    data = {};
    constructor() {
        this.data = {};
    }
    add(s) {
        this.data[s.getId()] = s;
    }
    byId(id) {
        return this.data[id];
    }
    query(options) {
        let out = Object.values(this.data);
        if (options?.roleSectionId) {
            out = out.filter(r => {
                if (r.getRoleSectionId() == options.roleSectionId) {
                    return r;
                }
            });
        }
        if (options?.assignedDate) {
            out = out.filter(r => {
                const hasDate = r.assignedDates.some(d => {
                    const optionDate = options.assignedDate;
                    if (!optionDate) {
                        return;
                    }
                    const isSameDate = d.getFullYear() === optionDate.getFullYear() &&
                        d.getMonth() === optionDate.getMonth() &&
                        d.getDate() === optionDate.getDate();
                    return isSameDate;
                });
                return hasDate;
            });
        }
        return out;
    }
    delete(id) {
        delete this.data[id];
    }
}
exports.RoleSectionTemplateStore = RoleSectionTemplateStore;
