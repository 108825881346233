"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmploymentShiftTableStatsMap = void 0;
const tableMap_1 = require("../table/v1/tableMap");
const employmentShiftTableStatsCell_1 = require("./employmentShiftTableStatsCell");
const employmentShiftTableStatsRow_1 = require("./employmentShiftTableStatsRow");
const employmentShiftTableStatsColumn_1 = require("./employmentShiftTableStatsColumn");
const employmentShiftTable_1 = require("./employmentShiftTable");
class EmploymentShiftTableStatsMap extends tableMap_1.TableMap {
    constructor(dates, employments, shifts) {
        super(dates, employments, employmentShiftTableStatsCell_1.EmploymentShiftTableStatsCell, employmentShiftTableStatsRow_1.EmploymentShiftTableStatsRow, employmentShiftTableStatsColumn_1.EmploymentShiftTableStatsColumn);
        const dateToUserToSlots = (0, employmentShiftTable_1.makeDateToUserToShiftMap)(dates, shifts);
        this.yAxisData.forEach(u => {
            this.xAxisData.forEach(d => {
                const cellSlotsMap = dateToUserToSlots[d.index()][u.index()];
                if (!cellSlotsMap) {
                    return;
                }
                const cellSlots = Object.values(cellSlotsMap);
                const cellStats = this.getCellStats(d.index(), u.index());
                cellStats.setSlots(cellSlots);
            });
        });
    }
    getCellStats(date, userId) {
        return this.data[date][userId];
    }
    getColumnStats(d) {
        return this.columns[d];
    }
    getRowStats(u) {
        return this.rows[u];
    }
}
exports.EmploymentShiftTableStatsMap = EmploymentShiftTableStatsMap;
