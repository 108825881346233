// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.have-shift-icon[data-v-6fa0238e] {
    position: relative;
    margin: 0 auto;
    width: 10px;
    height: 10px;
    background-color: #dbdbdb;
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 4px;
}
.have-shift-icon[data-v-6fa0238e]::before {
    content: "";
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    transform: translate(-50%, -50%);
}
.have-shift-icon.non-working[data-v-6fa0238e]::before {
    background: #e85600;
}
.have-shift-icon.working[data-v-6fa0238e]::before {
    background: #32b643;
}
  `, "",{"version":3,"sources":["webpack://./src/vue/ShiftSmallSlot.vue"],"names":[],"mappings":";AA2BE;IACE,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,kBAAkB;AACpB;AACA;IACE,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,gCAAgC;AAClC;AAEA;IACE,mBAAmB;AACrB;AAEA;IACE,mBAAmB;AACrB","sourcesContent":["<script>\n  import { isSlotTypeOne, isNonWorkingSlotType, isSlotPTOType } from '../js/slot_helpers';\n\n  export default {\n    props: {\n      slot: Object\n    },\n    computed: {\n      haveShiftIconClass() {\n        return {\n          'working': isSlotTypeOne(this.slot),\n          'non-working': isNonWorkingSlotType(this.slot) || isSlotPTOType(this.slot),\n        }\n      },\n      computedStyle() {\n        return { backgroundColor: '#' + this.slot.Color };\n      }\n    }\n  }\n</script>\n\n<template>\n    <div class=\"have-shift-icon show-sm\" :class=\"haveShiftIconClass\" :style=\"computedStyle\"></div>\n</template>\n\n<style scoped>\n\n  .have-shift-icon {\n    position: relative;\n    margin: 0 auto;\n    width: 10px;\n    height: 10px;\n    background-color: #dbdbdb;\n    border-radius: 50%;\n    cursor: pointer;\n    margin-bottom: 4px;\n  }\n  .have-shift-icon::before {\n    content: \"\";\n    font-weight: bold;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    border-radius: 50%;\n    width: 5px;\n    height: 5px;\n    transform: translate(-50%, -50%);\n  }\n\n  .have-shift-icon.non-working::before {\n    background: #e85600;\n  }\n\n  .have-shift-icon.working::before {\n    background: #32b643;\n  }\n  </style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
