"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const viewTypes = Object.freeze({
    DAY: "day",
    WEEK: "week",
    MONTH: "month",
    YEAR: "year",
    CUSTOM: "custom",
});
exports.default = viewTypes;
