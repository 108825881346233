<script>
import { JPDateRangeString, addMonthToDate, addDaysToDate, goDateString } from '../js/common'

export default {
  props: {
    currentCompanyID: Number,
    startDate: Date,
    endDate: Date,
    timePeriodType: String,
    currentPage: String,
  },
  methods: {
    changeListDates(newDate) {
      const param = getSearchParam();

      param.list = this.timePeriodType;
      param.date = newDate;

      const url = window.location.origin + window.location.pathname;
      window.location = url + '?' + $.param(param);
    },
    getPreviousDate() {
      if (this.timePeriodType === "month") {
        const startOfMonth = this.startDate.getUTCDate();
        return addMonthToDate(this.startDate, -1, startOfMonth);
      }
      else if (this.timePeriodType === "week") {
        return addDaysToDate(this.startDate, -7);
      }
    },
    getNextDate() {
      if (this.timePeriodType === "month") {
        const startOfMonth = this.startDate.getUTCDate();
        return addMonthToDate(this.startDate, 1, startOfMonth);
      }
      else if (this.timePeriodType === "week") {
        return addDaysToDate(this.startDate, 7);
      }
    },
    goToCurrentDate() {
      const today = GetToday();
      const param = {
        c: this.currentCompanyID,
        list: GetURLParam("list"),
        tags:  GetURLParam("tags"),
        date: today,
      };
      param = pushURLParmToParmObj(param, paramToPass);

      // Clicking "today" if you are in calendar tab should return url with: &date=(today)&until=(today + day difference)
      // getDaysDiff function to calculate the difference (or the date range) of days depending on the dates user selected.
      // addDays function to add the difference of days to current date to get the range of dates from today.
      const until = GetURLParam("until");
      if (until != "") {
        const date = GetURLParam("date");
        const daysDiff = getDaysDiff(until, date);
        const addedDays = addDays(today, daysDiff);

        param["until"] = addedDays;
      }

      const url = window.location.origin + window.location.pathname;
      window.location = url + "?" + $.param(param);
    },
    goToPreviousDate() {
      const prevDate = goDateString(this.getPreviousDate());
      return this.changeListDates(prevDate);
    },
    goToNextDate() {
      const nextDate = goDateString(this.getNextDate());
      return this.changeListDates(nextDate);
    },
    // From dashboard_base.html since these are specifically used in date nav, we move these functions here.
    // Once the refactor are done and all date-nav in the codebase are changed to Vue component, we should remove the same functions from dashboard_base
    listWeek() {
      const params = this.getParams();
      return changeSummaryList("week", params);
    },
    listMonth() {
      const params = this.getParams();
      return changeSummaryList("month", params);
    },
    // getParams will keep the passed param values alive across page loads.
    // We might want to pass more params in the future if we want to render tags or other filters.
    getParams() {
      // comqr param indicates that the user was redirected to the company user timecard page via a company QR punch. We need to keep the param to properly generate DOM.
      return ["comqr"];
    }
  },
  computed: {
    activeWeekBtn() {
      return this.timePeriodType === "week" ? "active" : '';
    },
    activeMonthBtn() {
      return this.timePeriodType === "month" ? "active" : '';
    },
    renderDateRange() {
      return JPDateRangeString(this.startDate, this.endDate);
    },
    renderYearDate() {
      return `${new Date(this.startDate).getUTCFullYear()}年`
    },
  },
}


</script>
<template>
  <div class="date-nav-wrapper">

    <div class="date-arrow-buttons">
      <div class="date-range" @click="goToPreviousDate()">
        <i class="icon icon-arrow-left nav-button"></i>
      </div>
      <span class="nav-button today" onclick="todayClicked()">今日</span>
      <div class="date-range" @click="goToNextDate()">
        <i class="icon icon-arrow-right nav-button"></i>
      </div>
    </div>

    <div class="date-range">
      <small class="date-year-label">{{ renderYearDate }}</small>
      <span class="date-range-label">{{ renderDateRange }}</span>
    </div>

    <div class="list-date-wrapper">
      <div :class="activeWeekBtn" class="side-margin-right nav-button date-list-btn" @click="listWeek()">
        <span>週</span>
      </div>
      <div :class="activeMonthBtn" class="nav-button date-list-btn" @click="listMonth()">
        <span>月</span>
      </div>
    </div>

  </div>
</template>

<style scoped>
  .date-nav-wrapper {
    margin: 15px 0;
  }

  .date-nav-wrapper,
  .list-date-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 27px;
  }

  .side-margin-right {
    margin-right: 10px
  }
  .date-range {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: large;
    color: #a5a5a5;
  }

  .date-arrow-buttons {
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.02rem solid #a5a5a5;
    border-radius: 50px;
    padding: 0 10px;
    height: 27px;
  }

  .icon-arrow-left::before,
  .icon-arrow-right::before {
    border: 0.05rem solid currentColor;
    border-bottom: 0;
    border-right: 0;
  }

  .icon.nav-button:hover, .today:hover {
    color: #222;
  }

  .date-list-btn {
    height: 35px;
    width: 35px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .date-list-btn:hover {
    background-color: #52a3d5;
    color: #fff;
    border-radius: 50px;
  }

  .nav-button {
    font-size: 0.8rem;
    cursor: pointer;
    color: #a5a5a5;
    transition: 0.3s ease;
  }

  .nav-button.active {
    background-color: #52a3d5;
    color: #fff;
    border-radius: 50px;
  }

  .date-year-label {
    line-height: 0.7rem;
    font-size: 0.7rem;
  }

  .date-range-label {
    font-size: 1rem;
    line-height: 1.2rem;
  }
</style>