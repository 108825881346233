<template>
  <svg version="1.1" class="message-board-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="20px" height="20px" viewBox="0 0 18.698 19.533" style="enable-background:new 0 0 18.698 19.533;" xml:space="preserve">
    <path fill="currentColor" d="M6.762,18.15c-0.069,0-0.139-0.017-0.205-0.045c-0.164-0.082-0.271-0.246-0.271-0.43v-2.442H3.044
    c-0.917,0-1.661-0.746-1.661-1.659V4.053c0-0.917,0.744-1.659,1.661-1.659h12.604c0.916,0,1.661,0.742,1.661,1.659v9.521
    c0,0.914-0.746,1.659-1.661,1.659H10.51l-3.447,2.811C6.973,18.113,6.868,18.15,6.762,18.15z M7.237,14.77v1.905l2.786-2.27
    c0.004,0,0.01-0.008,0.016-0.012l0.021-0.017c0.082-0.057,0.183-0.09,0.283-0.09h5.306c0.393,0,0.713-0.32,0.713-0.713V4.053
    c0-0.393-0.32-0.708-0.713-0.708H3.044c-0.393,0-0.713,0.315-0.713,0.708v9.521c0,0.393,0.32,0.713,0.713,0.713h3.718
    c0.166,0,0.315,0.086,0.404,0.225C7.212,14.59,7.237,14.68,7.237,14.77z"/>
    <path fill="currentColor" d="M6.121,6.395H4.386c-0.221,0-0.4-0.179-0.4-0.4s0.179-0.4,0.4-0.4h1.735c0.221,0,0.4,0.179,0.4,0.4
    S6.341,6.395,6.121,6.395z"/>
    <path fill="currentColor" d="M14.128,6.395h-6.27c-0.221,0-0.4-0.179-0.4-0.4s0.179-0.4,0.4-0.4h6.27c0.221,0,0.4,0.179,0.4,0.4
    S14.349,6.395,14.128,6.395z"/>
    <path fill="currentColor" d="M6.121,9.094H4.386c-0.221,0-0.4-0.179-0.4-0.4s0.179-0.4,0.4-0.4h1.735c0.221,0,0.4,0.179,0.4,0.4
    S6.341,9.094,6.121,9.094z"/>
    <path fill="currentColor" d="M14.128,9.094h-6.27c-0.221,0-0.4-0.179-0.4-0.4s0.179-0.4,0.4-0.4h6.27c0.221,0,0.4,0.179,0.4,0.4
    S14.349,9.094,14.128,9.094z"/>
    <path fill="currentColor" d="M6.121,11.814H4.386c-0.221,0-0.4-0.179-0.4-0.4s0.179-0.4,0.4-0.4h1.735c0.221,0,0.4,0.179,0.4,0.4
    S6.341,11.814,6.121,11.814z"/>
    <path fill="currentColor" d="M11.604,11.814H7.858c-0.221,0-0.4-0.179-0.4-0.4s0.179-0.4,0.4-0.4h3.747c0.221,0,0.4,0.179,0.4,0.4
    S11.825,11.814,11.604,11.814z"/>
  </svg>
</template>
