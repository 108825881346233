"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MergedShift = void 0;
const shift_1 = require("./shift");
class MergedShift extends shift_1.Shift {
    morningShiftId;
    nightShiftId;
    constructor(nightShiftId, morningShiftId, required, options) {
        super(required, options);
        this.nightShiftId = nightShiftId;
        this.morningShiftId = morningShiftId;
    }
    isMergedShift() {
        return true;
    }
    getMorningShiftId() {
        return this.morningShiftId.toString();
    }
    getNightShiftId() {
        return this.nightShiftId.toString();
    }
}
exports.MergedShift = MergedShift;
