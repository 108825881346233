<script>
import Modal from './Modal.vue';

export default {
  props: {
    branches: {
      type: Array,
      default: [],
    },
    // active branch is selected on page load if `view` param is empty/null
    isDefaultActiveBranchFilter: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      showPopup: null,
      branchData: {},
      checkedBranches: [],
      selectAll: false,
    }
  },
  components: {
    Modal,
  },
  methods: {
    closeModal() {
      this.initSelectedData();
      this.isVisible = false;
    },
    openModal() {
      this.isVisible = true;
    },
    onConfirm() {
      this.confirmFilter();
    },
    constructBranchData() {
      this.branches.forEach(branch => {
        this.branchData[branch.id || branch.ID] = {
          name: branch.name || branch.Name,
          selected: false,
        }
      })
      return this.branchData;
    },
    toggleAllCheckboxes() {
      if (this.selectAll) {
        for (const branchId in this.branchData) {
          this.branchData[branchId].selected = true;
        }
      } else {
        for (const branchId in this.branchData) {
          this.branchData[branchId].selected = false;
        }
      }
    },
    handleCheckboxChange() {
      this.selectAll = this.checkedBranches.length === this.branches.length;
    },
    initSelectedData() {
     const { all, branchIds } = this.getViewData();
      if (all) {
        this.selectAll = true;
        this.toggleAllCheckboxes();
      } else {
        branchIds.forEach(branchId => {
          this.branchData[branchId].selected = true;
        })
      }

    },
    getCheckedBranches() {
      const checkedBranchIds = [];
      for (const branchId in this.branchData) {
        if (this.branchData[branchId].selected) {
          checkedBranchIds.push(branchId)
        }
      }
      return checkedBranchIds;
    },
    confirmFilter() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      const params = new URLSearchParams(window.location.search)

      if (params.get("page")) {
        params.set("page", 1);
      }

      if (this.selectAll) {
        params.set("view", "all")
      } else {
        params.set("view", this.getCheckedBranches().join(","))
      }
      window.location.search = params.toString();
    },
    getViewData() {
      const params = new URLSearchParams(window.location.search)
      const viewParamValue = params.get("view");
      const activeBranchId = params.get("c");

      let all = false;
      let branchIds = [];

      if (!viewParamValue) {
        if (this.isDefaultActiveBranchFilter) {
          // if theres no view, default to active branch
          branchIds.push(activeBranchId)
        }
      }

      if (viewParamValue === "all") {
        all = true;
      } else if (viewParamValue) {
        branchIds = viewParamValue.split(",");
      }
      return { all, branchIds };
    }
  },
  computed: {
    loadingClass() {
      return this.isLoading ? "loading" : "";
    },
  },
  created() {
    this.constructBranchData();
    this.initSelectedData();
  },
}
</script>

<template>
  <Modal v-if="isVisible" @close-modal="closeModal" @confirm="onConfirm" :has-title-divider="true" :has-footer-divider="true">
    <template v-slot:header-title>
      拠点フィルター
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <!-- Branch list -->
        <div class="branch-filter">
          <div class="filter-wrapper">
            <label class="oplus-form-checkbox">
              <input v-model="selectAll" type="checkbox" name="all" id="all-branches" @change="toggleAllCheckboxes">
              <span class="filter-name">全部</span>
            </label>
            <div v-for="branch, id in branchData" :key="id">
              <label class="oplus-form-checkbox" :data-branch="id">
                <input :value="id" v-model="branch.selected" @change="handleCheckboxChange" class="filter-checkbox text-popup" type="checkbox">
                <span  @click="showPopup = id" @mouseleave="showPopup = null" class="filter-name">{{ branch.name }}</span>
                <transition name="fade">
                  <span v-show="showPopup === id" class="filter-text-popup">{{ branch.name }}</span>
                </transition>
              </label>
            </div>
          </div>
        </div>

      </div>
    </template>
    <template v-slot:footer-btn>
      <div class="btn-wrapper">
        <button :class="loadingClass" class="oplus-modal-btn oplus-confirm-btn"  @click="onConfirm">確定</button>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
  .header-wrapper {
    text-align: center;
    padding-bottom: 0;
  }
  .content-wrapper {
    display: flex;
    width: fit-content;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    height: fit-content;
  }

  .filter-title {
    text-align: left;
    font-size: 1rem;
  }

  .filter-name {
    max-width: 140px;
    width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .filter-wrapper {
    display: grid;
    min-width: 200px;
    max-width: 330px;
    grid-template-columns: repeat(auto-fill, minmax(150px,1fr));
    text-align: left;
    padding: 20px 1px;
    gap: 20px;
  }

  .filter-wrapper.etc-wrapper {
    padding-bottom: 0;
  }

  .branch-filter {
    display: flex;
    flex-direction: column;
  }

  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .filter-text-popup {
    position: absolute;
    right: 0%;
    bottom: -80%;
    background: rgba(48,55,66,.95);
    border-radius: .1rem;
    color: #fff;
    font-size: .7rem;
    padding: .2rem .4rem;
    pointer-events: none;
    z-index: 300;
    max-width: 200px;
    height: fit-content;
    white-space: break-spaces;
    word-wrap: break-word;
  }

  .oplus-form-checkbox {
    position: relative;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  @media (max-width: 576px) {
    .content-wrapper {
      width: 90vw;
    }
  }

  @media (max-width: 392px) {
    .filter-name {
      max-width: 280px;
    }
  }

</style>
