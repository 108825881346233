"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleSectionTimeSlot = void 0;
class RoleSectionTimeSlot {
    startTime;
    endTime;
    requiredCount;
    roleSectionId;
    constructor(req) {
        this.startTime = req.startTime;
        this.endTime = req.endTime;
        this.requiredCount = req.requiredCount;
        this.roleSectionId = req.roleSectionId;
    }
    getStartTime() {
        return this.startTime;
    }
    getEndTime() {
        return this.endTime;
    }
    getRequiredCount() {
        return this.requiredCount;
    }
    getRoleSectionId() {
        return this.roleSectionId;
    }
}
exports.RoleSectionTimeSlot = RoleSectionTimeSlot;
