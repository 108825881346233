"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCell = void 0;
class TableCell {
    xAxis;
    yAxis;
    constructor(xAxis, yAxis) {
        this.xAxis = xAxis;
        this.yAxis = yAxis;
    }
}
exports.TableCell = TableCell;
