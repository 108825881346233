"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseFromRaw = void 0;
const branch_1 = require("../../model/v1/branch");
const branchSettings_1 = require("../../model/v1/branchSettings");
class RawBranch {
    ID;
    Name;
    StackRequestSlots;
    StartOfYear;
    StartOfMonth;
    StartOfWeek;
    SummaryDayStart;
    SummaryDayEnd;
    TimeZone;
}
function parseFromRaw(raw) {
    const settingsReq = {
        timeZone: raw.TimeZone,
    };
    const settingsOpt = {
        weekStart: raw.StartOfWeek,
        monthStart: raw.StartOfMonth,
        yearStart: raw.StartOfYear,
        summaryDayStart: raw.SummaryDayStart,
        summaryDayEnd: raw.SummaryDayEnd
    };
    const settings = new branchSettings_1.BranchSettings(settingsReq, settingsOpt);
    const req = {
        id: raw.ID,
        name: raw.Name,
        settings,
    };
    const b = new branch_1.Branch(req);
    return b;
}
exports.parseFromRaw = parseFromRaw;
