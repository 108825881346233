<script>
export default {
  props: {
    isDisabled: Boolean,
    isActive: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    handleCreateClick() {
      this.$emit("open-create-modal", this.isActive);
    }
  },
  computed: {
    activeButton() {
      return {
        "active": this.isActive,
      }
    },
    activeBtn() {
      return {
        "close": this.isActive,
      }
    },
    activeText() {
      return this.isActive ? '閉じる' : '作成'
    }
  }
}
</script>
<template>
  <div id="role-relation-modal-btn" @click="handleCreateClick">
    <button class="oplus-create-btn oplus-btn oplus-alt-btn hide-sm" :class="activeButton">
      <span id="role-create-form-btn-text">{{ activeText }}</span>
    </button>
    <button class="oplus-btn oplus-alt-btn oplus-create-btn-small show-sm">
      <div class="oplus-add-icon no-border">
        <i id="role-create-form-icon" :class="activeBtn" class="icon icon-plus role-add-icon"></i>
      </div>
    </button>
  </div>
</template>
