"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleSectionTemplate = void 0;
const common_1 = require("../../common/common");
class RoleSectionTemplate {
    id;
    roleSectionId; // Reference ID, which this template belongs to
    name = ""; // name of the template
    requiredTimeSlots = []; // timeSlots, see roleSectionTimeSlots.ts
    assignedDates = []; // array of dates where this template is used
    repeatedWeekDays = [false, false, false, false, false, false, false]; // Repeat days, index correspond a day (Sun - 0, Mon - 1, etc) if true, it repeats in all that day throughtout (no max)
    skipDays = []; // skip days are date that are skipped,  only applies for repeat
    overrides = 0; // indicates template id, if this template is used to override other repeated templates,
    constructor(req, opt) {
        this.id = req.id;
        this.roleSectionId = req.roleSectionId;
        if (opt?.name) {
            this.name = opt.name;
        }
        if (opt?.requiredTimeSlots) {
            this.requiredTimeSlots = opt.requiredTimeSlots;
        }
        if (opt?.assignedDates) {
            this.assignedDates = opt.assignedDates;
        }
        if (opt?.skipDays) {
            this.skipDays = opt.skipDays;
        }
        if (opt?.repeatedWeekDays) {
            this.repeatedWeekDays = opt.repeatedWeekDays;
        }
        if (opt?.overrides) {
            this.overrides = opt.overrides;
        }
    }
    getId() {
        return this.id.toString();
    }
    getOriginalId() {
        return this.id;
    }
    getRoleSectionId() {
        return this.roleSectionId.toString();
    }
    getRequiredTimeSlots() {
        return this.requiredTimeSlots;
    }
    getRepeatedWeekDays() {
        return this.repeatedWeekDays;
    }
    isRepeatTemplate(date) {
        const dateWeekDayIndex = new Date(date).getDay();
        let out = this.repeatedWeekDays[dateWeekDayIndex];
        if (out) {
            for (let i = 0; i < this.skipDays.length; i++) {
                if ((0, common_1.isSameDay)(this.skipDays[i], date)) {
                    out = false;
                    break;
                }
            }
        }
        return out;
    }
    getSkipDays() {
        return this.skipDays;
    }
    addSkipDays(skipDate) {
        this.skipDays.push(skipDate);
        return;
    }
    getOverrides() {
        return this.overrides;
    }
}
exports.RoleSectionTemplate = RoleSectionTemplate;
