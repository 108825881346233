<template>
    <div>
        <div class="svg-container emotion-rating">
            <svg version="1.1" class="emotion-rating-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 32 32" width="60px" height="60px" xml:space="preserve">
                <path class="" d="M16.3,27.7c-6.43,0-11.67-5.23-11.67-11.67C4.63,9.6,9.86,4.36,16.3,4.36S27.96,9.6,27.96,16.03
                    C27.96,22.46,22.73,27.7,16.3,27.7z M16.3,5.43c-5.85,0-10.6,4.76-10.6,10.6s4.76,10.6,10.6,10.6s10.6-4.76,10.6-10.6
                    S22.14,5.43,16.3,5.43z M20.93,19.16c0.16-0.24,0.1-0.57-0.15-0.74c-0.24-0.16-0.57-0.1-0.74,0.15c-0.01,0.02-1.13,1.65-3.74,1.65
                    c-2.58,0-3.7-1.58-3.75-1.66c-0.16-0.24-0.49-0.31-0.74-0.15c-0.24,0.16-0.31,0.49-0.15,0.74c0.06,0.09,1.45,2.13,4.63,2.13
                    C19.47,21.28,20.87,19.24,20.93,19.16z M12.75,12.4c-0.71,0-1.28,0.57-1.28,1.28s0.57,1.28,1.28,1.28s1.28-0.57,1.28-1.28
                    S13.46,12.4,12.75,12.4z M19.86,12.4c-0.71,0-1.28,0.57-1.28,1.28s0.57,1.28,1.28,1.28c0.71,0,1.28-0.57,1.28-1.28
                    S20.57,12.4,19.86,12.4z"/>
            </svg>
        </div>
        <span class="satisfaction-text">やや満足</span>
    </div>
</template>
