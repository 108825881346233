<script>
  import TabooPatternsTable from './TabooPatternsTable.vue';
  import { store as roleStore } from '../js/stores/role_store';
  import { store as tabooPatternStore } from '../js/stores/taboo_pattern_store';
  import { store as appStore } from '../js/stores/app_store';
  import { LOADING_TYPES } from '../js/enums/loading_types';
  import { deleteTabooPatternRequest } from '../js/pattern_requests.js'
  import { toggleTabooCreateBtn } from '../js/taboo_patterns';
  import ERRORS from '../js/errors';

  export default {
    props: {
      userId: Number,
      branchId: Number,
    },
    methods: {
      onPatternDelete(tabooPatternId) {
        this.deletePattern(tabooPatternId)
      },
      async deletePattern(tabooPatternId) {
        appStore.setLoading(LOADING_TYPES.PATTERN_ROLE_TEMPLATE, true);
        try {
          const response = await deleteTabooPatternRequest(tabooPatternId);
          tabooPatternStore.deleteTabooPattern(response.tabooPatterns.ID)
        } catch (err) {
          alertModal.alert(err);
        }
        appStore.setLoading(LOADING_TYPES.PATTERN_ROLE_TEMPLATE, false);
      },
      onUpdatePatternPosition(reorderedIds) {
        tabooPatternStore.data.tabooPatternsOrder = reorderedIds.map((id) => parseInt(id));
        this.updatePatternPosition(reorderedIds);
      },
      async updatePatternPosition(reorderedIds) { 
        try {
          const p = new URLSearchParams();
          p.append('tabooPatterns', reorderedIds.join(','));
          p.append('c', this.branchId.toString());

          const response = await fetch(`/dashboard/${this.userId}/taboo_roles_position?${p.toString()}`, {
            method: 'PUT',
          });

          const result = await response.json();
          if (!result.success) {
            throw new Error("Error updating pattern");
          }

        } catch (error) {
          this.openAlertModal(ERRORS.PATTERN.UPDATE);
        }
      },
      openAlertModal(message) {
        alertModal.alert(message);
      },
    },
    computed: {
      rolesMap() {
        return roleStore.data.rolesMap;
      },
      tabooPatternsMap() {
        return tabooPatternStore.data.tabooPatternsMap;
      },
      tabooPatternsOrder() {
        return tabooPatternStore.data.tabooPatternsOrder;
      },
      hasRoles() {
        if (this.rolesMap) {
          return Object.keys(this.rolesMap).length ? true : false;
        }
        return false;
      },
      hasPatterns() {
        if (this.tabooPatternsMap) {
          return Object.keys(this.tabooPatternsMap).length ? true : false;
        }
        return false;
      },
    },
    components: {
      TabooPatternsTable,
    },
    updated() {
      return toggleTabooCreateBtn(Object.keys(this.rolesMap));
    },
  }
</script>
<template>
  <div class="app-container">
    <div v-if="hasRoles && hasPatterns">
      <div class="oplus-table-wrapper">
        <TabooPatternsTable
          @update-pattern-position="onUpdatePatternPosition"
          @pattern-delete="onPatternDelete"
        />
      </div>
    </div>
    <div v-else class="empty-container">
      <div v-if="hasRoles" class="empty centered">
        <p class="empty-title h5">
          NGパターンがありません
        </p>
        <p class="empty-subtitle">
          上の作成ボタンからを作成してください。
        </p>
      </div>
      <div v-else class="empty centered">
        <p class="empty-title h5">
          業務がありません
        </p>
        <p class="empty-subtitle">
          業務管理タッブから業務を作成してください。
        </p>
      </div>
    </div>
  </div>
</template>
<style>
  .pattern-create-btn-wrapper {
    display: flex;
    justify-content: end;
  }

  .empty-container {
    padding-top: 10px;
  }
</style>
