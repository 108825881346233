export const intToJapaneseWeekdayKanji = {
  0: "日",
  1: "月",
  2: "火",
  3: "水",
  4: "木",
  5: "金",
  6: "土",
}

export function splitFullName(name) {
  let family, first;
  name.replace(/^([^ 　]+)[ 　]+([^ 　]+)$/g, function(_, f, i) {
      family = f;
      first = i;
  });
  if (!family) {
      return [name];
  }
  return [family, first];
}

export function addZeroPlaceholders(i) {
  let s = i.toString();
  if (i < 10) {
    s= `0${i}`;
  }
  return s;
}

export function goDateString(t) {
  return `${t.getUTCFullYear()}-${addZeroPlaceholders(t.getUTCMonth() + 1)}-${addZeroPlaceholders(t.getUTCDate())}`
}

// JP Full Date with weekday format: 2023年1月5日 (月)
export function JPDateString(t) {
  return `${t.getUTCFullYear()}年${t.getUTCMonth() + 1}月${t.getUTCDate()}日 (${intToJapaneseWeekdayKanji[t.getUTCDay()]})`;
}

// JP Short Date format with optional weekday: 1月5日 (月)
export function JPShortDateString(t, includeWeekdayKanji) {
  return `${t.getUTCMonth() + 1}月${t.getUTCDate()}日${includeWeekdayKanji ? ` (${intToJapaneseWeekdayKanji[t.getUTCDay()]})` : ''}`;
}

// Short date label format with optional weekday: 1/5(月)
// Uses Oplus time and needs to depereciate ASAP
export function shortOplusTimeDateLabel(t, includeWeekdayKanji) {
  return `${t.getUTCMonth() + 1}/${t.getUTCDate()} ${includeWeekdayKanji ? ` (${intToJapaneseWeekdayKanji[t.getUTCDay()]})` : ''}`;
}

export function roundTwoDecimals(n, thousandsSeparator) {
  n = Math.round(n * 100) / 100;
  return addThousandsSeparator(n, thousandsSeparator);
}

//addThousandsSeparator formats the passed number using the passed string as a thousands digits group separator. Returns a string.
//addThousandsSeparator(10000, ",") -> "10,000"
export function addThousandsSeparator(n, thousandsSeparator = "") {
  return String(n).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${thousandsSeparator}`);
}

//roundNum rounds a number with Math.round and adds a thousands digits group separator if present. Returns a string.
//roundNum(1.2, "") -> "1"
//roundNum(1000.2, ",") -> "1,000"
export function roundNum(n, thousandsSeparator) {
  n = Math.round(n);
  return addThousandsSeparator(n, thousandsSeparator);
}

//dateFromSecondsTimestamp will convert a timestamp in seconds to the JS standard timestamp in milliseconds and return a Date.
export function dateFromSecondsTimestamp(timestamp) {
  return new Date(timestamp * 1000);
}
//millisecondsFromSeconds will convert a timestamp in seconds to milliseconds.
export function millisecondsFromSeconds(timestamp) {
  return timestamp * 1000;
}
// returns a timestamp from date, reseting the hours, mins, secs to midnight.
export function timestampFromDate(dateString) {
  return new Date(dateString).setUTCHours(0, 0, 0, 0);
}

//isIOSApp will test the user agent string to see if the user is using the iOS app. Returns a boolean.
export function isIOSApp() {
  let isIOSApp = false;
  const isStandalone = window.navigator.standalone;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isSafari = /safari/.test(userAgent);
  const isiOS = /iphone|ipod|ipad/.test(userAgent);

  if(isiOS && !isStandalone && !isSafari) {
    isIOSApp = true;
  };
  return isIOSApp
}

// createStartAndEndDatesFromArray will takes the array of dates from the BE and will create Date objects for the start and end dates, setting the end date's time to the beginning of its following hour instead of the start of its hour if requested.
// TODO: Add the ability to make end date inclusive of the final day in the case of weekly/monthly tables.
export function createStartAndEndDatesFromArray(dates, makeEndDateInclusive) {
  const startDate = dateFromSecondsTimestamp(dates[0].TimeStamp);
  const endDate = dateFromSecondsTimestamp(dates[dates.length - 1].TimeStamp);
  if (makeEndDateInclusive) {
    // Add an hour so it's at the end of the of the final hour of the table instead of the start of the final hour
    endDate.setUTCHours(endDate.getUTCHours() + 1, 0, 0, 0);
  }

  return {
    startDate,
    endDate
  }
}

// timestampsSecsFromDate takes a date object and returns its timestamp in seconds
export function timestampSecsFromDate(date) {
  return date.valueOf() / 1000;
}

// Creates date range ex: 1月1日 ~ 1月31日, range may vary to month or week
export function JPDateRangeString(startDate, endDate) {
  return `${JPShortDateString(startDate, false)} ~ ${JPShortDateString(endDate, false)}`;
}

// addMonthToDate function increment the current month and 1st day, accepts a Date object and an integer.
export function addMonthToDate(currentDate, numberOfMonthToAdd, startOfMonth = 1) {
  const newDate = new Date(currentDate);
  if (startOfMonth <= 0) {
    startOfMonth = 1;
  }
  if (startOfMonth > 28) {
    startOfMonth = 28
  }
  newDate.setUTCDate(startOfMonth);
  newDate.setUTCMonth(newDate.getUTCMonth() + numberOfMonthToAdd);

  return newDate;
}

// addDaysToDate function adds days to the passed date accepts a Date object and an integer.
export function addDaysToDate(currentDate, daysToAdd) {
  const newDate = new Date(currentDate);
  newDate.setUTCDate(newDate.getUTCDate() + daysToAdd);

  return newDate;
}

// previousMidnightUTC returns the input date with the time zeroed out
export function previousMidnightUTC(currentDate) {
  const midnight = new Date(currentDate);
  midnight.setUTCHours(0, 0, 0, 0);

  return midnight
}

// followingMidnightUTC returns the day after the input date, with the time zeroed out
export function followingMidnightUTC(currentDate) {
  return addDaysToDate(previousMidnightUTC(currentDate), 1);
}

// Converts minutes to HH:MM time format ex: 600 => 10:00
export function minutesToTime(min) {
  if (min < 0) {
    min = 0
  }
  min = min % 1440;
  const h = addZeroPlaceholders(0|min / 60);
  const m = addZeroPlaceholders(min % 60);

  return `${h}:${m}`
}

// roundDateDownToNearest15MinIncrement will take a date object, get the UTC minutes, and round it down to the nearest 15 minute increment (45, 30, 15, 0). It will mutate the passed date.
export function roundDateDownToNearest15MinIncrement(date) {
  const minutes = date.getUTCMinutes();
  if (minutes > 45) {
    date.setUTCMinutes(45, 0, 0);
  } else if (minutes > 30) {
    date.setUTCMinutes(30, 0 ,0);
  } else if (minutes > 15) {
    date.setUTCMinutes(15, 0, 0);
  } else {
    date.setUTCMinutes(0, 0, 0);
  }
}

// dateToTimeString creates a string of "HH:MM" in UTC time with zero placeholders.
export function dateToTimeString(date) {
  return `${addZeroPlaceholders(date.getUTCHours())}:${addZeroPlaceholders(date.getUTCMinutes())}`;
}

// isMobileBrowser will test the user agent string against a list of mobile browser keywords.
export function isMobileBrowser() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
}

// createDateFromTimeRangeAndDate will create a new date that is the original date plus the duration.
export function createDateFromTimeRangeAndDate(date, durationStart, durationEnd) {
  return new Date(date.valueOf() + durationEnd.valueOf() - durationStart.valueOf());
}

// timeSpansOverlap returns true if the time ranges s1(start1)->e1(end1) and s2->e2 overlap.
export function timespansOverlap(s1, e1, s2, e2) {
	return s1 < e2 && s2 < e1
}

// overlapDuration will return the overlap duration in milliseconds of two durations.
export function overlapDuration(s1, e1, s2, e2) {
  if (!timespansOverlap(s1, e1, s2, e2)) {
    return 0;
  }
  const s = Math.max(s1, s2);
  const e = Math.min(e1, e2);
  return e - s;
}

export function isQueryParamTrue(paramName) {
  return GetURLParam(paramName) === "true"
}

export function validateEmail(emailString) {
  const pattern = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.+_-]+\.[a-zA-Z]{2,6}$/;
  return pattern.test(emailString);
}

// Returns true if the date is "0001-01-01T00:00:00Z" a golang equivalent function of "IsZero"
// "0001-01-01T00:00:00Z" is unset value for a date object in GoLang
export function isZeroDate(date) {
  return (
    date.getUTCFullYear() === 1 &&
    date.getUTCMonth() === 0 &&
    date.getUTCDate() === 1 &&
    date.getUTCHours() === 0 &&
    date.getUTCMinutes() === 0 &&
    date.getUTCSeconds() === 0 &&
    date.getUTCMilliseconds() === 0
  );
}
// Converts seconds timestamp to time string ex: 1702291527 => 19:45
export function secondsTimestampToOplusTimeString(timestamp) {
  return dateToTimeString(dateFromSecondsTimestamp(timestamp));
}

export function isNonZeroDate(date) {
  return !isZeroDate(date);
}

export function refreshOnSuccess(response) {
  if (response.success) {
    setTimeout(function(){
      window.location.reload();
    }, 600);
  } else {
    alert(response);
  }
}
