<script>
import { setShiftViewLocalStorage } from '../js/shift';
// This component toggles between the calendar and table, which both shared a common state 'calendarReactiveObject' so it can be easier to manage
// This will probably removed in the future when we also refactor the shift-table to vue
export default {
  methods: {
    toggleCalendar(isVisible) {
      this.calendarReactiveObject.isCalendarView = isVisible;
      setShiftViewLocalStorage();
      removeEdit();
      resetTemplateSelection();
    }
  },
  computed: {
    isCalendarBtnActive() {
      return this.calendarReactiveObject.isCalendarView  ? 'active' : ''
    },
    isTableBtnActive() {
      return !this.calendarReactiveObject.isCalendarView  ? 'active' : ''
    },
  },
  inject: [
    'calendarReactiveObject',
  ]
}
</script>
<template>
  <div class="calendar-toggle-btn-container">
    <div class="calendar-toggle-btn-wrapper">
      <button :class="isCalendarBtnActive" class="oplus-btn" @click="toggleCalendar(true)">
        <span>カレンダー</span>
      </button>
      <button :class="isTableBtnActive" class="oplus-btn" @click="toggleCalendar(false)">
        <span>リスト</span>
      </button>
    </div>
  </div>
</template>

<style scoped>
  .calendar-toggle-btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  .calendar-toggle-btn-wrapper {
    border: 0.02rem solid #c4c4c4;
    border-radius: 30px;
    display: flex;
    padding: 4px 5px;
    gap: 3px;
  }

  .calendar-toggle-btn-wrapper .oplus-btn {
    border-radius: 15px;
    border: none;
    font-size: 0.6rem;
    width: 80px;
    padding: 3px 10px;
  }

  .calendar-toggle-btn-wrapper .oplus-btn:not(.active):hover{
    border: none;
    background-color: #a3a3a300;
    color: #2c2c2c;
  }

</style>
