"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftHasLinkNext = exports.shiftHasLinkPrev = exports.shiftName = void 0;
const luxon_1 = require("luxon");
const models_1 = require("../../js/models");
const common_1 = require("../../common/common");
// shiftName returns the text for a shift's cell, like in the summary page. `displayDate` is used for linkPrev/linkNext cutoffs.
function shiftName(shift, settings, displayDate, displayDateEnd, displayTz) {
    let text = "";
    if (!(0, common_1.timespansOverlapIncl)(shift.getStartDate(), shift.getEndDate(), displayDate, displayDateEnd)) {
        return text;
    }
    if (shift.getRoleId() != "") {
        const requestOffTypes = [models_1.SLOT_TYPES.OFF_DAY, models_1.SLOT_TYPES.PUBLIC_HOLIDAY, models_1.SLOT_TYPES.OTHER_HOLIDAY];
        if (requestOffTypes.some(x => x === shift.getType()) && settings.roleRequestOff && (!shift.getIsEdited() && !shift.getIsAuto() && !shift.getIsTemplate())) {
            if (settings.shiftStaffOffText && settings.shiftStaffOffText != "") {
                text = settings.shiftStaffOffText;
            }
            else {
                text = "希望休";
            }
        }
        else {
            text = shift.getRoleName();
        }
    }
    else if (shift.getType() === models_1.SLOT_TYPES.WORKDAY) {
        if (settings.shiftWorkText && settings.shiftWorkText != "") {
            text = settings.shiftWorkText;
        }
        else {
            text = "○";
        }
    }
    else if (shift.getType() === models_1.SLOT_TYPES.OFF_DAY) {
        if (settings.requestOff && (!shift.getIsEdited() && !shift.getIsAuto() && !shift.getIsTemplate())) {
            if (settings.shiftStaffOffText && settings.shiftStaffOffText != "") {
                text = settings.shiftStaffOffText;
            }
            else {
                text = "希望休";
            }
        }
        else if (settings.shiftOffText && settings.shiftOffText != "") {
            text = settings.shiftOffText;
        }
        else {
            text = "×";
        }
    }
    else if (shift.getType() === models_1.SLOT_TYPES.PTO) {
        if (settings.shiftPTOText && settings.shiftPTOText != "") {
            text = settings.shiftPTOText;
        }
        else {
            text = "有休";
        }
    }
    else if (shift.getType() === models_1.SLOT_TYPES.HALF_PTO) {
        if (settings.shiftHalfPTOText && settings.shiftHalfPTOText != "") {
            text = settings.shiftHalfPTOText;
        }
        else {
            text = "半有休";
        }
    }
    else if (shift.getType() === models_1.SLOT_TYPES.PUBLIC_HOLIDAY) {
        if (settings.requestOff && (!shift.getIsEdited() && !shift.getIsAuto() && !shift.getIsTemplate())) {
            if (settings.shiftStaffOffText && settings.shiftStaffOffText != "") {
                text = settings.shiftStaffOffText;
            }
            else {
                text = "希望休";
            }
        }
        else if (settings.shiftOffText && settings.shiftOffText != "") {
            text = settings.shiftOffText;
        }
        else {
            text = "法定休日";
        }
    }
    else if (shift.getType() === models_1.SLOT_TYPES.OTHER_HOLIDAY) {
        if (settings.requestOff && (!shift.getIsEdited() && !shift.getIsAuto() && !shift.getIsTemplate())) {
            if (settings.shiftStaffOffText && settings.shiftStaffOffText != "") {
                text = settings.shiftStaffOffText;
            }
            else {
                text = "希望休";
            }
        }
        else if (settings.shiftOffText && settings.shiftOffText != "") {
            text = settings.shiftOffText;
        }
        else {
            text = "法定外休日";
        }
    }
    else if (shift.getType() === models_1.SLOT_TYPES.KOT_PTO || shift.getType() === models_1.SLOT_TYPES.KOT_HOURLY_PTO) {
        text = "KOT 有休";
    }
    else if (shift.getType() === models_1.SLOT_TYPES.KOT_HALF_PTO_AM) {
        text = "KOT AM休";
    }
    else if (shift.getType() === models_1.SLOT_TYPES.KOT_HALF_PTO_PM) {
        text = "KOT PM休";
    }
    else if (shift.getPurpose() != "") {
        text = shift.getPurpose();
    }
    if (text === shift.getPurpose() || text === "" || (shift.getRoleId() != "" && (settings.showRoleTimes === 1 && shift.getUpdated() > shift.getCreated()) || settings.showRoleTimes === 2)) {
        let label = "";
        let fromStr = (0, common_1.dateToKitchenTime)(shift.getStartDate(), displayTz);
        let toStr = (0, common_1.dateToKitchenTime)(shift.getEndDate(), displayTz);
        if (shiftHasLinkPrev(shift, settings, displayDate, displayTz)) {
            fromStr = "00:00";
        }
        if (toStr === "23:59" || shiftHasLinkNext(shift, settings, displayDate, displayTz)) {
            toStr = "00:00";
        }
        if (shift.isHideFrom() && shift.isHideTo() || shift.getType() > 0) {
            label = "";
        }
        else if (shift.isHideFrom()) {
            label = `~ ${toStr}`;
        }
        else if (shift.isHideTo()) {
            label = `${fromStr} ~`;
        }
        else {
            label = `${fromStr} ~ ${toStr}`;
        }
        if (label != "") {
            text = `${text} ${label}`;
        }
    }
    return text;
}
exports.shiftName = shiftName;
function previousMidnight(date, timezone) {
    return luxon_1.DateTime.fromJSDate(date, { zone: timezone }).set({ hour: 0, minute: 0, millisecond: 0 }).toJSDate();
}
function followingMidnight(date, timezone) {
    return luxon_1.DateTime.fromJSDate(date, { zone: timezone }).set({ hour: 0, minute: 0, millisecond: 0 }).plus({ days: 1 }).toJSDate();
}
function shiftHasLinkPrev(shift, settings, displayDate, displayTz, repeatDate = null) {
    const oldLinkPrev = Boolean(shift.getLinkPrev());
    let startDate = shift.getStartDate();
    if (repeatDate) {
        startDate.setDate(repeatDate.getDate());
    }
    const newLinkPrev = settings.splitNightShift && (startDate < previousMidnight(displayDate, displayTz));
    return oldLinkPrev || newLinkPrev;
}
exports.shiftHasLinkPrev = shiftHasLinkPrev;
function shiftHasLinkNext(shift, settings, displayDate, displayTz, repeatDate = null) {
    const oldLinkNext = Boolean(shift.getLinkNext());
    let endDate = shift.getEndDate();
    if (repeatDate) {
        endDate.setDate(repeatDate.getDate());
    }
    const newLinkNext = settings.splitNightShift && (endDate > followingMidnight(displayDate, displayTz));
    return oldLinkNext || newLinkNext;
}
exports.shiftHasLinkNext = shiftHasLinkNext;
