"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = (n) => ((0, vue_2.pushScopeId)("data-v-76249f36"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = { class: "date-nav-cont" };
const _hoisted_2 = { class: "role-date-range oplus-gray-text" };
const vue_3 = require("vue");
const common_1 = require("../../common/common");
// Define the props with types
exports.default = (0, vue_1.defineComponent)({
    __name: 'RequiredCalendarNav',
    props: {
        selectedDate: {
            type: Date,
            default: new Date(),
        },
    },
    emits: ["emit-selected-date"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        // Define the emit event with a specific type
        const emits = __emit;
        // Computed value to get the selected month
        const selectedMonth = (0, vue_3.computed)(() => {
            return props.selectedDate.getMonth();
        });
        // Computed value to get the selected year
        const selectedYear = (0, vue_3.computed)(() => {
            return props.selectedDate.getFullYear();
        });
        // Computed value to get the date range as a string
        const dateRange = (0, vue_3.computed)(() => {
            const start = new Date(selectedYear.value, selectedMonth.value, 1);
            const end = new Date(selectedYear.value, selectedMonth.value + 1, 0);
            return `${(0, common_1.jpDateString)(start, "Asia/Tokyo", false)} ~ ${(0, common_1.jpDateString)(end, "Asia/Tokyo", false)}`;
        });
        // Helper function to construct a new date based on the selected year and month
        function constructNewDate() {
            return new Date(selectedYear.value, selectedMonth.value, 1);
        }
        // Emit the previous month’s date
        function handlePrevDateChange() {
            const newDate = constructNewDate();
            newDate.setMonth(newDate.getMonth() - 1);
            emits("emit-selected-date", newDate);
        }
        // Emit the current date (now)
        function handleNowDateChange() {
            emits("emit-selected-date", new Date());
        }
        // Emit the next month’s date
        function handleNextDateChange() {
            const newDate = constructNewDate();
            newDate.setMonth(newDate.getMonth() + 1);
            emits("emit-selected-date", newDate);
        }
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", { class: "date-nav-wrapper" }, [
                    (0, vue_2.createElementVNode)("button", {
                        class: "oplus-btn",
                        onClick: handlePrevDateChange
                    }, "＜"),
                    (0, vue_2.createElementVNode)("button", {
                        class: "oplus-btn",
                        onClick: handleNowDateChange
                    }, "今日"),
                    (0, vue_2.createElementVNode)("button", {
                        class: "oplus-btn",
                        onClick: handleNextDateChange
                    }, "＞")
                ]),
                (0, vue_2.createElementVNode)("span", _hoisted_2, (0, vue_2.toDisplayString)(dateRange.value), 1 /* TEXT */)
            ]));
        };
    }
});
