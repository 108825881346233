"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AShift = void 0;
class AShift {
    // The original id from database.
    id;
    // feId is FE only id that is relied on for repeat shifts and other special shift types that make copies of themselves but originally offer only a single id.
    feId = "";
    type;
    roleId = 0;
    roleName = "";
    hideTo = false;
    hideFrom = false;
    rest = 0;
    constructor(id, type, options) {
        this.id = id;
        this.type = type;
        if (options?.feId) {
            this.feId = options.feId;
        }
        if (options?.roleId) {
            this.roleId = options.roleId;
        }
        if (options?.roleName) {
            this.roleName = options.roleName;
        }
        if (options?.hideTo) {
            this.hideTo = options.hideTo;
        }
        if (options?.hideFrom) {
            this.hideFrom = options.hideFrom;
        }
        if (options?.rest) {
            this.rest = options.rest;
        }
    }
    // Only for use with communication with BE
    getOriginalId() {
        return this.id;
    }
    // For FE use
    getId() {
        if (this.feId) {
            return this.feId;
        }
        return this.id.toString();
    }
    getStatsId() {
        if (this.feId) {
            return this.feId;
        }
        return this.getId();
    }
    getType() {
        return this.type;
    }
    getRest() {
        return this.rest;
    }
    isHideTo() {
        return this.hideTo;
    }
    isHideFrom() {
        return this.hideFrom;
    }
    getRoleId() {
        if (this.roleId == 0) {
            return "";
        }
        return this.roleId.toString();
    }
    getOriginalRoleId() {
        return this.roleId;
    }
    getRoleName() {
        return this.roleName;
    }
    isRepeatShift() {
        return false;
    }
    isRepeatShiftTemplate() {
        return false;
    }
    isStandardShift() {
        return false;
    }
    isMorningShift() {
        return false;
    }
    isNightShift() {
        return false;
    }
    isShiftTemplate() {
        return false;
    }
    isSection() {
        return false;
    }
    isClone(branchId) {
        return false;
    }
    isLinkedShift() {
        return false;
    }
    isMergedShift() {
        return false;
    }
}
exports.AShift = AShift;
