<template>
  <svg version="1.1" class="infinity-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" x="0px" y="0px"
    viewBox="0 0 19 16" style="enable-background:new 0 0 19 19;" xml:space="preserve">
    <path fill="currentColor" d="M14.6,5.27c-0.01,0-0.01,0-0.02,0c-1.11,0-2.12,0.43-2.88,1.14L11.51,6.6l-1.99,2l-2.1-2.1
    C6.66,5.74,5.61,5.27,4.45,5.27H4.42c-2.31,0.02-4.18,1.9-4.18,4.21s1.87,4.19,4.18,4.21h0.03c1.11,0,2.12-0.43,2.87-1.14
    l2.19-2.19l1.93,1.93l0.34,0.33c0.75,0.67,1.73,1.07,2.8,1.07h0.03c2.31-0.02,4.18-1.9,4.18-4.21S16.91,5.28,14.6,5.27z
    M6.54,11.57C6,12.11,5.26,12.44,4.45,12.44c-1.63,0-2.96-1.33-2.96-2.96s1.33-2.96,2.96-2.96c0.81,0,1.55,0.33,2.09,0.87
    l2.09,2.09L6.54,11.57z M14.58,12.44c-0.82,0-1.56-0.33-2.09-0.87L10.4,9.48l2.09-2.09c0.53-0.54,1.27-0.87,2.09-0.87
    c1.63,0,2.96,1.33,2.96,2.96S16.21,12.44,14.58,12.44z"/>
  </svg>
</template>
