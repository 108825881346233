"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cutPasteSummaryCell = exports.copyPasteSummaryCell = void 0;
async function copyPasteSummaryCell(activeUserId, data) {
    try {
        const url = `/dashboard/${activeUserId}/summary/copy_and_paste`;
        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(data)
        });
        return response.json();
    }
    catch (err) {
        throw err;
    }
}
exports.copyPasteSummaryCell = copyPasteSummaryCell;
async function cutPasteSummaryCell(activeUserId, data) {
    try {
        const url = `/dashboard/${activeUserId}/summary/cut_and_paste`;
        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(data)
        });
        return response.json();
    }
    catch (err) {
        throw err;
    }
}
exports.cutPasteSummaryCell = cutPasteSummaryCell;
