"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableDate = void 0;
class TableDate {
    date;
    end;
    constructor(date, end) {
        this.date = new Date(date);
        this.end = new Date(end);
    }
    index() {
        return this.date.toISOString();
    }
    getDate() {
        return new Date(this.date);
    }
    getEnd() {
        return new Date(this.end);
    }
}
exports.TableDate = TableDate;
