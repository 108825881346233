"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tokenTypes = void 0;
exports.tokenTypes = Object.freeze({
    ADD: "+",
    SUB: "-",
    MULTI: "*",
    DIV: "/",
    NUM: "number",
    KEY: "keyword",
    OPAREN: "(",
    CPAREN: ")",
    END: ";",
    PERIOD: ".",
});
