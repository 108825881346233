"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.patchRoleSectionTemplates = exports.postRoleSectionTemplates = exports.getRoleSectionTemplates = void 0;
const roleSectionTemplateParser_1 = require("../legacy/parser/roleSectionTemplateParser");
async function getRoleSectionTemplates(userId, payload) {
    const searchParam = new URLSearchParams();
    if (payload.branchId) {
        searchParam.set("c", payload.branchId);
    }
    if (payload.roleSectionId) {
        searchParam.set("sectionID", payload.roleSectionId.toString());
    }
    if (payload.startDate) {
        searchParam.set("startDate", payload.startDate);
    }
    if (payload.endDate) {
        searchParam.set("endDate", payload.endDate);
    }
    const requestURL = `/dashboard/${userId}/role_section_template?${searchParam}`;
    try {
        const response = await fetch(requestURL, {
            method: "GET",
        });
        const data = await response.json();
        const feData = [];
        if (data.success) {
            data.roleSectionTemplates.forEach((d) => {
                const parsedData = (0, roleSectionTemplateParser_1.parseFromRaw)(d);
                feData.push(parsedData);
            });
        }
        return feData;
    }
    catch (err) {
        throw err;
    }
}
exports.getRoleSectionTemplates = getRoleSectionTemplates;
async function postRoleSectionTemplates(userId, payload) {
    const requestURL = `/dashboard/${userId}/role_section_template?c=${payload.branchId}`;
    try {
        const response = await fetch(requestURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload),
        });
        const data = await response.json();
        return (0, roleSectionTemplateParser_1.parseFromRaw)(data.createdTemplate);
    }
    catch (err) {
        throw err;
    }
}
exports.postRoleSectionTemplates = postRoleSectionTemplates;
async function patchRoleSectionTemplates(userId, payload) {
    const requestURL = `/dashboard/${userId}/role_section_template/${payload.templateId}?c=${payload.branchId}`;
    try {
        const response = await fetch(requestURL, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload),
        });
        const data = await response.json();
        return (0, roleSectionTemplateParser_1.parseFromRaw)(data.updatedTemplate);
    }
    catch (err) {
        throw err;
    }
}
exports.patchRoleSectionTemplates = patchRoleSectionTemplates;
