"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubRole = void 0;
const roleWithRelation_1 = require("./roleWithRelation");
class SubRole extends roleWithRelation_1.RoleWithRelation {
    mainRoleId = 0;
    constructor(mainRoleId, data, optional) {
        super(data, optional);
        this.mainRoleId = mainRoleId;
    }
    setMainRole(id) {
        this.mainRoleId = id;
    }
    getMainRoleId() {
        if (this.mainRoleId == 0) {
            return "";
        }
        return this.mainRoleId.toString();
    }
    isOrRole() {
        return true;
    }
    isStandardRole() {
        return false;
    }
}
exports.SubRole = SubRole;
