"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiError = void 0;
class ApiError extends Error {
    detail = "";
    statusCode = 0;
    constructor(message, opt) {
        super(message);
        if (opt?.detail) {
            this.detail = opt?.detail;
        }
        if (opt?.statusCode) {
            this.statusCode = opt?.statusCode;
        }
    }
    isEqualTo(otherError) {
        return otherError.message === this.message;
    }
}
exports.ApiError = ApiError;
