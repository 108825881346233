"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSlotNotification = exports.getBoardNotifications = void 0;
const notification_1 = require("../model/notification");
const notificationType_1 = require("../type/notificationType");
const notificationParser_1 = require("../parser/notificationParser");
async function getBoardNotifications(userId, activeBranchId, payload) {
    try {
        const response = await fetch(`/dashboard/${userId}/board/notifications?from=${payload.from}&c=${activeBranchId}`);
        if (!response.ok) {
            throw new Error(`Failed to fetch board notifications`);
        }
        const data = await response.json();
        if (data && data.length > 0) {
            data.forEach((notification) => {
                if (notification.UserID !== userId && notification.SeenBy.indexOf(userId) === -1) {
                    const notificationData = (0, notificationParser_1.parseBoardNotification)(notification);
                    const notificationToAdd = new notification_1.Notification({ time: notification.Date, type: notificationType_1.NotificationType.BoardNotification, data: notificationData });
                    payload.notificationStore.value.add(notificationToAdd);
                }
            });
        }
    }
    catch (err) {
        alert(err);
    }
}
exports.getBoardNotifications = getBoardNotifications;
async function getSlotNotification(userId, activeBranchId, payload) {
    try {
        const response = await fetch(`/dashboard/${userId}/summary/notifications?type=6&from=${payload.from}&c=${activeBranchId}`);
        if (!response.ok) {
            throw new Error(`Failed to fetch summary notifications`);
        }
        const data = await response.json();
        if (data && data.length > 0) {
            data.forEach((log) => {
                if (log.Type !== 0 && log.Type !== 6) {
                    return;
                }
                if (log.ReferenceId != userId &&
                    (!payload.hasSummaryPermission || log.SlotUserId === userId) &&
                    log.SeenBy.indexOf(userId) === -1) {
                    const notificationData = (0, notificationParser_1.parseSlotNotification)(log);
                    const notificationToAdd = new notification_1.Notification({ time: log.Date, type: notificationType_1.NotificationType.SlotNotification, data: notificationData });
                    payload.notificationStore.value.add(notificationToAdd);
                }
            });
        }
    }
    catch (err) {
        alert(err);
    }
}
exports.getSlotNotification = getSlotNotification;
