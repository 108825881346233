"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Notification = void 0;
class Notification {
    time;
    type;
    data;
    constructor(required, optional) {
        this.time = required.time;
        this.type = required.type;
        this.data = required.data;
    }
    // Only for use with communication with BE
    getOriginalId() {
        return this.data.id;
    }
    // For FE use
    getId() {
        return this.data.id.toString();
    }
}
exports.Notification = Notification;
