"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortableOptions = void 0;
const SortableElements = {
    HANDLE: '.handle',
    DRAGGABLE: 'tbody',
};
const SortableGroups = {
    TABLE_GROUP: 'table-group',
};
exports.sortableOptions = {
    animation: 150,
    handle: SortableElements.HANDLE,
    draggable: SortableElements.DRAGGABLE,
    group: {
        name: SortableGroups.TABLE_GROUP,
        pull: false,
        put: false,
    },
};
