<script>
// Note: This the component that wraps the modal inorder to umount it, if new modal is added:
// Import the Modal component here then add it in 'components' option, also make sure to add 'name' option in the added component,
// then pass the name props here
import RolesListModal from './RolesListModal.vue';
import TabooPatternModal from './TabooPatternModal.vue';

export default {
  props: {
    currentComponent: String,
  },
  data() {
    return {
      isVisible: false,
    }
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    }
  },
  components: {
    RolesListModal,
    TabooPatternModal,
  },
}
</script>
<template>
  <component v-if="isVisible" :is="currentComponent" @emit-close-modal="closeModal"></component>
</template>
