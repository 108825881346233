"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "role-section-template-tab" };
const _hoisted_2 = ["onClick"];
const _hoisted_3 = { class: "role-section-tab-text" };
const _hoisted_4 = { class: "tab-underline" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'RoleSectionTabs',
    props: {
        tabItemTexts: {
            type: Array,
            required: true,
        },
        activeTabIndex: {
            type: Number,
            default: 0,
            required: true,
        }
    },
    emits: ["emit-tab-index"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emits = __emit;
        const activeClass = (index) => {
            index === props.activeTabIndex ? "active" : "";
        };
        function handleTabClick(index) {
            emits("emit-tab-index", index);
        }
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(__props.tabItemTexts, (text, index) => {
                    return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", {
                        class: (0, vue_2.normalizeClass)(["role-section-tab-item", activeClass(index)]),
                        onClick: ($event) => (handleTabClick(index))
                    }, [
                        (0, vue_2.createElementVNode)("span", _hoisted_3, (0, vue_2.toDisplayString)(text), 1 /* TEXT */),
                        (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("span", _hoisted_4, null, 512 /* NEED_PATCH */), [
                            [vue_2.vShow, index === props.activeTabIndex]
                        ])
                    ], 10 /* CLASS, PROPS */, _hoisted_2));
                }), 256 /* UNKEYED_FRAGMENT */))
            ]));
        };
    }
});
