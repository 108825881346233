"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.summaryUndoDwsAuto = exports.summaryDwsAuto = void 0;
async function summaryDwsAuto(userId, activeBranchId, payload) {
    const requestURL = `/dashboard/${userId}/summary/dws_auto?c=${activeBranchId}`;
    try {
        const response = await fetch(requestURL, {
            method: 'POST',
            body: JSON.stringify(payload),
        });
        return response.json();
    }
    catch (err) {
        throw err;
    }
}
exports.summaryDwsAuto = summaryDwsAuto;
async function summaryUndoDwsAuto(userId, activeBranchId, payload) {
    const requestURL = `/dashboard/${userId}/summary/dws_auto/undo?c=${activeBranchId}`;
    try {
        const response = await fetch(requestURL, {
            method: 'POST',
            body: JSON.stringify(payload),
        });
        return response.json();
    }
    catch (err) {
        throw err;
    }
}
exports.summaryUndoDwsAuto = summaryUndoDwsAuto;
