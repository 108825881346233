"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const alertDescriptions = Object.freeze({
    1: "業務の配置スタッフ",
    2: "連勤アラート",
    3: "月最大出勤数",
    4: "月休み希望数",
    5: "NG組み合わせ",
    6: "NGパターン",
});
exports.default = alertDescriptions;
