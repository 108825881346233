<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 85 85"  width="16px" height="16px" xml:space="preserve">
    <path  d="M62.68,13.87H21.17c-6.3,0-11.42,5.12-11.42,11.42v42.54c0,7.95,4.92,8.57,6.43,8.57
      c3.92,0,6.28-2.85,8.37-5.36c2.04-2.45,3.96-4.77,7.16-4.77h30.98c6.3,0,11.42-5.12,11.42-11.42V25.29
      C74.1,18.99,68.97,13.87,62.68,13.87z M71.33,54.84c0,4.77-3.88,8.65-8.65,8.65H31.7c-4.5,0-7.04,3.07-9.29,5.78
      c-1.94,2.34-3.61,4.36-6.23,4.36c-3.18,0-3.66-3.63-3.66-5.8V25.29c0-4.77,3.88-8.65,8.65-8.65h41.51c4.77,0,8.65,3.88,8.65,8.65
      V54.84z M52.86,34.99c0,0.76-0.62,1.38-1.38,1.38h-8.21v8.21c0,0.76-0.62,1.38-1.38,1.38s-1.38-0.62-1.38-1.38v-8.21h-8.21
      c-0.76,0-1.38-0.62-1.38-1.38c0-0.76,0.62-1.38,1.38-1.38h8.21V25.4c0-0.76,0.62-1.38,1.38-1.38s1.38,0.62,1.38,1.38v8.21h8.21
      C52.24,33.61,52.86,34.23,52.86,34.99z M51.48,55.8H32.28c-0.76,0-1.38-0.62-1.38-1.38c0-0.76,0.62-1.38,1.38-1.38h19.19
      c0.76,0,1.38,0.62,1.38,1.38C52.86,55.18,52.24,55.8,51.48,55.8z"/>
  </svg>
</template>
