"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Role = void 0;
class Role {
    id;
    wage;
    name;
    startHour;
    startMinute;
    endHour;
    endMinute;
    employees;
    timezone;
    roleSections = [];
    isRoleSection;
    rest;
    color;
    purpose;
    isGroupRole;
    slotType;
    isNightShift;
    isHiddenInShiftPage = false;
    isDisabledWhenFull = false;
    showCount;
    isRestSection;
    // Placeholder fields, currently not in use:
    // dayNumbers: number[];
    // description: string;
    // employeePriority: number;
    // evenly: boolean;
    // kotHolidayType: number;
    // nextDayOff: boolean;
    // sections: Sections[];
    // showStaffCount: boolean;
    // type: number;
    // workEvenly: boolean;
    constructor(data, optional) {
        this.id = data.id;
        this.name = data.name;
        this.timezone = data.timezone;
        this.startHour = optional?.startHour || 0;
        this.startMinute = optional?.startMinute || 0;
        this.endHour = optional?.endHour || 24;
        this.endMinute = optional?.endMinute || 0;
        this.wage = optional?.wage || 0;
        this.color = optional?.color;
        this.purpose = optional?.purpose;
        this.rest = optional?.rest || 0;
        this.roleSections = optional?.roleSections || [];
        this.isRoleSection = optional?.isRoleSection;
        this.slotType = optional?.slotType || 0;
        this.isGroupRole = optional?.isGroupRole;
        this.isNightShift = optional?.isNightShift;
        this.employees = optional?.employees || [];
        this.isHiddenInShiftPage = optional?.isHiddenInShiftPage || false;
        this.isDisabledWhenFull = optional?.isDisabledWhenFull || false;
        this.showCount = optional?.showCount;
        this.isRestSection = optional?.isRestSection;
        // Placeholder fields, currently not in use:
        // this.dayNumbers = data.dayNumbers;
        // this.description = data.description;
        // this.employeePriority = data.employeePriority;
        // this.evenly = data.evenly;
        // this.kotHolidayType = data.kotHolidayType;
        // this.nextDayOff = data.nextDayOff;
        // this.sections = data.sections;
        // this.showStaffCount = data.showStaffCount;
        // this.type = data.type;
        // this.workEvenly = data.workEvenly;
    }
    // Only for use with communication with BE
    getOriginalId() {
        return this.id;
    }
    // For FE use
    getId() {
        return this.id.toString();
    }
    getName() {
        return this.name;
    }
    getWage() {
        return this.wage || 0;
    }
    index() {
        return this.getId();
    }
    isStandardRole() {
        return true;
    }
    isMainRole() {
        return false;
    }
    isOrRole() {
        return false;
    }
    getRoleSectionIds() {
        return this.roleSections;
    }
    getStartHour() {
        return this.startHour;
    }
    getStartMinute() {
        return this.startMinute;
    }
    getEndHour() {
        return this.endHour;
    }
    getEndMinute() {
        return this.endMinute;
    }
    getJoinedEmployees() {
        return this.employees;
    }
}
exports.Role = Role;
