"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseFromShiftRaw = exports.parseFromRaw = void 0;
const branchSettings_1 = require("../../model/v1/branchSettings");
function parseFromRaw(rawSettings) {
    const req = {
        timeZone: rawSettings.TimeZone,
    };
    const opt = {
        requestOff: rawSettings.RequestOff,
        roleRequestOff: rawSettings.RoleRequestOff,
        shiftHalfPTOText: rawSettings.ShiftHalfPTOText,
        shiftOffText: rawSettings.ShiftOffText,
        shiftPTOText: rawSettings.ShiftPTOText,
        shiftStaffOffText: rawSettings.ShiftStaffOffText,
        shiftWorkText: rawSettings.ShiftWorkText,
        showRoleTimes: rawSettings.ShowRoleTimes,
        splitNightShift: rawSettings.SplitNightShift,
        stackedRequest: rawSettings.StackRequestSlots,
        yearStart: rawSettings.StartOfYear,
        weekStart: rawSettings.StartOfWeek,
        monthStart: rawSettings.StartOfMonth,
        summaryDayStart: rawSettings.SummaryDayStart,
        summaryDayEnd: rawSettings.SummaryDayEnd,
        shiftLockDate: rawSettings.ShiftLockDate,
        closedWeekdays: rawSettings.ClosedWeekdays,
    };
    const settings = new branchSettings_1.BranchSettings(req, opt);
    return settings;
}
exports.parseFromRaw = parseFromRaw;
function parseFromShiftRaw(rawSettings) {
    const req = {
        timeZone: rawSettings.TimeZone,
    };
    const opt = {
        requestOff: rawSettings.RequestOff,
        roleRequestOff: rawSettings.RoleRequestOff,
        shiftHalfPTOText: rawSettings.ShiftHalfPTOText,
        shiftOffText: rawSettings.ShiftOffText,
        shiftPTOText: rawSettings.ShiftPTOText,
        shiftStaffOffText: rawSettings.ShiftStaffOffText,
        shiftWorkText: rawSettings.ShiftWorkText,
        showRoleTimes: rawSettings.ShowRoleTimes,
        splitNightShift: rawSettings.SplitNightShift,
        stackedRequest: rawSettings.StackRequestSlots,
        yearStart: rawSettings.StartOfYear,
        weekStart: rawSettings.StartOfWeek,
        monthStart: rawSettings.StartOfMonth,
        summaryDayStart: rawSettings.DayViewFrom,
        summaryDayEnd: rawSettings.DayViewTo,
        shiftLockDate: rawSettings.ShiftLockDate,
        closedWeekdays: rawSettings.ClosedWeekdays,
    };
    const settings = new branchSettings_1.BranchSettings(req, opt);
    return settings;
}
exports.parseFromShiftRaw = parseFromShiftRaw;
