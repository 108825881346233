"use strict";
// Plan interface methods
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeTableForPlanFunction = exports.evaluatePlanFunction = void 0;
const oplusLang_1 = require("../../oplusLang/v1/oplusLang");
function evaluatePlanFunction(functionBody, options) {
    let computedValue;
    const errors = [];
    const { tokens, errors: e } = (0, oplusLang_1.parse)(functionBody);
    errors.push(...e);
    const requiredKeywords = (0, oplusLang_1.getKeywords)(tokens);
    const requiredParams = [];
    const requiredFunctions = [];
    requiredKeywords.forEach(s => {
        if (!options?.functionStore) {
            errors.push(new Error('insufficient options to perform query'));
            return;
        }
        const pf = options?.functionStore?.query({
            variableName: s,
        });
        if (pf.length) {
            requiredFunctions.push(...pf);
        }
        else {
            // Don't have required plan function
            // TODO: refactor localization out of this package
            errors.push(new Error(`必要なカスタム計算が確認できません。 ${s}`));
            return;
        }
    });
    requiredFunctions.forEach(f => {
        if (!options?.valueStore || !options.startDateGte || !options.endDateLte) {
            errors.push(new Error('insufficient options to perform query'));
            return;
        }
        const pv = options.valueStore.query({
            variableId: f.getId(),
            startDateGte: options.startDateGte,
            endDateLte: options.endDateLte,
            periodLength: options.endDateLte.valueOf() - options.startDateGte.valueOf(),
        });
        if (pv.length) {
            requiredParams.push(...pv);
        }
        else {
            // Don't have required value.
            // TODO: refactor localization out of this package
            errors.push(new Error(`必要な情報が確認できません。 ${f.getVariableName()}`));
            return;
        }
    });
    if (!errors.length) {
        try {
            const table = makeTableForPlanFunction(requiredParams, requiredFunctions);
            computedValue = (0, oplusLang_1.evaluate)(functionBody, table);
        }
        catch (e) {
            if (e instanceof Error) {
                errors.push(e);
            }
        }
    }
    return { value: computedValue, errors };
}
exports.evaluatePlanFunction = evaluatePlanFunction;
function makeTableForPlanFunction(requiredParams, requiredFunctions) {
    const table = {};
    const functionMap = {};
    requiredFunctions.forEach(v => {
        functionMap[v.getId()] = v;
    });
    requiredParams.forEach(p => {
        const v = functionMap[p.getVariableId()];
        if (!v) {
            throw new Error("cannot find variable");
        }
        const n = p.getNumValue();
        if (n === undefined) {
            throw new Error('cannot use a non-computed value');
        }
        table[v.getVariableName()] = n;
    });
    return table;
}
exports.makeTableForPlanFunction = makeTableForPlanFunction;
