"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationStore = void 0;
class NotificationStore {
    data;
    constructor() {
        this.data = {};
    }
    add(n) {
        return this.data[n.getId()] = n;
    }
    byId(id) {
        return this.data[id];
    }
    query(options) {
        let out = Object.values(this.data);
        return out;
    }
    delete(id) {
        delete this.data[id];
    }
    deleteAll() {
        this.data = {};
    }
}
exports.NotificationStore = NotificationStore;
