async function passwordRequest(url, payload) {
  if (!url) {
    throw new Error("Invalid URL");
  }

  const data = JSON.stringify(payload)
  return $.post(url, data)
          .done((response) => {
            return response;
          })
          .fail((err) => {
            throw err;
          })
}

export async function setPasswordRequest(activeUserId, payload) {
  const url = `/dashboard/${activeUserId}/password`;
  return await passwordRequest(url, payload);
}

export async function setOthersPasswordRequest(activeUserId, otherUserId, payload) {
  const url = `/dashboard/${activeUserId}/password/${otherUserId}`;
  return await passwordRequest(url, payload);
}

export async function loginRequest(payload) {
  const url = `/login/${payload.UserID}/password`;
  return await passwordRequest(url, payload);
}
