"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmploymentShiftTableStatsColumn = void 0;
const shiftColumnStats_1 = require("../stats/shiftColumnStats");
const tableColumn_1 = require("../table/v1/tableColumn");
class EmploymentShiftTableStatsColumn extends tableColumn_1.TableColumn {
    data;
    constructor(y) {
        super(y);
        this.data = new shiftColumnStats_1.ShiftColumnStats();
    }
    getData() {
        return this.data;
    }
    setData(d) {
        this.data = d;
    }
}
exports.EmploymentShiftTableStatsColumn = EmploymentShiftTableStatsColumn;
