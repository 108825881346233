// GET
export async function getTabooPatternsRequest() {
  return $.get(DashURL("taboo_roles"))
          .done((response) => {
            return response;
          })
          .fail((error) => {
            throw error;
          })
}
// POST
export async function createTabooPatternRequest(payload) {
  const data = {
    mainRoleID: payload.roleID,
    name: payload.patternName,
    fullPattern: payload.isFullPattern
  }
  return $.post(DashURL("taboo_roles"), JSON.stringify(data))
          .done((response) => {
            return response;
          })
          .fail((error) => {
            throw error;
          })
}

// DELETE
export async function deleteTabooPatternRequest(tabooPatternID = 0) {
  return $.delete(DashURL("taboo_roles/" + tabooPatternID))
          .done((response) => {
            return response;
          })
          .fail((error) => {
            throw error;
          })
}

// PUT (Add or Delete Role inside pattern)

// Base request Function:
 export async function updateRolePatternRequest(payload) {
  const data = {
    tabooPatternID: payload.tabooPatternID,
    newName: payload.patternName
  }

  if (payload?.addedRoleID) {
    data["addRoleID"] = payload.addedRoleID;
  }

  if (payload?.newMainRoleID) {
    data["newMainRoleID"] = payload.newMainRoleID
  }

  if (payload?.deletedRoleID) {
    data["deleteRoleID"] = payload.deletedRoleID;
  }

  if (payload?.deleteRoleIndex > 0) {
    data["deleteRoleIndex"] = payload.deleteRoleIndex;
  }

  if (payload?.fullPattern !== undefined) {
    data["fullPattern"] = payload.fullPattern;
  }
  return $.put(DashURL("taboo_roles/" + data.tabooPatternID), JSON.stringify(data))
          .done((response) => {
            return response;
          })
          .fail((error) => {
            throw error;
          })
}

// Add Role
export async function addRolePatternRequest(tabooPatternID, addedRoleID, isFullPattern) {
  const payload = {
    tabooPatternID: tabooPatternID,
    addedRoleID: addedRoleID,
    fullPattern: isFullPattern,
  }
  return await updateRolePatternRequest(payload);
}

// Delete Role
export async function deleteRolePatternRequest(tabooPatternID, deletedRoleID, deleteRoleIndex) {
  const payload = {
    tabooPatternID: tabooPatternID,
    deletedRoleID: deletedRoleID,
    deleteRoleIndex: deleteRoleIndex,
  }
  return await updateRolePatternRequest(payload);
}

// Edit/Replace Role
export async function editMainRolePatternRequest(tabooPatternID, newMainRoleID, isFullPattern) {
  const payload = {
    tabooPatternID: tabooPatternID,
    newMainRoleID: newMainRoleID,
    fullPattern: isFullPattern,
  }
  return await updateRolePatternRequest(payload);
}

// Edit Pattern name
export async function editRolePatternRequest(tabooPatternID, patternName, isFullPattern) {
  const payload = {
    tabooPatternID: tabooPatternID,
    patternName: patternName,
    fullPattern: isFullPattern,
  }
  return await updateRolePatternRequest(payload);
}
