"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanValueStore = void 0;
class PlanValueStore {
    data = {};
    add(item) {
        this.data[item.getId()] = item;
    }
    byId(id) {
        return this.data[id];
    }
    query(options) {
        let out = Object.values(this.data);
        if (options.variableId !== undefined) {
            out = out.filter(p => p.getVariableId() == options.variableId);
        }
        if (options.startDateGte !== undefined) {
            const startDateGte = options.startDateGte.valueOf();
            out = out.filter(p => p.getStartDate().valueOf() >= startDateGte);
        }
        if (options.startDateEq !== undefined) {
            const startDateEq = options.startDateEq.valueOf();
            out = out.filter(p => p.getStartDate().valueOf() === startDateEq);
        }
        if (options.endDateLte !== undefined) {
            const endDateLt = options.endDateLte.valueOf();
            out = out.filter(p => p.getEndDate().valueOf() <= endDateLt);
        }
        if (options.endDateEq !== undefined) {
            const endDateEq = options.endDateEq.valueOf();
            out = out.filter(p => p.getEndDate().valueOf() === endDateEq);
        }
        if (options.periodLength !== undefined) {
            out = out.filter(p => {
                const pLength = p.getEndDate().valueOf() - p.getStartDate().valueOf();
                return pLength === options.periodLength;
            });
        }
        return out;
    }
    delete(id) {
        if (!this.data[id]) {
            return;
        }
        delete this.data[id];
    }
}
exports.PlanValueStore = PlanValueStore;
