import ModalWrapper from '../vue/ModalWrapper.vue'
import AlertModal from '../vue/AlertModal.vue'
import CustomerSurveyModal from '../vue/CustomerSurveyModal.vue'
import ImportModal from '../vue/ImportModal.vue'
import LaborModal from '../vue/LaborModal.vue'
import TagRoleFilterModal from '../vue/TagRoleFilterModal.vue'
import AddGroupUserModal from '../vue/AddGroupUserModal.vue'
import PtoRequestModal from '../vue/PtoRequestModal.vue'
import KotSyncModal from '../vue/KotSyncModal.vue'
import PlanEquationModal from '../vue/PlanEquationModal.vue'
import AutoModal from '../vue/AutoModal.vue'
import TimecardModal from '../vue/TimecardModal.vue'
import PasswordModal from '../vue/components/PasswordModal.vue'
import { rolesImportSuccessCallback } from './roles.js'
import { createApp } from "vue";
import TimepickerModal from '../vue/TimepickerModal.vue'
import BranchModalFilter from '../vue/BranchFilterModal.vue'
import { refreshOnSuccess } from "../js/common.js"
import LogoutModal from '../vue/LogoutModal.vue'
import GroupEditModal from '../vue/GroupEditModal.vue'
import BranchEditModal from '../vue/BranchEditModal.vue'
import OcrDownloadModal from '../vue/OcrDownloadModal.vue'

// TODO: Move this to /js/enums/modal_types
export const MODAL_ID = Object.freeze({
  ALERT_CONFIRM: "#alert-confirm-modal",
  STAFF_IMPORT: "#staff-import-modal",
  ROLES_IMPORT: "#roles-import-modal",
  TIME_IMPORT: "#time-import-modal",
  PLAN_IMPORT: "#plan-import-modal",
  LABOR: "#labor-modal",
  TAG_ROLE_FILTER: "#tag-role-filter-modal",
  AUTO_MODAL: "#auto-modal",
  ADD_GROUP_USER: "#add-group-user-modal",
  PTO_REQUEST: "#pto-request-modal",
  KOT_SYNC: "#kot-sync-modal",
  REASON_FOR_CANCEL: "#reason-for-cancel-modal",
  PLAN_EQUATION: "#plan-equation-modal",
  TIMECARD_MODAL: "#timecard-vue-modal",
  ROLES_LIST_MODAL: "#roles-list-modal",
  TABOO_PATTERN_MODAL: "#taboo-pattern-modal",
  PASSWORD_MODAL: "#password-modal",
  TIMEPICKER_MODAL: "#timepicker-vue-modal",
  BRANCH_FILTER_MODAL: "#branch-filter-modal",
  GROUP_BRANCH_IMPORT: "#group-branch-import-modal",
  GROUP_EMPLOYEE_IMPORT: "#group-employee-import-modal",
  LOGOUT_CONFIRM: "#logout-confirm-modal",
  CUSTOMER_SURVEY: "#customer-satisfaction-survey-modal",
  GROUP_EDIT_MODAL: "#group-edit-modal",
  BRANCH_EDIT_MODAL: "#branch-edit-modal",
  OCR_DOWNLOAD_MODAL: "#ocr-download-modal",
})

// TODO: Move this to /js/enums/modal_types
// TODO: Apply to all current vue modals and change the names to PascalCase
export const MODAL_NAMES = Object.freeze({
  ALERT_CONFIRM: "alert-confirm-modal",
  STAFF_IMPORT: "staff-import-modal",
  ROLES_IMPORT: "roles-import-modal",
  TIME_IMPORT: "time-import-modal",
  PLAN_IMPORT: "plan-import-modal",
  LABOR: "labor-modal",
  TAG_ROLE_FILTER: "tag-role-filter-modal",
  AUTO_MODAL: "auto-modal",
  PTO_REQUEST: "pto-request-modal",
  ADD_GROUP_USER: "add-group-user-modal",
  PTO_REQUEST: "pto-request-modal",
  KOT_SYNC: "kot-sync-modal,",
  REASON_FOR_CANCEL: "reason-for-cancel-modal",
  PLAN_EQUATION: "plan-equation-modal",
  TIMECARD_MODAL: "timecard-vue-modal",
  TIMEPICKER_MODAL: "timepicker-vue-modal",
  GROUP_BRANCH_IMPORT: "group-branch-import-modal",
  GROUP_EMPLOYEE_IMPORT: "group-employee-import-modal",
  // Modal component names needs to be PascalCase in order to render:
  // https://vuejs.org/guide/components/registration
  ROLES_LIST_MODAL: "RolesListModal",
  TABOO_PATTERN_MODAL: "TabooPatternModal",
  LOGOUT_CONFIRM: "logout-confirm-modal",
})

// When adding new modal components:
// 1. Make sure to declare component's name, (name: ModalName)
// 2. add component inside ModalWrapper
// 3. createApp using ModalWrapper and pass the the component name as prop
// Optional: if you want to add props on your component, you can either use store or provide-inject method
function mountModalWrapper(mountPoint, currentComponent, modalProps = {}) {
  const app = createApp(ModalWrapper, {
    currentComponent: currentComponent,
  });
  app.config.unwrapInjectedRef = true;
  app.provide('modalProps', modalProps);
  window[mountPoint] = app.mount(mountPoint)
}

// **START** Using ModalWrapper
export function mountRolesListModal() {
  mountModalWrapper(MODAL_ID.ROLES_LIST_MODAL, MODAL_NAMES.ROLES_LIST_MODAL)
}

export function mountPatternModal() {
  mountModalWrapper(MODAL_ID.TABOO_PATTERN_MODAL, MODAL_NAMES.TABOO_PATTERN_MODAL)
}
// **END**

export function mountModalComponent(component, mountPoint, props) {
  const app = createApp(component, props);
  window[mountPoint] = app.mount(mountPoint);
}

export function mountAlertModal() {
  mountModalComponent(AlertModal, MODAL_ID.ALERT_CONFIRM);
}

export function mountTimecardModal() {
  mountModalComponent(TimecardModal, MODAL_ID.TIMECARD_MODAL);
}

export function mountCustomerSurveyModal() {
  mountModalComponent(CustomerSurveyModal, MODAL_ID.CUSTOMER_SURVEY);
}

export function mountLaborModal(props) {
  mountModalComponent(LaborModal, MODAL_ID.LABOR, {
    modalName: "labor",
    companyID: props.pipelineActiveCompany.ID,
    minVacationWeekly: props.pipelineActiveCompany.MinVacationWeekly,
    minVacationMonthly: props.pipelineActiveCompany.MinVacationMonthly,
  });
}

export function mountTagRoleFilterModal(props) {
  mountModalComponent(TagRoleFilterModal, MODAL_ID.TAG_ROLE_FILTER, {
    modalName: "tag-role-filter",
    companyID: props.pipelineActiveCompany.ID,
    tags: props.tags,
    roles: props.roles,
  });
  const tagRoleFilterModal = window["#tag-role-filter-modal"];
  $("#tag-role-filter-btn").on("click", () => {
    tagRoleFilterModal.openModal();
  })
}

export function mountAutoModal(props) {
  mountModalComponent(AutoModal, MODAL_ID.AUTO_MODAL, {
    roles: props.roles,
    timePeriodType: props.timePeriodType,
    tags: props.tags,
    activeDate: props.activeDate
  });
  const autoModal = window[MODAL_ID.AUTO_MODAL];
  $(`${MODAL_ID.AUTO_MODAL}-btn`).on("click", () => {
    autoModal.openModal();
  })
}

// Import modal utility function
function mountImportModal(options) {
  mountModalComponent(ImportModal, options.modalID, options.modalData);
  const importModal = window[options.modalID];
  $(options.buttonSelector).on("click", () => {
    importModal.openModal();
  });
}

export function mountStaffImportModal(comID) {
  mountImportModal({
    modalID: MODAL_ID.STAFF_IMPORT,
    buttonSelector: `${MODAL_ID.STAFF_IMPORT}-btn, ${MODAL_ID.STAFF_IMPORT}-2-btn`,
    modalData: {
      modalName:  MODAL_NAMES.STAFF_IMPORT,
      importURL: DashURL(`company/${comID}/bulk_import`),
      csvName: '',
      contentText: 'CSVファイルから複数のスタッフを登録出来ます。メールアドレスは必須となっております。なお、こちらの機能で編集する事も出来ます。',
      excelDownloadLink: 'https://storage.googleapis.com/opluswork.com/dashboard/%E3%82%B9%E3%82%BF%E3%83%83%E3%83%95%E7%AE%A1%E7%90%86%E3%82%A4%E3%83%B3%E3%83%9B%E3%82%9A%E3%83%BC%E3%83%88v3.xlsx',
      errorMessage: errors.STAFF.IMPORT,
      successCallback: (response) => {
        if (response.skippedEmails?.length) {
          alertModal.csvImportAlert(response.skippedEmails); // here it refreshes as well
        } else {
          refreshOnSuccess(response);
        }
      },
    }
  });
}

export function mountRoleImportModal(isGroupList) {
  let text = 'CSVファイルから業務の登録や必要人数の設定を行うことができます';
  if (isGroupList) {
    text = '下記サンプルの形式で作成したファイルを取り込んでください。(拠点から出力したファイルは取り込めません）'
  }
  mountImportModal({
    modalID: MODAL_ID.ROLES_IMPORT,
    buttonSelector:`${MODAL_ID.ROLES_IMPORT}-btn`,
    modalData: {
      modalName:  MODAL_NAMES.ROLES_IMPORT,
      importURL: DashURL("roles/bulk_import"),
      csvName: '業務',
      contentText: text,
      excelDownloadLink: DashURL("roles/bulk_import_header"),
      errorMessage: errors.ROLE.IMPORT,
      successCallback: (response) => {
        rolesImportSuccessCallback(response)
      }
    }
  });
}

export function mountTimeImportModal() {
  mountImportModal({
    modalID: MODAL_ID.TIME_IMPORT,
    buttonSelector:`${MODAL_ID.TIME_IMPORT}-btn`,
    modalData: {
      modalName:  MODAL_NAMES.TIME_IMPORT,
      importURL: "/api/v1/time_cards/bulk",
      csvName: 'タイムカード',
      contentText: 'CSVファイルから勤怠情報をインポートすることができます。',
      excelDownloadLink: "https://storage.googleapis.com/opluswork.com/dashboard/%E3%80%8C%E3%82%BF%E3%82%A4%E3%83%A0%E3%82%AB%E3%83%BC%E3%83%88%E3%82%99%E3%80%8D%E3%82%A4%E3%83%B3%E3%83%9B%E3%82%9A%E3%83%BC%E3%83%88v2.xlsx",
      errorMessage: errors.TIMECARD.IMPORT,
      importCSVKey: "timecards", // TODO: Enum
      successCallback: (response) => {
        if (response.success) {
          alertModal.alertSuccess("タイムカードのインポートに成功しました。", true);
        }
      }
    }
  });
}

export function mountPlanImportModal(sampleUrl) {
  mountImportModal({
    modalID: MODAL_ID.PLAN_IMPORT,
    buttonSelector: `${MODAL_ID.PLAN_IMPORT}-btn`,
    modalData: {
      modalName: MODAL_NAMES.PLAN_IMPORT,
      importURL: DashURL("plans/bulk-import"),
      csvName: '計画',
      contentText: 'CSVファイルから日、時間ごとの必要人数の設定や売上・メモの記入を行うことができます。',
      excelDownloadLink: sampleUrl,
      errorMessage: errors.PLAN.ACTION,
      successCallback: (response) => {
        refreshOnSuccess(response)
      }
    }
  });
}

export function mountGroupBranchImportModal() {
  mountImportModal({
    modalID: MODAL_ID.GROUP_BRANCH_IMPORT,
    buttonSelector: `${MODAL_ID.GROUP_BRANCH_IMPORT}-btn`,
    modalData: {
      modalName: MODAL_NAMES.GROUP_BRANCH_IMPORT,
      importURL: DashURL("company/csv_bulk_import"),
      csvName: '拠点',
      contentText: 'CSVファイルから複数の拠点登録を一括で行うことができます',
      excelDownloadLink: "https://storage.googleapis.com/opluswork.com/dashboard/%E6%8B%A0%E7%82%B9%E3%82%A4%E3%83%B3%E3%83%9B%E3%82%9A%E3%83%BC%E3%83%88%E3%82%B5%E3%83%B3%E3%83%95%E3%82%9A%E3%83%ABv2.xlsx",
      errorMessage: errors.IAM.ACTION,
      successCallback: (response) => {
        refreshOnSuccess(response)
      }
    }
  });
}

export function mountGroupEmployeeImportModal() {
  mountImportModal({
    modalID: MODAL_ID.GROUP_EMPLOYEE_IMPORT,
    buttonSelector: `${MODAL_ID.GROUP_EMPLOYEE_IMPORT}-btn`,
    modalData: {
      modalName: MODAL_NAMES.GROUP_EMPLOYEE_IMPORT,
      importURL: DashURL("employee/csv_bulk_import"),
      csvName: 'スタッフ',
      contentText: 'CSVファイルから複数のスタッフ登録を一括で行うことができます',
      excelDownloadLink: "https://storage.googleapis.com/opluswork.com/dashboard/%E3%82%B9%E3%82%BF%E3%83%83%E3%83%95%E3%82%A4%E3%83%B3%E3%83%9B%E3%82%9A%E3%83%BC%E3%83%88%E3%82%B5%E3%83%B3%E3%83%95%E3%82%9A%E3%83%ABv3.xlsx",
      errorMessage: errors.IAM.ACTION,
      successCallback: (response) => {
        refreshOnSuccess(response)
      }
    }
  });
}

export function mountAddGroupUserModal() {
  mountModalComponent(AddGroupUserModal, MODAL_ID.ADD_GROUP_USER);
  const addGroupUserModal = window[MODAL_ID.ADD_GROUP_USER];
  $(`${MODAL_ID.ADD_GROUP_USER}-btn`).on("click", () => {
    addGroupUserModal.openModal();
  })
}

export function mountPTORequestModal(props) {
  mountModalComponent(PtoRequestModal, MODAL_ID.PTO_REQUEST, {
    companyID: props.companyID,
    userID: props.userID,
  });
  const ptoRequest = window[MODAL_ID.PTO_REQUEST];
  $(`${MODAL_ID.PTO_REQUEST}-btn`).on("click", () => {
    ptoRequest.openModal();
  });
}

export function mountKotSyncModal() {
  mountModalComponent(KotSyncModal, MODAL_ID.KOT_SYNC);
  const kotSyncModal = window[MODAL_ID.KOT_SYNC];
  $(`${MODAL_ID.KOT_SYNC}-btn`).on("click", () => {
    kotSyncModal.openModal();
  })
}

export function mountPlanEquationModal(props) {
  mountModalComponent(PlanEquationModal, MODAL_ID.PLAN_EQUATION, {
    planEquationValue: props.planEquationValue
  });
  const planEqModal = window[MODAL_ID.PLAN_EQUATION];
  $(`${MODAL_ID.PLAN_EQUATION}-btn`).on("click", () => {
    planEqModal.openModal();
  })
}

export function mountTimepickerModal() {
  mountModalComponent(TimepickerModal, MODAL_ID.TIMEPICKER_MODAL)
}

export function mountPasswordModal(props) {
  mountModalComponent(PasswordModal, MODAL_ID.PASSWORD_MODAL, props);
}

export function mountBranchFilterModal(props) {
  mountModalComponent(BranchModalFilter, MODAL_ID.BRANCH_FILTER_MODAL, props);
  const branchFilter = window[MODAL_ID.BRANCH_FILTER_MODAL];
  $(`${MODAL_ID.BRANCH_FILTER_MODAL}-btn`).on("click", () => {
    branchFilter.openModal();
  });
}

export function mountLogoutModal() {
  mountModalComponent(LogoutModal, MODAL_ID.LOGOUT_CONFIRM);
}

export function mountGroupEditModal(props) {
  mountModalComponent(GroupEditModal, MODAL_ID.GROUP_EDIT_MODAL, {
    groupData: props.groupData,
  });
}

export function mountBranchEditModal(props) {
  mountModalComponent(BranchEditModal, MODAL_ID.BRANCH_EDIT_MODAL, {
    groupData: props.groupData,
    isOwner: props.isOwner,
    haveMultiPerm: props.haveMultiPerm,
  });
}

export function mountOcrDownloadModal() {
  mountModalComponent(OcrDownloadModal, MODAL_ID.OCR_DOWNLOAD_MODAL, {});
}
