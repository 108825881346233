<script>
  import Modal from './Modal.vue';
  import { roleFontColorStyle } from '../js/role_helpers';
  import { store as roleStore } from '../js/stores/role_store';
  import { store as tabooPatternStore } from '../js/stores/taboo_pattern_store';
  import { store as tabooPatternModalStore } from '../js/stores/taboo_pattern_modal_store';
  import { addRolePatternRequest, editMainRolePatternRequest } from '../js/pattern_requests';
  import { MODAL_NAMES } from '../js/enums/modal_types';
  import { ALERT_MESSAGE } from '../js/enums/form_alerts';

  export default {
    name: MODAL_NAMES.ROLES_LIST_MODAL,
    props: {
      includeRoleSections: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        roleModalSelectedId: 0,
        isLoading: false,
      }
    },
    methods: {
      closeModal() {
        return this.$emit("emit-close-modal", false)
      },
      onConfirm() {
        if (this.roleModalSelectedId == 0) {
          alertModal.alert(ALERT_MESSAGE.ROLE.NO_SELECT);
          return;
        }

        if (this.selectedTabooId) {
          if (this.isLoading) {
            return;
          }

          this.isLoading = true;
          if (tabooPatternModalStore.data.isEditRolePattern) {
            return this.updateMainRoleInPattern();
          }

          return this.addRoleToPattern();
        }
        tabooPatternModalStore.setRoleId(this.roleModalSelectedId);
        this.closeModal();
      },
      roleFontColor(role) {
        return roleFontColorStyle(role)
      },
      async updateMainRoleInPattern() {
        try {
          const response = await editMainRolePatternRequest(this.selectedTabooId, this.roleModalSelectedId, this.isFullPattern);
          const { tabooPatternId, newMainRole } = response;
          tabooPatternStore.updateRolePattern(tabooPatternId, newMainRole);
        } catch (err) {
          alertModal.alert(err);
        } finally {
          this.isLoading = false;
          this.closeModal();
        }
      },
      async addRoleToPattern() {
        try {
          const response = await addRolePatternRequest(this.selectedTabooId, this.roleModalSelectedId, this.isFullPattern);
          tabooPatternStore.addRoleToPattern(response.tabooPatternId, response.addedRoleId, response.isFullPattern);
        } catch (err) {
          alertModal.alert(err);
        } finally {
          this.isLoading = false;
          this.closeModal();
        }
      },
    },
    computed: {
      rolesMap() {
        return Object.values(roleStore.data.rolesMap).filter(r => {
          if (r.IsRoleSection === this.includeRoleSections) {
            return r;
          }
        });
      },
      isTabooPatternEdit() {
        return this.selectedTabooId && !this.selectedRoleId ? true : false;
      },
      isTabooRolePatternEdit() {
        return this.selectedTabooId && this.selectedRoleId ? true : false;
      },
      selectedTabooId() {
        return tabooPatternModalStore.data.selectedTabooId;
      },
      selectedRoleId() {
        return tabooPatternModalStore.data.selectedRoleId;
      },
      mainRoleId() {
        return tabooPatternStore.data.tabooPatternsMap[this.selectedTabooId].MainRoleID;
      },
      isFullPattern() {
        return tabooPatternStore.data.tabooPatternsMap[this.selectedTabooId].patternData.IsFullPattern;
      },
      loadingClass() {
        return this.isLoading ? "loading" : "";
      },
    },
    components: {
      Modal
    },
    mounted() {
      this.roleModalSelectedId = this.selectedRoleId;
    },
  }
</script>

<template>
  <Modal @closeModal="closeModal" :has-title-divider="true" :has-footer-divider="true">
    <template v-slot:header-title>
      業務を選択してください
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <div class="form-group">
          <label v-for="role in rolesMap" class="form-radio">
            <input v-model="roleModalSelectedId" :value="role.ID" type="radio" name="role">
            <i class="form-icon"></i>
            <span :style="roleFontColor(role)">{{ role.Name }}</span>
          </label>
        </div>
      </div>
    </template>
    <template v-slot:footer-btn>
      <button class="oplus-modal-btn oplus-alt-confirm-btn" :class="loadingClass" @click="onConfirm">確定</button>
    </template>
  </Modal>
</template>

<style>
  .content-wrapper {
    width: 250px;
    padding: 10px 0;
  }
</style>
