<script>
import Modal from './Modal.vue';

export default {
  props: {
    groupData: Object,
    isOwner: Boolean,
    haveMultiPerm: Boolean,
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      isError: false,
      selectedBranchData: this.getDefaultBranchData(),
      localGroupData: { ...this.groupData },
    }
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
      this.isError = false;
      this.selectedBranchData = this.getDefaultBranchData();
    },
    getDefaultBranchData() {
      return {
        id: '',
        name: '',
        address: '',
        integrations: {},
        externalId: '',
        canOverrideSettings: false,
      };
    },
    async onSubmit() {
      if (this.isLoading) {
        return;
      }
      if (!this.selectedBranchData.name) {
        this.isError = true;
        return;
      }
      if (!this.selectedBranchData.address) {
        this.isError = true;
        return;
      }

      this.isLoading = true;
      const data = new URLSearchParams();
      data.append("name", this.selectedBranchData.name);
      data.append("address", this.selectedBranchData.address);
      data.append("can-override-settings", this.selectedBranchData.canOverrideSettings);
      data.append("external-id", this.selectedBranchData.externalId ?? "");

      if (this.selectedBranchData.integrations.hasOwnProperty('kotDivision')) {
        data.append("kot-division", this.selectedBranchData.integrations.kotDivision);
      }
      if (this.selectedBranchData.integrations.hasOwnProperty('sfCompanyID')) {
        data.append("sf-company-id", this.selectedBranchData.integrations.sfCompanyID);
      }
      if (this.selectedBranchData.integrations.hasOwnProperty('aimCompanyCSV')) {
        data.append("aim-csv-id", this.selectedBranchData.integrations.aimCompanyCSV);
      }
      if (this.selectedBranchData.integrations.hasOwnProperty('ccbizmate')) {
        data.append("ccbizmate-id", this.selectedBranchData.integrations.ccbizmate);
      }
      if (this.selectedBranchData.integrations.hasOwnProperty('minajinSectionCode')) {
        data.append("minajin-section-code", this.selectedBranchData.integrations.minajinSectionCode);
      }

      try {
        let response;
        if (!this.selectedBranchData.id) {
          response = await fetch(DashURL("group/company"), {
            method: "POST",
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: data.toString(),
          });
        } else {
          response = await fetch(DashURL(`company/${this.selectedBranchData.id}`), {
            method: "PUT",
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: data.toString(),
          });
        }

        const json = await response.json();
        if (!response.ok || !json.success) {
          alertModal.alert("送信に失敗しました、再度お試しください。");
        } else {
          window.location.reload();
        }

      } catch (error) {
        alertModal.alert("送信に失敗しました、再度お試しください。");
      } finally {
        this.isLoading = false;
      }
    },
    updateSelectedBranchData(data) {
      this.selectedBranchData = {
        ...data,
        integrations: { ...data.integrations }
      };
    },
    canOpenModal() {
      if (!this.isOwner) {
        return false;
      }
      if (!this.haveMultiPerm && !this.selectedBranchData.id) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    if (!this.canOpenModal()) {
      this.isVisible = false;
    }
  },
  computed: {
    loadingClass() {
      return this.isLoading ? "loading" : "";
    },
    errorClass() {
      return this.isError ? "is-error" : "";
    },
    kotDivision: {
      get() {
        return this.selectedBranchData.integrations.kotDivision !== 0 ? this.selectedBranchData.integrations.kotDivision : '';
      },
      set(value) {
        this.selectedBranchData.integrations.kotDivision = value;
      }
    },
    externalId: {
      get() {
        return this.selectedBranchData.externalId || '';
      },
      set(value) {
        this.selectedBranchData.externalId = value;
      }
    },
    integrationFields() {
      return [
        { key: 'sfCompanyID', placeholder: 'セールスフォース連携', id: "sf-company-id" },
        { key: 'aimCompanyCSV', placeholder: 'Company連携用のID', id: "aim-csv-id" },
        { key: 'ccbizmate', placeholder: 'CC·BizMate勤務場所コード', id: "ccbizmate-id" },
        { key: 'minajinSectionCode', placeholder: 'ミナジン部署コード', id: "minajin-code" },
      ];
    },
    validIntegrationFields() {
      return this.integrationFields.filter(
        (integration) => this.selectedBranchData.integrations.hasOwnProperty(integration.key)
      );
    },
  },
  components: {
    Modal,
  },
}
</script>

<template>
  <Modal :modal-name="modalName" v-if="isVisible" :has-title-divider="true" :has-footer-divider="true" @closeModal="closeModal" @confirm="onConfirm">
    <template v-slot:header-title>
      <span class="branch-edit-header">
        {{ selectedBranchData.id ? '拠点編集' : '店舗作成' }}
      </span>
    </template>
    <template v-slot:content>
      <div class="content-wrapper">
        <form>
          <input class="form-input br-5 form-group" :class="errorClass" id="company-modal-name" type="text" placeholder="会社名" v-model="selectedBranchData.name" />
          <input class="form-input br-5 form-group" :class="errorClass"id="company-modal-address" type="text" placeholder="住所" v-model="selectedBranchData.address" />

          <div v-if="selectedBranchData.integrations.hasOwnProperty('kotDivision')" class="form-group">
            <input class="form-input br-5" id="company-modal-kot-division" type="text" placeholder="KOT所属コード" v-model="kotDivision">
          </div>

          <div v-for="integration in validIntegrationFields" :key="integration.key" class="form-group">
            <input
              class="form-input br-5"
              :id="`company-modal-${integration.id}`"
              type="text"
              :placeholder="integration.placeholder"
              v-model="selectedBranchData.integrations[integration.key]"
            />
          </div>

          <div v-if="selectedBranchData.id" class="form-group">
            <input class="form-input br-5" id="company-modal-external-id" type="text" placeholder="連携用ID" :disabled="!localGroupData.hasEditLinkIDPerm"  v-model="externalId">
          </div>

          <div v-if="selectedBranchData.id" class="form-group align-center flex-gap">
            <label class="oplus-form-switch">
              <input id="settings-override-input" type="checkbox" v-model="selectedBranchData.canOverrideSettings" />
              <i class="oplus-form-icon"></i>
            </label>
            設定の編集権限
          </div>
        </form>
      </div>
    </template>
    <template v-slot:footer-btn>
      <div class="footer-btn-container">
        <button id="staff-import-btn" class="oplus-btn oplus-confirm-btn" :class="loadingClass" @click="onSubmit">
          {{ selectedBranchData.id ? '編集' : '作成' }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
  .content-wrapper {
    width: 280px;
    padding: 1rem 0;
  }

  .oplus-btn {
    width: 100px;
    border-radius: 7px;
    background: #fff;
    height: 35px;
    cursor: pointer;
    border-width: 1px;
    text-decoration: none;
  }

  .oplus-btn:hover {
    color: #fff;
    border-width: 0.1px;
  }

  .oplus-confirm-btn {
    color: #52a3d5;
    border-color: #52a3d5;
  }

  .oplus-confirm-btn:hover {
    background: #52a3d5;
    border-color: #52a3d5;
  }

  .footer-btn-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .br-5 {
    border-radius: 5px;
  }

  .branch-edit-header {
    color: #8D99A0;
  }
</style>
